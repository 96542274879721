import { KeyboardArrowRight } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import type { DataStorePathGenerator } from "~/paths";
import { DataStoreLink } from "~/paths";

export interface RelatedResourceLinkProps {
  text: string;
  to: DataStorePathGenerator;
}

export function RelatedResourceLink({ text, to }: RelatedResourceLinkProps) {
  return (
    <Card>
      <CardActionArea component={DataStoreLink} to={to}>
        <CardContent
          sx={{
            py: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="p" color="primary">
            {text}
          </Typography>
          <KeyboardArrowRight fontSize="large" />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
