/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ObjectStore {
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  id: string;
  bucketName: string;
  accessKeyId: string | null;
  regionName: string | null;
  endpointUrl: string | null;
  note: string | null;
  context: object | null;
  disabled: boolean;
}

export function ObjectStoreFromJSON(json: any): ObjectStore {
  return ObjectStoreFromJSONTyped(json);
}

export function ObjectStoreFromJSONTyped(json: any): ObjectStore {
  if (json == null) {
    return json;
  }

  return {
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    id: json["id"],
    bucketName: json["bucket_name"],
    accessKeyId: json["access_key_id"],
    regionName: json["region_name"],
    endpointUrl: json["endpoint_url"],
    note: json["note"],
    context: json["context"],
    disabled: json["disabled"],
  };
}

export function ObjectStoreToJSON(value: ObjectStore | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    id: value.id,
    bucket_name: value.bucketName,
    access_key_id: value.accessKeyId,
    region_name: value.regionName,
    endpoint_url: value.endpointUrl,
    note: value.note,
    context: value.context,
    disabled: value.disabled,
  };
}
