import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { ObjectStoreTable } from "./components/ObjectStoreTable";

export function ObjectStoresPage() {
  return (
    <Page title="Search Object Stores">
      <FullWidthContainer>
        <ObjectStoreTable />
      </FullWidthContainer>
    </Page>
  );
}
