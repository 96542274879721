import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { WorkflowHookDetails } from "./components/WorkflowHookDetails";

export function WorkflowHookPage() {
  return (
    <Page title="Hook Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <WorkflowHookDetails />
      </Container>
    </Page>
  );
}
