import React from "react";
import { Typography } from "@mui/material";
import type { JsonArray, JsonObject } from "type-fest";
import { z } from "zod";
import { assertNever, formatPercent, getFieldLabel } from "~/utils";
import { formatNumber } from "~/utils/formats";
import { Boolean } from "../Boolean";
import { Bytes } from "../Bytes";
import { CopyButton } from "../CopyableText";
import { Datetime } from "../Datetime";
import {
  DigestionInfo,
  GroupInfo,
  IngestionInfo,
  LabelInfo,
  LogInfo,
  ObjectStoreInfo,
  RoleInfo,
  TopicInfo,
  UserInfo,
  WorkflowInfo,
} from "../ResourceInfo";
import { Timestamp } from "../Timestamp";
import { JsonField } from "./JsonField";
import { Pre } from "./Pre";
import type { AccessorResourceField, FieldType, ResourceField } from "./types";

const uuidSchema = z.string().uuid();

const numberSchema = z.number();

const bigintSchema = z.bigint();

const datetimeSchema = z.date();

const booleanSchema = z.boolean();

const jsonSchema = z
  .unknown()
  .refine((value): value is JsonObject | JsonArray => {
    return typeof value === "object";
  });

export function renderField<TResource extends object>(
  resource: TResource,
  field: ResourceField<TResource>,
) {
  const label = isAccessorField(field)
    ? field.label ?? getFieldLabel(field.accessor)
    : field.label;

  const content = isAccessorField(field)
    ? getAccessorFieldContent(resource[field.accessor], field.dataType)
    : field.renderField(resource);

  return (
    <React.Fragment key={getFieldKey(field)}>
      <Typography
        component="dt"
        sx={{
          color: "text.secondary",
          fontWeight: "bold",
        }}
      >
        {label}
      </Typography>
      <Typography component="dd">{content}</Typography>
    </React.Fragment>
  );
}

function getAccessorFieldContent(
  fieldData: unknown,
  fieldType: FieldType,
): React.ReactNode {
  if (fieldData == null) {
    return "-";
  }

  switch (fieldType) {
    case "text": {
      return String(fieldData);
    }
    case "id": {
      const value = String(fieldData);

      return (
        <span>
          {value} <CopyButton text={value} size="small" />
        </span>
      );
    }
    case "pre": {
      return <Pre>{String(fieldData)}</Pre>;
    }
    case "number": {
      return formatNumber(numberSchema.parse(fieldData));
    }
    case "percent": {
      return formatPercent(numberSchema.parse(fieldData));
    }
    case "timestamp": {
      return <Timestamp date={bigintSchema.parse(fieldData)} />;
    }
    case "bigint": {
      // TODO: Format this better
      return String(bigintSchema.parse(fieldData));
    }
    case "datetime": {
      return <Datetime date={datetimeSchema.parse(fieldData)} />;
    }
    case "bytes": {
      return <Bytes value={numberSchema.parse(fieldData)} />;
    }
    case "boolean": {
      return <Boolean value={booleanSchema.parse(fieldData)} />;
    }
    case "json": {
      return <JsonField value={jsonSchema.parse(fieldData)} />;
    }
    case "digestion": {
      return <DigestionInfo digestionId={uuidSchema.parse(fieldData)} />;
    }
    case "group": {
      return <GroupInfo groupId={uuidSchema.parse(fieldData)} />;
    }
    case "ingestion": {
      return <IngestionInfo ingestionId={uuidSchema.parse(fieldData)} />;
    }
    case "label": {
      return <LabelInfo labelId={uuidSchema.parse(fieldData)} />;
    }
    case "log": {
      return <LogInfo logId={uuidSchema.parse(fieldData)} />;
    }
    case "object-store": {
      return <ObjectStoreInfo objectStoreId={uuidSchema.parse(fieldData)} />;
    }
    case "role": {
      return <RoleInfo roleId={uuidSchema.parse(fieldData)} />;
    }
    case "topic": {
      return <TopicInfo topicId={uuidSchema.parse(fieldData)} />;
    }
    case "user": {
      return <UserInfo userId={uuidSchema.parse(fieldData)} />;
    }
    case "workflow": {
      return <WorkflowInfo workflowId={uuidSchema.parse(fieldData)} />;
    }
    default: {
      assertNever(fieldType);
    }
  }
}

export function getFieldKey(field: ResourceField<any>): React.Key {
  return isAccessorField(field)
    ? field.label ?? getFieldLabel(field.accessor)
    : field.label;
}

export function isAccessorField<TResource extends object>(
  resourceField: ResourceField<TResource>,
): resourceField is AccessorResourceField<TResource> {
  return "accessor" in resourceField;
}
