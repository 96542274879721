import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { TagTable } from "./components/TagTable";

export function TagsPage() {
  return (
    <Page title="Search Tags">
      <FullWidthContainer>
        <DataStoreBreadcrumbs />
        <TagTable />
      </FullWidthContainer>
    </Page>
  );
}
