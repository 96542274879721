import { z } from "zod";
import {
  boolean,
  createErrorMap,
  optionalBigInt,
  optionalNumber,
  optionalProcessStateSchema,
  optionalProcessTypeSchema,
  optionalText,
  optionalTypeEncodingSchema,
  uuid,
} from "../Form";

export const filterText = optionalText.default(null);

export const filterUuid = uuid.nullable().default(null);

export const filterNumber = z.preprocess((arg) => {
  if (arg == null) {
    return arg;
  } else {
    return Number(arg);
  }
}, optionalNumber.default(null));

export const filterBigInt = optionalBigInt.default(null);

export function deserializeBooleanParam(arg: unknown): unknown {
  if (arg === "1") {
    return true;
  } else if (arg === "0") {
    return false;
  } else {
    return arg;
  }
}

export const filterBoolean = z.preprocess(
  deserializeBooleanParam,
  boolean.nullable().default(null),
);

export const filterDate = z.coerce
  .date({
    errorMap: createErrorMap({
      invalid_type: "Expected a date",
      invalid_date: "Invalid date",
    }),
  })
  .nullable()
  .default(null);

export const filterTypeEncoding = optionalTypeEncodingSchema.default(null);

export const filterProcessState = optionalProcessStateSchema.default(null);

export const filterProcessType = optionalProcessTypeSchema.default(null);
