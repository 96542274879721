import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import type {
  APIKey,
  APIKeyCreateRequest,
  APIKeyDataResponse,
  APIKeyListResponse,
  APIKeyUpdateRequest,
  ListApiKeysRequest,
} from "~/services/datastore";
import type { KeyFactory } from "~/types";
import { useDataStoreClients } from "../providers";
import { getInitialDetailsData } from "./utils";

export const apiKeyKeys = {
  all: ["api-keys"] as const,
  lists: () => [...apiKeyKeys.all, "list"] as const,
  list: (request: ListApiKeysRequest) =>
    [...apiKeyKeys.lists(), request] as const,
  fetches: () => [...apiKeyKeys.all, "fetch"] as const,
  fetch: (apiKeyId: APIKey["id"]) =>
    [...apiKeyKeys.fetches(), apiKeyId] as const,
};

export type ApiKeyKeys = KeyFactory<typeof apiKeyKeys>;

export function useApiKeys<TData = APIKeyListResponse>(
  request: ListApiKeysRequest,
  options?: UseQueryOptions<
    APIKeyListResponse,
    unknown,
    TData,
    ApiKeyKeys["list"]
  >,
): UseQueryResult<TData> {
  const { apiKeyApi } = useDataStoreClients();

  return useQuery({
    queryKey: apiKeyKeys.list(request),
    queryFn(context) {
      return apiKeyApi.listApiKeys(request, context);
    },
    ...options,
  });
}

export function useApiKey<TData = APIKeyDataResponse>(
  apiKeyId: APIKey["id"],
  options?: StrictOmit<
    UseQueryOptions<APIKeyDataResponse, unknown, TData, ApiKeyKeys["fetch"]>,
    "initialData"
  >,
): UseQueryResult<TData> {
  const queryClient = useQueryClient();

  const { apiKeyApi } = useDataStoreClients();

  return useQuery({
    queryKey: apiKeyKeys.fetch(apiKeyId),
    queryFn(context) {
      return apiKeyApi.fetchApiKey({ apiKeyId }, context);
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        apiKeyKeys.lists(),
        (apiKey: APIKey) => apiKey.id === apiKeyId,
      );
    },
  });
}

export function useCreateApiKey(): UseMutationResult<
  APIKeyDataResponse,
  unknown,
  APIKeyCreateRequest
> {
  const queryClient = useQueryClient();

  const { apiKeyApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return apiKeyApi.createApiKey({ aPIKeyCreateRequest: request });
    },
    // The returned API key contains a populated `secret` field. The only time
    // a user should ever be able to see that value is when they create the API
    // key (i.e. as a result of this mutation). Along with setting the mutation
    // cache time to 0, don't store the API key in the query cache either!
    cacheTime: 0,
    onSuccess() {
      queryClient.invalidateQueries(apiKeyKeys.lists());
    },
  });
}

export function useUpdateApiKey(
  apiKeyId: APIKey["id"],
): UseMutationResult<APIKeyDataResponse, unknown, APIKeyUpdateRequest> {
  const queryClient = useQueryClient();

  const { apiKeyApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return apiKeyApi.updateApiKey({ apiKeyId, aPIKeyUpdateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<APIKeyDataResponse>(
        apiKeyKeys.fetch(response.data.id),
        response,
      );
    },
  });
}

export function useDeleteApiKey(
  apiKeyId: APIKey["id"],
): UseMutationResult<void, unknown, void> {
  const { apiKeyApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return apiKeyApi.deleteApiKey({ apiKeyId });
    },
  });
}
