import { useCallback } from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

const LogIdParam = withDefault(StringParam, null);
const InitialTimeParam = withDefault(StringParam, null);
const config = {
  logId: LogIdParam,
  t: InitialTimeParam,
};

type UsePlayerConfigReturn = {
  logId: string | null;
  initialTime: bigint | null;
  setLogId: (logId: string) => void;
};

export function usePlayerConfig(): UsePlayerConfigReturn {
  const [params, setParams] = useQueryParams(config);

  const setLogId = useCallback(
    (logId: string) => {
      // The initial timestamp param is specific to a given log ID, so changing
      // the log ID requires the initial timestamp param to be stripped from
      // the URL if present
      setParams({ logId, t: undefined });
    },
    [setParams],
  );

  return {
    ...params,
    initialTime:
      params.t === null ? null : safeParseEncodedNanoseconds(params.t),
    setLogId,
  };
}

function safeParseEncodedNanoseconds(t: string): bigint | null {
  try {
    return BigInt(t);
  } catch {
    return null;
  }
}
