import { useEffect, useRef } from "react";
import type { Record } from "~/services/datastore";
import { usePlaybackSource } from "../playback";
import type { UseRecordChunksResult } from "./useRecordChunks";

export function useCurrentWindowRecord(
  windowQuery: UseRecordChunksResult,
  currentDurationMs: number = Infinity,
): Record | undefined {
  const playbackSource = usePlaybackSource();

  // If the panel is in a "standard" state (data loaded, not showing
  // placeholder data), then the most recent record (if any) should be
  // retrieved.
  const candidateRecord =
    windowQuery.data === undefined ||
    windowQuery.isPlaceholderData ||
    playbackSource.timestamp === undefined
      ? undefined
      : windowQuery.data.findLast(
          (record) =>
            record.timestamp <= playbackSource.timestamp &&
            playbackSource.timestamp - record.timestamp <= currentDurationMs,
        );

  const previousRecordRef = useRef<Record | undefined>(undefined);
  useEffect(function storeCandidateRecord() {
    // Only store the candidate record if it's from real data,
    // not placeholder data. Specifically, if the candidate is `undefined`
    // because we're showing placeholder data, then the previous record
    // ref's value shouldn't be overwritten
    if (windowQuery.isPlaceholderData) {
      return;
    }

    previousRecordRef.current = candidateRecord;
  });

  // When displaying placeholder data, the last record that was on screen
  // (or lack thereof if there was no recent candidate record) should continue
  // being displayed until real data is available.
  return windowQuery.isPlaceholderData
    ? previousRecordRef.current
    : candidateRecord;
}
