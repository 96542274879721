import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { TagDetails } from "./components/TagDetails";

export function TagPage() {
  return (
    <Page title="Tag Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <TagDetails />
      </Container>
    </Page>
  );
}
