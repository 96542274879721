import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  ManageCard,
  ResourceFields,
} from "~/components/DetailsCards";
import {
  CheckboxField,
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import {
  useDeleteWorkflowHook,
  useUpdateWorkflowHook,
  useWorkflowHook,
} from "~/domain/datastores";
import { pick } from "~/lib/std";
import {
  makeEditHookLocation,
  makeHooksLocation,
  useHookParams,
} from "~/paths";
import type { Hook } from "~/services/datastore";
import { selectData } from "~/utils";
import { editWorkflowHookSchema } from "../schemas";

export function WorkflowHookDetails() {
  const { workflowId, hookId } = useHookParams();

  const query = useWorkflowHook(workflowId, hookId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <HistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="hook"
      query={query}
      editLocation={makeEditHookLocation({ workflowId, hookId })}
      deleteMutation={useDeleteWorkflowHook(workflowId, hookId)}
      getReadableName={(workflowHook) => workflowHook.name ?? workflowHook.id}
      listLocation={makeHooksLocation({ workflowId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Hook> }) {
  return (
    <Card title="General">
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "workflow", accessor: "workflowId" },
          { dataType: "text", accessor: "name" },
          { dataType: "text", accessor: "triggerProcess" },
          { dataType: "text", accessor: "triggerState" },
          { dataType: "text", accessor: "uri" },
          { dataType: "boolean", accessor: "managed" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Hook> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["multiline", "multiline", "text"]} />}
        success={(hook) => <InfoSectionImpl hook={hook} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ hook }: { hook: Hook }) {
  const updateWorkflowHook = useUpdateWorkflowHook(hook.workflowId, hook.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editWorkflowHookSchema.pick({
    note: true,
    context: true,
    disabled: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(hook, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateWorkflowHook.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Hook updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update hook", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateWorkflowHook.isLoading}
      submitText="Save Changes"
    >
      <TextField control={control} name="note" multiline />
      <ObjectField control={control} name="context" />
      <CheckboxField control={control} name="disabled" />
    </Form>
  );
}
