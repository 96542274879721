import { Button, Grid } from "@mui/material";
import { BooleanField, TimestampField, useStudioForm } from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListDigestionTopicsFormValues } from "../schemas";
import { listDigestionTopicsSchema } from "../schemas";

export interface DigestionTopicFiltersProps {
  values: ListDigestionTopicsFormValues;
  setValues: (newValues: ListDigestionTopicsFormValues) => void;
}

export function DigestionTopicFilters({
  values,
  setValues,
}: DigestionTopicFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listDigestionTopicsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <TimestampField control={control} name="startTimeGte" />
        </Grid>
        <Grid item xs={4}>
          <TimestampField control={control} name="startTimeLte" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField control={control} name="startTimeNull" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <TimestampField control={control} name="endTimeGte" />
        </Grid>
        <Grid item xs={4}>
          <TimestampField control={control} name="endTimeLte" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField control={control} name="endTimeNull" />
        </Grid>
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
