import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { ObjectStoreObjectsTable } from "./components/ObjectStoreObjectsTable";

export function ObjectStoreObjectsPage() {
  return (
    <Page title="Search Objects">
      <FullWidthContainer>
        <DataStoreBreadcrumbs />
        <ObjectStoreObjectsTable />
      </FullWidthContainer>
    </Page>
  );
}
