import {
  CheckboxField,
  EditResourceForm,
  ObjectField,
  TextField,
} from "~/components/Form";
import { useRole, useUpdateRole } from "~/domain/datastores";
import { makeRoleLocation, useRoleParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_ROLE_FIELDS, editRoleSchema } from "../schemas";

export function RoleEditForm() {
  const { roleId } = useRoleParams();

  return (
    <EditResourceForm
      schema={editRoleSchema}
      resourceName="role"
      query={useRole(roleId, { select: selectData })}
      editableFields={EDITABLE_ROLE_FIELDS}
      mutation={useUpdateRole(roleId)}
      resourceLocation={makeRoleLocation({ roleId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <ObjectField control={control} name="policy" required />
          <CheckboxField control={control} name="_default" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" />
        </>
      )}
    </EditResourceForm>
  );
}
