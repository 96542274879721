import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import type {
  Hook,
  HookCreateRequest,
  HookDataResponse,
  HookListResponse,
  HookUpdateRequest,
  ListHooksRequest,
  ListWorkflowsRequest,
  Workflow,
  WorkflowCreateRequest,
  WorkflowUpdateRequest,
} from "~/services/datastore";
import type { KeyFactory } from "~/types";
import { useDataStoreClients } from "../providers";
import { createResourceCrudHooks, getInitialDetailsData } from "./utils";

export const {
  queryKeyFactory: workflowKeys,
  useList: useWorkflows,
  useFetch: useWorkflow,
  useCreate: useCreateWorkflow,
  useUpdate: useUpdateWorkflow,
  useDelete: useDeleteWorkflow,
} = createResourceCrudHooks({
  baseQueryKey: "workflows",
  getIdentifier(workflow: Workflow) {
    return workflow.id;
  },
  listResource({ signal }, { workflowApi }, request: ListWorkflowsRequest) {
    return workflowApi.listWorkflows(request, { signal });
  },
  fetchResource({ signal }, { workflowApi }, workflowId: Workflow["id"]) {
    return workflowApi.fetchWorkflow({ workflowId }, { signal });
  },
  createResource({ workflowApi }, request: WorkflowCreateRequest) {
    return workflowApi.createWorkflow({ workflowCreateRequest: request });
  },
  updateResource(
    { workflowApi },
    workflowId: Workflow["id"],
    updates: WorkflowUpdateRequest,
  ) {
    return workflowApi.updateWorkflow({
      workflowId,
      workflowUpdateRequest: updates,
    });
  },
  deleteResource({ workflowApi }, workflowId: Workflow["id"]) {
    return workflowApi.deleteWorkflow({ workflowId });
  },
});

export type WorkflowHooks = KeyFactory<typeof workflowKeys>;

export const workflowHookKeys = {
  all: (workflowId: Workflow["id"]) =>
    [...workflowKeys.fetch(workflowId), "hooks"] as const,
  lists: (workflowId: Workflow["id"]) =>
    [...workflowHookKeys.all(workflowId), "list"] as const,
  list: (
    workflowId: Workflow["id"],
    request: StrictOmit<ListHooksRequest, "workflowId">,
  ) => [...workflowHookKeys.lists(workflowId), request] as const,
  fetches: (workflowId: Workflow["id"]) =>
    [...workflowHookKeys.all(workflowId), "fetch"] as const,
  fetch: (workflowId: Workflow["id"], hookId: Hook["id"]) =>
    [...workflowHookKeys.fetches(workflowId), hookId] as const,
};

export type WorkflowHookKeys = KeyFactory<typeof workflowHookKeys>;

export function useWorkflowHooks<TData = HookListResponse>(
  workflowId: Workflow["id"],
  request: StrictOmit<ListHooksRequest, "workflowId">,
  options?: UseQueryOptions<
    HookListResponse,
    unknown,
    TData,
    WorkflowHookKeys["list"]
  >,
): UseQueryResult<TData> {
  const { workflowApi } = useDataStoreClients();

  return useQuery({
    queryKey: workflowHookKeys.list(workflowId, request),
    queryFn({ signal }) {
      return workflowApi.listHooks({ workflowId, ...request }, { signal });
    },
    ...options,
  });
}

export function useWorkflowHook<TData = HookDataResponse>(
  workflowId: Workflow["id"],
  hookId: Hook["id"],
  options?: StrictOmit<
    UseQueryOptions<
      HookDataResponse,
      unknown,
      TData,
      WorkflowHookKeys["fetch"]
    >,
    "initialData"
  >,
): UseQueryResult<TData> {
  const queryClient = useQueryClient();

  const { workflowApi } = useDataStoreClients();

  return useQuery({
    queryKey: workflowHookKeys.fetch(workflowId, hookId),
    queryFn({ signal }) {
      return workflowApi.fetchHook({ workflowId, hookId }, { signal });
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        workflowHookKeys.lists(workflowId),
        (workflowHook: Hook) =>
          workflowHook.workflowId === workflowId && workflowHook.id === hookId,
      );
    },
  });
}

export function useCreateWorkflowHook(
  workflowId: Workflow["id"],
): UseMutationResult<HookDataResponse, unknown, HookCreateRequest> {
  const queryClient = useQueryClient();

  const { workflowApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return workflowApi.createHook({
        workflowId,
        hookCreateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<HookDataResponse>(
        workflowHookKeys.fetch(workflowId, response.data.id),
        response,
      );
    },
  });
}

export function useUpdateWorkflowHook(
  workflowId: Workflow["id"],
  hookId: Hook["id"],
): UseMutationResult<HookDataResponse, unknown, HookUpdateRequest> {
  const queryClient = useQueryClient();

  const { workflowApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return workflowApi.updateHook({
        workflowId,
        hookId,
        hookUpdateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<HookDataResponse>(
        workflowHookKeys.fetch(workflowId, hookId),
        response,
      );
    },
  });
}

export function useDeleteWorkflowHook(
  workflowId: Workflow["id"],
  hookId: Hook["id"],
): UseMutationResult<void, unknown, void> {
  const queryClient = useQueryClient();

  const { workflowApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return workflowApi.deleteHook({ workflowId, hookId });
    },
    onSuccess() {
      // Immediately removing the resource from the cache seems to cause
      // react-query to trigger a refetch which is immediately cancelled as
      // Studio navigates back to the table page. Since this resource is no
      // longer needed, it's fine to remove it shortly after deleting rather
      // than immediately
      setTimeout(() => {
        queryClient.removeQueries({
          queryKey: workflowHookKeys.fetch(workflowId, hookId),
        });
      }, 50);
    },
  });
}
