import React from "react";
import { Card as MuiCard, CardContent, CardHeader } from "@mui/material";

interface CardProps {
  title?: string;
  action?: React.ReactNode;
  error?: boolean;
  children: React.ReactNode;
}

export function Card({ title, action, error = false, children }: CardProps) {
  return (
    <MuiCard
      sx={{
        ...(error && {
          border: "1px solid",
          borderColor: (theme) => theme.palette.error.main,
        }),
      }}
    >
      {(title !== undefined || action !== undefined) && (
        <CardHeader
          title={title}
          titleTypographyProps={{ component: "h2" }}
          action={action}
        />
      )}
      <CardContent>{children}</CardContent>
    </MuiCard>
  );
}
