import type { Column } from "~/components/Table";
import type { Auditable } from "./types";

export const auditColumns: ReadonlyArray<Column<Auditable>> = [
  {
    accessor: "createdAt",
    dataType: "datetime",
    isSortable: true,
  },
  {
    accessor: "createdBy",
    dataType: "foreign-key",
    resourceType: "user",
  },
  {
    accessor: "updatedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "updatedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "deletedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "deletedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
];
