/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { StatementAction } from "./StatementAction";
import {
  StatementActionFromJSON,
  StatementActionFromJSONTyped,
  StatementActionToJSON,
} from "./StatementAction";
import type { StatementEffect } from "./StatementEffect";
import {
  StatementEffectFromJSON,
  StatementEffectFromJSONTyped,
  StatementEffectToJSON,
} from "./StatementEffect";

export interface Statement {
  effect: StatementEffect;
  action: Array<StatementAction>;
  resource: Array<string>;
}

export function StatementFromJSON(json: any): Statement {
  return StatementFromJSONTyped(json);
}

export function StatementFromJSONTyped(json: any): Statement {
  if (json == null) {
    return json;
  }

  return {
    effect: StatementEffectFromJSON(json["effect"]),
    action: (json["action"] as Array<any>).map(StatementActionFromJSON),
    resource: json["resource"],
  };
}

export function StatementToJSON(value: Statement | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    effect: StatementEffectToJSON(value.effect),
    action: (value.action as Array<any>).map(StatementActionToJSON),
    resource: value.resource,
  };
}
