import { QueryClient } from "@tanstack/react-query";
import { getDataStoreUrl, getDsmUrl } from "./config";
import { getAuthToken } from "./services/auth";
import { Configuration as DataStoreConfiguration } from "./services/datastore";
import type { DataStore } from "./services/dsm";
import { Configuration as DSMConfiguration } from "./services/dsm";

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // Preserve v3 behavior, opting out of smart query tracking (for now).
        // Smart tracking should be done on a case-by-case basis to avoid
        // unnoticed bugs
        notifyOnChangeProps: "all",
        // API clients use `fetch-retry` library to handle refetching
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        // Default staleTime of Infinity for all queries. We deal with too
        // much data to be refetching willy-nilly and I'd prefer to control
        // when refetching happens anyways
        staleTime: Infinity,
      },
    },
  });
}

export function createDataStoreManagementApiConfiguration(): DSMConfiguration {
  return new DSMConfiguration({
    basePath: getDsmUrl(),
    accessToken: getAuthToken,
  });
}

export function createDataStoreApiConfiguration(
  dataStore: DataStore,
): DataStoreConfiguration {
  return new DataStoreConfiguration({
    basePath: getDataStoreUrl(dataStore),
    accessToken: getAuthToken,
  });
}
