import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useUsers } from "~/domain/datastores";
import { DataStoreLink, makeNewUserLocation, makeUserLocation } from "~/paths";
import type { User } from "~/services/datastore";
import { listUsersSchema } from "../schemas";
import { UserFilters } from "./UserFilters";

const COLUMNS: ReadonlyArray<Column<User>> = [
  {
    header: "Username",
    sortKey: "username",
    renderCell(user) {
      return (
        <TableCell>
          {user.username}
          <Link
            component={DataStoreLink}
            to={makeUserLocation({ userId: user.id })}
          >
            <Typography variant="body2">{user.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "roleId",
    dataType: "foreign-key",
    resourceType: "role",
  },
  {
    accessor: "admin",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listUsersSchema);

export const UserTable = withSearchValidationErrorBoundary(
  function UserTable() {
    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useUsers(prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="user"
            resourceCreateLocation={makeNewUserLocation()}
            getRowKey={(user) => user.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <UserFilters values={filterValues} setValues={setRequest} />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
