import { SidebarSwitch } from "~/components/Layout";
import { SettingsDrawer } from "~/components/SettingsDrawer";
import { usePlayerTopics } from "../../hooks";
import { usePanelLayoutContext } from "../../panels";
import { usePlaybackSource } from "../../playback";
import DigestionDrawer, {
  useDraftDigestion,
  useDigestionFinalizer,
} from "./DigestionDrawer";
import LogDrawer from "./LogDrawer";
import PlayerSettings from "./PlayerSettings";

export default function PlayerSidebar() {
  const topicsQuery = usePlayerTopics();

  const playbackSource = usePlaybackSource();

  const { layout } = usePanelLayoutContext();

  const draftDigestion = useDraftDigestion({
    playerTopics: topicsQuery.data,
    playerRange: playbackSource.range,
    layout,
  });

  const { createDigestion, form, onSubmit } = useDigestionFinalizer(
    draftDigestion.topics,
  );

  return (
    <SidebarSwitch
      config={[
        { id: "logs", element: <LogDrawer /> },
        {
          id: "digestions",
          element: (
            <DigestionDrawer
              draftDigestion={draftDigestion}
              createDigestion={createDigestion}
              form={form}
              onSubmit={onSubmit}
            />
          ),
        },
        {
          id: "settings",
          element: (
            <SettingsDrawer>
              <PlayerSettings />
            </SettingsDrawer>
          ),
        },
      ]}
    />
  );
}
