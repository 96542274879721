import type React from "react";
import type { ImmerReducer } from "use-immer";
import { millisecondsToNanoseconds, secondsToNanoseconds } from "~/lib/dates";
import { invariant } from "~/lib/invariant";
import { assertNever, bigintClamp, bigintMax, bigintMin } from "~/utils";
import type { TimeRange, TimestepValue } from "../types";
import { Timestep } from "../types";

export type PlaybackMode = "single" | "range";

export type PlaybackSourceStatus = "paused" | "playing" | "range-mode";

export interface PlaybackSourceState {
  status: PlaybackSourceStatus;
  range: TimeRange | undefined;
  timestamp: bigint | undefined;
}

export interface BasePlaybackSource
  extends Pick<PlaybackSourceState, "range" | "timestamp"> {
  isLoading: boolean;
  bounds: TimeRange | undefined;
  mode: PlaybackMode;
  inRangeMode: boolean;
  isPlaying: boolean;
  dispatch: React.Dispatch<PlaybackSourceReducerAction>;
}

export interface LoadingPlaybackSource extends BasePlaybackSource {
  isLoading: true;
  bounds: undefined;
  mode: "single";
  inRangeMode: false;
  isPlaying: false;
  range: undefined;
  timestamp: undefined;
}

export interface LoadedPlaybackSource extends BasePlaybackSource {
  isLoading: false;
  bounds: TimeRange;
  range: TimeRange;
  timestamp: bigint;
}

export type PlaybackSource = LoadingPlaybackSource | LoadedPlaybackSource;

/**
 * Pause playback.
 *
 * Invariants:
 *  - Must be playing
 */
export type PauseAction = {
  type: "pause";
};

export function pause(): PauseAction {
  return {
    type: "pause",
  };
}

/**
 * Start playing from current timestamp.
 *
 * Invariants:
 *  - Must be paused
 */
export type PlayAction = {
  type: "play";
};

export function play(): PlayAction {
  return {
    type: "play",
  };
}

/**
 * Manually set the digestion time range. Allows the user to fine-tune the
 * range.
 *
 * Invariants:
 *  - Must be in range mode
 */
// TODO: Should update playback timestamp as well
export type SetRangeAction = {
  type: "set-range";
  payload: {
    range: LoadedPlaybackSource["range"];
  };
};

export function setRange(payload: SetRangeAction["payload"]): SetRangeAction {
  return {
    type: "set-range",
    payload,
  };
}

/**
 * Enter into range-selection mode. Mutually exclusive state with normal
 * playback. Playback will be paused. Meant for manually adjusting digestion
 * time range.
 *
 * When entering range mode, the digestion range will be set to start 15
 * seconds prior to the current playback timestamp (clamped at the log's start
 * if needed). However, if the current playback timestamp is already at the
 * log's start, the range will instead be set to end 15 seconds after the log's
 * start (clamped at the log's end if needed).
 *
 * Invariants:
 *  - Must be in playback mode
 */
export type EnterRangeModeAction = {
  type: "enter-range-mode";
};

export function enterRangeMode(): EnterRangeModeAction {
  return {
    type: "enter-range-mode",
  };
}

/**
 * Center the range around the current playback timestamp. The payload
 * should be a number in nanoseconds representing half the duration of the
 * final range, or more concretely how many nanoseconds from the current
 * timestamp each endpoint of the new range should extend. The endpoints
 * will be clamped within the playback bounds, so the payload represents the
 * desired half-duration, not necessarily the final half-duration.
 *
 * Invariants:
 *  - Must be in playback mode
 */
export type CenterRangeAction = {
  type: "center-range";
  payload: {
    halfDuration: bigint;
  };
};

export function centerRange(
  payload: CenterRangeAction["payload"],
): CenterRangeAction {
  return {
    type: "center-range",
    payload,
  };
}

/**
 * Exit range-select mode. Signals the user has finished manually adjusting
 * digestion time range. Returns to a paused playback state.
 *
 * Invariants:
 *  - Must be in range mode
 */
export type ExitRangeModeAction = {
  type: "exit-range-mode";
};

export function exitRangeMode(): ExitRangeModeAction {
  return {
    type: "exit-range-mode",
  };
}

/**
 * Seek the playback timestamp directly to the provided timestamp (in
 * nanoseconds). If provided timestamp is at the end of the log playback
 * will be paused (if it wasn't already), otherwise playback will remain in
 * its current state.
 *
 * Invariants:
 *  - Must be in playback mode
 *  - Timestamp must be within log's bounds (can be equal to either bound)
 */
export type SeekAction = {
  type: "seek";
  payload: {
    to: bigint;
  };
};

export function seek(payload: SeekAction["payload"]): SeekAction {
  return {
    type: "seek",
    payload,
  };
}

/**
 * Seeks to the previous frame from the current timestamp taking into
 * consideration the playback timestep setting. Always pauses playback.
 * Cannot be performed if already at log's start but will allow and clamp a
 * seek that would put the timestamp past the log's start.
 *
 * Invariants:
 *  - Must be in playback mode
 *  - Current timestamp must not be at start of log
 */
export type PreviousFrameAction = {
  type: "previous-frame";
};

export function previousFrame(): PreviousFrameAction {
  return {
    type: "previous-frame",
  };
}

/**
 * Seeks to the next frame from the current timestamp taking into consideration
 * the playback timestep setting. Always pauses playback. Cannot be performed
 * if already at the log's end but will allow and clamp a seek that would put
 * the timestamp past the log's end.
 *
 * Invariants:
 *  - Must be in playback mode
 *  - Current timestamp must not be at end of log
 */
export type NextFrameAction = {
  type: "next-frame";
};

export function nextFrame(): NextFrameAction {
  return {
    type: "next-frame",
  };
}

/**
 * Perform a single tick of the simulated clock. Equivalent to performing the
 * "next-frame" action but continuing playback unless the next frame is at the
 * end of the log.
 *
 * Invariants*:
 *  - Must be in playback mode
 *  - Must be playing
 *  - Current timestamp must not be at end of log
 *
 * *If these conditions are not met the reducer will return the existing state
 *   unchanged rather than throwing an invariant violation. This is due to
 *   the asynchronous interplay between the browser executing the interval
 *   call, React updating the reducer's state, and React cleaning up the
 *   previous effect that will cancel the interval.
 */
export type TickAction = {
  type: "tick";
};

export function tick(): TickAction {
  return {
    type: "tick",
  };
}

/**
 * Restart playback from the start of the log and automatically play.
 *
 * Invariants:
 *  - Must be in playback mode
 */
export type RestartAction = {
  type: "restart";
};

export function restart(): RestartAction {
  return {
    type: "restart",
  };
}

export type PlaybackSourceReducerAction =
  | PauseAction
  | PlayAction
  | SetRangeAction
  | EnterRangeModeAction
  | CenterRangeAction
  | ExitRangeModeAction
  | SeekAction
  | PreviousFrameAction
  | NextFrameAction
  | TickAction
  | RestartAction;

export const initialState: PlaybackSourceState = {
  status: "paused",
  range: undefined,
  timestamp: undefined,
};

export function makeReducer(
  playerBounds: TimeRange | undefined,
  initialTime: bigint | undefined,
  timestep: TimestepValue,
): ImmerReducer<PlaybackSourceState, PlaybackSourceReducerAction> {
  return function reducer(state, action) {
    invariant(
      playerBounds !== undefined && initialTime !== undefined,
      "Bounds and initial time must be defined",
    );

    const { timestamp: currentTimestamp = initialTime } = state;

    switch (action.type) {
      case "pause": {
        invariant(state.status === "playing", "Can only pause when playing");

        state.status = "paused";

        return;
      }
      case "play": {
        invariant(state.status === "paused", "Can only play when paused");

        state.status = "playing";

        return;
      }
      case "set-range": {
        invariant(
          state.status === "range-mode",
          "Can only set range directly in range mode",
        );

        state.range = {
          startTime: bigintMax(
            playerBounds.startTime,
            action.payload.range.startTime,
          ),
          endTime: bigintMin(
            playerBounds.endTime,
            action.payload.range.endTime,
          ),
        };

        return;
      }
      case "enter-range-mode": {
        invariant(state.status !== "range-mode", "Already in range mode");

        // When entering range mode in typical cases the range should end
        // at the current playback timestamp and start 15 seconds prior (clamped
        // if the playback bounds aren't that long). If the current playback
        // timestamp is at the start of the bounds though, do the opposite: start
        // the range at the current timestamp and end 15 seconds later (with
        // clamping).
        //
        // The idea is that, in most cases, if someone wants to select manually
        // they're probably stopped at something interesting and want to select
        // records in the time leading up to it.
        if (currentTimestamp === playerBounds.startTime) {
          state.range = {
            startTime: playerBounds.startTime,
            endTime: bigintMin(
              playerBounds.startTime + secondsToNanoseconds(15),
              playerBounds.endTime,
            ),
          };
        } else {
          state.range = {
            startTime: bigintMax(
              currentTimestamp - secondsToNanoseconds(15),
              playerBounds.startTime,
            ),
            endTime: currentTimestamp,
          };
        }

        state.status = "range-mode";

        return;
      }
      case "center-range": {
        invariant(
          state.status !== "range-mode",
          "Cannot perform this action in range mode",
        );

        const {
          payload: { halfDuration },
        } = action;

        state.range = {
          startTime: bigintMax(
            currentTimestamp - halfDuration,
            playerBounds.startTime,
          ),
          endTime: bigintMin(
            currentTimestamp + halfDuration,
            playerBounds.endTime,
          ),
        };

        return;
      }
      case "exit-range-mode": {
        invariant(state.status === "range-mode", "Not in range mode");

        state.status = "paused";

        return;
      }
      case "seek": {
        const {
          payload: { to },
        } = action;

        const clampedTo = bigintClamp(
          to,
          playerBounds.startTime,
          playerBounds.endTime,
        );

        state.timestamp = clampedTo;

        if (state.status === "playing" && clampedTo === playerBounds.endTime) {
          state.status = "paused";
        }

        return;
      }
      case "previous-frame": {
        if (currentTimestamp === playerBounds.startTime) {
          return;
        }

        state.timestamp = calculateFrameSeekTimestamp({
          currentTimestamp,
          direction: "previous",
          playerBounds,
          timestep,
        });
        state.status = "paused";

        return;
      }
      case "next-frame": {
        if (currentTimestamp === playerBounds.endTime) {
          return;
        }

        state.timestamp = calculateFrameSeekTimestamp({
          currentTimestamp,
          direction: "next",
          playerBounds,
          timestep,
        });
        state.status = "paused";

        return;
      }
      case "tick": {
        if (
          state.status !== "playing" ||
          currentTimestamp === playerBounds.endTime
        ) {
          return;
        }

        const nextTimestamp = calculateFrameSeekTimestamp({
          currentTimestamp,
          direction: "next",
          playerBounds,
          timestep,
        });

        state.timestamp = nextTimestamp;
        // Automatically pause at the end of the log
        state.status =
          nextTimestamp === playerBounds.endTime ? "paused" : "playing";

        return;
      }
      case "restart": {
        state.timestamp = playerBounds.startTime;
        state.status = "playing";

        return;
      }
      default: {
        assertNever(action);
      }
    }
  };
}

interface CalculateFrameSeekParams {
  currentTimestamp: bigint;
  direction: "previous" | "next";
  playerBounds: LoadedPlaybackSource["bounds"];
  timestep: TimestepValue;
}

function calculateFrameSeekTimestamp({
  currentTimestamp,
  direction,
  playerBounds,
  timestep,
}: CalculateFrameSeekParams) {
  const absoluteOffset =
    timestep === Timestep.Second
      ? secondsToNanoseconds(1)
      : millisecondsToNanoseconds(100);
  const offset = direction === "next" ? absoluteOffset : -absoluteOffset;

  // It's valid for a frame seek to potentially put you past a bound. For
  // example, the user could seek to the second frame at decisecond frequency,
  // switch to second frequency, and then seek to the previous frame.
  return bigintClamp(
    currentTimestamp + offset,
    playerBounds.startTime,
    playerBounds.endTime,
  );
}
