import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useRoles } from "~/domain/datastores";
import { DataStoreLink, makeNewRoleLocation, makeRoleLocation } from "~/paths";
import type { Role } from "~/services/datastore";
import { listRolesSchema } from "../schemas";
import { RoleFilters } from "./RoleFilters";

const COLUMNS: ReadonlyArray<Column<Role>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell(role) {
      return (
        <TableCell>
          {role.name}
          <Link
            component={DataStoreLink}
            to={makeRoleLocation({ roleId: role.id })}
          >
            <Typography variant="body2">{role.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "_default",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listRolesSchema);

export const RoleTable = withSearchValidationErrorBoundary(
  function RoleTable() {
    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useRoles(prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="role"
            resourceCreateLocation={makeNewRoleLocation()}
            getRowKey={(role) => role.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <RoleFilters values={filterValues} setValues={setRequest} />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
