import type { ElementOf, StrictOmit } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  optionalBigInt,
  optionalObject,
  optionalText,
  optionalUuid,
  requiredText,
  schemaShapeForType,
  uuid,
} from "~/components/Form";
import {
  filterBigInt,
  filterBoolean,
  filterNumber,
  filterProcessState,
  filterText,
  filterUuid,
} from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  ListLogsRequest,
  ListQueriesRequest,
  ListTagsRequest,
  Log,
  LogCreateRequest,
  LogUpdateRequest,
  QueryCreateRequest,
  QueryUpdateRequest,
  Tag,
  TagCreateRequest,
  TagUpdateRequest,
} from "~/services/datastore";

export const listLogsSchema = z.object(
  schemaShapeForType<StrictOmit<ListLogsRequest, "contextFilter">>()({
    name: filterText,
    nameLike: filterText,
    groupId: filterUuid,
    locked: filterBoolean,
    noteLike: filterText,
    defaultWorkflowId: filterUuid,
    defaultWorkflowIdNull: filterBoolean,
    timeAdjustmentGte: filterBigInt,
    timeAdjustmentLte: filterBigInt,
    timeAdjustmentNull: filterBoolean,
    startTimeGte: filterBigInt,
    startTimeLte: filterBigInt,
    startTimeNull: filterBoolean,
    endTimeGte: filterBigInt,
    endTimeLte: filterBigInt,
    endTimeNull: filterBoolean,
    recordSizeGte: filterNumber,
    recordSizeLte: filterNumber,
    recordCountGte: filterNumber,
    recordCountLte: filterNumber,
    objectSizeGte: filterNumber,
    objectSizeLte: filterNumber,
    objectCountGte: filterNumber,
    objectCountLte: filterNumber,
    ...auditFieldSchemas,
  }),
);

export type ListLogsFormValues = z.infer<typeof listLogsSchema>;

export const createLogSchema = z.object(
  schemaShapeForType<LogCreateRequest>()({
    name: requiredText,
    groupId: uuid,
    defaultWorkflowId: optionalUuid,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export type CreateLogFormValues = z.infer<typeof createLogSchema>;

export const editLogSchema = z.object(
  schemaShapeForType<LogUpdateRequest>()({
    name: requiredText,
    groupId: uuid,
    defaultWorkflowId: optionalUuid,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export const EDITABLE_LOG_FIELDS = editLogSchema.keyof().options;

export type EditLogFormValues = Pick<
  Log,
  ElementOf<typeof EDITABLE_LOG_FIELDS>
>;

export const listTagsSchema = z.object(
  schemaShapeForType<StrictOmit<ListTagsRequest, "logId" | "contextFilter">>()({
    labelId: filterUuid,
    topicId: filterUuid,
    note: filterText,
    noteLike: filterText,
    startTimeGte: filterBigInt,
    startTimeLte: filterBigInt,
    startTimeNull: filterBoolean,
    endTimeGte: filterBigInt,
    endTimeLte: filterBigInt,
    endTimeNull: filterBoolean,
    ...auditFieldSchemas,
  }),
);

export type ListTagsFormValues = z.infer<typeof listTagsSchema>;

export const createTagSchema = z.object(
  schemaShapeForType<TagCreateRequest>()({
    labelId: uuid,
    topicId: optionalUuid,
    note: optionalText,
    startTime: optionalBigInt,
    endTime: optionalBigInt,
    context: optionalObject,
  }),
);

export type CreateTagFormValues = z.infer<typeof createTagSchema>;

export const editTagSchema = z.object(
  schemaShapeForType<TagUpdateRequest>()({
    labelId: uuid,
    topicId: optionalUuid,
    note: optionalText,
    startTime: optionalBigInt,
    endTime: optionalBigInt,
    context: optionalObject,
  }),
);

export const EDITABLE_TAG_FIELDS = editTagSchema.keyof().options;

export type EditTagFormValues = Pick<
  Tag,
  ElementOf<typeof EDITABLE_TAG_FIELDS>
>;

export const listLogQueriesSchema = z.object(
  schemaShapeForType<
    StrictOmit<
      ListQueriesRequest,
      "logId" | "workflowContextFilter" | "contextFilter"
    >
  >()({
    name: filterText,
    nameLike: filterText,
    noteLike: filterText,
    statement: filterText,
    statementLike: filterText,
    workflowId: filterUuid,
    workflowIdNull: filterBoolean,
    state: filterProcessState,
    ...auditFieldSchemas,
  }),
);

export type ListLogQueriesFormValues = z.infer<typeof listLogQueriesSchema>;

export const createLogQuerySchema = z.object(
  schemaShapeForType<QueryCreateRequest>()({
    name: optionalText,
    statement: optionalText,
    parameters: optionalObject,
    note: optionalText,
    context: optionalObject,
  }),
);

export const editLogQuerySchema = z.object(
  schemaShapeForType<QueryUpdateRequest>()({
    name: optionalText,
    statement: optionalText,
    parameters: optionalObject,
    note: optionalText,
    context: optionalObject,
  }),
);

export const EDITABLE_LOG_QUERY_FIELDS = editLogQuerySchema.keyof().options;
