import { useEffect, useState } from "react";
import { checkUserIsAuthenticated, redirectToSignIn } from "~/services/auth";

export function useAuthenticationCheck() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    (async () => {
      if (await checkUserIsAuthenticated()) {
        setIsAuthenticated(true);
      } else {
        redirectToSignIn();
      }
    })();
  }, []);

  return isAuthenticated;
}
