import type { ElementOf, StrictOmit } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  optionalNumber,
  optionalObject,
  optionalText,
  optionalTypeEncodingSchema,
  optionalUuid,
  requiredBigInt,
  requiredText,
  schemaShapeForType,
  uuid,
} from "~/components/Form";
import {
  filterBigInt,
  filterBoolean,
  filterNumber,
  filterProcessState,
  filterText,
  filterTypeEncoding,
  filterUuid,
} from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  ListRecordsRequest,
  ListTopicsRequest,
  Record,
  RecordCreateRequest,
  RecordUpdateRequest,
  Topic,
  TopicCreateRequest,
  TopicUpdateRequest,
} from "~/services/datastore";

export const listTopicsSchema = z.object(
  schemaShapeForType<
    StrictOmit<
      ListTopicsRequest,
      "contextFilter" | "typeData" | "typeDataLike" | "typeSchemaFilter"
    >
  >()({
    logId: filterUuid,
    associatedTopicId: filterUuid,
    locked: filterBoolean,
    strict: filterBoolean,
    latched: filterBoolean,
    name: filterText,
    nameLike: filterText,
    startTimeGte: filterBigInt,
    startTimeLte: filterBigInt,
    startTimeNull: filterBoolean,
    endTimeGte: filterBigInt,
    endTimeLte: filterBigInt,
    endTimeNull: filterBoolean,
    recordSizeGte: filterNumber,
    recordSizeLte: filterNumber,
    recordCountGte: filterNumber,
    recordCountLte: filterNumber,
    typeName: filterText,
    typeNameLike: filterText,
    typeEncoding: filterTypeEncoding,
    ...auditFieldSchemas,
  }),
);

export type ListTopicsFormValues = z.infer<typeof listTopicsSchema>;

export const createTopicSchema = z.object(
  schemaShapeForType<TopicCreateRequest>()({
    name: requiredText,
    logId: uuid,
    associatedTopicId: optionalUuid,
    strict: boolean,
    typeName: optionalText,
    typeEncoding: optionalTypeEncodingSchema,
    typeData: optionalText,
    typeSchema: optionalObject,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export type CreateTopicFormValues = z.infer<typeof createTopicSchema>;

export const editTopicSchema = z.object(
  schemaShapeForType<TopicUpdateRequest>()({
    name: requiredText,
    associatedTopicId: optionalUuid,
    strict: boolean,
    typeName: optionalText,
    typeEncoding: optionalTypeEncodingSchema,
    typeData: optionalText,
    typeSchema: optionalObject,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export const EDITABLE_TOPIC_FIELDS = editTopicSchema.keyof().options;

export type EditTopicFormValues = Pick<
  Topic,
  ElementOf<typeof EDITABLE_TOPIC_FIELDS>
>;

export const listRecordsSchema = z.object(
  schemaShapeForType<
    StrictOmit<
      ListRecordsRequest,
      | "topicId"
      | "logId"
      | "errorLike"
      | "noteLike"
      | "queryDataFilter"
      | "includeAuxiliaryData"
      | "includeRawData"
      | "contextFilter"
      | "frequency"
      | "timestampGt"
      | "timestampGte"
      | "timestampLt"
      | "timestampLte"
    >
  >()({
    ingestionId: filterUuid,
    workflowId: filterUuid,
    workflowIdNull: filterBoolean,
    source: filterText,
    altered: filterBoolean,
    state: filterProcessState,
    dataLengthGte: filterNumber,
    dataLengthLte: filterNumber,
    dataOffsetGte: filterNumber,
    dataOffsetLte: filterNumber,
    chunkCompression: filterText,
    chunkOffsetGte: filterNumber,
    chunkOffsetLte: filterNumber,
    chunkLengthGte: filterNumber,
    chunkLengthLte: filterNumber,
    ...auditFieldSchemas,
  }),
);

export type ListRecordsFormValues = z.infer<typeof listRecordsSchema>;

export const createRecordSchema = z.object(
  schemaShapeForType<StrictOmit<RecordCreateRequest, "auxiliaryData">>()({
    timestamp: requiredBigInt,
    dataOffset: optionalNumber,
    dataLength: optionalNumber,
    chunkCompression: optionalText,
    chunkOffset: optionalNumber,
    chunkLength: optionalNumber,
    source: optionalText,
    queryData: optionalObject,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export type CreateRecordFormValues = z.infer<typeof createRecordSchema>;

export const editRecordSchema = z.object(
  schemaShapeForType<StrictOmit<RecordUpdateRequest, "auxiliaryData">>()({
    queryData: optionalObject,
    error: optionalObject,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export const EDITABLE_RECORD_FIELDS = editRecordSchema.keyof().options;

export type EditRecordFormValues = Pick<
  Record,
  ElementOf<typeof EDITABLE_RECORD_FIELDS>
>;
