import React from "react";
import { Skeleton, Stack } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import type { NoInfer } from "~/types";
import { assertNever } from "~/utils";
import { QueryRenderer } from "../QueryRenderer";
import { getFieldKey, isAccessorField, renderField } from "./fields";
import type { FieldType, ResourceField } from "./types";

export interface ResourceFieldsProps<TResource extends object> {
  query: UseQueryResult<TResource>;
  fields: ReadonlyArray<ResourceField<NoInfer<TResource>>>;
}

export function ResourceFields<TResource extends object>({
  query,
  fields,
}: ResourceFieldsProps<TResource>) {
  return (
    <QueryRenderer
      query={query}
      loading={
        <Stack spacing={2} useFlexGap>
          {fields.map(renderSkeletonField)}
        </Stack>
      }
      success={(resource) => (
        <Stack spacing={2} useFlexGap component="dl">
          {fields.map((field) => (
            <div key={getFieldKey(field)}>{renderField(resource, field)}</div>
          ))}
        </Stack>
      )}
    />
  );
}

function renderSkeletonField(field: ResourceField<any>) {
  const dataType: FieldType = isAccessorField(field) ? field.dataType : "text";

  let valueSkeleton: React.ReactNode;
  switch (dataType) {
    case "text":
    case "id":
    case "number":
    case "percent":
    case "timestamp":
    case "bigint":
    case "datetime":
    case "bytes":
    case "boolean":
    case "digestion":
    case "group":
    case "ingestion":
    case "label":
    case "log":
    case "object-store":
    case "role":
    case "topic":
    case "user":
    case "workflow": {
      valueSkeleton = <Skeleton variant="text" sx={{ maxWidth: 350 }} />;

      break;
    }
    case "json":
    case "pre": {
      valueSkeleton = <Skeleton variant="rounded" height={250} />;

      break;
    }
    default: {
      assertNever(dataType);
    }
  }

  return (
    <div key={getFieldKey(field)}>
      <Skeleton variant="text" sx={{ maxWidth: 200 }} />
      {valueSkeleton}
    </div>
  );
}
