import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { UserEditForm } from "./components/UserEditForm";

export function EditUserPage() {
  return (
    <Page title="Edit User">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <UserEditForm />
      </Container>
    </Page>
  );
}
