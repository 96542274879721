import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { IngestionTable } from "./components/IngestionTable";

export function IngestionsPage() {
  return (
    <Page title="Search Ingestions">
      <FullWidthContainer>
        <IngestionTable />
      </FullWidthContainer>
    </Page>
  );
}
