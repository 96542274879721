import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { WorkflowHookEditForm } from "./components/WorkflowHookEditForm";

export function EditWorkflowHookPage() {
  return (
    <Page title="Edit Hook">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <WorkflowHookEditForm />
      </Container>
    </Page>
  );
}
