import type {
  ListUsersRequest,
  User,
  UserCreateRequest,
  UserUpdateRequest,
} from "~/services/datastore";
import { createResourceCrudHooks } from "./utils";

export const {
  queryKeyFactory: userKeys,
  useList: useUsers,
  useFetch: useUser,
  useCreate: useCreateUser,
  useUpdate: useUpdateUser,
  useDelete: useDeleteUser,
} = createResourceCrudHooks({
  baseQueryKey: "users",
  getIdentifier(user: User) {
    return user.id;
  },
  listResource({ signal }, { userApi }, request: ListUsersRequest) {
    return userApi.listUsers(request, { signal });
  },
  fetchResource({ signal }, { userApi }, userId: User["id"]) {
    return userApi.fetchUser({ userId }, { signal });
  },
  createResource({ userApi }, request: UserCreateRequest) {
    return userApi.createUser({ userCreateRequest: request });
  },
  updateResource({ userApi }, userId: User["id"], updates: UserUpdateRequest) {
    return userApi.updateUser({ userId, userUpdateRequest: updates });
  },
  deleteResource({ userApi }, userId: User["id"]) {
    return userApi.deleteUser({ userId });
  },
});
