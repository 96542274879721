import { z } from "zod";
import {
  MAX_CHART_FIELDS,
  SplitOrientation,
  VisualizationType,
  FlipDirection,
} from "../constants";

/**
 * @file models.ts
 *
 * This module encapsulates all the logic for serializing and deserializing
 * layout profiles kept in web storage to ensure they haven't been corrupted in
 * some way, such as a user editing the stored value. It exposes parsers and
 * types for both the runtime and storage format of the layout profiles, though
 * only the runtime types should be exposed outside the parent module.
 *
 * The runtime types refer to the shape of the data Studio works with in the
 * panel layout feature. It's a tree structure of panels - leaf nodes
 * representing panels on screen the user can interact with - and containers -
 * internal nodes transparent to the user which track the position and size
 * of panels and other containers.
 *
 * The storage types refer to the shape of the data when it's to be kept in
 * web storage. It's also a tree structure but with a different shape for the
 * panel nodes.
 *
 * This module additionally provides logic for migrating previous versions of
 * panel nodes. Prior implementations of this feature treated non-layout-related
 * panel fields as "state" and stored it as its own object on the panel node.
 * The runtime types have since moved away from that implementation but the
 * storage types have remained the same, as migrations aren't strictly necessary
 * to translate between the runtime and storage formats. The migration feature
 * uses several zod schemas representing "versions" of the stored data. Schemas
 * for older versions parse the data to ensure it's valid according to that
 * version's requirements, after which transformers will sequentially migrate
 * the data to the newest version of the storage format, applying default field
 * values when needed. The migration is entirely encapsulated within this
 * module: only the most recent storage format is exposed to sibling modules.
 *
 * The panel nodes have a notion, though not explicitly referenced, of
 * "session-only" fields: fields describing state about a given panel that
 * must persist for the duration of the panel's lifetime but should not be
 * stored. For example, the field describing if the image visualization's
 * controls are visible is considered session-only. These fields can be
 * identified by looking at the transforms between the runtime and storage
 * formats: session-only fields are dropped when transforming to the storage
 * format and given hard-coded default values when transforming to the runtime
 * format.
 */

// Panel visualization schemas

const TopicNameSchema = z.string();
const TopicTypeNameSchema = z.string().nullable();
const FieldsSchema = z.array(z.string()).max(MAX_CHART_FIELDS);
const VisualizationSchema = z.nativeEnum(VisualizationType);
const ImageRotationSchema = z.number();
const ImageFlipDirectionSchema = z.nativeEnum(FlipDirection);

// Parsing and migrating stored panel state

const PersistentPanelStateV1Schema = z.strictObject({
  // Existing v1 objects in storage may not have the version field set so it
  // needs to have a default
  version: z.literal("1").default("1"),
  name: TopicNameSchema,
  messageTypeName: TopicTypeNameSchema,
  fields: FieldsSchema,
  tab: VisualizationSchema,
});

const PersistentPanelStateV2Schema = PersistentPanelStateV1Schema.extend({
  version: z.literal("2"),
  image: z.strictObject({
    rotateDeg: ImageRotationSchema,
  }),
});

function transformV1ToV2(
  v1Data: z.infer<typeof PersistentPanelStateV1Schema>,
): z.infer<typeof PersistentPanelStateV2Schema> {
  return {
    ...v1Data,
    version: "2",
    image: {
      rotateDeg: 0,
    },
  };
}

const V1ToV2Schema = PersistentPanelStateV1Schema.transform(
  transformV1ToV2,
).pipe(PersistentPanelStateV2Schema);

// By putting the current state schema as the first member in the union, data
// will be parsed by it first. Assuming that over time most user profiles in
// web storage will use the most recent storage format, this saves time by
// not attempting to parse with older schemas unless necessary.
const PersistentPanelStateSchema = z
  .union([PersistentPanelStateV2Schema, V1ToV2Schema])
  .nullable();

// Base node schemas

const NodeIdSchema = z.number();
const ParentNodeIdSchema = z.number().nullable();
const FlexSchema = z.number();

const BasePanelNodeSchema = z.strictObject({
  type: z.literal("panel"),
  id: NodeIdSchema,
  parentId: ParentNodeIdSchema,
  flex: FlexSchema,
});

const BaseContainerNodeSchema = z.strictObject({
  type: z.literal("container"),
  id: NodeIdSchema,
  parentId: ParentNodeIdSchema,
  flex: FlexSchema,
  orientation: z.nativeEnum(SplitOrientation),
});

// Panel nodes schemas

const StoredPanelNodeSchema = BasePanelNodeSchema.extend({
  state: PersistentPanelStateSchema,
});

type StoredPanelNode = z.infer<typeof StoredPanelNodeSchema>;

const UninitializedRuntimePanelNodeSchema = BasePanelNodeSchema.extend({
  isInitialized: z.literal(false),
});

const InitializedRuntimePanelNodeSchema = BasePanelNodeSchema.extend({
  isInitialized: z.literal(true),
  topicName: TopicNameSchema,
  topicTypeName: TopicTypeNameSchema,
  visualization: VisualizationSchema,
  fields: FieldsSchema,
  imageRotationDeg: ImageRotationSchema,
  imageFlipDirection: ImageFlipDirectionSchema.nullable(),
  segmentationTopicName: z.string().nullable(),
  lockSegmentations: z.boolean(),
  segmentationRotationDeg: ImageRotationSchema,
  segmentationFlipDirection: ImageFlipDirectionSchema.nullable(),
  showPanelControls: z.boolean(),
  selectedTag: z.string().nullable(),
  showSegmentationBoundingBoxes: z.boolean(),
  showSegmentationClassNames: z.boolean(),
  hiddenObjectClassNames: z.array(z.string()),
});

const RuntimePanelNodeSchema = z.discriminatedUnion("isInitialized", [
  UninitializedRuntimePanelNodeSchema,
  InitializedRuntimePanelNodeSchema,
]);

type RuntimePanelNode = z.infer<typeof RuntimePanelNodeSchema>;

// Panel node transformations

// Given a parsed panel node in storage format, transform it into an equivalent
// panel node in runtime format.
function storedPanelToRuntimePanel(
  storedPanel: StoredPanelNode,
): RuntimePanelNode {
  const { state, ...baseFields } = storedPanel;

  if (state === null) {
    return {
      ...baseFields,
      isInitialized: false,
    };
  } else {
    return {
      ...baseFields,
      isInitialized: true,
      topicName: state.name,
      topicTypeName: state.messageTypeName,
      visualization: state.tab,
      fields: state.fields,
      imageRotationDeg: state.image.rotateDeg,
      imageFlipDirection: null,
      segmentationTopicName: null,
      lockSegmentations: true,
      segmentationRotationDeg: 0,
      segmentationFlipDirection: null,
      showPanelControls: false,
      selectedTag: null,
      showSegmentationBoundingBoxes: true,
      showSegmentationClassNames: true,
      hiddenObjectClassNames: [],
    };
  }
}

// Given a parsed panel node in runtime format, transform it into an equivalent
// panel node in storage format.
function runtimePanelToStoredPanel(
  runtimePanel: RuntimePanelNode,
): StoredPanelNode {
  const basePanel = {
    type: runtimePanel.type,
    id: runtimePanel.id,
    parentId: runtimePanel.parentId,
    flex: runtimePanel.flex,
  };

  if (runtimePanel.isInitialized) {
    return {
      ...basePanel,
      state: {
        version: "2",
        name: runtimePanel.topicName,
        messageTypeName: runtimePanel.topicTypeName,
        fields: runtimePanel.fields,
        tab: runtimePanel.visualization,
        image: {
          rotateDeg: runtimePanel.imageRotationDeg,
        },
      },
    };
  } else {
    return {
      ...basePanel,
      state: null,
    };
  }
}

// Container and layout node schemas

// zod has a concept of schema input and output types, with input essentially
// meaning "the shape of data that would be successfully parsed by the base
// schema" and output meaning "the shape of the parsed data after any
// transformations." If no transformations or refinements are used then there's
// no difference. Honestly, I don't think the input type has any meaningful
// usage but it's a required generic for all schemas. This pattern of creating
// separate input and output generics is necessary for defining lazy schemas
// when transformations are involved.
type StoredContainerNodeInput = z.input<typeof BaseContainerNodeSchema> & {
  firstChild: StoredLayoutNodeInput;
  secondChild: StoredLayoutNodeInput;
};

type StoredLayoutNodeInput =
  | z.input<typeof StoredPanelNodeSchema>
  | StoredContainerNodeInput;

type StoredContainerNodeOutput = z.output<typeof BaseContainerNodeSchema> & {
  firstChild: StoredLayoutNodeOutput;
  secondChild: StoredLayoutNodeOutput;
};

type StoredLayoutNodeOutput =
  | z.output<typeof StoredPanelNodeSchema>
  | StoredContainerNodeOutput;

type RuntimeContainerNode = z.infer<typeof BaseContainerNodeSchema> & {
  firstChild: RuntimeLayoutNode;
  secondChild: RuntimeLayoutNode;
};

type RuntimeLayoutNode =
  | z.infer<typeof RuntimePanelNodeSchema>
  | RuntimeContainerNode;

// The following unions with lazy schemas will parse a tree of panel or
// container nodes in one format and transform it to the other. Adding the
// transformations to the panel nodes in each union does mean there aren't
// schemas which can parse data in their base format (i.e. no parsing runtime
// format and outputting runtime format) but there's currently no need for that.
// When parsing the storage format, the goal is to make sure it's valid and
// immediately convert to runtime format, and vice versa. By applying the
// transformations to the panel node schemas we avoid needing to process the
// tree a second time to transform between formats which is difficult for TS.

const StoredToRuntimeLayoutNodeSchema: z.ZodType<
  RuntimeLayoutNode,
  z.ZodTypeDef,
  StoredLayoutNodeInput
> = z.union([
  StoredPanelNodeSchema.transform(storedPanelToRuntimePanel),
  BaseContainerNodeSchema.extend({
    firstChild: z.lazy(() => StoredToRuntimeLayoutNodeSchema),
    secondChild: z.lazy(() => StoredToRuntimeLayoutNodeSchema),
  }),
]);

const RuntimeToStoredLayoutNodeSchema: z.ZodType<
  StoredLayoutNodeOutput,
  z.ZodTypeDef,
  RuntimeLayoutNode
> = z.union([
  RuntimePanelNodeSchema.transform(runtimePanelToStoredPanel),
  BaseContainerNodeSchema.extend({
    firstChild: z.lazy(() => RuntimeToStoredLayoutNodeSchema),
    secondChild: z.lazy(() => RuntimeToStoredLayoutNodeSchema),
  }),
]);

// Layout profile schemas

const BaseLayoutProfileSchema = z.strictObject({
  name: z.string(),
});

export const StoredToRuntimeLayoutProfileSchema =
  BaseLayoutProfileSchema.extend({
    layout: StoredToRuntimeLayoutNodeSchema,
  });

export const RuntimeProfileArraySchema =
  StoredToRuntimeLayoutProfileSchema.array();

export const RuntimeToStoredLayoutProfileSchema =
  BaseLayoutProfileSchema.extend({
    layout: RuntimeToStoredLayoutNodeSchema,
  });

export const StoredProfileArraySchema =
  RuntimeToStoredLayoutProfileSchema.array();

// Curated exports

export type UninitializedRuntimePanelNode = z.infer<
  typeof UninitializedRuntimePanelNodeSchema
>;

export type InitializedRuntimePanelNode = z.infer<
  typeof InitializedRuntimePanelNodeSchema
>;

export type { RuntimePanelNode, RuntimeContainerNode, RuntimeLayoutNode };

export type RuntimeLayoutProfile = z.infer<
  typeof StoredToRuntimeLayoutProfileSchema
>;

export type RuntimeLayoutProfilesList = z.infer<
  typeof RuntimeProfileArraySchema
>;
