import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { UserTable } from "./components/UserTable";

export function UsersPage() {
  return (
    <Page title="Search Users">
      <FullWidthContainer>
        <UserTable />
      </FullWidthContainer>
    </Page>
  );
}
