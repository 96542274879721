import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  NumberField,
  processStateOptions,
  SelectField,
  useStudioForm,
  WorkflowSelect,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListDigestionPartsFormValues } from "../schemas";
import { listDigestionPartsSchema } from "../schemas";

export interface DigestionPartFiltersProps {
  values: ListDigestionPartsFormValues;
  setValues: (newValues: ListDigestionPartsFormValues) => void;
}

export function DigestionPartFilters({
  values,
  setValues,
}: DigestionPartFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listDigestionPartsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item xs={3}>
        <NumberField control={control} name="sequence" />
      </Grid>
      <Grid item xs={3}>
        <WorkflowSelect control={control} name="workflowId" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="workflowIdNull" />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          control={control}
          name="state"
          options={processStateOptions}
        />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
