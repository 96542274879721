import {
  CheckboxField,
  EditResourceForm,
  NumberField,
  ObjectField,
  processStateOptions,
  SelectField,
  WorkflowSelect,
} from "~/components/Form";
import { useDigestionPart, useUpdateDigestionPart } from "~/domain/datastores";
import { makeDigestionPartLocation, useDigestionPartParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_DIGESTION_PART_FIELDS,
  editDigestionPartSchema,
} from "../schemas";

export function DigestionPartEditForm() {
  const { digestionId, digestionPartId } = useDigestionPartParams();

  return (
    <EditResourceForm
      schema={editDigestionPartSchema}
      resourceName="digestion part"
      query={useDigestionPart(digestionId, digestionPartId, {
        select: selectData,
      })}
      editableFields={EDITABLE_DIGESTION_PART_FIELDS}
      mutation={useUpdateDigestionPart(digestionId, digestionPartId)}
      resourceLocation={makeDigestionPartLocation({
        digestionId,
        digestionPartId,
      })}
    >
      {(control) => (
        <>
          <NumberField control={control} name="sequence" required />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <NumberField control={control} name="progress" />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <ObjectField control={control} name="error" />
          <CheckboxField control={control} name="locked" />
        </>
      )}
    </EditResourceForm>
  );
}
