import React, { useState } from "react";
import { Error, Refresh, Tune } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { TableCog } from "mdi-material-ui";
import type { ListResponse } from "~/domain/datastores";
import type { DataStorePathGenerator } from "~/paths";
import { DataStoreLink } from "~/paths";
import { getFieldLabel, pluralize } from "~/utils";
import type { NormalizedColumn } from "./types";

export interface TableHeaderProps<TResource extends object> {
  /**
   * Name of the resource being searched, i.e. "log" or "digestion." Should
   * be singular, not plural.
   */
  resource: string;
  resourceCreateLocation?: DataStorePathGenerator;
  columns: ReadonlyArray<NormalizedColumn<TResource>>;
  visibleColumns: ReadonlyArray<NormalizedColumn<TResource>>;
  toggleColumnVisibility: (column: NormalizedColumn<TResource>) => void;
  searchQuery: UseQueryResult<ListResponse<TResource>>;
  filterSection?: React.ReactNode;
  activeFilterCount?: number;
}

export function TableHeader<TResource extends object>({
  resourceCreateLocation,
  resource,
  columns,
  visibleColumns,
  toggleColumnVisibility,
  searchQuery,
  filterSection,
  activeFilterCount,
}: TableHeaderProps<TResource>) {
  const [areFiltersExpanded, setAreFiltersExpanded] = useState(false);

  const columnMenuPopupState = usePopupState({
    variant: "popover",
    popupId: "column-menu",
  });

  function makeColumnVisibilityToggleHandler(
    column: NormalizedColumn<TResource>,
  ) {
    return function handleToggleColumnVisibility() {
      toggleColumnVisibility(column);
    };
  }

  function handleFilterSectionToggle() {
    setAreFiltersExpanded(!areFiltersExpanded);
  }

  function handleRefresh() {
    searchQuery.refetch();
  }

  let message;
  if (searchQuery.isLoading) {
    message = <Typography>Fetching {resource}s...</Typography>;
  } else if (searchQuery.isError) {
    message = (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Error color="error" />
        <Typography>Unable to perform search</Typography>
      </Stack>
    );
  } else if (searchQuery.isRefetching) {
    message = <Typography>Searching...</Typography>;
  } else {
    message = (
      <Typography>{pluralize(searchQuery.data.count, resource)}</Typography>
    );
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center">
        <Tooltip title="Toggle column visibility" sx={{ mr: 1 }}>
          <Badge
            color="primary"
            variant="dot"
            overlap="circular"
            invisible={visibleColumns.length === columns.length}
          >
            <IconButton size="small" {...bindTrigger(columnMenuPopupState)}>
              <TableCog fontSize="small" />
            </IconButton>
          </Badge>
        </Tooltip>
        <Menu
          slotProps={{
            paper: {
              sx: {
                minWidth: 250,
                maxHeight: 500,
              },
            },
          }}
          {...bindMenu(columnMenuPopupState)}
        >
          {columns.map((column) => {
            const isVisible = visibleColumns.some(
              ({ header }) => header === column.header,
            );

            return (
              <MenuItem
                key={column.header}
                onClick={makeColumnVisibilityToggleHandler(column)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={isVisible}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                {column.header}
              </MenuItem>
            );
          })}
        </Menu>
        {filterSection !== undefined && (
          <Tooltip title="Filters" sx={{ mr: 1 }}>
            <Badge badgeContent={activeFilterCount} color="primary">
              <IconButton size="small" onClick={handleFilterSectionToggle}>
                <Tune fontSize="small" />
              </IconButton>
            </Badge>
          </Tooltip>
        )}
        {message}
        <Tooltip title="Refresh" sx={{ ml: "auto" }}>
          <IconButton size="small" onClick={handleRefresh}>
            <Refresh fontSize="small" />
          </IconButton>
        </Tooltip>
        {resourceCreateLocation !== undefined && (
          <Button
            sx={{ ml: 2 }}
            color="primary"
            variant="contained"
            component={DataStoreLink}
            to={resourceCreateLocation}
          >
            Create {getFieldLabel(resource)}
          </Button>
        )}
      </Stack>
      <Box position="relative">
        <Divider />
        {searchQuery.isRefetching && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
        {filterSection !== undefined && (
          <Collapse
            sx={{
              "& .MuiCollapse-wrapperInner": {
                position: "relative",
                my: 2,
              },
            }}
            in={areFiltersExpanded}
          >
            {filterSection}
            <Divider sx={{ mt: 2 }} />
            {searchQuery.isRefetching && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              />
            )}
          </Collapse>
        )}
      </Box>
    </Stack>
  );
}
