import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { RecordTable } from "./components/RecordTable";

export function RecordsPage() {
  return (
    <Page title="Search Records">
      <FullWidthContainer>
        <DataStoreBreadcrumbs />
        <RecordTable />
      </FullWidthContainer>
    </Page>
  );
}
