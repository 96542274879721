import { useCallback } from "react";
import type { NavigateOptions } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCurrentDataStore } from "~/domain/datastores";
import type { DataStorePathGenerator } from "./types";

export function useNavigateDataStore() {
  const dataStore = useCurrentDataStore();

  const navigate = useNavigate();

  return useCallback(
    (generator: DataStorePathGenerator, options?: NavigateOptions) => {
      const path = generator(dataStore);

      navigate(path, options);
    },
    [dataStore, navigate],
  );
}
