import React from "react";
import { NavigateNext } from "@mui/icons-material";
import { ListItemButton, ListItemText } from "@mui/material";
import type { Topic } from "~/services/datastore";
import type { InitializedPanelNode } from "../../../../panels";
import { rotateImage, setImageFlipDirection } from "../../../../panels";
import { ControlsSection, TagsControlButton } from "../../../PanelControls";
import { Transformations } from "./Transformations";

export interface GeneralControlsProps {
  panel: InitializedPanelNode;
  topic: Topic;
  onToSegmentationsPage: () => void;
}

export function GeneralControls({
  panel,
  topic,
  onToSegmentationsPage,
}: GeneralControlsProps) {
  return (
    <>
      <ControlsSection>
        <TagsControlButton topic={topic} />
      </ControlsSection>
      <ControlsSection>
        <Transformations
          panel={panel}
          type="image"
          rotateActionCreator={rotateImage}
          flipDirection={panel.imageFlipDirection}
          flipActionCreator={setImageFlipDirection}
        />
      </ControlsSection>
      <ControlsSection>
        <ListItemButton onClick={onToSegmentationsPage}>
          <ListItemText>Segmentations</ListItemText>
          <NavigateNext />
        </ListItemButton>
      </ControlsSection>
    </>
  );
}
