import React, { useMemo, useState } from "react";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { matchSorter } from "match-sorter";
import { compact } from "~/lib/std";
import type { Topic } from "~/services/datastore";
import { traverseTree } from "~/utils";

export interface TopicTreeProps {
  topics: ReadonlyArray<Topic>;
  filter?: string;
  onSelect: (topic: Topic) => void;
}

interface TopicNode {
  name: Topic["name"];
  nodeId: string;
  typeName?: Topic["typeName"];
  children: TopicNode[];
}

export default function TopicTree({
  topics,
  filter = "",
  onSelect,
}: TopicTreeProps) {
  const { rootNodes, nodes } = useMemo(() => {
    const filteredTopics = matchSorter(topics, filter, {
      keys: ["name", (topic) => topic.typeName ?? []],
      threshold: matchSorter.rankings.CONTAINS,
      sorter: (matchItems) => matchItems,
    });

    return treeifyTopics(filteredTopics);
  }, [filter, topics]);

  const [defaultExpanded] = useState<Array<TopicNode["nodeId"]>>(() => {
    if (filter === "") {
      return [];
    }

    const internalNodeIds: Array<TopicNode["nodeId"]> = [];

    function visitor(node: TopicNode) {
      if (node.children.length > 0) {
        // Don't add the ID of leaf nodes since they can't expand
        internalNodeIds.push(node.nodeId);
      }
    }

    traverseTree(rootNodes, (node) => node.children, visitor);

    return internalNodeIds;
  });

  function handleNodeSelect(
    e: React.SyntheticEvent,
    nodeId: TopicNode["nodeId"],
  ) {
    const topic = nodes.get(nodeId);

    // The node map only contains the IDs of leaf nodes since they represent
    // actual topics, not sub-paths of topics. Selecting a non-leaf node
    // shouldn't fire the `onSelect` callback.
    if (topic !== undefined) {
      onSelect(topic);
    }
  }

  if (rootNodes.length === 0) {
    return (
      <Typography>No topics or message types matched your search</Typography>
    );
  } else {
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        defaultExpanded={defaultExpanded}
        onNodeSelect={handleNodeSelect}
      >
        {rootNodes.map(renderTree)}
      </TreeView>
    );
  }
}

/**
 * Generates a list of globally unique node IDs based on the parts of the
 * topic's name which are assumed to have already been split on the "/"
 * character.
 *
 * Each node ID is the absolute path from the base namespace to the given node.
 * Leaf nodes are distinguished from internal nodes by prepending a prefix.
 * For example, if run against a topic named "/namespace/sub_namespace/topic",
 * the node IDs would be:
 * ["internal:/namespace", "internal:/namespace/sub_namespace", "leaf:/namespace/sub_namespace/topic"]
 *
 * @param pathParts Array of strings created by splitting a topic's name on "/"
 */
function generateTopicNodeIds(pathParts: string[]): string[] {
  const nodeIds: string[] = [];

  pathParts.forEach((part, index, parts) => {
    // Some IDs can represent both actual topics (i.e. leaf nodes) and
    // internal nodes which have leaves under them. An example would be
    // the topics `/a/b` and `/a/b/c` where the node `b` would represent
    // both a leaf and an internal node. In this case there needs to be two
    // separate nodes in the tree differentiated by leaf status
    const prefix = index === parts.length - 1 ? "leaf" : "internal";
    const nodeId = `${prefix}/${parts.slice(0, index + 1).join("/")}`;

    nodeIds.push(nodeId);
  });

  return nodeIds;
}

function treeifyTopics(topics: Topic[]) {
  const rootNodes: TopicNode[] = [];
  const nodes = new Map<string, Topic>();

  // To easily look up existing nodes and append children to them, a cache
  // is kept mapping node IDs to that node's array of children. Root nodes have
  // no parent so `null` is used instead
  const cache = new Map<TopicNode["nodeId"] | null, TopicNode[]>([
    [null, rootNodes],
  ]);

  topics.forEach((topic) => {
    const { name, typeName } = topic;

    const nameParts = compact(name.split("/"));
    const topicNodeIds = generateTopicNodeIds(nameParts);

    nameParts.forEach((pathPart, index, pathParts) => {
      const currentId = topicNodeIds[index];
      // Root nodes will be at index 0 and have no parent so the "path"
      // should default to `null`
      const parentPath = topicNodeIds[index - 1] ?? null;

      if (!cache.has(currentId)) {
        const node: TopicNode = {
          name: pathPart,
          nodeId: currentId,
          children: [],
        };

        if (index === pathParts.length - 1) {
          node.typeName = typeName;

          nodes.set(currentId, topic);
        }

        cache.get(parentPath)!.push(node);
        cache.set(currentId, node.children);
      }
    });
  });

  return { rootNodes, nodes };
}

function renderTree(treeItem: TopicNode) {
  const isLeaf = treeItem.children.length === 0;

  function formatTopicName(name: TopicNode["name"]) {
    return (
      <>
        /
        {name.split(/(_)/).map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {part === "_" && <wbr />}
          </React.Fragment>
        ))}
      </>
    );
  }

  function formatTypeName(typeName: TopicNode["typeName"]) {
    if (typeName != null) {
      return typeName.split(/([_/])/).map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {["_", "/"].includes(part) && <wbr />}
        </React.Fragment>
      ));
    }
  }

  let label;
  if (isLeaf) {
    label = (
      <>
        <Typography component="span">
          {formatTopicName(treeItem.name)}
        </Typography>{" "}
        <Typography component="span" variant="subtitle2" color="text.secondary">
          {formatTypeName(treeItem.typeName)}
        </Typography>
      </>
    );
  } else {
    label = <b>{formatTopicName(treeItem.name)}</b>;
  }

  return (
    <TreeItem key={treeItem.nodeId} nodeId={treeItem.nodeId} label={label}>
      {isLeaf ? null : treeItem.children.map(renderTree)}
    </TreeItem>
  );
}
