import { Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  LockCard,
  ManageCard,
  RelatedResourceLink,
  ResourceFields,
} from "~/components/DetailsCards";
import {
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { calculateProcessRefetchInterval, Process } from "~/components/Process";
import { QueryRenderer } from "~/components/QueryRenderer";
import {
  useDeleteIngestion,
  useIngestion,
  useUpdateIngestion,
} from "~/domain/datastores";
import { pick } from "~/lib/std";
import {
  makeEditIngestionLocation,
  makeIngestionPartsLocation,
  makeIngestionsLocation,
  useIngestionParams,
} from "~/paths";
import type { Ingestion } from "~/services/datastore";
import { selectData } from "~/utils";
import { editIngestionSchema } from "../schemas";

export function IngestionDetails() {
  const { ingestionId } = useIngestionParams();

  const query = useIngestion(ingestionId, {
    select: selectData,
    refetchInterval: calculateProcessRefetchInterval,
  });

  const generalSection = <GeneralSection query={query} />;
  const processSection = (
    <Process query={query} mutation={useUpdateIngestion(ingestionId)} />
  );
  const relatedResourcesSection = (
    <Stack spacing={2} useFlexGap>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResourceLink
        text="Parts"
        to={makeIngestionPartsLocation({ ingestionId })}
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="ingestion"
      query={query}
      updateMutation={useUpdateIngestion(ingestionId)}
    />
  );
  const historySection = <HistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="ingestion"
      query={query}
      editLocation={makeEditIngestionLocation({ ingestionId })}
      deleteMutation={useDeleteIngestion(ingestionId)}
      getReadableName={(ingestion) => ingestion.name ?? ingestion.id}
      listLocation={makeIngestionsLocation()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {processSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {processSection}
          {lockSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Ingestion> }) {
  return (
    <Card title="General">
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "log", accessor: "logId" },
          { dataType: "text", accessor: "objectKey" },
          { dataType: "object-store", accessor: "objectStoreId" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Ingestion> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["text", "multiline", "multiline"]} />}
        success={(ingestion) => <InfoSectionImpl ingestion={ingestion} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ ingestion }: { ingestion: Ingestion }) {
  const updateIngestion = useUpdateIngestion(ingestion.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editIngestionSchema.pick({
    name: true,
    note: true,
    context: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(ingestion, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateIngestion.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Ingestion updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update ingestion", { variant: "error" });
        },
      });
    },
  });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        loading={updateIngestion.isLoading}
        submitText="Save Changes"
        disabled={ingestion.locked}
      >
        <TextField control={control} name="name" />
        <TextField control={control} name="note" multiline />
        <ObjectField control={control} name="context" />
      </Form>
      {ingestion.locked && (
        <Typography variant="body2">
          Unlock this ingestion to make updates.
        </Typography>
      )}
    </>
  );
}
