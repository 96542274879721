import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { RoleDetails } from "./components/RoleDetails";

export function RolePage() {
  return (
    <Page title="Role Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <RoleDetails />
      </Container>
    </Page>
  );
}
