import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import type {
  DataStoreListResponse,
  ListDatastoresRequest,
} from "~/services/dsm";
import type { KeyFactory } from "~/types";
import {
  DataStoreManagementQueryClientContext,
  useDataStoreManagementContext,
} from "./provider";

export const dataStoreKeys = {
  all: ["datastores"] as const,
  lists: () => [...dataStoreKeys.all, "list"] as const,
  list: (request: ListDatastoresRequest) =>
    [...dataStoreKeys.lists(), request] as const,
};

export type DataStoreKeys = KeyFactory<typeof dataStoreKeys>;

export function useDataStores<TData = DataStoreListResponse>(
  request: ListDatastoresRequest,
  options?: StrictOmit<
    UseQueryOptions<
      DataStoreListResponse,
      unknown,
      TData,
      DataStoreKeys["list"]
    >,
    "context"
  >,
) {
  const { dataStoreApi } = useDataStoreManagementContext();

  return useQuery({
    queryKey: dataStoreKeys.list(request),
    queryFn({ signal }) {
      return dataStoreApi.listDatastores(request, { signal });
    },
    ...options,
    context: DataStoreManagementQueryClientContext,
  });
}
