import { nanosecondsToMilliseconds } from "~/lib/dates";
import type { TimeRange } from "../types";

export function calculateWindowTicks(
  recordWindow: TimeRange,
  intervalLength: bigint,
): number[] {
  // Floor to a multiple of the interval size
  let firstTick = (recordWindow.startTime / intervalLength) * intervalLength;
  if (firstTick < recordWindow.startTime) {
    firstTick += intervalLength;
  }

  const ticks = [nanosecondsToMilliseconds(firstTick)];
  for (
    let tick = firstTick;
    tick < recordWindow.endTime;
    tick += intervalLength
  ) {
    ticks.push(nanosecondsToMilliseconds(tick));
  }

  return ticks;
}
