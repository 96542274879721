import type { Topic } from "~/services/datastore";
import { useRecordsQueries } from "../queries";

export function useFirstRecordTimestamp(topicId: Topic["id"]) {
  const [query] = useRecordsQueries({
    requests: [
      {
        topicId,
        limit: 1,
      },
    ],
    options: {
      select(response): bigint | undefined {
        const {
          data: [record],
        } = response;

        if (record === undefined) {
          return undefined;
        } else {
          return record.timestamp;
        }
      },
    },
  });

  return query;
}
