import { useRole, useRoles } from "~/domain/datastores";
import type { Role } from "~/services/datastore";
import { makeResourceSelect } from "./makeResourceSelect";
import type { Option } from "./types";

export const RoleSelect = makeResourceSelect({
  useGet(uuid) {
    return useRole(uuid, {
      select({ data }) {
        return makeOptionFromRole(data);
      },
    });
  },
  useSearch(text) {
    return useRoles(
      {
        nameLike: text,
        limit: 5,
        sort: "asc",
        order: "name",
      },
      {
        keepPreviousData: true,
        enabled: text !== null,
        select({ data }) {
          return data.map(makeOptionFromRole);
        },
      },
    );
  },
});

function makeOptionFromRole({ id, name }: Role): Option {
  return {
    value: id,
    label: name,
  };
}
