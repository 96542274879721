import React from "react";
import { useGroup } from "~/domain/datastores";
import { makeGroupLocation } from "~/paths";
import type { Group } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface GroupInfoProps {
  groupId: Group["id"];
}

export function GroupInfo({ groupId }: GroupInfoProps) {
  const groupNameQuery = useGroup(groupId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <ResourceInfo
      name={groupNameQuery.data}
      identifier={groupId}
      to={makeGroupLocation({ groupId })}
    />
  );
}
