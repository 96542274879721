import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { LogEditForm } from "./components/LogEditForm";

export function EditLogPage() {
  return (
    <Page title="Edit Log">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LogEditForm />
      </Container>
    </Page>
  );
}
