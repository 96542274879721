import { useLog, useLogs } from "~/domain/datastores";
import { makeResourceSelect } from "./makeResourceSelect";

export const LogSelect = makeResourceSelect({
  useGet(uuid) {
    return useLog(uuid, {
      select({ data: { id, name } }) {
        return {
          value: id,
          label: name,
        };
      },
    });
  },
  useSearch(text) {
    return useLogs(
      {
        nameLike: text,
        limit: 5,
        sort: "asc",
        order: "name",
      },
      {
        keepPreviousData: true,
        enabled: text !== null,
        select({ data }) {
          return data.map((log) => ({
            value: log.id,
            label: log.name,
          }));
        },
      },
    );
  },
});
