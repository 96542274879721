import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import {
  requiredText,
  optionalUuid,
  schemaShapeForType,
  boolean,
  optionalText,
  optionalObject,
} from "~/components/Form";
import { filterBoolean, filterText, filterUuid } from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  Group,
  GroupCreateRequest,
  GroupUpdateRequest,
  ListGroupsRequest,
} from "~/services/datastore";

export const listGroupsSchema = z.object(
  schemaShapeForType<ListGroupsRequest>()({
    name: filterText,
    nameLike: filterText,
    defaultWorkflowId: filterUuid,
    defaultWorkflowIdNull: filterBoolean,
    ...auditFieldSchemas,
  }),
);

export type ListGroupsFormValues = z.infer<typeof listGroupsSchema>;

export const createGroupSchema = z.object(
  schemaShapeForType<GroupCreateRequest>()({
    name: requiredText,
    defaultWorkflowId: optionalUuid,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export type CreateGroupFormValues = z.infer<typeof createGroupSchema>;

export const editGroupSchema = z.object(
  schemaShapeForType<GroupUpdateRequest>()({
    name: requiredText,
    defaultWorkflowId: optionalUuid,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export const EDITABLE_GROUP_FIELDS = editGroupSchema.keyof().options;

export type EditGroupFormValues = Pick<
  Group,
  ElementOf<typeof EDITABLE_GROUP_FIELDS>
>;
