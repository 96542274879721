import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { LogQueryTable } from "./components/LogQueryTable";

export function LogQueriesPage() {
  return (
    <Page title="Search Queries">
      <FullWidthContainer>
        <DataStoreBreadcrumbs />
        <LogQueryTable />
      </FullWidthContainer>
    </Page>
  );
}
