import React, { useMemo } from "react";
import type { PaletteMode } from "@mui/material";
import { createSafeContext } from "~/contexts";
import { useLocalStorage } from "~/hooks";

const PALETTE_MODE_STORAGE_KEY = "palette-mode";

export interface DarkModeContextValue {
  mode: PaletteMode;
  setMode: React.Dispatch<React.SetStateAction<PaletteMode>>;
}

export const [useDarkMode, DarkModeContext] =
  createSafeContext<DarkModeContextValue>("DarkMode");

const defaultMode = "dark";

export interface DarkModeProviderProps {
  children: React.ReactNode;
}

export default function DarkModeProvider({ children }: DarkModeProviderProps) {
  const [mode, setMode] = useLocalStorage<PaletteMode>(
    PALETTE_MODE_STORAGE_KEY,
    defaultMode,
  );

  const value = useMemo(() => ({ mode, setMode }), [mode, setMode]);

  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  );
}
