import { selectData } from "~/utils";
import { useTopics } from "../queries";
import { usePlayerConfig } from "./usePlayerConfig";

export function usePlayerTopics() {
  const playerConfig = usePlayerConfig();

  const logId = playerConfig.logId ?? undefined;

  return useTopics(
    {
      logId,
      limit: -1,
      sort: "asc",
      order: "name",
    },
    { select: selectData, enabled: logId !== undefined },
  );
}
