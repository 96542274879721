import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  optionalText,
  requiredObject,
  requiredText,
  schemaShapeForType,
} from "~/components/Form";
import { filterBoolean, filterText } from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  ListRolesRequest,
  Role,
  RoleCreateRequest,
  RoleUpdateRequest,
} from "~/services/datastore";

export const listRolesSchema = z.object(
  schemaShapeForType<ListRolesRequest>()({
    name: filterText,
    nameLike: filterText,
    disabled: filterBoolean,
    _default: filterBoolean,
    managed: filterBoolean,
    ...auditFieldSchemas,
  }),
);

export type ListRolesFormValues = z.infer<typeof listRolesSchema>;

export const createRoleSchema = z.object(
  schemaShapeForType<RoleCreateRequest>()({
    name: requiredText,
    policy: requiredObject,
    note: optionalText,
    _default: boolean,
    managed: boolean,
    disabled: boolean,
  }),
);

export type CreateRoleFormValues = z.infer<typeof createRoleSchema>;

export const editRoleSchema = z.object(
  schemaShapeForType<RoleUpdateRequest>()({
    name: requiredText,
    policy: requiredObject,
    note: optionalText,
    _default: boolean,
    disabled: boolean,
  }),
);

export const EDITABLE_ROLE_FIELDS = editRoleSchema.keyof().options;

export type EditRoleFormValues = Pick<
  Role,
  ElementOf<typeof EDITABLE_ROLE_FIELDS>
>;
