import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { RecordDetails } from "./components/RecordDetails";

export function RecordPage() {
  return (
    <Page title="Record Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <RecordDetails />
      </Container>
    </Page>
  );
}
