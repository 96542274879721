import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { GroupEditForm } from "./components/GroupEditForm";

export function EditGroupPage() {
  return (
    <Page title="Edit Group">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <GroupEditForm />
      </Container>
    </Page>
  );
}
