import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { ObjectStoreCreateForm } from "./components/ObjectStoreCreateForm";

export function NewObjectStorePage() {
  return (
    <Page title="New Object Store">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <ObjectStoreCreateForm />
      </Container>
    </Page>
  );
}
