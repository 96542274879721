import { Button, Grid } from "@mui/material";
import { BooleanField, FuzzyField, useStudioForm } from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListRolesFormValues } from "../schemas";
import { listRolesSchema } from "../schemas";

export interface RoleFiltersProps {
  values: ListRolesFormValues;
  setValues: (newValues: ListRolesFormValues) => void;
}

export function RoleFilters({ values, setValues }: RoleFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listRolesSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="disabled" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="_default" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="managed" />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
