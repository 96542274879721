import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import type { ListResponse } from "~/domain/datastores";
import type { DataStorePathGenerator } from "~/paths";
import { Center } from "../Center";
import { Error } from "../Error";
import { Loading } from "../Loading";
import { TableFooter } from "./TableFooter";
import { TableHeader } from "./TableHeader";
import { normalizeColumn, useColumnVisibility } from "./columns";
import type { Column, NormalizedColumn } from "./types";
import type { ResourceTableModel } from "./validation";
import { SortDirection } from "./validation";

export interface ResourceTableProps<TResource extends object> {
  resourceName: string;
  resourceCreateLocation?: DataStorePathGenerator;
  getRowKey: (resource: TResource) => React.Key;
  columns: ReadonlyArray<Column<TResource>>;
  searchQuery: UseQueryResult<ListResponse<TResource>>;
  tableModel: ResourceTableModel;
  onTableModelChange: (changes: Partial<ResourceTableModel>) => void;
  filterSection?: React.ReactNode;
  activeFilterCount?: number;
}

export function ResourceTable<TResource extends object>({
  resourceCreateLocation,
  resourceName,
  getRowKey,
  columns,
  searchQuery,
  tableModel,
  onTableModelChange,
  filterSection,
  activeFilterCount,
}: ResourceTableProps<TResource>) {
  const normalizedColumns = columns.map(normalizeColumn);

  const { visibleColumns, toggleColumnVisibility } = useColumnVisibility(
    resourceName,
    normalizedColumns,
  );

  function makeSortChangeHandler(order: string) {
    const isActiveSort = order === tableModel.order;
    const newSort =
      !isActiveSort || tableModel.sort === SortDirection.Desc
        ? SortDirection.Asc
        : SortDirection.Desc;

    return function handleSortChange() {
      onTableModelChange({ sort: newSort, order, offset: 0 });
    };
  }

  function renderHeaderCell(column: NormalizedColumn<TResource>) {
    const { sortKey } = column;

    let children: React.ReactNode = column.header;
    if (sortKey !== undefined) {
      const isActiveSort = sortKey === tableModel.order;

      children = (
        <TableSortLabel
          active={isActiveSort}
          direction={isActiveSort ? tableModel.sort : SortDirection.Asc}
          onClick={makeSortChangeHandler(sortKey)}
        >
          {children}
        </TableSortLabel>
      );
    }

    return (
      <TableCell key={column.header} align={column.align}>
        {children}
      </TableCell>
    );
  }

  let body: React.ReactNode = null;
  if (searchQuery.isInitialLoading) {
    body = (
      <Box sx={{ py: 5 }}>
        <Loading type="circular" />
      </Box>
    );
  } else if (searchQuery.isError) {
    body = (
      <Box sx={{ py: 5 }}>
        <Error>
          <Typography variant="h4" component="p">
            An error occurred searching for {resourceName}s
          </Typography>
        </Error>
      </Box>
    );
  } else if (searchQuery.isSuccess && searchQuery.data.count === 0) {
    body = (
      <Center sx={{ py: 5 }}>
        <Typography variant="h4" component="p">
          The search returned 0 results
        </Typography>
      </Center>
    );
  } else if (searchQuery.isSuccess) {
    body = (
      <TableContainer sx={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                  borderBottom: "unset",
                },
              }}
            >
              {visibleColumns.map(renderHeaderCell)}
            </TableRow>
          </TableHead>
          <TableBody>
            {searchQuery.data.data.map((resource) => (
              <TableRow
                key={getRowKey(resource)}
                sx={{
                  // Remove bottom border for table cells in last row
                  [`&:last-of-type .${tableCellClasses.root}`]: {
                    borderBottom: "unset",
                  },
                }}
              >
                {visibleColumns.map((column) => (
                  <React.Fragment key={column.header}>
                    {column.renderCell(resource, { align: column.align })}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <TableHeader
        resourceCreateLocation={resourceCreateLocation}
        resource={resourceName}
        columns={normalizedColumns}
        visibleColumns={visibleColumns}
        toggleColumnVisibility={toggleColumnVisibility}
        searchQuery={searchQuery}
        filterSection={filterSection}
        activeFilterCount={activeFilterCount}
      />
      {body}
      <TableFooter
        count={searchQuery.data?.count}
        paginationModel={tableModel}
        onPaginationModelChange={onTableModelChange}
      />
    </>
  );
}
