import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  ManageCard,
  ResourceFields,
} from "~/components/DetailsCards";
import {
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { useDeleteTag, useTag, useUpdateTag } from "~/domain/datastores";
import { pick } from "~/lib/std";
import { makeEditTagLocation, makeTagsLocation, useTagParams } from "~/paths";
import type { Tag } from "~/services/datastore";
import { selectData } from "~/utils";
import { editTagSchema } from "../schemas";

export function TagDetails() {
  const { logId, tagId } = useTagParams();

  const query = useTag(logId, tagId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <HistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="tag"
      query={query}
      editLocation={makeEditTagLocation({ logId, tagId })}
      deleteMutation={useDeleteTag(logId, tagId)}
      getReadableName={(tag) => tag.id}
      listLocation={makeTagsLocation({ logId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Tag> }) {
  return (
    <Card title="General">
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "log", accessor: "logId" },
          { dataType: "label", accessor: "labelId" },
          { dataType: "topic", accessor: "topicId" },
          { dataType: "timestamp", accessor: "startTime" },
          { dataType: "timestamp", accessor: "endTime" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Tag> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["multiline", "multiline"]} />}
        success={(tag) => <InfoSectionImpl tag={tag} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ tag }: { tag: Tag }) {
  const updateTag = useUpdateTag(tag.logId, tag.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editTagSchema.pick({ note: true, context: true });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(tag, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateTag.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Tag updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update tag", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateTag.isLoading}
      submitText="Save Changes"
    >
      <TextField control={control} name="note" multiline />
      <ObjectField control={control} name="context" />
    </Form>
  );
}
