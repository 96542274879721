import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { ApiKeyTable } from "./components/ApiKeyTable";

export function ApiKeysPage() {
  return (
    <Page title="Search API Keys">
      <FullWidthContainer>
        <ApiKeyTable />
      </FullWidthContainer>
    </Page>
  );
}
