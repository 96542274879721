/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Group {
  locked: boolean;
  lockedBy: string | null;
  lockedAt: Date | null;
  lockToken: string | null;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  id: string;
  name: string;
  note: string | null;
  context: object | null;
  defaultWorkflowId: string | null;
}

export function GroupFromJSON(json: any): Group {
  return GroupFromJSONTyped(json);
}

export function GroupFromJSONTyped(json: any): Group {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    lockedBy: json["locked_by"],
    lockedAt: json["locked_at"] === null ? null : new Date(json["locked_at"]),
    lockToken: json["lock_token"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    id: json["id"],
    name: json["name"],
    note: json["note"],
    context: json["context"],
    defaultWorkflowId: json["default_workflow_id"],
  };
}

export function GroupToJSON(value: Group | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    locked_by: value.lockedBy,
    locked_at: value.lockedAt === null ? null : value.lockedAt.toISOString(),
    lock_token: value.lockToken,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    id: value.id,
    name: value.name,
    note: value.note,
    context: value.context,
    default_workflow_id: value.defaultWorkflowId,
  };
}
