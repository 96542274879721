import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { DigestionCreateForm } from "./components/DigestionCreateForm";

export function NewDigestionPage() {
  return (
    <Page title="New Digestion">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <DigestionCreateForm />
      </Container>
    </Page>
  );
}
