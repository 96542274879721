import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useLogQueries } from "~/domain/datastores";
import {
  DataStoreLink,
  makeLogQueryLocation,
  makeNewLogQueryLocation,
  useLogParams,
} from "~/paths";
import type { Query } from "~/services/datastore";
import { listLogQueriesSchema } from "../schemas";
import { LogQueryFilters } from "./LogQueryFilters";

const COLUMNS: ReadonlyArray<Column<Query>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell(logQuery) {
      return (
        <TableCell>
          {logQuery.name ?? "-"}
          <Link
            component={DataStoreLink}
            to={makeLogQueryLocation({
              logId: logQuery.logId,
              queryId: logQuery.id,
            })}
          >
            <Typography variant="body2">{logQuery.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listLogQueriesSchema);

export const LogQueryTable = withSearchValidationErrorBoundary(
  function LogQueryTable() {
    const { logId } = useLogParams();

    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useLogQueries(logId, prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="query"
            resourceCreateLocation={makeNewLogQueryLocation({ logId })}
            getRowKey={(logQuery) => logQuery.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <LogQueryFilters values={filterValues} setValues={setRequest} />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
