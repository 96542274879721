import { useRole } from "~/domain/datastores";
import { makeRoleLocation } from "~/paths";
import type { Role } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface RoleInfoProps {
  roleId: Role["id"];
}

export function RoleInfo({ roleId }: RoleInfoProps) {
  const roleNameQuery = useRole(roleId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <ResourceInfo
      name={roleNameQuery.data}
      identifier={roleId}
      to={makeRoleLocation({ roleId })}
    />
  );
}
