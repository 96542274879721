/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ProcessType = {
  Ingestion: "ingestion",
  IngestionPart: "ingestion_part",
  Digestion: "digestion",
  DigestionPart: "digestion_part",
} as const;
export type ProcessType = (typeof ProcessType)[keyof typeof ProcessType];

export function ProcessTypeFromJSON(json: any): ProcessType {
  return ProcessTypeFromJSONTyped(json, false);
}

export function ProcessTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProcessType {
  return json as ProcessType;
}

export function ProcessTypeToJSON(value?: ProcessType | null): any {
  return value as any;
}
