import type { AccessorResourceField } from "~/components/DetailsCards/types";
import type { Auditable } from "./types";

export const auditFields: ReadonlyArray<AccessorResourceField<Auditable>> = [
  { dataType: "user", accessor: "createdBy" },
  { dataType: "datetime", accessor: "createdAt" },
  { dataType: "user", accessor: "updatedBy" },
  { dataType: "datetime", accessor: "updatedAt" },
  { dataType: "user", accessor: "deletedBy" },
  { dataType: "datetime", accessor: "deletedAt" },
];
