import {
  createObjectExplorer,
  OBJECT_KEY_DELIMITER,
} from "~/components/ObjectExplorer";
import { useLogObjects } from "~/domain/datastores";
import {
  makeLogObjectLocation,
  makeLogObjectsLocation,
  useLogParams,
} from "~/paths";
import type { Log } from "~/services/datastore";

export const LogObjectsTable = createObjectExplorer({
  useIdentifier() {
    return useLogParams().logId;
  },
  useSearch: useLogObjects,
  generateDirectoryLocation(logId, directory) {
    if (directory === undefined) {
      return makeLogObjectsLocation(logId);
    } else {
      return makeLogObjectsLocation(logId, {
        directory: stripLogPrefix(logId, directory),
      });
    }
  },
  generateObjectLocation(logId, objectKey) {
    return makeLogObjectLocation({
      logId,
      key: stripLogPrefix(logId, objectKey),
    });
  },
});

function stripLogPrefix(logId: Log["id"], directoryOrKey: string): string {
  const searchString = `${logId}${OBJECT_KEY_DELIMITER}`;
  const searchStringIndex = directoryOrKey.indexOf(searchString);

  if (searchStringIndex === -1) {
    return directoryOrKey;
  } else {
    return directoryOrKey.slice(searchStringIndex + searchString.length);
  }
}
