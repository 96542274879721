import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useDigestionParts } from "~/domain/datastores";
import {
  DataStoreLink,
  makeDigestionPartLocation,
  makeNewDigestionPartLocation,
  useDigestionParams,
} from "~/paths";
import type { DigestionPart } from "~/services/datastore";
import { listDigestionPartsSchema } from "../schemas";
import { DigestionPartFilters } from "./DigestionPartFilters";

const COLUMNS: ReadonlyArray<Column<DigestionPart>> = [
  {
    header: "ID",
    renderCell(digestionPart) {
      return (
        <TableCell>
          <Link
            component={DataStoreLink}
            to={makeDigestionPartLocation({
              digestionId: digestionPart.digestionId,
              digestionPartId: digestionPart.id,
            })}
          >
            <Typography>{digestionPart.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "sequence",
    dataType: "number",
    isSortable: true,
  },
  {
    accessor: "state",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "progress",
    dataType: "percent",
    isSortable: true,
  },
  {
    accessor: "transitionedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "workflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
    defaultHidden: true,
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listDigestionPartsSchema);

export const DigestionPartTable = withSearchValidationErrorBoundary(
  function DigestionPartTable() {
    const { digestionId } = useDigestionParams();

    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useDigestionParts(
      digestionId,
      prepareListRequest(request),
      {
        keepPreviousData: true,
        cacheTime: 0,
      },
    );

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="digestion part"
            resourceCreateLocation={makeNewDigestionPartLocation({
              digestionId,
            })}
            getRowKey={(digestionPart) => digestionPart.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <DigestionPartFilters
                values={filterValues}
                setValues={setRequest}
              />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
