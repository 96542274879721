import {
  CheckboxField,
  NewResourceForm,
  NumberField,
  ObjectField,
  TextField,
  TimestampField,
} from "~/components/Form";
import { useCreateRecord } from "~/domain/datastores";
import { makeRecordLocation, useTopicParams } from "~/paths";
import { createRecordSchema } from "../schemas";

export function RecordCreateForm() {
  const { topicId } = useTopicParams();

  return (
    <NewResourceForm
      schema={createRecordSchema}
      defaultValues={{
        timestamp: null,
        dataOffset: null,
        dataLength: null,
        chunkCompression: null,
        chunkOffset: null,
        chunkLength: null,
        source: null,
        queryData: null,
        locked: false,
        note: null,
        context: null,
      }}
      resourceName="record"
      mutation={useCreateRecord(topicId)}
      generateLocation={(response) =>
        makeRecordLocation({ topicId, timestamp: response.data.timestamp })
      }
    >
      {(control) => (
        <>
          <TimestampField control={control} name="timestamp" required />
          <NumberField control={control} name="dataOffset" />
          <NumberField control={control} name="dataLength" />
          <TextField control={control} name="chunkCompression" />
          <NumberField control={control} name="chunkOffset" />
          <NumberField control={control} name="chunkLength" />
          <ObjectField control={control} name="queryData" />
          <TextField control={control} name="source" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewResourceForm>
  );
}
