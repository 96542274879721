import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { IngestionPartDetails } from "./components/IngestionPartDetails";

export function IngestionPartPage() {
  return (
    <Page title="Ingestion Part Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <IngestionPartDetails />
      </Container>
    </Page>
  );
}
