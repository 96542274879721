import { filterBoolean, filterDate, filterUuid } from "~/components/Table";

export const auditFieldSchemas = {
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedByNull: filterBoolean,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  updatedAtNull: filterBoolean,
  deletedBy: filterUuid,
  deletedByNull: filterBoolean,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
  deletedAtNull: filterBoolean,
} as const;
