import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { LabelTable } from "./components/LabelTable";

export function LabelsPage() {
  return (
    <Page title="Search Labels">
      <FullWidthContainer>
        <LabelTable />
      </FullWidthContainer>
    </Page>
  );
}
