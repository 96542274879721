import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { RecordEditForm } from "./components/RecordEditForm";

export function EditRecordPage() {
  return (
    <Page title="Edit Record">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <RecordEditForm />
      </Container>
    </Page>
  );
}
