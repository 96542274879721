import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  FuzzyField,
  GroupSelect,
  NumberField,
  TextField,
  TimestampField,
  useStudioForm,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListLogsFormValues } from "../schemas";
import { listLogsSchema } from "../schemas";

export interface LogFiltersProps {
  values: ListLogsFormValues;
  setValues: (newValues: ListLogsFormValues) => void;
}

export function LogFilters({ values, setValues }: LogFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listLogsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          <FuzzyField
            control={control}
            exactName="name"
            fuzzyName="nameLike"
            label="Name"
          />
        </Grid>
        <Grid item xs={3}>
          <GroupSelect control={control} name="groupId" />
        </Grid>
        <Grid item xs={3}>
          <BooleanField control={control} name="locked" />
        </Grid>
        <Grid item xs={3}>
          <TextField control={control} name="noteLike" label="Note" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <TimestampField control={control} name="timeAdjustmentGte" />
        </Grid>
        <Grid item xs={4}>
          <TimestampField control={control} name="timeAdjustmentLte" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField control={control} name="timeAdjustmentNull" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <TimestampField control={control} name="startTimeGte" />
        </Grid>
        <Grid item xs={4}>
          <TimestampField control={control} name="startTimeLte" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField control={control} name="startTimeNull" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <TimestampField control={control} name="endTimeGte" />
        </Grid>
        <Grid item xs={4}>
          <TimestampField control={control} name="endTimeLte" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField control={control} name="endTimeNull" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          <NumberField control={control} name="recordSizeGte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="recordSizeLte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="recordCountGte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="recordCountLte" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          <NumberField control={control} name="objectSizeGte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="objectSizeLte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="objectCountGte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="objectCountLte" />
        </Grid>
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
