import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { useDebouncedValue } from "~/hooks";
import type { Topic } from "~/services/datastore";
import type { UninitializedPanelNode } from "../../panels";
import { selectTopic, usePanelLayoutContext } from "../../panels";
import PanelLayout from "../PanelLayout";
import TopicTree from "../TopicTree";

interface TopicSelectorProps {
  panelId: UninitializedPanelNode["id"];
  topics: ReadonlyArray<Topic>;
}

export default function TopicSelector({ panelId, topics }: TopicSelectorProps) {
  const [inputValue, setInputValue] = useState("");
  const filter = useDebouncedValue(inputValue, 300);

  const { dispatch } = usePanelLayoutContext();

  function handleSelect(topic: Topic) {
    dispatch(selectTopic({ panelId, topic }));
  }

  return (
    <PanelLayout>
      <Box sx={{ width: 1, height: 1, overflowY: "auto" }}>
        <Box sx={{ width: "min(100%, 60ch)", p: 2 }}>
          <TextField
            sx={{ mb: 1 }}
            fullWidth
            label="Filter topics"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <TopicTree
            key={filter}
            topics={topics}
            filter={filter}
            onSelect={handleSelect}
          />
        </Box>
      </Box>
    </PanelLayout>
  );
}
