/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const UploadState = {
  Complete: "complete",
  Processing: "processing",
  Aborted: "aborted",
} as const;
export type UploadState = (typeof UploadState)[keyof typeof UploadState];

export function UploadStateFromJSON(json: any): UploadState {
  return UploadStateFromJSONTyped(json, false);
}

export function UploadStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UploadState {
  return json as UploadState;
}

export function UploadStateToJSON(value?: UploadState | null): any {
  return value as any;
}
