import React, { useMemo, useState } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { Navigate, Outlet } from "react-router-dom";
import { GlobalLoadingFallback } from "~/components/GlobalLoadingFallback";
import { createSafeContext } from "~/contexts";
import {
  createDataStoreApiConfiguration,
  createQueryClient,
} from "~/factories";
import * as paths from "~/paths";
import { useDataStoreParams } from "~/paths";
import {
  APIKeyApi,
  DigestionApi,
  GroupApi,
  IngestionApi,
  LabelApi,
  LogApi,
  ObjectStoreApi,
  RoleApi,
  TopicApi,
  UserApi,
  WorkflowApi,
} from "~/services/datastore";
import type { DataStore } from "~/services/dsm";
import { useDataStores } from "../../dsm";
import type { DataStoreClients } from "../types";

export type DataStoreContextValue = {
  dataStore: DataStore;
  clients: DataStoreClients;
};

export const [useDataStoreContext, DataStoreContext] =
  createSafeContext<DataStoreContextValue>("DataStore");

export function useCurrentDataStore() {
  return useDataStoreContext().dataStore;
}

export function useDataStoreClients() {
  return useDataStoreContext().clients;
}

export default function DataStoreProvider() {
  const { dataStoreName } = useDataStoreParams();

  const dataStoreQuery = useDataStores(
    // Search the user's DataStores for one whose name exactly matches
    // the URL path param
    { name: dataStoreName },
    {
      select(response) {
        if (response.count !== 1) {
          return null;
        }

        return response.data[0];
      },
    },
  );

  // TODO: Should do something better for errors
  if (!dataStoreQuery.isSuccess) {
    return <GlobalLoadingFallback />;
  }

  const { data: dataStore } = dataStoreQuery;

  if (dataStore === null) {
    const { state, ...to } = paths.makeDataStoresLocation({
      unknownDataStore: dataStoreName,
    });

    return <Navigate replace to={to} state={state} />;
  }

  return <InnerProvider key={dataStore.id} dataStore={dataStore} />;
}

function InnerProvider({ dataStore }: { dataStore: DataStore }) {
  const [clients] = useState(() => {
    const configuration = createDataStoreApiConfiguration(dataStore);

    return {
      apiKeyApi: new APIKeyApi(configuration),
      digestionApi: new DigestionApi(configuration),
      groupApi: new GroupApi(configuration),
      ingestionApi: new IngestionApi(configuration),
      labelApi: new LabelApi(configuration),
      logApi: new LogApi(configuration),
      objectStoreApi: new ObjectStoreApi(configuration),
      roleApi: new RoleApi(configuration),
      topicApi: new TopicApi(configuration),
      userApi: new UserApi(configuration),
      workflowApi: new WorkflowApi(configuration),
    };
  });

  // All DataStore queries will be isolated to their own query client.
  // Since this component is expected to be rendered with a `key` linked to
  // the current DataStore, changing DataStores will ensure the old client
  // is discarded and a new one always created.
  const [queryClient] = useState(createQueryClient);

  const value = useMemo(
    () => ({
      dataStore,
      clients,
    }),
    [dataStore, clients],
  );

  return (
    <DataStoreContext.Provider value={value}>
      <QueryClientProvider client={queryClient}>
        <Outlet />
      </QueryClientProvider>
    </DataStoreContext.Provider>
  );
}
