import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import type { UseRecordChunksResult } from "../hooks/useRecordChunks";

export interface PlaceholderOverlayProps {
  windowQuery: UseRecordChunksResult;
}

export default function PlaceholderOverlay({
  windowQuery,
}: PlaceholderOverlayProps) {
  if (!windowQuery.isPlaceholderData) {
    return null;
  }

  return (
    <Backdrop
      open={windowQuery.status === "loading"}
      sx={{ position: "absolute" }}
    >
      <CircularProgress />
    </Backdrop>
  );
}
