import type { Draft } from "immer";
import { produce } from "immer";
import { invariant } from "~/lib/invariant";
import { get, pull } from "~/lib/std";
import type { Record, Topic } from "~/services/datastore";
import { assertNever } from "~/utils";
import type {
  ContainerNode,
  InitializedPanelNode,
  LayoutNode,
  PanelNode,
  UninitializedPanelNode,
} from "./api";
import type { SplitOrientation } from "./constants";
import {
  FlipDirection,
  IMAGE_ROTATION_STEP_MAGNITUDE_DEG,
  MAX_CHART_FIELDS,
  RotationDirection,
  VisualizationType,
} from "./constants";
import { supportsImage, supportsMap, walkLayoutTree } from "./utils";

type LayoutState = { root: LayoutNode; nextId: number };

/**
 * Split a panel, creating a new, uninitialized sibling panel. The orientation
 * describes if the sibling should be to the right of or below the base panel.
 */
export type SplitPanelAction = {
  type: "split-panel";
  payload: {
    panelId: PanelNode["id"];
    orientation: SplitOrientation;
  };
};

export function splitPanel(
  payload: SplitPanelAction["payload"],
): SplitPanelAction {
  return {
    type: "split-panel",
    payload,
  };
}

/**
 * Remove a panel from the tree entirely. If the panel is the only one in the
 * tree, a new, uninitialized panel replaces it.
 */
export type RemovePanelAction = {
  type: "remove-panel";
  payload: {
    panelId: PanelNode["id"];
  };
};

export function removePanel(
  payload: RemovePanelAction["payload"],
): RemovePanelAction {
  return {
    type: "remove-panel",
    payload,
  };
}

/**
 * Resize a panel or container. The flex describes its relative size compared
 * to its sibling.
 */
export type ResizeNodeAction = {
  type: "resize-node";
  payload: {
    nodeId: LayoutNode["id"];
    flex: number;
  };
};

export function resizeNode(
  payload: ResizeNodeAction["payload"],
): ResizeNodeAction {
  return {
    type: "resize-node",
    payload,
  };
}

/**
 * Load a layout, overwriting the existing layout completely. Useful to load
 * layout profiles kept in a user's web storage.
 */
export type LoadLayoutAction = {
  type: "load-layout";
  payload: {
    layout: LayoutNode;
  };
};

export function loadLayout(
  payload: LoadLayoutAction["payload"],
): LoadLayoutAction {
  return {
    type: "load-layout",
    payload,
  };
}

/**
 * Initialize the panel using the given topic. The panel's visualization-related
 * fields will be given default values. The initial visualization depends on the
 * topic's message type, if any:
 *  - If the message type supports image data, the panel will be initialized
 *    to the "image" visualization
 *  - If the message type supports GPS data, the panel will be initialized to
 *    the "map" visualization
 *  - For all other message types, the panel will be initialized to the
 *    "timeline" visualization
 *
 * Invariants:
 *  - Panel must be uninitialized
 */
export type SelectTopicAction = {
  type: "select-topic";
  payload: {
    panelId: PanelNode["id"];
    topic: Topic;
  };
};

export function selectTopic(
  payload: SelectTopicAction["payload"],
): SelectTopicAction {
  return {
    type: "select-topic",
    payload,
  };
}

/**
 * Shows or hides the controls for the image visualization component.
 *
 * Invariants:
 *  - Panel must be initialized
 */
export type TogglePanelControlsAction = {
  type: "toggle-panel-controls";
  payload: {
    panelId: PanelNode["id"];
  };
};

export function togglePanelControls(
  payload: TogglePanelControlsAction["payload"],
): TogglePanelControlsAction {
  return {
    type: "toggle-panel-controls",
    payload,
  };
}

/**
 * Rotate the displayed image 90 degrees in the specified direction
 *
 * Invariants:
 *  - Panel must be initialized
 */
export type RotateImageAction = {
  type: "rotate-image";
  payload: {
    panelId: PanelNode["id"];
    direction: RotationDirection;
  };
};

export function rotateImage(
  payload: RotateImageAction["payload"],
): RotateImageAction {
  return {
    type: "rotate-image",
    payload,
  };
}

/**
 * Set or clear the direction in which the image should be flipped.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type SetImageFlipDirectionAction = {
  type: "set-image-flip-direction";
  payload: {
    panelId: PanelNode["id"];
    flipDirection: FlipDirection | null;
  };
};

export function setImageFlipDirection(
  payload: SetImageFlipDirectionAction["payload"],
): SetImageFlipDirectionAction {
  return {
    type: "set-image-flip-direction",
    payload,
  };
}

/**
 * Set or clear the segmentation topic to be visualized in this panel alongside
 * the main topic.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type SetSegmentationTopicAction = {
  type: "set-segmentation-topic";
  payload: {
    panelId: PanelNode["id"];
    segmentationTopic: Topic | null;
  };
};

export function setSegmentationTopic(
  payload: SetSegmentationTopicAction["payload"],
): SetSegmentationTopicAction {
  return {
    type: "set-segmentation-topic",
    payload,
  };
}

export type ChangeSegmentationsLockAction = {
  type: "change-segmentations-lock";
  payload: {
    panelId: PanelNode["id"];
    lockSegmentations: boolean;
  };
};

export function changeSegmentationsLock(
  payload: ChangeSegmentationsLockAction["payload"],
): ChangeSegmentationsLockAction {
  return {
    type: "change-segmentations-lock",
    payload,
  };
}

/**
 * Adds a field to be plotted in the chart visualization. The field can be
 * top-level or nested and must be available in the `data` payload
 * argument. The field must follow the syntax for lodash's `get` method. The
 * value at the given field should be a numeric type.
 *
 * There are some circumstances under which the field will not be added, though
 * these circumstances do not represent an error:
 *  - Maximum number of fields already added
 *  - Field has already been added
 *  - Field value is not a numeric type
 *
 * Invariants:
 *  - Panel must be initialized
 */
export type AddChartFieldAction = {
  type: "add-chart-field";
  payload: {
    panelId: PanelNode["id"];
    field: string;
    queryData: Record["queryData"];
  };
};

export function addChartField(
  payload: AddChartFieldAction["payload"],
): AddChartFieldAction {
  return {
    type: "add-chart-field",
    payload,
  };
}

/**
 * Removes the given field from the panel's chart visualization fields.
 *
 * Invariants:
 *  - Panel must be initialized
 *  - Field must have previously been added
 */
export type RemoveChartFieldAction = {
  type: "remove-chart-field";
  payload: {
    panelId: PanelNode["id"];
    field: string;
  };
};

export function removeChartField(
  payload: RemoveChartFieldAction["payload"],
): RemoveChartFieldAction {
  return {
    type: "remove-chart-field",
    payload,
  };
}

/**
 * Selects the provided tag for the panel, unless that tag is already selected,
 * in which case the tag will be un-selected.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type SelectTagAction = {
  type: "select-tag";
  payload: {
    panelId: PanelNode["id"];
    tag: string;
  };
};

export function selectTag(
  payload: SelectTagAction["payload"],
): SelectTagAction {
  return {
    type: "select-tag",
    payload,
  };
}

/**
 * Change whether a segmentation's bounding box should be drawn.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type ShowSegmentationBoundingBoxesAction = {
  type: "show-segmentation-bounding-boxes";
  payload: {
    panelId: PanelNode["id"];
    showSegmentationBoundingBoxes: boolean;
  };
};

export function showSegmentationBoundingBoxes(
  payload: ShowSegmentationBoundingBoxesAction["payload"],
): ShowSegmentationBoundingBoxesAction {
  return {
    type: "show-segmentation-bounding-boxes",
    payload,
  };
}

/**
 * Change whether a segmentation's class name should be displayed.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type ShowSegmentationClassNamesAction = {
  type: "show-segmentation-class-names";
  payload: {
    panelId: PanelNode["id"];
    showSegmentationClassNames: boolean;
  };
};

export function showSegmentationClassNames(
  payload: ShowSegmentationClassNamesAction["payload"],
): ShowSegmentationClassNamesAction {
  return {
    type: "show-segmentation-class-names",
    payload,
  };
}

/**
 * Change the visibility for this object class' segmentations.
 *
 * Invariants:
 *   - Panel must be initialized
 */
export type ChangeObjectClassVisibilityAction = {
  type: "change-object-class-visibility";
  payload: {
    panelId: PanelNode["id"];
    className: string;
    hideClass: boolean;
  };
};

export function changeObjectClassVisibility(
  payload: ChangeObjectClassVisibilityAction["payload"],
): ChangeObjectClassVisibilityAction {
  return {
    type: "change-object-class-visibility",
    payload,
  };
}

/**
 * Switches the panel to the given visualization. To choose the "chart"
 * visualization, at least one field must have already been selected.
 *
 * Invariants:
 *  - Panel must be initialized
 *  - If `visualization === "chart"`, must be > 0 fields added
 */
export type ChooseVisualizationAction = {
  type: "choose-visualization";
  payload: {
    panelId: PanelNode["id"];
    tab: VisualizationType;
  };
};

export function chooseVisualization(
  payload: ChooseVisualizationAction["payload"],
): ChooseVisualizationAction {
  return {
    type: "choose-visualization",
    payload,
  };
}

/**
 * Clears the selected topic for the panel, returning the panel to an
 * uninitialized state.
 *
 * Invariants:
 *  - Panel must be initialized
 */
export type ChooseNewTopicAction = {
  type: "choose-new-topic";
  payload: {
    panelId: PanelNode["id"];
  };
};

export function chooseNewTopic(
  payload: ChooseNewTopicAction["payload"],
): ChooseNewTopicAction {
  return {
    type: "choose-new-topic",
    payload,
  };
}

export type PanelLayoutAction =
  | SplitPanelAction
  | RemovePanelAction
  | ResizeNodeAction
  | SelectTopicAction
  | TogglePanelControlsAction
  | RotateImageAction
  | SetImageFlipDirectionAction
  | SetSegmentationTopicAction
  | ChangeSegmentationsLockAction
  | AddChartFieldAction
  | RemoveChartFieldAction
  | SelectTagAction
  | ShowSegmentationBoundingBoxesAction
  | ShowSegmentationClassNamesAction
  | ChangeObjectClassVisibilityAction
  | ChooseVisualizationAction
  | ChooseNewTopicAction
  | LoadLayoutAction;

export const initialState: LayoutState = {
  root: makeUninitializedPanel(0, null, 1),
  nextId: 1,
};

export default function reducer(
  draft: Draft<LayoutState>,
  action: PanelLayoutAction,
) {
  switch (action.type) {
    case "split-panel": {
      const panel = getPanel(draft, action.payload.panelId);

      const panelParentId = panel.parentId;

      const siblingId = draft.nextId++;
      const newParentId = draft.nextId++;

      const sibling = makeUninitializedPanel(siblingId, newParentId, 0.5);

      const newParent: ContainerNode = {
        type: "container",
        parentId: panelParentId,
        id: newParentId,
        flex: panel.flex,
        orientation: action.payload.orientation,
        firstChild: panel,
        secondChild: sibling,
      };

      panel.parentId = newParentId;
      panel.flex = 0.5;

      replaceNode(draft, panelParentId, panel, newParent);

      return;
    }
    case "remove-panel": {
      const panel = getPanel(draft, action.payload.panelId);

      const panelParentId = panel.parentId;

      if (panelParentId === null) {
        draft.root = makeUninitializedPanel(draft.nextId++, null, 1);
      } else {
        const parent = getContainer(draft, panelParentId);

        const sibling =
          panel === parent.firstChild ? parent.secondChild : parent.firstChild;

        replaceNode(draft, parent.parentId, parent, sibling);

        sibling.parentId = parent.parentId;

        if (parent.parentId === null) {
          sibling.flex = 1;
        } else {
          sibling.flex = parent.flex;
        }
      }

      return;
    }
    case "resize-node": {
      const node = getNode(draft, action.payload.nodeId);

      node.flex = action.payload.flex;

      return;
    }
    case "load-layout": {
      let maxId = action.payload.layout.id;

      draft.root = produce(action.payload.layout, (draftLayout) => {
        walkLayoutTree(draftLayout, (node) => {
          maxId = Math.max(maxId, node.id);
        });
      });

      draft.nextId = maxId + 1;

      return;
    }
    case "select-topic": {
      const panel = getUninitializedPanel(draft, action.payload.panelId);

      const initializedPanel = makeInitializedPanel(
        panel.id,
        panel.parentId,
        panel.flex,
        action.payload.topic,
      );

      replaceNode(draft, initializedPanel.parentId, panel, initializedPanel);

      return;
    }
    case "toggle-panel-controls": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      panel.showPanelControls = !panel.showPanelControls;

      return;
    }
    case "rotate-image": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      panel.imageRotationDeg = calculateNewRotationDeg(
        panel.imageRotationDeg,
        action.payload.direction,
      );

      if (panel.lockSegmentations) {
        panel.segmentationRotationDeg = calculateNewRotationDeg(
          panel.segmentationRotationDeg,
          action.payload.direction,
        );
      }

      return;
    }
    case "set-image-flip-direction": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      const {
        payload: { flipDirection },
      } = action;

      if (panel.imageFlipDirection === flipDirection) {
        // Probably shouldn't happen but it's a no-op
        return;
      }

      if (panel.lockSegmentations) {
        // Locked segmentations' initial flip direction might not correspond to
        // the image's. For example, the image may be horizontally reflected
        // and the user needed to flip it horizontally and _then_ lock the
        // segmentations. In such a situation, the two can never have the same
        // flip direction *but* they'll only be a reflection of each other along
        // a single axis (since reflection along both axes isn't permitted by
        // Studio as it's just a 180-degree rotation).
        //
        // The stored segmentations' flip direction and rotation should always
        // represent the transformations necessary to keep the segmentations
        // aligned relative to the image in the same manner as when the user
        // locked the segmentations (or when the panel was initialized as locked
        // is the default setting).

        const currentFlipDirections = new Set([
          panel.imageFlipDirection,
          panel.segmentationFlipDirection,
        ]);

        const containsOrthogonalFlips =
          currentFlipDirections.has(FlipDirection.X) &&
          currentFlipDirections.has(FlipDirection.Y);

        invariant(
          !containsOrthogonalFlips,
          "Images and segmentations should not be flipped orthogonally",
        );

        if (currentFlipDirections.size === 1) {
          // Both image and segmentations share the same flip direction, so
          // they should remain that way
          panel.segmentationFlipDirection = flipDirection;
        } else if (currentFlipDirections.has(flipDirection)) {
          // By this point, the segmentations are guaranteed to be reflected
          // relative to the other along some axis *and* the payload's flip
          // direction matches the segmentations'. In this case, the
          // segmentations' and image's flip directions need to be swapped.
          panel.segmentationFlipDirection = panel.imageFlipDirection;
        } else {
          // By this point, the payload flip direction is guaranteed to be the
          // x or y axis but doesn't correspond to either the image's or the
          // segmentations' flip directions. Since the segmentations are
          // reflected relative to the image, applying this second flip along
          // the orthogonal axis is equivalent to rotating 180 degrees.
          panel.segmentationFlipDirection = null;
          panel.segmentationRotationDeg +=
            2 * IMAGE_ROTATION_STEP_MAGNITUDE_DEG;
        }
      }

      // Image's final flip direction is always the payload's flip direction
      panel.imageFlipDirection = flipDirection;

      return;
    }
    case "set-segmentation-topic": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      panel.segmentationTopicName =
        action.payload.segmentationTopic?.name ?? null;

      return;
    }
    case "change-segmentations-lock": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      panel.lockSegmentations = action.payload.lockSegmentations;

      if (!action.payload.lockSegmentations) {
        panel.segmentationRotationDeg = 0;
        panel.segmentationFlipDirection = null;
      }

      return;
    }
    case "add-chart-field": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      const { field, queryData } = action.payload;

      if (panel.fields.length >= MAX_CHART_FIELDS) {
        return;
      }

      if (panel.fields.includes(field)) {
        return;
      }

      if (typeof get(queryData, field) !== "number") {
        return;
      }

      panel.fields.push(field);

      return;
    }
    case "remove-chart-field": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      const { field } = action.payload;

      invariant(
        panel.fields.includes(field),
        `Field not currently selected: "${field}"`,
      );

      pull(panel.fields, field);

      return;
    }
    case "select-tag": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      if (panel.selectedTag === action.payload.tag) {
        panel.selectedTag = null;
      } else {
        panel.selectedTag = action.payload.tag;
      }

      return;
    }
    case "show-segmentation-bounding-boxes": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      panel.showSegmentationBoundingBoxes =
        action.payload.showSegmentationBoundingBoxes;

      return;
    }
    case "show-segmentation-class-names": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      panel.showSegmentationClassNames =
        action.payload.showSegmentationClassNames;

      return;
    }
    case "change-object-class-visibility": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      const {
        payload: { className, hideClass },
      } = action;

      const isCurrentlyHidden =
        panel.hiddenObjectClassNames.includes(className);

      if (isCurrentlyHidden && !hideClass) {
        pull(panel.hiddenObjectClassNames, className);
      } else if (!isCurrentlyHidden && hideClass) {
        panel.hiddenObjectClassNames.push(className);
      }

      return;
    }
    case "choose-visualization": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      invariant(
        action.payload.tab !== "chart" || panel.fields.length > 0,
        "Must have at least 1 field selected to show chart",
      );

      panel.visualization = action.payload.tab;

      return;
    }
    case "choose-new-topic": {
      const panel = getInitializedPanel(draft, action.payload.panelId);

      const uninitializedPanel = makeUninitializedPanel(
        panel.id,
        panel.parentId,
        panel.flex,
      );

      replaceNode(
        draft,
        uninitializedPanel.parentId,
        panel,
        uninitializedPanel,
      );

      return;
    }
    default: {
      assertNever(action);
    }
  }
}

// Helpers

function makeUninitializedPanel(
  id: PanelNode["id"],
  parentId: PanelNode["parentId"],
  flex: PanelNode["flex"],
): UninitializedPanelNode {
  return {
    type: "panel",
    parentId,
    id,
    flex,
    isInitialized: false,
  };
}

function makeInitializedPanel(
  id: PanelNode["id"],
  parentId: PanelNode["parentId"],
  flex: PanelNode["flex"],
  topic: Topic,
): InitializedPanelNode {
  const topicTypeName = topic.typeName;

  let initialVisualization: VisualizationType;
  if (supportsImage(topicTypeName)) {
    initialVisualization = VisualizationType.Image;
  } else if (supportsMap(topicTypeName)) {
    initialVisualization = VisualizationType.Map;
  } else {
    initialVisualization = VisualizationType.Timeline;
  }

  return {
    type: "panel",
    id,
    parentId,
    flex,
    isInitialized: true,
    topicName: topic.name,
    topicTypeName,
    visualization: initialVisualization,
    fields: [],
    imageRotationDeg: 0,
    imageFlipDirection: null,
    segmentationTopicName: null,
    lockSegmentations: true,
    segmentationRotationDeg: 0,
    segmentationFlipDirection: null,
    showPanelControls: false,
    selectedTag: null,
    showSegmentationBoundingBoxes: true,
    showSegmentationClassNames: true,
    hiddenObjectClassNames: [],
  };
}

function replaceNode(
  draft: Draft<LayoutState>,
  parentId: LayoutNode["parentId"],
  currentNode: LayoutNode,
  replacementNode: LayoutNode,
): void {
  if (parentId === null) {
    draft.root = replacementNode;
  } else {
    const parent = getContainer(draft, parentId);

    if (currentNode === parent.firstChild) {
      parent.firstChild = replacementNode;
    } else {
      parent.secondChild = replacementNode;
    }
  }
}

function getUninitializedPanel(
  draft: Draft<LayoutState>,
  panelId: PanelNode["id"],
): UninitializedPanelNode {
  const panel = getPanel(draft, panelId);

  invariant(
    !panel.isInitialized,
    `Panel with ID ${panelId} is already initialized`,
  );

  return panel;
}

function getInitializedPanel(
  draft: Draft<LayoutState>,
  panelId: PanelNode["id"],
): InitializedPanelNode {
  const panel = getPanel(draft, panelId);

  invariant(panel.isInitialized, `Panel with ID ${panelId} is not initialized`);

  return panel;
}

function getPanel(
  draft: Draft<LayoutState>,
  nodeId: PanelNode["id"],
): PanelNode {
  const node = getNode(draft, nodeId);

  invariant(node.type === "panel", `Node with ID ${nodeId} is not a panel`);

  return node;
}

function getContainer(
  draft: Draft<LayoutState>,
  nodeId: ContainerNode["id"],
): ContainerNode {
  const node = getNode(draft, nodeId);

  invariant(
    node.type === "container",
    `Node with ID ${nodeId} is not a container`,
  );

  return node;
}

function getNode(
  draft: Draft<LayoutState>,
  nodeId: LayoutNode["id"],
): LayoutNode {
  let maybeNode: LayoutNode | undefined = undefined;

  function visitor(node: LayoutNode) {
    if (node.id === nodeId) {
      maybeNode = node;
      return false;
    }
  }

  walkLayoutTree(draft.root, visitor);

  invariant(maybeNode !== undefined, `Node with ID ${nodeId} does not exist`);

  return maybeNode;
}

function calculateNewRotationDeg(
  currentRotationDeg: number,
  direction: RotationDirection,
): number {
  const rotateByDeg =
    direction === RotationDirection.Left
      ? -IMAGE_ROTATION_STEP_MAGNITUDE_DEG
      : IMAGE_ROTATION_STEP_MAGNITUDE_DEG;

  return currentRotationDeg + rotateByDeg;
}
