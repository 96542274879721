import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  LockCard,
  ManageCard,
  ResourceFields,
} from "~/components/DetailsCards";
import { calculateProcessRefetchInterval, Process } from "~/components/Process";
import {
  useDeleteIngestionPart,
  useIngestionPart,
  useUpdateIngestionPart,
} from "~/domain/datastores";
import {
  makeEditIngestionPartLocation,
  makeIngestionPartsLocation,
  useIngestionPartParams,
} from "~/paths";
import type { IngestionPart } from "~/services/datastore";
import { selectData } from "~/utils";

export function IngestionPartDetails() {
  const { ingestionId, ingestionPartId } = useIngestionPartParams();

  const query = useIngestionPart(ingestionId, ingestionPartId, {
    select: selectData,
    refetchInterval: calculateProcessRefetchInterval,
  });

  const generalSection = <GeneralSection query={query} />;
  const processSection = (
    <Process
      query={query}
      mutation={useUpdateIngestionPart(ingestionId, ingestionPartId)}
    />
  );
  const lockSection = (
    <LockCard
      resourceName="ingestion part"
      query={query}
      updateMutation={useUpdateIngestionPart(ingestionId, ingestionPartId)}
    />
  );
  const historySection = <HistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="ingestion part"
      query={query}
      editLocation={makeEditIngestionPartLocation({
        ingestionId,
        ingestionPartId,
      })}
      deleteMutation={useDeleteIngestionPart(ingestionId, ingestionPartId)}
      getReadableName={(ingestionPart) => ingestionPart.id}
      listLocation={makeIngestionPartsLocation({ ingestionId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {processSection}
        </>
      }
      secondaryGridColumn={
        <>
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {processSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<IngestionPart> }) {
  return (
    <Card title="General">
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "ingestion", accessor: "ingestionId" },
          { dataType: "number", accessor: "sequence" },
        ]}
      />
    </Card>
  );
}
