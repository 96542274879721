import type {
  ListRolesRequest,
  Role,
  RoleCreateRequest,
  RoleUpdateRequest,
} from "~/services/datastore";
import { createResourceCrudHooks } from "./utils";

export const {
  queryKeyFactory: roleKeys,
  useList: useRoles,
  useFetch: useRole,
  useCreate: useCreateRole,
  useUpdate: useUpdateRole,
  useDelete: useDeleteRole,
} = createResourceCrudHooks({
  baseQueryKey: "roles",
  getIdentifier(role: Role) {
    return role.id;
  },
  listResource({ signal }, { roleApi }, request: ListRolesRequest) {
    return roleApi.listRoles(request, { signal });
  },
  fetchResource({ signal }, { roleApi }, roleId: Role["id"]) {
    return roleApi.fetchRole({ roleId }, { signal });
  },
  createResource({ roleApi }, request: RoleCreateRequest) {
    return roleApi.createRole({ roleCreateRequest: request });
  },
  updateResource({ roleApi }, roleId: Role["id"], updates: RoleUpdateRequest) {
    return roleApi.updateRole({ roleId, roleUpdateRequest: updates });
  },
  deleteResource({ roleApi }, roleId: Role["id"]) {
    return roleApi.deleteRole({ roleId });
  },
});
