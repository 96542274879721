import { NewResourceForm, TextField } from "~/components/Form";
import { useCreateLabel } from "~/domain/datastores";
import { makeLabelLocation } from "~/paths";
import { createLabelSchema } from "../schemas";

export function LabelCreateForm() {
  return (
    <NewResourceForm
      schema={createLabelSchema}
      defaultValues={{
        value: null,
        note: null,
      }}
      resourceName="label"
      mutation={useCreateLabel()}
      generateLocation={(response) =>
        makeLabelLocation({ labelId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="value" required />
          <TextField control={control} name="note" />
        </>
      )}
    </NewResourceForm>
  );
}
