import { useEffect, useRef } from "react";
import { nanosecondsToSeconds } from "~/lib/dates";
import type { Record, Topic } from "~/services/datastore";
import type { SimpleQueryResult } from "~/types";
import { combineQueries, selectData } from "~/utils";
import type { UseRecordsQueriesParams } from "../queries";
import { useRecordsQueries } from "../queries";
import type { SampleFrequencyValue, TimeRange } from "../types";
import { SampleFrequency } from "../types";
import { calculateWindowChunks } from "./utils";

export interface UseChunksOptions {
  topicId: Topic["id"];
  enabled?: boolean;
  playerBounds?: TimeRange;
  recordWindow?: TimeRange;
  sampleFrequency?: SampleFrequencyValue;
  bufferBehind?: bigint;
  bufferAhead?: bigint;
  chunkSize: bigint;
}

interface UseRecordChunksLoadingResult {
  status: "loading";
  isPlaceholderData: false;
  data: undefined;
  recordWindow: TimeRange;
}

interface UseRecordChunksLoadingPlaceholderResult {
  status: "loading";
  isPlaceholderData: true;
  data: Array<Record>;
  recordWindow: TimeRange;
}

interface UseRecordChunksErrorResult {
  status: "error";
  isPlaceholderData: false;
  data: undefined;
  recordWindow: TimeRange;
}

interface UseRecordChunksErrorPlaceholderResult {
  status: "error";
  isPlaceholderData: true;
  data: Array<Record>;
  recordWindow: TimeRange;
}

interface UseRecordChunksSuccessResult {
  status: "success";
  isPlaceholderData: false;
  data: Array<Record>;
  recordWindow: TimeRange;
}

export type UseRecordChunksResult =
  | UseRecordChunksLoadingResult
  | UseRecordChunksLoadingPlaceholderResult
  | UseRecordChunksErrorResult
  | UseRecordChunksErrorPlaceholderResult
  | UseRecordChunksSuccessResult;

interface LastSuccessful {
  recordWindow: NonNullable<UseChunksOptions["recordWindow"]>;
  queriesParams: UseRecordsQueriesParams<Array<Record>>;
}

export default function useRecordChunks({
  topicId,
  enabled = true,
  playerBounds,
  recordWindow = playerBounds,
  sampleFrequency = SampleFrequency.Second,
  bufferBehind = 0n,
  bufferAhead = 0n,
  chunkSize,
}: UseChunksOptions) {
  const windowChunks = calculateWindowChunks({
    chunkSize,
    bufferAhead,
    bufferBehind,
    window: recordWindow,
    playerBounds,
  });

  function createQueriesParams(
    chunks: ReadonlyArray<TimeRange>,
  ): UseRecordsQueriesParams<Array<Record>> {
    return {
      requests: chunks.map((chunk) => ({
        topicId,
        limit: nanosecondsToSeconds(chunkSize) * sampleFrequency,
        timestampGte: chunk.startTime,
        timestampLt: chunk.endTime,
        frequency: sampleFrequency,
      })),
      options: {
        enabled,
        select: selectData,
      },
    };
  }

  const requiredQueriesParams = createQueriesParams(windowChunks.required);
  const requiredQueries = useRecordsQueries(requiredQueriesParams);
  const currentResult = combineQueries({
    queries: requiredQueries,
    transform(results) {
      return results.flat();
    },
  });

  // Results aren't used for buffered chunks. Just creating query observers so
  // react-query will fire off the requests if needed and won't evict the
  // responses from its cache.
  useRecordsQueries(createQueriesParams(windowChunks.bufferAhead));
  useRecordsQueries(createQueriesParams(windowChunks.bufferBehind));

  const lastSuccessfulRef = useRef<LastSuccessful>();
  useEffect(function saveLastSuccessfulData() {
    if (recordWindow === undefined) {
      return;
    }

    if (requiredQueriesParams.requests.length === 0) {
      return;
    }

    if (currentResult.status !== "success") {
      return;
    }

    lastSuccessfulRef.current = {
      recordWindow,
      queriesParams: requiredQueriesParams,
    };
  });

  const placeholderQueriesParams =
    currentResult.status === "success"
      ? createQueriesParams([])
      : lastSuccessfulRef.current?.queriesParams ?? createQueriesParams([]);
  const placeholderQueries = useRecordsQueries(placeholderQueriesParams);
  const placeholderResult = combineQueries({
    queries: placeholderQueries,
    transform(results) {
      return results.flat();
    },
  });

  return consolidateResults(
    currentResult,
    recordWindow,
    placeholderResult,
    lastSuccessfulRef.current?.recordWindow,
  );
}

// Exported for testing
export function consolidateResults(
  currentResult: SimpleQueryResult<Array<Record>>,
  currentRecordWindow: UseChunksOptions["recordWindow"],
  placeholderResult: SimpleQueryResult<Array<Record>> | undefined,
  placeholderRecordWindow: UseChunksOptions["recordWindow"],
): UseRecordChunksResult {
  const isPlaceholderData =
    (currentResult.status === "loading" || currentResult.status === "error") &&
    placeholderResult !== undefined;

  return {
    status: currentResult.status,
    isPlaceholderData,
    data: isPlaceholderData ? placeholderResult.data : currentResult.data,
    recordWindow: isPlaceholderData
      ? placeholderRecordWindow
      : currentRecordWindow,
  } as UseRecordChunksResult;
}
