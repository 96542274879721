import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import type { ValueOf } from "type-fest";
import { z } from "zod";
import { ValidationError } from "~/errors";
import { omit, snakeCase } from "~/lib/std";
import { serializeSearchParams } from "~/utils";
import { getSortableFields } from "./columns";
import type { Column } from "./types";

export interface PaginationModel {
  limit: number;
  offset: number;
}

export interface ResourceTableModel extends PaginationModel {
  sort: SortDirection;
  order: string;
}

export const LIMIT_OPTIONS = [5, 10, 25, 50, 100];

export const SortDirection = {
  Asc: "asc",
  Desc: "desc",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SortDirection = ValueOf<typeof SortDirection>;

const orderKeys = new Map([["_default", "default"]]);

/**
 * Perform common preparation tasks on a list request's parameters.
 *
 * Tasks performed:
 * 1. The `order` field's value is converted to snake_case
 */
export function prepareListRequest<TRequest extends { order: string }>(
  request: TRequest,
): TRequest {
  return {
    ...request,
    order: orderKeys.get(request.order) ?? snakeCase(request.order),
  };
}

const number = z.coerce.number();

export function makeRequestSchema<TFilterSchema extends z.AnyZodObject>(
  columns: ReadonlyArray<Column<any>>,
  filterSchema: TFilterSchema,
): z.ZodType<
  z.output<TFilterSchema> & ResourceTableModel,
  z.ZodTypeDef,
  z.input<TFilterSchema> & Partial<ResourceTableModel>
> {
  const sortableFields = getSortableFields(columns);

  const baseSchema = z
    .object({
      sort: z.nativeEnum(SortDirection).default(SortDirection.Desc),
      order: z
        .string()
        .refine((value) => sortableFields.includes(value as any))
        // This isn't type-safe but all resource (as of now) have
        // a `createdAt` field
        .default("createdAt"),
      limit: number
        .refine((value) => LIMIT_OPTIONS.includes(value))
        .default(LIMIT_OPTIONS[1]),
      offset: number.nonnegative().int().default(0),
    }) // Check if `offset` is a multiple of `limit`
    .refine(({ limit, offset }) => offset % limit === 0);

  return filterSchema.and(baseSchema);
}

export function useSearchRequest<TRequestSchema extends z.ZodTypeAny>(
  requestSchema: TRequestSchema,
): [
  z.infer<TRequestSchema>,
  (updates: Partial<z.infer<TRequestSchema>>) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();

  let request: z.infer<TRequestSchema>;
  try {
    request = requestSchema.parse(Object.fromEntries(searchParams));
  } catch (e) {
    throw new ValidationError({
      source: "search",
      cause: e,
    });
  }
  const setRequest = useCallback(
    (updates: Partial<z.infer<TRequestSchema>>) => {
      setSearchParams((prevSearchParams) => {
        let updatedSearchParams = new URLSearchParams(prevSearchParams);

        // The offset should always reset when the filters, page size or
        // sort parameters change
        if (!("offset" in updates)) {
          updatedSearchParams.delete("offset");
        }

        updatedSearchParams = serializeSearchParams(
          updates,
          updatedSearchParams,
        );

        return updatedSearchParams;
      });
    },
    [setSearchParams],
  );

  return [request, setRequest];
}

export function withoutBaseTableModel<TRequest extends ResourceTableModel>(
  request: TRequest,
): Omit<TRequest, keyof ResourceTableModel> {
  return omit(request, ["sort", "order", "limit", "offset"]);
}

export function getActiveFiltersCount(
  filters: Record<string, unknown>,
): number {
  return Object.entries(filters).filter(([, value]) => value != null).length;
}
