import {
  Box,
  Card,
  CardContent,
  Link,
  TableCell,
  Typography,
} from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useRecords } from "~/domain/datastores";
import {
  DataStoreLink,
  makeNewRecordLocation,
  makeRecordLocation,
  useTopicParams,
} from "~/paths";
import type { Record } from "~/services/datastore";
import { listRecordsSchema } from "../schemas";
import { RecordFilters } from "./RecordFilters";

const COLUMNS: ReadonlyArray<Column<Record>> = [
  {
    header: "Timestamp",
    renderCell(record) {
      return (
        <TableCell>
          <Link
            component={DataStoreLink}
            to={makeRecordLocation({
              topicId: record.topicId,
              timestamp: record.timestamp,
            })}
          >
            <Typography variant="body2">{String(record.timestamp)}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "topicId",
    dataType: "foreign-key",
    resourceType: "topic",
    defaultHidden: true,
  },
  {
    accessor: "logId",
    dataType: "foreign-key",
    resourceType: "log",
    defaultHidden: true,
  },
  {
    accessor: "ingestionId",
    dataType: "foreign-key",
    resourceType: "ingestion",
  },
  {
    accessor: "dataOffset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "dataLength",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "chunkCompression",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "chunkOffset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "chunkLength",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listRecordsSchema);

export const RecordTable = withSearchValidationErrorBoundary(
  function RecordTable() {
    const { topicId } = useTopicParams();

    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useRecords(topicId, prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Box>
        <Typography variant="h4" component="h2" gutterBottom>
          Records
        </Typography>
        <Card>
          <CardContent>
            <ResourceTable
              resourceName="record"
              resourceCreateLocation={makeNewRecordLocation({ topicId })}
              getRowKey={(record) => String(record.timestamp)}
              columns={COLUMNS}
              searchQuery={searchQuery}
              tableModel={request}
              onTableModelChange={setRequest}
              filterSection={
                <RecordFilters values={filterValues} setValues={setRequest} />
              }
              activeFilterCount={getActiveFiltersCount(filterValues)}
            />
          </CardContent>
        </Card>
      </Box>
    );
  },
);
