import {
  CheckboxField,
  EditResourceForm,
  GroupSelect,
  ObjectField,
  TextField,
  WorkflowSelect,
} from "~/components/Form";
import { useLog, useUpdateLog } from "~/domain/datastores";
import { makeLogLocation, useLogParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_LOG_FIELDS, editLogSchema } from "../schemas";

export function LogEditForm() {
  const { logId } = useLogParams();

  return (
    <EditResourceForm
      schema={editLogSchema}
      resourceName="log"
      query={useLog(logId, { select: selectData })}
      editableFields={EDITABLE_LOG_FIELDS}
      mutation={useUpdateLog(logId)}
      resourceLocation={makeLogLocation({ logId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <GroupSelect control={control} name="groupId" required />
          <WorkflowSelect control={control} name="defaultWorkflowId" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditResourceForm>
  );
}
