import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  FuzzyField,
  LogSelect,
  SelectField,
  TopicSelect,
  typeEncodingOptions,
  useStudioForm,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListTopicsFormValues } from "../schemas";
import { listTopicsSchema } from "../schemas";

export interface TopicFiltersProps {
  values: ListTopicsFormValues;
  setValues: (newValues: ListTopicsFormValues) => void;
}

export function TopicFilters({ values, setValues }: TopicFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listTopicsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      </Grid>
      <Grid item xs={3}>
        <FuzzyField
          control={control}
          exactName="typeName"
          fuzzyName="typeNameLike"
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          control={control}
          name="typeEncoding"
          options={typeEncodingOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <LogSelect control={control} name="logId" />
      </Grid>
      <Grid item xs={3}>
        <TopicSelect control={control} name="associatedTopicId" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="locked" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="strict" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="latched" />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
