import type { StrictOmit } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  optionalObject,
  optionalText,
  requiredText,
  schemaShapeForType,
} from "~/components/Form";
import { filterBoolean, filterText } from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  ListObjectStoresRequest,
  ObjectStoreCreateRequest,
  ObjectStoreUpdateRequest,
} from "~/services/datastore";

export const listObjectStoresSchema = z.object(
  schemaShapeForType<ListObjectStoresRequest>()({
    bucketName: filterText,
    accessKeyId: filterText,
    regionName: filterText,
    endpointUrl: filterText,
    note: filterText,
    noteLike: filterText,
    disabled: filterBoolean,
    ...auditFieldSchemas,
  }),
);

export type ListObjectStoresFormValues = z.infer<typeof listObjectStoresSchema>;

export const createObjectStoreSchema = z.object(
  schemaShapeForType<ObjectStoreCreateRequest>()({
    bucketName: requiredText,
    accessKeyId: optionalText,
    secretAccessKey: optionalText,
    regionName: optionalText,
    endpointUrl: optionalText,
    disabled: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export const editObjectStoreSchema = z.object(
  // TODO: Add `secretAccessKey`
  schemaShapeForType<StrictOmit<ObjectStoreUpdateRequest, "secretAccessKey">>()(
    {
      bucketName: requiredText,
      accessKeyId: optionalText,
      regionName: optionalText,
      endpointUrl: optionalText,
      disabled: boolean,
      note: optionalText,
      context: optionalObject,
    },
  ),
);

export const EDITABLE_OBJECT_STORE_FIELDS =
  editObjectStoreSchema.keyof().options;
