import { useUser, useUsers } from "~/domain/datastores";
import { makeResourceSelect } from "./makeResourceSelect";

export const UserSelect = makeResourceSelect({
  useGet(uuid) {
    return useUser(uuid, {
      select({ data: { id, username } }) {
        return {
          value: id,
          label: username,
        };
      },
    });
  },
  useSearch(text) {
    return useUsers(
      {
        usernameLike: text ?? "",
        limit: 5,
        sort: "asc",
        order: "username",
      },
      {
        keepPreviousData: true,
        enabled: text !== null,
        select({ data }) {
          return data.map((user) => ({
            value: user.id,
            label: user.username,
          }));
        },
      },
    );
  },
});
