import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useGroups } from "~/domain/datastores";
import {
  DataStoreLink,
  makeGroupLocation,
  makeNewGroupLocation,
} from "~/paths";
import type { Group } from "~/services/datastore";
import { listGroupsSchema } from "../schemas";
import { GroupFilters } from "./GroupFilters";

const COLUMNS: ReadonlyArray<Column<Group>> = [
  {
    header: "Group",
    sortKey: "name",
    renderCell(group) {
      return (
        <TableCell>
          {group.name}
          <Link
            component={DataStoreLink}
            to={makeGroupLocation({ groupId: group.id })}
          >
            <Typography variant="body2">{group.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "defaultWorkflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listGroupsSchema);

export const GroupTable = withSearchValidationErrorBoundary(
  function GroupTable() {
    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useGroups(prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="group"
            resourceCreateLocation={makeNewGroupLocation()}
            getRowKey={(group) => group.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <GroupFilters values={filterValues} setValues={setRequest} />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
