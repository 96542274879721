import {
  EditResourceForm,
  LabelSelect,
  ObjectField,
  TextField,
  TimestampField,
  TopicSelect,
} from "~/components/Form";
import { useTag, useUpdateTag } from "~/domain/datastores";
import { makeTagLocation, useTagParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_TAG_FIELDS, editTagSchema } from "../schemas";

export function TagEditForm() {
  const { logId, tagId } = useTagParams();

  return (
    <EditResourceForm
      schema={editTagSchema}
      resourceName="tag"
      query={useTag(logId, tagId, { select: selectData })}
      editableFields={EDITABLE_TAG_FIELDS}
      mutation={useUpdateTag(logId, tagId)}
      resourceLocation={makeTagLocation({ logId, tagId })}
    >
      {(control) => (
        <>
          <LabelSelect control={control} name="labelId" required />
          <TopicSelect control={control} name="topicId" logId={logId} />
          <TextField control={control} name="note" />
          <TimestampField control={control} name="startTime" />
          <TimestampField control={control} name="endTime" />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditResourceForm>
  );
}
