import prettyBytes from "pretty-bytes";
import { fromUnixTime, nanosecondsToSeconds } from "~/lib/dates";

export function formatDate(date: Date, timezone: "UTC" | "local") {
  if (timezone === "UTC") {
    return date.toUTCString();
  }
  return date.toLocaleString();
}

export function formatTimestamp(timestamp: bigint) {
  const date = fromUnixTime(nanosecondsToSeconds(timestamp));
  return date.toUTCString();
}

export function formatBytes(bytes: number) {
  return prettyBytes(bytes);
}

const numberFormatter = new Intl.NumberFormat();

export function formatNumber(value: number) {
  return numberFormatter.format(value);
}

// Formatted values are interpreted such that 1 will become "100%", 0.5
// will become "50%", etc.
const percentFormatter = new Intl.NumberFormat(undefined, {
  style: "percent",
  maximumFractionDigits: 1,
});

export function formatPercent(value: number | null): string {
  return percentFormatter.format(value ?? 0);
}
