import React from "react";
import { Box } from "@mui/material";

export interface TableContainerProps {
  children: React.ReactNode;
}

export function FullWidthContainer({ children }: TableContainerProps) {
  return <Box sx={{ ml: 8, mr: 8 }}>{children}</Box>;
}
