function serializeSearchParam(value: NonNullable<unknown>): string {
  if (value instanceof Date) {
    return value.toISOString();
  } else if (typeof value === "boolean") {
    return value ? "1" : "0";
  } else if (typeof value !== "object") {
    return String(value);
  } else {
    throw new Error("Object/array serialization not supported");
  }
}

export function serializeSearchParams(
  params: Record<string, unknown>,
  currentParams?: URLSearchParams,
): URLSearchParams {
  const clone = new URLSearchParams(currentParams);

  Object.entries(params).forEach(([key, value]) => {
    if (value == null) {
      clone.delete(key);
    } else {
      clone.set(key, serializeSearchParam(value));
    }
  });

  return clone;
}
