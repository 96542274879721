/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Query {
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  id: string;
  logId: string;
  name: string | null;
  note: string | null;
  context: object | null;
  statement: string | null;
  parameters: object | null;
  columns: Array<string> | null;
  rows: Array<Array<any>> | null;
  error: object | null;
}

export function QueryFromJSON(json: any): Query {
  return QueryFromJSONTyped(json);
}

export function QueryFromJSONTyped(json: any): Query {
  if (json == null) {
    return json;
  }

  return {
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    id: json["id"],
    logId: json["log_id"],
    name: json["name"],
    note: json["note"],
    context: json["context"],
    statement: json["statement"],
    parameters: json["parameters"],
    columns: json["columns"],
    rows: json["rows"],
    error: json["error"],
  };
}

export function QueryToJSON(value: Query | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    id: value.id,
    log_id: value.logId,
    name: value.name,
    note: value.note,
    context: value.context,
    statement: value.statement,
    parameters: value.parameters,
    columns: value.columns,
    rows: value.rows,
    error: value.error,
  };
}
