import {
  EditResourceForm,
  NumberField,
  ObjectField,
  TimestampField,
} from "~/components/Form";
import {
  useDigestionTopic,
  useUpdateDigestionTopic,
} from "~/domain/datastores";
import { makeDigestionTopicLocation, useDigestionTopicParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_DIGESTION_TOPIC_FIELDS,
  editDigestionTopicSchema,
} from "../schemas";

export function DigestionTopicEditForm() {
  const { digestionId, topicId } = useDigestionTopicParams();

  return (
    <EditResourceForm
      schema={editDigestionTopicSchema}
      resourceName="digestion topic"
      query={useDigestionTopic(digestionId, topicId, { select: selectData })}
      editableFields={EDITABLE_DIGESTION_TOPIC_FIELDS}
      mutation={useUpdateDigestionTopic(digestionId, topicId)}
      resourceLocation={makeDigestionTopicLocation({ digestionId, topicId })}
    >
      {(control) => (
        <>
          <TimestampField control={control} name="startTime" />
          <TimestampField control={control} name="endTime" />
          <NumberField control={control} name="frequency" />
          <ObjectField control={control} name="queryDataFilter" />
          <ObjectField control={control} name="contextFilter" />
        </>
      )}
    </EditResourceForm>
  );
}
