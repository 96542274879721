import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { RoleTable } from "./components/RoleTable";

export function RolesPage() {
  return (
    <Page title="Search Roles">
      <FullWidthContainer>
        <RoleTable />
      </FullWidthContainer>
    </Page>
  );
}
