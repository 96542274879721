import { useCallback } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Stack, Tab } from "@mui/material";
import type { ValueOf } from "type-fest";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";
import { FullWidthContainer } from "~/components/FullWidthContainer";

export interface TabsProps {
  thumbnails: JSX.Element;
  map: JSX.Element;
  table: JSX.Element;
}

export default function Tabs({ thumbnails, map, table }: TabsProps) {
  const [view, setView] = useView();

  function handleTabChange(_: unknown, newTab: string) {
    setView(newTab as ValueOf<typeof View>);
  }

  return (
    <TabContext value={view}>
      <Stack
        sx={{
          flexGrow: 1,
          minWidth: 0,
          "& .MuiTabs-root": {
            flex: "none",
            borderBottom: 1,
            borderColor: "divider",
          },
          "& .MuiTabPanel-root": {
            flexGrow: 1,
            minHeight: 0,
            p: 0,
            display: "flex",
            "&[hidden]": {
              display: "none",
            },
          },
        }}
      >
        <TabList onChange={handleTabChange}>
          <Tab label="Thumbnails" value={View.Thumbnails} />
          <Tab label="Map" value={View.Map} />
          <Tab label="Table" value={View.Table} />
        </TabList>
        <TabPanel value={View.Thumbnails}>
          <Box
            data-scroll-root=""
            sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}
          >
            <Container fixed>{thumbnails}</Container>
          </Box>
        </TabPanel>
        <TabPanel value={View.Map}>{map}</TabPanel>
        <TabPanel value={View.Table}>
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <FullWidthContainer>{table}</FullWidthContainer>
          </Box>
        </TabPanel>
      </Stack>
    </TabContext>
  );
}

const View = {
  Thumbnails: "thumbnails",
  Map: "map",
  Table: "table",
} as const;

const ViewParam = withDefault(
  createEnumParam([View.Thumbnails, View.Map, View.Table]),
  View.Thumbnails,
);

function useView() {
  const [viewParam, setViewParam] = useQueryParam("view", ViewParam);

  const setView = useCallback(
    (newView: ValueOf<typeof View>) => {
      // When changing tabs/views, blow away *all* existing query params
      setViewParam(newView, "replace");
    },
    [setViewParam],
  );

  return [viewParam, setView] as const;
}
