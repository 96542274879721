import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { DigestionPartDetails } from "./components/DigestionPartDetails";

export function DigestionPartPage() {
  return (
    <Page title="Digestion Part Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <DigestionPartDetails />
      </Container>
    </Page>
  );
}
