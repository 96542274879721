import React, { useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import type { FieldValues, Path } from "react-hook-form";
import { useController } from "react-hook-form";
import { z } from "zod";
import { identity } from "~/lib/std";
import { getFieldLabel } from "~/utils";
import type { BaseFieldProps, Option } from "./types";

interface MakeResourceSelectParams<TProps extends object> {
  useGet: (uuid: string | null, props: TProps) => UseQueryResult<Option>;
  useSearch: (text: string | null, props: TProps) => UseQueryResult<Option[]>;
}

/**
 * Create a specialized `<Autocomplete />` which allows users to populate
 * foreign key fields by searching the foreign resource, either by name or
 * directly by ID
 */
export function makeResourceSelect<TProps extends object>({
  useGet,
  useSearch,
}: MakeResourceSelectParams<TProps>) {
  return function ResourceSelect<
    TFieldValues extends FieldValues,
    TName extends Path<TFieldValues>,
  >({
    control,
    name,
    label = getFieldLabel(name),
    required,
    ...props
  }: TProps & BaseFieldProps<TFieldValues, TName>) {
    const {
      open,
      handleOpen,
      handleClose,
      inputValue,
      handleInputValueChange,
      autocompleteValue,
      handleAutocompleteValueChange,
      isOptionEqualToValue,
      loading,
      options,
      filterOptions,
      fieldState,
    } = useResourceSelect({
      control,
      name,
      useGet,
      useSearch,
      // Any additional props passed to the component are forwarded to the
      // query hooks
      props: props as TProps,
    });

    return (
      <Autocomplete
        autoHighlight
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        inputValue={inputValue}
        onInputChange={handleInputValueChange}
        value={autocompleteValue}
        onChange={handleAutocompleteValueChange}
        isOptionEqualToValue={isOptionEqualToValue}
        options={options}
        filterOptions={filterOptions}
        loading={loading}
        renderInput={(props) => (
          <TextField
            {...props}
            required={required}
            label={label}
            error={fieldState.error !== undefined}
            helperText={fieldState.error?.message ?? " "}
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {props.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  };
}

function useResourceSelect<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TProps extends object,
>({
  control,
  name,
  useGet,
  useSearch,
  props,
}: { props: TProps } & BaseFieldProps<TFieldValues, TName> &
  MakeResourceSelectParams<TProps>) {
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ control, name });

  // Fetch the resource corresponding to the selected ID, if any
  const valueQuery = useGet(value, props);

  const [open, setOpen] = useState(false);

  const [inputValue, setInputValue] = useState("");
  // If the text field's value is a UUID, a direct lookup is performed to
  // see if a resource matches that ID
  const isUuid = checkIsUuid(inputValue);

  // If the text field's value is a UUID, then the component should "search"
  // by that UUID. Essentially, perform a GET request and if it returns a 404
  // then no options matched the "search value"
  const uuidQuery = useGet(isUuid ? inputValue : null, props);
  const searchQuery = useSearch(inputValue, props);

  let loading: boolean;
  let options: Option[];
  if (open) {
    if (isUuid) {
      loading = uuidQuery.isFetching;
      options = uuidQuery.isSuccess ? [uuidQuery.data] : [];
    } else {
      loading = searchQuery.isFetching;
      options = searchQuery.data ?? [];
    }
  } else {
    loading = valueQuery.isFetching;
    options = valueQuery.isSuccess ? [valueQuery.data] : [];
  }

  const autocompleteValue = valueQuery.data ?? null;

  return {
    autocompleteValue,
    handleAutocompleteValueChange(_: unknown, newValue: Option | null) {
      onChange(newValue?.value ?? null);
    },
    isOptionEqualToValue(option: Option, value: Option) {
      return option.value === value.value;
    },
    fieldState,
    open,
    handleOpen() {
      setOpen(true);
    },
    handleClose() {
      setOpen(false);
    },
    inputValue,
    handleInputValueChange(_: unknown, newInputValue: string) {
      setInputValue(newInputValue);
    },
    loading,
    options,
    // Disable client-side filtering
    filterOptions: identity,
  };
}

function checkIsUuid(input: string): boolean {
  return z.string().uuid().safeParse(input).success;
}
