/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  HTTPValidationError,
  HookCreateRequest,
  HookDataResponse,
  HookListResponse,
  HookUpdateRequest,
  WorkflowCreateRequest,
  WorkflowDataResponse,
  WorkflowListResponse,
  WorkflowUpdateRequest,
} from "../models";
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  HookCreateRequestFromJSON,
  HookCreateRequestToJSON,
  HookDataResponseFromJSON,
  HookDataResponseToJSON,
  HookListResponseFromJSON,
  HookListResponseToJSON,
  HookUpdateRequestFromJSON,
  HookUpdateRequestToJSON,
  WorkflowCreateRequestFromJSON,
  WorkflowCreateRequestToJSON,
  WorkflowDataResponseFromJSON,
  WorkflowDataResponseToJSON,
  WorkflowListResponseFromJSON,
  WorkflowListResponseToJSON,
  WorkflowUpdateRequestFromJSON,
  WorkflowUpdateRequestToJSON,
} from "../models";

export interface CreateHookRequest {
  workflowId: string;
  hookCreateRequest: HookCreateRequest;
}

export interface CreateWorkflowRequest {
  workflowCreateRequest: WorkflowCreateRequest;
}

export interface DeleteHookRequest {
  hookId: string;
  workflowId: string | null;
}

export interface DeleteWorkflowRequest {
  workflowId: string;
}

export interface FetchHookRequest {
  hookId: string;
  workflowId: string | null;
}

export interface FetchWorkflowRequest {
  workflowId: string;
}

export interface ListHooksRequest {
  workflowId: string | null;
  triggerProcess?: string | null;
  triggerState?: string | null;
  name?: string | null;
  nameLike?: string | null;
  noteLike?: string | null;
  managed?: boolean | null;
  disabled?: boolean | null;
  uri?: string | null;
  uriLike?: string | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface ListWorkflowsRequest {
  name?: string | null;
  nameLike?: string | null;
  _default?: boolean | null;
  disabled?: boolean | null;
  managed?: boolean | null;
  contextSchemaFilter?: string | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateHookRequest {
  hookId: string;
  workflowId: string | null;
  hookUpdateRequest: HookUpdateRequest;
}

export interface UpdateWorkflowRequest {
  workflowId: string;
  workflowUpdateRequest: WorkflowUpdateRequest;
}

export class WorkflowApi extends runtime.BaseAPI {
  async createHook(
    this: WorkflowApi,
    requestParameters: CreateHookRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HookDataResponse> {
    runtime.assertParamIsPresent(requestParameters.workflowId, "workflowId");

    runtime.assertParamIsPresent(
      requestParameters.hookCreateRequest,
      "hookCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/workflows/{workflow_id}/hooks`.replace(
          `{${"workflow_id"}}`,
          encodeURIComponent(String(requestParameters.workflowId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: HookCreateRequestToJSON(requestParameters.hookCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return HookDataResponseFromJSON(jsonValue);
  }

  async createWorkflow(
    this: WorkflowApi,
    requestParameters: CreateWorkflowRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WorkflowDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.workflowCreateRequest,
      "workflowCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/workflows`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: WorkflowCreateRequestToJSON(
          requestParameters.workflowCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return WorkflowDataResponseFromJSON(jsonValue);
  }

  async deleteHook(
    this: WorkflowApi,
    requestParameters: DeleteHookRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.hookId, "hookId");

    runtime.assertParamIsPresent(requestParameters.workflowId, "workflowId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/workflows/{workflow_id}/hooks/{hook_id}`
          .replace(
            `{${"hook_id"}}`,
            encodeURIComponent(String(requestParameters.hookId)),
          )
          .replace(
            `{${"workflow_id"}}`,
            encodeURIComponent(String(requestParameters.workflowId)),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async deleteWorkflow(
    this: WorkflowApi,
    requestParameters: DeleteWorkflowRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.workflowId, "workflowId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/workflows/{workflow_id}`.replace(
          `{${"workflow_id"}}`,
          encodeURIComponent(String(requestParameters.workflowId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchHook(
    this: WorkflowApi,
    requestParameters: FetchHookRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HookDataResponse> {
    runtime.assertParamIsPresent(requestParameters.hookId, "hookId");

    runtime.assertParamIsPresent(requestParameters.workflowId, "workflowId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/workflows/{workflow_id}/hooks/{hook_id}`
          .replace(
            `{${"hook_id"}}`,
            encodeURIComponent(String(requestParameters.hookId)),
          )
          .replace(
            `{${"workflow_id"}}`,
            encodeURIComponent(String(requestParameters.workflowId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return HookDataResponseFromJSON(jsonValue);
  }

  async fetchWorkflow(
    this: WorkflowApi,
    requestParameters: FetchWorkflowRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WorkflowDataResponse> {
    runtime.assertParamIsPresent(requestParameters.workflowId, "workflowId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/workflows/{workflow_id}`.replace(
          `{${"workflow_id"}}`,
          encodeURIComponent(String(requestParameters.workflowId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return WorkflowDataResponseFromJSON(jsonValue);
  }

  async listHooks(
    this: WorkflowApi,
    requestParameters: ListHooksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HookListResponse> {
    runtime.assertParamIsPresent(requestParameters.workflowId, "workflowId");

    const queryParameters: any = {};

    if (requestParameters.triggerProcess != null) {
      queryParameters["trigger_process"] = requestParameters.triggerProcess;
    }

    if (requestParameters.triggerState != null) {
      queryParameters["trigger_state"] = requestParameters.triggerState;
    }

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.managed != null) {
      queryParameters["managed"] = requestParameters.managed;
    }

    if (requestParameters.disabled != null) {
      queryParameters["disabled"] = requestParameters.disabled;
    }

    if (requestParameters.uri != null) {
      queryParameters["uri"] = requestParameters.uri;
    }

    if (requestParameters.uriLike != null) {
      queryParameters["uri_like"] = requestParameters.uriLike;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/workflows/{workflow_id}/hooks`.replace(
          `{${"workflow_id"}}`,
          encodeURIComponent(String(requestParameters.workflowId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return HookListResponseFromJSON(jsonValue);
  }

  async listWorkflows(
    this: WorkflowApi,
    requestParameters: ListWorkflowsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WorkflowListResponse> {
    const queryParameters: any = {};

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters._default != null) {
      queryParameters["default"] = requestParameters._default;
    }

    if (requestParameters.disabled != null) {
      queryParameters["disabled"] = requestParameters.disabled;
    }

    if (requestParameters.managed != null) {
      queryParameters["managed"] = requestParameters.managed;
    }

    if (requestParameters.contextSchemaFilter != null) {
      queryParameters["context_schema_filter"] =
        requestParameters.contextSchemaFilter;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/workflows`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return WorkflowListResponseFromJSON(jsonValue);
  }

  async updateHook(
    this: WorkflowApi,
    requestParameters: UpdateHookRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HookDataResponse> {
    runtime.assertParamIsPresent(requestParameters.hookId, "hookId");

    runtime.assertParamIsPresent(requestParameters.workflowId, "workflowId");

    runtime.assertParamIsPresent(
      requestParameters.hookUpdateRequest,
      "hookUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/workflows/{workflow_id}/hooks/{hook_id}`
          .replace(
            `{${"hook_id"}}`,
            encodeURIComponent(String(requestParameters.hookId)),
          )
          .replace(
            `{${"workflow_id"}}`,
            encodeURIComponent(String(requestParameters.workflowId)),
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: HookUpdateRequestToJSON(requestParameters.hookUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return HookDataResponseFromJSON(jsonValue);
  }

  async updateWorkflow(
    this: WorkflowApi,
    requestParameters: UpdateWorkflowRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WorkflowDataResponse> {
    runtime.assertParamIsPresent(requestParameters.workflowId, "workflowId");

    runtime.assertParamIsPresent(
      requestParameters.workflowUpdateRequest,
      "workflowUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/workflows/{workflow_id}`.replace(
          `{${"workflow_id"}}`,
          encodeURIComponent(String(requestParameters.workflowId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: WorkflowUpdateRequestToJSON(
          requestParameters.workflowUpdateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return WorkflowDataResponseFromJSON(jsonValue);
  }
}
