import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { find } from "~/lib/std";
import type { Topic } from "~/services/datastore";
import { usePlayerTopics } from "../hooks";
import { iteratePanels, usePanelLayoutContext } from "../panels";
import type { PlayerRecordKeys } from "../queries";
import { playerRecordKeys } from "../queries";

export function CacheBuster() {
  const { layout } = usePanelLayoutContext();

  const playerTopicsQuery = usePlayerTopics();

  const queryClient = useQueryClient();

  useEffect(
    function removeUnusedTopicCacheEntries() {
      if (playerTopicsQuery.data === undefined) {
        return;
      }

      const currentTopicIds = new Set<Topic["id"]>();

      iteratePanels(layout, (panel) => {
        if (!panel.isInitialized) {
          return;
        }

        const mainTopic = find(playerTopicsQuery.data, {
          name: panel.topicName,
        });
        if (mainTopic !== undefined) {
          currentTopicIds.add(mainTopic.id);
        }

        const segmentationsTopic = find(playerTopicsQuery.data, {
          name: panel.segmentationTopicName as any,
        });
        if (segmentationsTopic !== undefined) {
          currentTopicIds.add(segmentationsTopic.id);
        }
      });

      // For all player record list queries, check the request's topic ID to
      // see if there's a panel on screen for that topic. If not, immediately
      // remove it from the cache to free up some memory
      queryClient.removeQueries({
        queryKey: playerRecordKeys.lists(),
        predicate(query) {
          const queryKey = query.queryKey as
            | PlayerRecordKeys["lists"]
            | PlayerRecordKeys["list"];

          if (queryKey.length === 2) {
            return false;
          }

          const request = queryKey[2];

          return !currentTopicIds.has(request.topicId);
        },
      });
    },
    [playerTopicsQuery.data, layout, queryClient],
  );

  return null;
}
