import React, { useState } from "react";
import {
  Error as ErrorIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Tune,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import type {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { z } from "zod";
import { invariant } from "~/lib/invariant";
import { isEqual, sortBy } from "~/lib/std";
import type { DataStorePathGenerator } from "~/paths";
import { DataStoreLink } from "~/paths";
import type {
  CloudObject,
  ListObjectsRequest,
  ObjectListResponse,
} from "~/services/datastore";
import { pluralize } from "~/utils";
import { Center } from "./Center";
import { Error } from "./Error";
import { boolean, CheckboxField, TextField, useStudioForm } from "./Form";
import { Loading } from "./Loading";
import { QueryRenderer } from "./QueryRenderer";
import type { Column } from "./Table";
import {
  deserializeBooleanParam,
  filterText,
  normalizeColumn,
  useSearchRequest,
  withSearchValidationErrorBoundary,
} from "./Table";

const defaultBoolean = z.preprocess(
  // Neither `null` nor `undefined` are allowed but since this is parsing
  // search params those values need to be defaulted rather than rejected
  (arg) => deserializeBooleanParam(arg) ?? false,
  boolean,
);

export const listObjectsSchema = z.object({
  directory: filterText,
  prefix: filterText,
  processing: defaultBoolean,
});

export const OBJECT_KEY_DELIMITER = "/";

interface CreateObjectExplorerParams {
  useIdentifier: () => string;
  useSearch: (
    identifier: string,
    request: Pick<
      ListObjectsRequest,
      "delimiter" | "prefix" | "processing" | "maxKeys"
    >,
    options: Pick<UseInfiniteQueryOptions, "cacheTime" | "keepPreviousData">,
  ) => UseInfiniteQueryResult<ObjectListResponse>;
  generateDirectoryLocation: (
    identifier: string,
    directory?: string,
  ) => DataStorePathGenerator;
  generateObjectLocation: (
    identifier: string,
    objectKey: CloudObject["key"],
  ) => DataStorePathGenerator;
}

export function createObjectExplorer({
  useIdentifier,
  useSearch,
  generateDirectoryLocation,
  generateObjectLocation,
}: CreateObjectExplorerParams) {
  function ObjectLinkCell({ cloudObject }: { cloudObject: CloudObject }) {
    const identifier = useIdentifier();

    return (
      <TableCell>
        <Link
          component={DataStoreLink}
          to={generateObjectLocation(identifier, cloudObject.key)}
        >
          {formatObjectKey(cloudObject.key)}
        </Link>
      </TableCell>
    );
  }

  const COLUMNS: ReadonlyArray<Column<CloudObject>> = [
    {
      header: "Key",
      renderCell(cloudObject) {
        return <ObjectLinkCell cloudObject={cloudObject} />;
      },
    },
    {
      accessor: "etag",
      dataType: "text",
    },
    {
      accessor: "size",
      dataType: "bytes",
    },
    {
      accessor: "lastModified",
      dataType: "datetime",
    },
    {
      accessor: "uploadState",
      dataType: "text",
    },
  ];

  const NORMALIZED_COLUMNS = COLUMNS.map(normalizeColumn);

  function useObjectsSearch(identifier: string) {
    const [request, setRequest] = useSearchRequest(listObjectsSchema);

    const searchQuery = useSearch(
      identifier,
      {
        delimiter: OBJECT_KEY_DELIMITER,
        prefix: `${request.directory ?? ""}${request.prefix ?? ""}` || null,
        processing: request.processing,
        maxKeys: 100,
      },
      {
        cacheTime: 0,
        keepPreviousData: true,
      },
    );

    const [pagination, setPagination] = useState({
      previousRequest: request,
      page: 0,
    });

    // Pagination should be reset whenever the filters change. However, pagination
    // can't be stored directly with those filters since it shouldn't be a search
    // param. Consequently, setting state during render seems like the only choice
    // to perform this reset. The calling component can return early if this
    // variable indicates state was set during render.
    //
    // Note: Using a `key` prop based on the request parameters on a wrapper
    // component didn't work. There were various UI inconsistencies caused by
    // remounting essentially the entire page when a filter changed.
    let _didQueuePaginationReset = false;
    if (!isEqual(pagination.previousRequest, request)) {
      _didQueuePaginationReset = true;
      setPagination({ previousRequest: request, page: 0 });
    }

    const { page } = pagination;

    const previousPageEnabled = !searchQuery.isFetching && page > 0;
    const nextPageEnabled =
      searchQuery.isSuccess &&
      !searchQuery.isFetching &&
      // The `hasNextPage` field is only useful when the user is on the last
      // page of the table. If they're on a prior page, that implies a
      // subsequent page has been fetched before so they can definitely go
      // to the next page.
      (page === searchQuery.data.pages.length - 1
        ? searchQuery.hasNextPage
        : true);

    return {
      _didQueuePaginationReset,
      searchQuery,
      request,
      setRequest,
      previousPageDisabled: !previousPageEnabled,
      toPreviousPage() {
        invariant(previousPageEnabled, "Cannot go to previous page");

        setPagination({ ...pagination, page: page - 1 });
      },
      nextPageDisabled: !nextPageEnabled,
      toNextPage() {
        invariant(nextPageEnabled, "Cannot go to next page");

        const nextPage = page + 1;

        setPagination({ ...pagination, page: nextPage });

        if (nextPage === searchQuery.data.pageParams.length) {
          searchQuery.fetchNextPage();
        }
      },
      getCurrentPageResponse(data: InfiniteData<ObjectListResponse>) {
        // Need to access the current page based on the `page` state variable.
        // However, if incrementing `page` led to the next page being fetched,
        // then there won't be a response at `page`'s index until the fetch
        // succeeds. In that situation the prior page should be shown instead
        if (searchQuery.isFetchingNextPage && page === data.pages.length) {
          return data.pages.at(-1)!;
        } else {
          const response = data.pages.at(page);

          invariant(response !== undefined, `No page found at index ${page}`);

          return response;
        }
      },
    };
  }

  return withSearchValidationErrorBoundary(function ObjectExplorer() {
    const identifier = useIdentifier();

    const [areFiltersExpanded, setAreFiltersExpanded] = useState(false);

    function handleFilterSectionToggle() {
      setAreFiltersExpanded(!areFiltersExpanded);
    }

    const objectsSearch = useObjectsSearch(identifier);

    const { control, handleSubmit } = useStudioForm({
      schema: listObjectsSchema,
      values: objectsSearch.request,
      onSubmit: objectsSearch.setRequest,
    });

    if (objectsSearch._didQueuePaginationReset) {
      return null;
    }

    const { searchQuery } = objectsSearch;

    let toolbarMessage;
    if (searchQuery.isLoading) {
      toolbarMessage = <Typography>Fetching objects...</Typography>;
    } else if (searchQuery.isError) {
      toolbarMessage = (
        <Stack direction="row" alignItems="center" spacing={1}>
          <ErrorIcon color="error" />
          <Typography>Unable to perform search</Typography>
        </Stack>
      );
    } else if (searchQuery.isRefetching) {
      toolbarMessage = <Typography>Searching...</Typography>;
    } else {
      toolbarMessage = (
        <Typography>
          {pluralize(
            objectsSearch.getCurrentPageResponse(searchQuery.data).keyCount ??
              0,
            "object",
          )}
        </Typography>
      );
    }

    function renderDirectoryBreadcrumbs() {
      const {
        request: { directory },
      } = objectsSearch;

      if (directory === null) {
        return <Typography>{identifier}</Typography>;
      }

      let segments = directory.split(OBJECT_KEY_DELIMITER);
      if (segments.at(-1) === "") {
        segments = segments.slice(0, -1);
      }

      return (
        <Breadcrumbs>
          <Link
            component={DataStoreLink}
            to={generateDirectoryLocation(identifier)}
          >
            {identifier}
          </Link>
          {segments.map((segment, index, segments) => {
            if (index === segments.length - 1) {
              return <Typography key={segment}>{segment}</Typography>;
            } else {
              const directory = `${segments
                .slice(0, index + 1)
                .join(OBJECT_KEY_DELIMITER)}${OBJECT_KEY_DELIMITER}`;

              return (
                <Link
                  key={segment}
                  component={DataStoreLink}
                  to={generateDirectoryLocation(identifier, directory)}
                >
                  {segment}
                </Link>
              );
            }
          })}
        </Breadcrumbs>
      );
    }

    return (
      <Card>
        <CardContent>
          <Stack spacing={2} useFlexGap>
            <Stack direction="row" alignItems="center">
              <Tooltip title="Filters" sx={{ mr: 1 }}>
                <Badge badgeContent={0} color="primary">
                  <IconButton size="small" onClick={handleFilterSectionToggle}>
                    <Tune fontSize="small" />
                  </IconButton>
                </Badge>
              </Tooltip>
              {toolbarMessage}
            </Stack>
            {renderDirectoryBreadcrumbs()}
            <Box position="relative">
              <Divider />
              {(searchQuery.isRefetching || searchQuery.isFetchingNextPage) && (
                <LinearProgress
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                />
              )}
              <Collapse
                sx={{
                  "& .MuiCollapse-wrapperInner": {
                    position: "relative",
                    my: 2,
                  },
                }}
                in={areFiltersExpanded}
              >
                <Grid
                  container
                  spacing={2}
                  component="form"
                  onSubmit={handleSubmit}
                >
                  <Grid item xs={3}>
                    <TextField control={control} name="prefix" />
                  </Grid>
                  <Grid item xs={3}>
                    <CheckboxField control={control} name="processing" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained">
                      Filter
                    </Button>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 2 }} />
                {(searchQuery.isRefetching ||
                  searchQuery.isFetchingNextPage) && (
                  <LinearProgress
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                  />
                )}
              </Collapse>
            </Box>
          </Stack>
          <QueryRenderer
            query={searchQuery}
            loading={
              <Box sx={{ py: 5 }}>
                <Loading type="circular" />
              </Box>
            }
            error={
              <Box sx={{ py: 5 }}>
                <Error>
                  <Typography variant="h4" component="p">
                    An error occurred searching for objects
                  </Typography>
                </Error>
              </Box>
            }
            success={(data) => {
              const response = objectsSearch.getCurrentPageResponse(data);

              if (
                (response.keyCount === null || response.keyCount === 0) &&
                response.data.length === 0
              ) {
                return (
                  <Center sx={{ py: 5 }}>
                    <Typography variant="h4" component="p">
                      The search returned 0 results
                    </Typography>
                  </Center>
                );
              } else {
                const sortedRows = sortBy(
                  [...(response.commonPrefixes ?? []), ...response.data],
                  (item) =>
                    typeof item === "string" ? item : formatObjectKey(item.key),
                );

                return (
                  <TableContainer
                    sx={{ overflowX: "auto", whiteSpace: "nowrap" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            [`& .${tableCellClasses.root}`]: {
                              bgcolor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "grey.800"
                                  : "grey.300",
                              borderBottom: "unset",
                            },
                          }}
                        >
                          {NORMALIZED_COLUMNS.map((column) => (
                            <TableCell key={column.header} align={column.align}>
                              {column.header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedRows.map((row) => (
                          <TableRow
                            key={typeof row === "string" ? row : row.key}
                            sx={{
                              // Remove bottom border for table cells in last row
                              [`&:last-of-type .${tableCellClasses.root}`]: {
                                borderBottom: "unset",
                              },
                            }}
                          >
                            {typeof row === "string" ? (
                              <TableCell colSpan={NORMALIZED_COLUMNS.length}>
                                <Link
                                  component={DataStoreLink}
                                  to={generateDirectoryLocation(
                                    identifier,
                                    row,
                                  )}
                                >
                                  {formatCommonPrefix(row)}
                                </Link>
                              </TableCell>
                            ) : (
                              NORMALIZED_COLUMNS.map((column) => (
                                <React.Fragment key={column.header}>
                                  {column.renderCell(row, {
                                    align: column.align,
                                  })}
                                </React.Fragment>
                              ))
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }
            }}
          />
          <Divider sx={{ mb: 2 }} />
          <Stack direction="row" justifyContent="end" spacing={1} useFlexGap>
            <Tooltip title="Previous page">
              <span>
                <IconButton
                  disabled={objectsSearch.previousPageDisabled}
                  onClick={objectsSearch.toPreviousPage}
                >
                  <KeyboardArrowLeft />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Next page">
              <span>
                <IconButton
                  disabled={objectsSearch.nextPageDisabled}
                  onClick={objectsSearch.toNextPage}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </CardContent>
      </Card>
    );
  });
}

function formatObjectKey(key: CloudObject["key"]): string {
  return key.slice(key.lastIndexOf(OBJECT_KEY_DELIMITER) + 1);
}

function formatCommonPrefix(commonPrefix: string): string {
  return commonPrefix.slice(
    commonPrefix.lastIndexOf(
      OBJECT_KEY_DELIMITER,
      // Common prefixes end with the delimiter so the search needs to start
      // from the index directly before that
      commonPrefix.length - 2,
    ) + 1,
  );
}
