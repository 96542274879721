import { useTopic } from "~/domain/datastores";
import { makeTopicLocation } from "~/paths";
import type { Topic } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface TopicInfoProps {
  topicId: Topic["id"];
}

export function TopicInfo({ topicId }: TopicInfoProps) {
  const topicNameQuery = useTopic(topicId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <ResourceInfo
      name={topicNameQuery.data}
      identifier={topicId}
      to={makeTopicLocation({ topicId })}
    />
  );
}
