import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { ApiKeyEditForm } from "./components/ApiKeyEditForm";

export function EditApiKeyPage() {
  return (
    <Page title="Edit API Key">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <ApiKeyEditForm />
      </Container>
    </Page>
  );
}
