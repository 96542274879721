import { Button, Grid } from "@mui/material";
import { BooleanField, FuzzyField, useStudioForm } from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListWorkflowsFormValues } from "../schemas";
import { listWorkflowsSchema } from "../schemas";

export interface WorkflowFiltersProps {
  values: ListWorkflowsFormValues;
  setValues: (newValues: ListWorkflowsFormValues) => void;
}

export function WorkflowFilters({ values, setValues }: WorkflowFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listWorkflowsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
        </Grid>
        <Grid item xs={3}>
          <BooleanField control={control} name="_default" />
        </Grid>
        <Grid item xs={3}>
          <BooleanField control={control} name="managed" />
        </Grid>
        <Grid item xs={3}>
          <BooleanField control={control} name="disabled" />
        </Grid>
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
