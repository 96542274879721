import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  FuzzyField,
  processStateOptions,
  SelectField,
  TextField,
  useStudioForm,
  WorkflowSelect,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListLogQueriesFormValues } from "../schemas";
import { listLogQueriesSchema } from "../schemas";

export interface LogQueryFiltersProps {
  values: ListLogQueriesFormValues;
  setValues: (newValues: ListLogQueriesFormValues) => void;
}

export function LogQueryFilters({ values, setValues }: LogQueryFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listLogQueriesSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      </Grid>
      <Grid item xs={3}>
        <FuzzyField
          control={control}
          exactName="statement"
          fuzzyName="statementLike"
        />
      </Grid>
      <Grid item xs={3}>
        <WorkflowSelect control={control} name="workflowId" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="workflowIdNull" />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          control={control}
          name="state"
          options={processStateOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="noteLike" />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
