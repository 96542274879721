import type { UseQueryResult } from "@tanstack/react-query";
import type { Auditable } from "~/domain/auditable";
import { auditFields } from "~/domain/auditable";
import { Card } from "../Card";
import { ResourceFields } from "./ResourceFields";

export interface HistoryCardProps {
  query: UseQueryResult<Auditable>;
}

export function HistoryCard({ query }: HistoryCardProps) {
  return (
    <Card title="History">
      <ResourceFields query={query} fields={auditFields} />
    </Card>
  );
}
