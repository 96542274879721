import React from "react";
import { Settings } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { Error } from "./Error";
import { GlobalNavigation } from "./GlobalNavigation";
import { Header } from "./Header";
import { Helmet } from "./Helmet";
import {
  Layout,
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "./Layout";
import { SettingsDrawer } from "./SettingsDrawer";

export interface PageProps {
  title: string;
  children: React.ReactNode;
}

export function Page({ title, children }: PageProps) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title={title}
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <ErrorBoundary fallback={<PageError />}>{children}</ErrorBoundary>
          </Box>
        </Layout>
      </LayoutStateProvider>
    </>
  );
}

function PageError() {
  return (
    <Error>
      <Typography variant="h4" component="p" paragraph>
        An unexpected error occurred.
      </Typography>
      <Typography variant="h4" component="p">
        Try refreshing the page.
      </Typography>
    </Error>
  );
}
