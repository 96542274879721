import type { ComponentType, RefCallback } from "react";
import { useCallback } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import type { FallbackProps } from "react-error-boundary";
import { withErrorBoundary } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import { ValidationError } from "~/errors";

/**
 * Handles validation errors thrown by the `useSearchRequest` hook. Any other
 * errors are propagated up to the next error boundary.
 */
export function withSearchValidationErrorBoundary<TProps extends object>(
  component: ComponentType<TProps>,
) {
  return withErrorBoundary(component, {
    FallbackComponent: SearchValidationErrorBoundary,
  });
}

function SearchValidationErrorBoundary({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  const [, setSearchParams] = useSearchParams();

  // On composite pages like topic details this component will likely be
  // rendered out of view at the bottom of the page, so on mount it should
  // be scrolled into view
  const ref = useScrollIntoView();

  // This component only handle validation errors whose source was the
  // URL search params
  if (!(error instanceof ValidationError) || error.source !== "search") {
    throw error;
  }

  function handleReset() {
    // Clear the search params and replace the current location so back button
    // won't cause the user to see this error again
    setSearchParams({}, { replace: true });
    // Should be safe to re-render once search params are cleared
    resetErrorBoundary();
  }

  return (
    <Card ref={ref} sx={{ border: 1, borderColor: "error.main" }}>
      <CardContent>
        <Typography variant="h4" component="p" paragraph>
          Some query params in the link you followed were invalid.
        </Typography>
        <Typography variant="h5" component="p" paragraph>
          This could happen for several reasons:
        </Typography>
        <Typography variant="h5" component="ul" paragraph>
          <li>The link was copy/pasted incorrectly</li>
          <li>
            The link was old and Studio no longer recognizes those query params
          </li>
          <li>You manually edited the query params incorrectly</li>
          <li>There is a bug in Studio</li>
        </Typography>
        <Button variant="contained" onClick={handleReset}>
          Clear Query Params
        </Button>
      </CardContent>
    </Card>
  );
}

function useScrollIntoView(): RefCallback<HTMLElement> {
  return useCallback((node) => {
    node?.scrollIntoView(true);
  }, []);
}
