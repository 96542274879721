import React from "react";
import { alpha, Button, Stack, styled } from "@mui/material";
import { useAreTopicTagsShowing, useTagActions } from "~/pages/player/tags";
import type { Topic } from "~/services/datastore";

const ClassNames = {
  ControlsSection: "panel-controls-section",
} as const;

const Root = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  height: "100%",
  right: 0,
  width: "min(80%, 300px)",
  padding: theme.spacing(2),
  overflowY: "auto",
  backgroundColor: alpha(
    theme.palette.mode === "light"
      ? theme.palette.grey["200"]
      : theme.palette.grey["500"],
    0.5,
  ),
  "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)": {
    backdropFilter: "blur(6px)",
  },
  [`& .${ClassNames.ControlsSection}:not(:first-of-type)`]: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiTypography-root": {
    fontWeight: 600,
  },
}));

interface PanelControlsProps {
  children: React.ReactNode;
}

export function PanelControls({ children }: PanelControlsProps) {
  return <Root>{children}</Root>;
}

interface ControlsSectionProps {
  children: React.ReactNode;
}

export function ControlsSection({ children }: ControlsSectionProps) {
  return <Stack className={ClassNames.ControlsSection}>{children}</Stack>;
}

interface TagsControlButtonProps {
  topic: Topic;
}

export function TagsControlButton({ topic }: TagsControlButtonProps) {
  const areTopicTagsShowing = useAreTopicTagsShowing(topic.id);
  const { toggleTags } = useTagActions();

  function handleToggleTags() {
    toggleTags({ topicId: topic.id });
  }

  return (
    <Button
      fullWidth
      disableElevation
      variant="contained"
      onClick={handleToggleTags}
    >
      {areTopicTagsShowing ? "Hide tags" : "Show tags"}
    </Button>
  );
}
