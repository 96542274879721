import React, { useMemo } from "react";
import type { ThemeOptions } from "@mui/material";
import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useDarkMode } from "./DarkModeProvider";

export interface ThemeProviderProps {
  children: React.ReactNode;
}

const staticThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#E11E25",
    },
    secondary: {
      main: "#8B8C90",
    },
    grey: {
      "50": "#F6F7FC",
      "100": "#EEEFF3",
      "200": "#E2E3E8",
      "300": "#D0D1D6",
      "400": "#ACADB1",
      "500": "#8B8C90",
      "600": "#646468",
      "700": "#515155",
      "800": "#333337",
      "900": "#131417",
      A100: "#EEEFF3",
      A200: "#E2E3E8",
      A400: "#ACADB1",
      A700: "#515155",
    },
  },
  typography: {
    fontFamily: "'Open Sans', Arial, sans-serif",
    h1: {
      fontSize: 64,
      lineHeight: 80 / 64,
      fontWeight: 700,
    },
    h2: {
      fontSize: 48,
      lineHeight: 64 / 48,
      fontWeight: 700,
    },
    h3: {
      fontSize: 32,
      lineHeight: 48 / 32,
      fontWeight: 700,
    },
    h4: {
      fontSize: 24,
      lineHeight: 36 / 24,
      fontWeight: 700,
    },
    h5: {
      fontSize: 20,
      lineHeight: 30 / 20,
      fontWeight: 700,
    },
    h6: {
      fontSize: 18,
      lineHeight: 28 / 18,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 24 / 16,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 22 / 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      lineHeight: 24 / 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      lineHeight: 22 / 14,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      lineHeight: 18 / 12,
      fontWeight: 400,
    },
    overline: {
      fontSize: 12,
      lineHeight: 18 / 12,
      fontWeight: 700,
      letterSpacing: 1.2,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-focused": {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 100,
      },
    },
  },
};

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const { mode } = useDarkMode();

  const theme = useMemo(
    () =>
      responsiveFontSizes(
        createTheme(
          deepmerge(staticThemeOptions, {
            palette: {
              mode,
              ...(mode === "dark" && {
                background: {
                  default: "#000000",
                  paper: "#000000",
                },
              }),
            },
          }),
        ),
      ),
    [mode],
  );

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </MuiThemeProvider>
  );
}
