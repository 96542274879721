import {
  CheckboxField,
  EditResourceForm,
  NumberField,
  ObjectField,
  ObjectStoreSelect,
  processStateOptions,
  SelectField,
  TextField,
  WorkflowSelect,
} from "~/components/Form";
import { useIngestion, useUpdateIngestion } from "~/domain/datastores";
import { makeIngestionLocation, useIngestionParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_INGESTION_FIELDS, editIngestionSchema } from "../schemas";

export function IngestionEditForm() {
  const { ingestionId } = useIngestionParams();

  return (
    <EditResourceForm
      schema={editIngestionSchema}
      resourceName="ingestion"
      query={useIngestion(ingestionId, { select: selectData })}
      editableFields={EDITABLE_INGESTION_FIELDS}
      mutation={useUpdateIngestion(ingestionId)}
      resourceLocation={makeIngestionLocation({ ingestionId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <ObjectStoreSelect control={control} name="objectStoreId" />
          <TextField control={control} name="objectKey" />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <NumberField control={control} name="progress" />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <ObjectField control={control} name="error" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditResourceForm>
  );
}
