import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  requiredText,
  schemaShapeForType,
  uuid,
} from "~/components/Form";
import { filterText, filterUuid } from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  APIKey,
  APIKeyCreateRequest,
  APIKeyUpdateRequest,
  ListApiKeysRequest,
} from "~/services/datastore";

export const listApiKeysSchema = z.object(
  schemaShapeForType<ListApiKeysRequest>()({
    name: filterText,
    nameLike: filterText,
    userId: filterUuid,
    ...auditFieldSchemas,
  }),
);

export type ListApiKeysFormValues = z.infer<typeof listApiKeysSchema>;

export const createApiKeySchema = z.object(
  schemaShapeForType<APIKeyCreateRequest>()({
    name: requiredText,
    userId: uuid,
    disabled: boolean,
  }),
);

export type CreateApiKeyFormValues = z.infer<typeof createApiKeySchema>;

export const editApiKeySchema = z.object(
  schemaShapeForType<APIKeyUpdateRequest>()({
    name: requiredText,
    disabled: boolean,
  }),
);

export const EDITABLE_API_KEY_FIELDS = editApiKeySchema.keyof().options;

export type EditApiKeyFormValues = Pick<
  APIKey,
  ElementOf<typeof EDITABLE_API_KEY_FIELDS>
>;
