import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { TopicDetails } from "./components/TopicDetails";

export function TopicPage() {
  return (
    <Page title="Topic Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <TopicDetails />
      </Container>
    </Page>
  );
}
