import { Link, Skeleton, Stack, Typography } from "@mui/material";
import type { DataStorePathGenerator } from "~/paths";
import { DataStoreLink } from "~/paths";
import type { Maybe } from "~/types";

export interface ResourceInfoProps {
  name: Maybe<string>;
  identifier: string;
  to?: DataStorePathGenerator;
}

export function ResourceInfo({ name, identifier, to }: ResourceInfoProps) {
  const identifierText = <Typography variant="body2">{identifier}</Typography>;

  return (
    <Stack>
      {name === null ? "-" : name ?? <Skeleton />}
      {to === undefined ? (
        identifierText
      ) : (
        <Link component={DataStoreLink} to={to}>
          {identifierText}
        </Link>
      )}
    </Stack>
  );
}
