/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  HTTPValidationError,
  LogCreateRequest,
  LogDataResponse,
  LogListResponse,
  LogUpdateRequest,
  ObjectCreateRequest,
  ObjectDataResponse,
  ObjectListResponse,
  ObjectPartCreateRequest,
  ObjectPartDataResponse,
  ObjectPartListResponse,
  ObjectUpdateRequest,
  QueryCreateRequest,
  QueryDataResponse,
  QueryListResponse,
  QueryUpdateRequest,
  TagCreateRequest,
  TagDataResponse,
  TagListResponse,
  TagUpdateRequest,
} from "../models";
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  LogCreateRequestFromJSON,
  LogCreateRequestToJSON,
  LogDataResponseFromJSON,
  LogDataResponseToJSON,
  LogListResponseFromJSON,
  LogListResponseToJSON,
  LogUpdateRequestFromJSON,
  LogUpdateRequestToJSON,
  ObjectCreateRequestFromJSON,
  ObjectCreateRequestToJSON,
  ObjectDataResponseFromJSON,
  ObjectDataResponseToJSON,
  ObjectListResponseFromJSON,
  ObjectListResponseToJSON,
  ObjectPartCreateRequestFromJSON,
  ObjectPartCreateRequestToJSON,
  ObjectPartDataResponseFromJSON,
  ObjectPartDataResponseToJSON,
  ObjectPartListResponseFromJSON,
  ObjectPartListResponseToJSON,
  ObjectUpdateRequestFromJSON,
  ObjectUpdateRequestToJSON,
  QueryCreateRequestFromJSON,
  QueryCreateRequestToJSON,
  QueryDataResponseFromJSON,
  QueryDataResponseToJSON,
  QueryListResponseFromJSON,
  QueryListResponseToJSON,
  QueryUpdateRequestFromJSON,
  QueryUpdateRequestToJSON,
  TagCreateRequestFromJSON,
  TagCreateRequestToJSON,
  TagDataResponseFromJSON,
  TagDataResponseToJSON,
  TagListResponseFromJSON,
  TagListResponseToJSON,
  TagUpdateRequestFromJSON,
  TagUpdateRequestToJSON,
} from "../models";

export interface CreateLogRequest {
  logCreateRequest: LogCreateRequest;
}

export interface CreateLogObjectRequest {
  logId: string;
  objectCreateRequest: ObjectCreateRequest;
}

export interface CreateLogObjectPartRequest {
  objectKey: string;
  logId: string;
  objectPartCreateRequest: ObjectPartCreateRequest;
}

export interface CreateQueryRequest {
  logId: string;
  queryCreateRequest: QueryCreateRequest;
}

export interface CreateTagRequest {
  logId: string;
  tagCreateRequest: TagCreateRequest;
}

export interface DeleteLogRequest {
  logId: string;
}

export interface DeleteLogObjectRequest {
  logId: string;
  objectKey: string;
}

export interface DeleteQueryRequest {
  queryId: string;
  logId: string | null;
}

export interface DeleteTagRequest {
  tagId: string;
  logId: string | null;
}

export interface FetchLogRequest {
  logId: string;
}

export interface FetchLogObjectRequest {
  objectKey: string;
  logId: string;
  redirect?: boolean | null;
  offset?: number | null;
  length?: number | null;
}

export interface FetchLogObjectPartRequest {
  objectKey: string;
  partNumber: number;
  logId: string;
}

export interface FetchQueryRequest {
  queryId: string;
  logId: string | null;
}

export interface FetchTagRequest {
  tagId: string;
  logId: string | null;
}

export interface ListLogObjectPartsRequest {
  objectKey: string;
  logId: string;
  maxParts?: number | null;
  partNumberMarker?: number | null;
}

export interface ListLogObjectsRequest {
  logId: string;
  processing?: boolean | null;
  maxKeys?: number | null;
  prefix?: string | null;
  startAfter?: string | null;
  delimiter?: string | null;
  continuationToken?: string | null;
}

export interface ListLogsRequest {
  groupId?: string | null;
  defaultWorkflowId?: string | null;
  defaultWorkflowIdNull?: boolean | null;
  name?: string | null;
  nameLike?: string | null;
  locked?: boolean | null;
  noteLike?: string | null;
  contextFilter?: string | null;
  timeAdjustmentNull?: boolean | null;
  timeAdjustmentGte?: bigint | null;
  timeAdjustmentLte?: bigint | null;
  startTimeNull?: boolean | null;
  startTimeGte?: bigint | null;
  startTimeLte?: bigint | null;
  endTimeNull?: boolean | null;
  endTimeGte?: bigint | null;
  endTimeLte?: bigint | null;
  recordSizeGte?: number | null;
  recordSizeLte?: number | null;
  recordCountGte?: number | null;
  recordCountLte?: number | null;
  objectSizeGte?: number | null;
  objectSizeLte?: number | null;
  objectCountGte?: number | null;
  objectCountLte?: number | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface ListQueriesRequest {
  logId: string | null;
  name?: string | null;
  nameLike?: string | null;
  noteLike?: string | null;
  statement?: string | null;
  statementLike?: string | null;
  workflowId?: string | null;
  workflowIdNull?: boolean | null;
  workflowContextFilter?: string | null;
  state?: string | null;
  contextFilter?: string | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface ListTagsRequest {
  logId: string | null;
  labelId?: string | null;
  topicId?: string | null;
  note?: string | null;
  noteLike?: string | null;
  contextFilter?: string | null;
  startTimeNull?: boolean | null;
  startTimeGte?: bigint | null;
  startTimeLte?: bigint | null;
  endTimeNull?: boolean | null;
  endTimeGte?: bigint | null;
  endTimeLte?: bigint | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateLogRequest {
  logId: string;
  logUpdateRequest: LogUpdateRequest;
}

export interface UpdateLogObjectRequest {
  logId: string;
  objectKey: string;
  objectUpdateRequest: ObjectUpdateRequest;
}

export interface UpdateQueryRequest {
  queryId: string;
  logId: string | null;
  queryUpdateRequest: QueryUpdateRequest;
}

export interface UpdateTagRequest {
  tagId: string;
  logId: string | null;
  tagUpdateRequest: TagUpdateRequest;
}

export class LogApi extends runtime.BaseAPI {
  async createLog(
    this: LogApi,
    requestParameters: CreateLogRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LogDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.logCreateRequest,
      "logCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/logs`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LogCreateRequestToJSON(requestParameters.logCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return LogDataResponseFromJSON(jsonValue);
  }

  async createLogObject(
    this: LogApi,
    requestParameters: CreateLogObjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectDataResponse> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    runtime.assertParamIsPresent(
      requestParameters.objectCreateRequest,
      "objectCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/objects`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ObjectCreateRequestToJSON(requestParameters.objectCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectDataResponseFromJSON(jsonValue);
  }

  async createLogObjectPart(
    this: LogApi,
    requestParameters: CreateLogObjectPartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectPartDataResponse> {
    runtime.assertParamIsPresent(requestParameters.objectKey, "objectKey");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    runtime.assertParamIsPresent(
      requestParameters.objectPartCreateRequest,
      "objectPartCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/objects/{object_key}/parts`
          .replace(
            `{${"object_key"}}`,
            encodeURIComponent(String(requestParameters.objectKey)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ObjectPartCreateRequestToJSON(
          requestParameters.objectPartCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectPartDataResponseFromJSON(jsonValue);
  }

  async createQuery(
    this: LogApi,
    requestParameters: CreateQueryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<QueryDataResponse> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    runtime.assertParamIsPresent(
      requestParameters.queryCreateRequest,
      "queryCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/queries`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: QueryCreateRequestToJSON(requestParameters.queryCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return QueryDataResponseFromJSON(jsonValue);
  }

  async createTag(
    this: LogApi,
    requestParameters: CreateTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TagDataResponse> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    runtime.assertParamIsPresent(
      requestParameters.tagCreateRequest,
      "tagCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/tags`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TagCreateRequestToJSON(requestParameters.tagCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return TagDataResponseFromJSON(jsonValue);
  }

  async deleteLog(
    this: LogApi,
    requestParameters: DeleteLogRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/logs/{log_id}`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async deleteLogObject(
    this: LogApi,
    requestParameters: DeleteLogObjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    runtime.assertParamIsPresent(requestParameters.objectKey, "objectKey");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/logs/{log_id}/objects/{object_key}`
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          )
          .replace(
            `{${"object_key"}}`,
            encodeURIComponent(String(requestParameters.objectKey)),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async deleteQuery(
    this: LogApi,
    requestParameters: DeleteQueryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.queryId, "queryId");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/logs/{log_id}/queries/{query_id}`
          .replace(
            `{${"query_id"}}`,
            encodeURIComponent(String(requestParameters.queryId)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async deleteTag(
    this: LogApi,
    requestParameters: DeleteTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.tagId, "tagId");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/logs/{log_id}/tags/{tag_id}`
          .replace(
            `{${"tag_id"}}`,
            encodeURIComponent(String(requestParameters.tagId)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchLog(
    this: LogApi,
    requestParameters: FetchLogRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LogDataResponse> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs/{log_id}`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return LogDataResponseFromJSON(jsonValue);
  }

  async fetchLogObject(
    this: LogApi,
    requestParameters: FetchLogObjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectDataResponse> {
    runtime.assertParamIsPresent(requestParameters.objectKey, "objectKey");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    if (requestParameters.redirect != null) {
      queryParameters["redirect"] = requestParameters.redirect;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.length != null) {
      queryParameters["length"] = requestParameters.length;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/objects/{object_key}`
          .replace(
            `{${"object_key"}}`,
            encodeURIComponent(String(requestParameters.objectKey)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectDataResponseFromJSON(jsonValue);
  }

  async fetchLogObjectPart(
    this: LogApi,
    requestParameters: FetchLogObjectPartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectPartDataResponse> {
    runtime.assertParamIsPresent(requestParameters.objectKey, "objectKey");

    runtime.assertParamIsPresent(requestParameters.partNumber, "partNumber");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/objects/{object_key}/parts/{part_number}`
          .replace(
            `{${"object_key"}}`,
            encodeURIComponent(String(requestParameters.objectKey)),
          )
          .replace(
            `{${"part_number"}}`,
            encodeURIComponent(String(requestParameters.partNumber)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectPartDataResponseFromJSON(jsonValue);
  }

  async fetchQuery(
    this: LogApi,
    requestParameters: FetchQueryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<QueryDataResponse> {
    runtime.assertParamIsPresent(requestParameters.queryId, "queryId");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/queries/{query_id}`
          .replace(
            `{${"query_id"}}`,
            encodeURIComponent(String(requestParameters.queryId)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return QueryDataResponseFromJSON(jsonValue);
  }

  async fetchTag(
    this: LogApi,
    requestParameters: FetchTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TagDataResponse> {
    runtime.assertParamIsPresent(requestParameters.tagId, "tagId");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/tags/{tag_id}`
          .replace(
            `{${"tag_id"}}`,
            encodeURIComponent(String(requestParameters.tagId)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return TagDataResponseFromJSON(jsonValue);
  }

  async listLogObjectParts(
    this: LogApi,
    requestParameters: ListLogObjectPartsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectPartListResponse> {
    runtime.assertParamIsPresent(requestParameters.objectKey, "objectKey");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    if (requestParameters.maxParts != null) {
      queryParameters["max_parts"] = requestParameters.maxParts;
    }

    if (requestParameters.partNumberMarker != null) {
      queryParameters["part_number_marker"] =
        requestParameters.partNumberMarker;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/objects/{object_key}/parts`
          .replace(
            `{${"object_key"}}`,
            encodeURIComponent(String(requestParameters.objectKey)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectPartListResponseFromJSON(jsonValue);
  }

  async listLogObjects(
    this: LogApi,
    requestParameters: ListLogObjectsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectListResponse> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    if (requestParameters.processing != null) {
      queryParameters["processing"] = requestParameters.processing;
    }

    if (requestParameters.maxKeys != null) {
      queryParameters["max_keys"] = requestParameters.maxKeys;
    }

    if (requestParameters.prefix != null) {
      queryParameters["prefix"] = requestParameters.prefix;
    }

    if (requestParameters.startAfter != null) {
      queryParameters["start_after"] = requestParameters.startAfter;
    }

    if (requestParameters.delimiter != null) {
      queryParameters["delimiter"] = requestParameters.delimiter;
    }

    if (requestParameters.continuationToken != null) {
      queryParameters["continuation_token"] =
        requestParameters.continuationToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/objects`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectListResponseFromJSON(jsonValue);
  }

  async listLogs(
    this: LogApi,
    requestParameters: ListLogsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LogListResponse> {
    const queryParameters: any = {};

    if (requestParameters.groupId != null) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.defaultWorkflowId != null) {
      queryParameters["default_workflow_id"] =
        requestParameters.defaultWorkflowId;
    }

    if (requestParameters.defaultWorkflowIdNull != null) {
      queryParameters["default_workflow_id_null"] =
        requestParameters.defaultWorkflowIdNull;
    }

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.locked != null) {
      queryParameters["locked"] = requestParameters.locked;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.contextFilter != null) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.timeAdjustmentNull != null) {
      queryParameters["time_adjustment_null"] =
        requestParameters.timeAdjustmentNull;
    }

    if (requestParameters.timeAdjustmentGte != null) {
      queryParameters["time_adjustment_gte"] =
        requestParameters.timeAdjustmentGte;
    }

    if (requestParameters.timeAdjustmentLte != null) {
      queryParameters["time_adjustment_lte"] =
        requestParameters.timeAdjustmentLte;
    }

    if (requestParameters.startTimeNull != null) {
      queryParameters["start_time_null"] = requestParameters.startTimeNull;
    }

    if (requestParameters.startTimeGte != null) {
      queryParameters["start_time_gte"] = requestParameters.startTimeGte;
    }

    if (requestParameters.startTimeLte != null) {
      queryParameters["start_time_lte"] = requestParameters.startTimeLte;
    }

    if (requestParameters.endTimeNull != null) {
      queryParameters["end_time_null"] = requestParameters.endTimeNull;
    }

    if (requestParameters.endTimeGte != null) {
      queryParameters["end_time_gte"] = requestParameters.endTimeGte;
    }

    if (requestParameters.endTimeLte != null) {
      queryParameters["end_time_lte"] = requestParameters.endTimeLte;
    }

    if (requestParameters.recordSizeGte != null) {
      queryParameters["record_size_gte"] = requestParameters.recordSizeGte;
    }

    if (requestParameters.recordSizeLte != null) {
      queryParameters["record_size_lte"] = requestParameters.recordSizeLte;
    }

    if (requestParameters.recordCountGte != null) {
      queryParameters["record_count_gte"] = requestParameters.recordCountGte;
    }

    if (requestParameters.recordCountLte != null) {
      queryParameters["record_count_lte"] = requestParameters.recordCountLte;
    }

    if (requestParameters.objectSizeGte != null) {
      queryParameters["object_size_gte"] = requestParameters.objectSizeGte;
    }

    if (requestParameters.objectSizeLte != null) {
      queryParameters["object_size_lte"] = requestParameters.objectSizeLte;
    }

    if (requestParameters.objectCountGte != null) {
      queryParameters["object_count_gte"] = requestParameters.objectCountGte;
    }

    if (requestParameters.objectCountLte != null) {
      queryParameters["object_count_lte"] = requestParameters.objectCountLte;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return LogListResponseFromJSON(jsonValue);
  }

  async listQueries(
    this: LogApi,
    requestParameters: ListQueriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<QueryListResponse> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.statement != null) {
      queryParameters["statement"] = requestParameters.statement;
    }

    if (requestParameters.statementLike != null) {
      queryParameters["statement_like"] = requestParameters.statementLike;
    }

    if (requestParameters.workflowId != null) {
      queryParameters["workflow_id"] = requestParameters.workflowId;
    }

    if (requestParameters.workflowIdNull != null) {
      queryParameters["workflow_id_null"] = requestParameters.workflowIdNull;
    }

    if (requestParameters.workflowContextFilter != null) {
      queryParameters["workflow_context_filter"] =
        requestParameters.workflowContextFilter;
    }

    if (requestParameters.state != null) {
      queryParameters["state"] = requestParameters.state;
    }

    if (requestParameters.contextFilter != null) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/queries`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return QueryListResponseFromJSON(jsonValue);
  }

  async listTags(
    this: LogApi,
    requestParameters: ListTagsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TagListResponse> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    const queryParameters: any = {};

    if (requestParameters.labelId != null) {
      queryParameters["label_id"] = requestParameters.labelId;
    }

    if (requestParameters.topicId != null) {
      queryParameters["topic_id"] = requestParameters.topicId;
    }

    if (requestParameters.note != null) {
      queryParameters["note"] = requestParameters.note;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.contextFilter != null) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.startTimeNull != null) {
      queryParameters["start_time_null"] = requestParameters.startTimeNull;
    }

    if (requestParameters.startTimeGte != null) {
      queryParameters["start_time_gte"] = requestParameters.startTimeGte;
    }

    if (requestParameters.startTimeLte != null) {
      queryParameters["start_time_lte"] = requestParameters.startTimeLte;
    }

    if (requestParameters.endTimeNull != null) {
      queryParameters["end_time_null"] = requestParameters.endTimeNull;
    }

    if (requestParameters.endTimeGte != null) {
      queryParameters["end_time_gte"] = requestParameters.endTimeGte;
    }

    if (requestParameters.endTimeLte != null) {
      queryParameters["end_time_lte"] = requestParameters.endTimeLte;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/tags`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return TagListResponseFromJSON(jsonValue);
  }

  async updateLog(
    this: LogApi,
    requestParameters: UpdateLogRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LogDataResponse> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    runtime.assertParamIsPresent(
      requestParameters.logUpdateRequest,
      "logUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/logs/{log_id}`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: LogUpdateRequestToJSON(requestParameters.logUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return LogDataResponseFromJSON(jsonValue);
  }

  async updateLogObject(
    this: LogApi,
    requestParameters: UpdateLogObjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectDataResponse> {
    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    runtime.assertParamIsPresent(requestParameters.objectKey, "objectKey");

    runtime.assertParamIsPresent(
      requestParameters.objectUpdateRequest,
      "objectUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/objects/{object_key}`
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          )
          .replace(
            `{${"object_key"}}`,
            encodeURIComponent(String(requestParameters.objectKey)),
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: ObjectUpdateRequestToJSON(requestParameters.objectUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectDataResponseFromJSON(jsonValue);
  }

  async updateQuery(
    this: LogApi,
    requestParameters: UpdateQueryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<QueryDataResponse> {
    runtime.assertParamIsPresent(requestParameters.queryId, "queryId");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    runtime.assertParamIsPresent(
      requestParameters.queryUpdateRequest,
      "queryUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/queries/{query_id}`
          .replace(
            `{${"query_id"}}`,
            encodeURIComponent(String(requestParameters.queryId)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: QueryUpdateRequestToJSON(requestParameters.queryUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return QueryDataResponseFromJSON(jsonValue);
  }

  async updateTag(
    this: LogApi,
    requestParameters: UpdateTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TagDataResponse> {
    runtime.assertParamIsPresent(requestParameters.tagId, "tagId");

    runtime.assertParamIsPresent(requestParameters.logId, "logId");

    runtime.assertParamIsPresent(
      requestParameters.tagUpdateRequest,
      "tagUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/tags/{tag_id}`
          .replace(
            `{${"tag_id"}}`,
            encodeURIComponent(String(requestParameters.tagId)),
          )
          .replace(
            `{${"log_id"}}`,
            encodeURIComponent(String(requestParameters.logId)),
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: TagUpdateRequestToJSON(requestParameters.tagUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return TagDataResponseFromJSON(jsonValue);
  }
}
