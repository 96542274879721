import { sortedIndexBy, sortedLastIndexBy } from "~/lib/std";
import type { Record, Topic } from "~/services/datastore";
import { usePlaybackSource } from "../playback";
import type { SampleFrequencyValue } from "../types";
import type { UseRecordChunksResult } from "./useRecordChunks";
import useRecordChunks from "./useRecordChunks";
import { useRecordWindow } from "./useRecordWindow";

export interface UseRecordWindowOptions {
  topicId: Topic["id"];
  enabled?: boolean;
  windowSize: bigint;
  sampleFrequency?: SampleFrequencyValue;
  bufferBehind?: bigint;
  bufferAhead?: bigint;
  chunkSize: bigint;
}

export function useRecordWindowQuery({
  topicId,
  enabled = true,
  windowSize,
  sampleFrequency,
  bufferBehind,
  bufferAhead,
  chunkSize,
}: UseRecordWindowOptions) {
  const playbackSource = usePlaybackSource();

  const recordWindow = useRecordWindow(windowSize);

  const result = useRecordChunks({
    topicId,
    enabled: enabled && recordWindow !== undefined,
    playerBounds: playbackSource.bounds,
    recordWindow,
    sampleFrequency,
    bufferBehind,
    bufferAhead,
    chunkSize,
  });

  return sliceResult(result);
}

function sliceResult(result: UseRecordChunksResult): UseRecordChunksResult {
  if (result.data === undefined) {
    return result;
  }

  if (result.recordWindow === undefined) {
    return result;
  }

  const lowerIndex = sortedIndexBy(
    result.data,
    { timestamp: result.recordWindow.startTime } as Record,
    "timestamp",
  );
  const upperIndex = sortedLastIndexBy(
    result.data,
    { timestamp: result.recordWindow.endTime } as Record,
    "timestamp",
  );

  return {
    ...result,
    data: result.data.slice(lowerIndex, upperIndex),
  };
}
