import React from "react";
import { DoNotDisturb } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { Error } from "~/components/Error";
import { Loading } from "~/components/Loading";
import { find } from "~/lib/std";
import type { Topic } from "~/services/datastore";
import { usePanelContext, VisualizationType } from "../../panels";
import PanelLayout from "../PanelLayout";
import ChartVisualization from "./ChartVisualization";
import { ImageVisualization } from "./ImageVisualization";
import type { MapVisualizationProps } from "./MapVisualization";
import TimelineVisualization from "./TimelineVisualization";
import TopicSelector from "./TopicSelector";

const MapPanel = React.lazy(() => import("./MapVisualization"));

interface VisualizationSwitchProps {
  topics: ReadonlyArray<Topic>;
}

export function VisualizationSwitch({ topics }: VisualizationSwitchProps) {
  const panel = usePanelContext();

  if (!panel.isInitialized) {
    return <TopicSelector panelId={panel.id} topics={topics} />;
  } else {
    const topic = find(topics, { name: panel.topicName });

    if (topic === undefined) {
      return (
        <PanelLayout>
          <Error icon={<DoNotDisturb fontSize="large" color="error" />}>
            <Typography variant="h5" component="p">
              <code>{panel.topicName}</code> is not in this log
            </Typography>
          </Error>
        </PanelLayout>
      );
    } else if (panel.visualization === VisualizationType.Timeline) {
      return <TimelineVisualization panel={panel} topic={topic} />;
    } else if (panel.visualization === VisualizationType.Chart) {
      return <ChartVisualization panel={panel} topic={topic} />;
    } else if (panel.visualization === VisualizationType.Image) {
      return (
        <ImageVisualization panel={panel} topic={topic} playerTopics={topics} />
      );
    } else if (panel.visualization === VisualizationType.Map) {
      return renderLazyMapPanel({ panel, topic });
    } else {
      return null;
    }
  }
}

function renderLazyMapPanel(props: MapVisualizationProps) {
  return (
    <ErrorBoundary
      fallback={renderPanelFallback(
        <Error>
          <Typography variant="h5" component="p">
            Could not initialize map view
          </Typography>
        </Error>,
      )}
    >
      <React.Suspense
        fallback={renderPanelFallback(<Loading type="circular" />)}
      >
        <MapPanel {...props} />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function renderPanelFallback(children: React.ReactNode) {
  return <PanelLayout>{children}</PanelLayout>;
}
