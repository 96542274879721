import {
  CheckboxField,
  NewResourceForm,
  RoleSelect,
  TextField,
} from "~/components/Form";
import { useCreateUser } from "~/domain/datastores";
import { makeUserLocation } from "~/paths";
import { createUserSchema } from "../schemas";

export function UserCreateForm() {
  return (
    <NewResourceForm
      schema={createUserSchema}
      defaultValues={{
        username: null,
        roleId: null,
        admin: false,
        managed: false,
        disabled: false,
        externalId: null,
      }}
      resourceName="user"
      mutation={useCreateUser()}
      generateLocation={(response) =>
        makeUserLocation({ userId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="username" required />
          <RoleSelect control={control} name="roleId" />
          <CheckboxField control={control} name="admin" />
          <CheckboxField control={control} name="managed" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="externalId" />
        </>
      )}
    </NewResourceForm>
  );
}
