import {
  Box,
  Card,
  CardContent,
  Link,
  TableCell,
  Typography,
} from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useDigestionTopics } from "~/domain/datastores";
import {
  DataStoreLink,
  makeDigestionTopicLocation,
  makeNewDigestionTopicLocation,
  useDigestionParams,
} from "~/paths";
import type { DigestionTopic } from "~/services/datastore";
import { listDigestionTopicsSchema } from "../schemas";
import { DigestionTopicFilters } from "./DigestionTopicFilters";

const COLUMNS: ReadonlyArray<Column<DigestionTopic>> = [
  {
    header: "Digestion Topic ID",
    renderCell(topic) {
      return (
        <TableCell>
          <Link
            component={DataStoreLink}
            to={makeDigestionTopicLocation({
              digestionId: topic.digestionId,
              topicId: topic.id,
            })}
          >
            <Typography variant="body2">{topic.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "topicId",
    dataType: "foreign-key",
    resourceType: "topic",
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "frequency",
    dataType: "number",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listDigestionTopicsSchema);

export const DigestionTopicTable = withSearchValidationErrorBoundary(
  function DigestionTopicTable() {
    const { digestionId } = useDigestionParams();

    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useDigestionTopics(
      digestionId,
      prepareListRequest(request),
      {
        keepPreviousData: true,
        cacheTime: 0,
      },
    );

    const filterValues = withoutBaseTableModel(request);

    return (
      <Box>
        <Typography variant="h4" component="h2" gutterBottom>
          Digestion Topics
        </Typography>
        <Card>
          <CardContent>
            <ResourceTable
              resourceName="topic"
              resourceCreateLocation={makeNewDigestionTopicLocation({
                digestionId,
              })}
              getRowKey={(topic) => topic.id}
              columns={COLUMNS}
              searchQuery={searchQuery}
              tableModel={request}
              onTableModelChange={setRequest}
              filterSection={
                <DigestionTopicFilters
                  values={filterValues}
                  setValues={setRequest}
                />
              }
              activeFilterCount={getActiveFiltersCount(filterValues)}
            />
          </CardContent>
        </Card>
      </Box>
    );
  },
);
