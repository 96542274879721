import React from "react";
import { useUser } from "~/domain/datastores";
import { makeUserLocation } from "~/paths";
import type { User } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface UserInfoProps {
  userId: User["id"];
}

export function UserInfo({ userId }: UserInfoProps) {
  const usernameQuery = useUser(userId, {
    select(response) {
      return response.data.username;
    },
  });

  return (
    <ResourceInfo
      name={usernameQuery.data}
      identifier={userId}
      to={makeUserLocation({ userId })}
    />
  );
}
