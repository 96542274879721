import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { WorkflowTable } from "./components/WorkflowTable";

export function WorkflowsPage() {
  return (
    <Page title="Search Workflows">
      <FullWidthContainer>
        <WorkflowTable />
      </FullWidthContainer>
    </Page>
  );
}
