import { usePlaybackSource } from "../playback";
import type { TimeRange } from "../types";
import { calculateRecordWindow } from "./utils";

export function useRecordWindow(windowSize: bigint): TimeRange | undefined {
  const playbackSource = usePlaybackSource();

  if (playbackSource.isLoading) {
    return;
  } else {
    return calculateRecordWindow(
      windowSize,
      playbackSource.timestamp,
      playbackSource.bounds,
    );
  }
}
