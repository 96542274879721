import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  FuzzyField,
  LabelSelect,
  TimestampField,
  TopicSelect,
  useStudioForm,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListTagsFormValues } from "../schemas";
import { listTagsSchema } from "../schemas";

export interface TagFiltersProps {
  values: ListTagsFormValues;
  setValues: (newValues: ListTagsFormValues) => void;
}

export function TagFilters({ values, setValues }: TagFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listTagsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item container spacing={2}>
        <Grid item xs={3}>
          <LabelSelect control={control} name="labelId" />
        </Grid>
        <Grid item xs={3}>
          <TopicSelect control={control} name="topicId" />
        </Grid>
        <Grid item xs={3}>
          <FuzzyField control={control} exactName="note" fuzzyName="noteLike" />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={3}>
          <TimestampField control={control} name="startTimeGte" />
        </Grid>
        <Grid item xs={3}>
          <TimestampField control={control} name="startTimeLte" />
        </Grid>
        <Grid item xs={3}>
          <BooleanField control={control} name="startTimeNull" />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={3}>
          <TimestampField control={control} name="endTimeGte" />
        </Grid>
        <Grid item xs={3}>
          <TimestampField control={control} name="endTimeLte" />
        </Grid>
        <Grid item xs={3}>
          <BooleanField control={control} name="endTimeNull" />
        </Grid>
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
