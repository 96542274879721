import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { UserDetails } from "./components/UserDetails";

export function UserPage() {
  return (
    <Page title="User Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <UserDetails />
      </Container>
    </Page>
  );
}
