import React from "react";
import { Schedule as Clock, Watch } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import type { DisplayFormat } from "../../playback";
import { usePlaybackSettings } from "../../playback";

export default function PlayerSettings() {
  const playbackSettings = usePlaybackSettings();

  function handleFormatChange(e: unknown, newFormat: DisplayFormat | null) {
    if (newFormat !== null) {
      playbackSettings.setDisplayFormat(newFormat);
    }
  }

  return (
    <div>
      <Typography variant="h6" component="p" id="display-format-title">
        Timestamp Format
      </Typography>
      <ToggleButtonGroup
        sx={{
          "& .MuiSvgIcon-root": {
            mr: 1,
          },
        }}
        aria-labelledby="display-format-title"
        value={playbackSettings.displayFormat}
        onChange={handleFormatChange}
        exclusive
        color="primary"
        fullWidth
      >
        <ToggleButton value="elapsed">
          <Watch />
          Elapsed
        </ToggleButton>
        <ToggleButton value="original">
          <Clock />
          Original
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
