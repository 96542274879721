import {
  CheckboxField,
  LogSelect,
  NewResourceForm,
  ObjectField,
  processStateOptions,
  SelectField,
  TextField,
  WorkflowSelect,
} from "~/components/Form";
import { useCreateDigestion } from "~/domain/datastores";
import { makeDigestionLocation } from "~/paths";
import { ProcessState } from "~/services/datastore";
import { createDigestionSchema } from "../schemas";

export function DigestionCreateForm() {
  return (
    <NewResourceForm
      schema={createDigestionSchema}
      defaultValues={{
        name: null,
        logId: null,
        state: ProcessState.Ready,
        workflowId: null,
        workflowContext: null,
        locked: false,
        note: null,
        context: null,
      }}
      resourceName="digestion"
      mutation={useCreateDigestion()}
      generateLocation={(response) =>
        makeDigestionLocation({ digestionId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <LogSelect control={control} name="logId" required />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewResourceForm>
  );
}
