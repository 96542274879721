import { z } from "zod";
import { createErrorMap } from "./utils";

export const boolean = z.boolean({
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
  }),
});

export const requiredText = z.string({
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Expected text";
      }
    },
  }),
});

export const optionalText = requiredText.nullable();

export const requiredNumber = z
  .number({
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (isValueMissing(issue)) {
          return "Field is required";
        } else {
          return "Expected a number";
        }
      },
      not_finite: "Expected a number",
    }),
  })
  .finite();

export const optionalNumber = requiredNumber.nullable();

export const requiredBigInt = z.preprocess(
  (arg) => {
    if (arg == null || typeof arg !== "string") {
      return arg;
    } else {
      try {
        // Unlike numbers which convert invalid strings to `NaN`, the bigint
        // constructor will throw if passed **anything** that's not an integer
        // or a string representing an integer.
        return BigInt(arg);
      } catch {
        return arg;
      }
    }
  },
  z.bigint({
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (
          [z.ZodParsedType.undefined, z.ZodParsedType.null].includes(
            issue.received as any,
          )
        ) {
          return "Field is required";
        } else if (issue.received === z.ZodParsedType.number) {
          return "Unexpected value";
        } else {
          return "Expected an integer";
        }
      },
    }),
  }),
);

export const optionalBigInt = requiredBigInt.nullable();

export const uuid = z
  .string({
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (issue.received === z.ZodParsedType.null) {
          return "Field is required";
        } else {
          return "Unexpected input";
        }
      },
      invalid_string(issue) {
        if (issue.validation === "uuid") {
          return "Must be a UUID";
        }
      },
    }),
  })
  .uuid();

export const optionalUuid = uuid.nullable();

export const requiredObject: z.ZodType<object> = z.record(z.unknown(), {
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (isValueMissing(issue)) {
        return "Field is required";
      } else {
        return "Expected an object";
      }
    },
  }),
});

export const optionalObject = requiredObject.nullable();

function isValueMissing(issue: z.ZodInvalidTypeIssue): boolean {
  return [z.ZodParsedType.null, z.ZodParsedType.undefined].includes(
    issue.received as any,
  );
}
