import { useState } from "react";
import { TextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { getFieldLabel } from "~/utils";
import type { BaseFieldProps } from "./types";

export function ObjectField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, object | null>,
>({
  control,
  name,
  label = getFieldLabel(name),
  required,
}: BaseFieldProps<TFieldValues, TName>) {
  const {
    field: { value, onChange, ...field },
    fieldState,
  } = useController({ control, name });

  // The user's input shouldn't change as they're typing, so the display value
  // needs to be tracked separately from the stored value. However, when
  // initializing this state, if the value is not null then it can be
  // pretty-printed
  const [displayValue, setDisplayValue] = useState(() =>
    value === null ? "" : JSON.stringify(value, null, 2),
  );

  return (
    <TextField
      sx={{ "& textarea": { fontFamily: "monospace" } }}
      fullWidth
      multiline
      required={required}
      minRows={3}
      maxRows={6}
      label={label}
      error={fieldState.error !== undefined}
      helperText={fieldState.error?.message ?? " "}
      {...field}
      value={displayValue}
      onChange={(e) => {
        const rawValue = e.target.value;

        setDisplayValue(rawValue);

        if (rawValue === "") {
          onChange(null);
        } else {
          try {
            onChange(JSON.parse(rawValue));
          } catch {
            onChange(rawValue);
          }
        }
      }}
    />
  );
}
