import type { Location, ParamParseKey, Params } from "react-router-dom";
import { generatePath, useParams } from "react-router-dom";
import type { DeepNonNullable } from "ts-essentials";
import type {
  OverrideProperties,
  RequireExactlyOne,
  SetReturnType,
} from "type-fest";
import type { Log, Record, Topic } from "~/services/datastore";
import { serializeSearchParams } from "~/utils";
import type { DataStorePathGenerator } from "./types";

function createDataStoreResourcePaths<
  const TBasePath extends string,
  const TIdentifier extends string,
>(basePath: TBasePath, identifier: TIdentifier) {
  const LIST = `${DATASTORE}/${basePath}` as const;
  const NEW = `${LIST}/new` as const;
  const DETAILS = `${LIST}/:${identifier}` as const;
  const EDIT = `${DETAILS}/edit` as const;

  return {
    LIST,
    NEW,
    DETAILS,
    EDIT,
  };
}

function createDataStoreSubresourcePaths<
  const TParentPath extends string,
  const TBasePath extends string,
  const TIdentifier extends string,
>(parentPath: TParentPath, basePath: TBasePath, identifier: TIdentifier) {
  const LIST = `${parentPath}/${basePath}` as const;
  const NEW = `${LIST}/new` as const;
  const DETAILS = `${LIST}/:${identifier}` as const;
  const EDIT = `${DETAILS}/edit` as const;

  return {
    LIST,
    NEW,
    DETAILS,
    EDIT,
  };
}

type ParamsShape<TPath extends string> = DeepNonNullable<
  Params<ParamParseKey<TPath>>
>;

/**
 * Creates a typed version of react-router's `useParams` hook for a specific
 * path.
 */
type UseTypedParams<TPath extends string> = SetReturnType<
  typeof useParams,
  ParamsShape<TPath>
>;

type DataStoreResourcePathParams<
  TPath extends `${typeof DATASTORE}/${string}`,
> = TPath extends `${typeof DATASTORE}${infer Rest}`
  ? ParamsShape<Rest>
  : never;

function createDataStoreLocationGenerator<
  TPath extends `${typeof DATASTORE}/${string}`,
>(path: TPath) {
  return function makeLocation(
    params: DataStoreResourcePathParams<TPath>,
  ): DataStorePathGenerator {
    return (dataStore) => {
      return {
        pathname: generatePath(path as any, {
          dataStoreName: dataStore.name,
          ...(params as any),
        }),
      };
    };
  };
}

export const DATASTORES = "/" as const;

export interface DataStoresState {
  unknownDataStore?: string;
}

export function makeDataStoresLocation(
  state?: RequireExactlyOne<DataStoresState>,
): Partial<Location> {
  return {
    pathname: DATASTORES,
    state,
  };
}

export const DATASTORE = "/datastores/:dataStoreName" as const;

export function makeDataStoreLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(DATASTORE, { dataStoreName: dataStore.name }),
    };
  };
}

export const useDataStoreParams = useParams as UseTypedParams<typeof DATASTORE>;

export const UPLOAD = `${DATASTORE}/upload` as const;

export function makeUploadLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(UPLOAD, { dataStoreName: dataStore.name }),
    };
  };
}

export const PLAYER = `${DATASTORE}/player` as const;

export type PlayerQuery = { logId?: Log["id"] };

export function makePlayerLocation(
  query?: PlayerQuery,
): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(PLAYER, { dataStoreName: dataStore.name }),
      search: makeSearchString(query),
    };
  };
}

export const PROFILE = `${DATASTORE}/profile` as const;

export function makeProfileLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(PROFILE, { dataStoreName: dataStore.name }),
    };
  };
}

export const {
  LIST: LOGS,
  NEW: NEW_LOG,
  DETAILS: LOG,
  EDIT: EDIT_LOG,
} = createDataStoreResourcePaths("logs", "logId");

export function makeLogsLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(LOGS, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeLogLocation = createDataStoreLocationGenerator(LOG);

export const useLogParams = useParams as UseTypedParams<typeof LOG>;

export function makeNewLogLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_LOG, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeEditLogLocation = createDataStoreLocationGenerator(EDIT_LOG);

export const {
  LIST: TAGS,
  NEW: NEW_TAG,
  DETAILS: TAG,
  EDIT: EDIT_TAG,
} = createDataStoreSubresourcePaths(LOG, "tags", "tagId");

export const makeTagsLocation = createDataStoreLocationGenerator(TAGS);

export const makeNewTagLocation = createDataStoreLocationGenerator(NEW_TAG);

export const makeTagLocation = createDataStoreLocationGenerator(TAG);

export const useTagParams = useParams as UseTypedParams<typeof TAG>;

export const makeEditTagLocation = createDataStoreLocationGenerator(EDIT_TAG);

export const { LIST: LOG_OBJECTS, DETAILS: LOG_OBJECT } =
  createDataStoreSubresourcePaths(LOG, "objects", "key");

export interface LogObjectsLocationQuery {
  directory?: string;
}

export function makeLogObjectsLocation(
  logId: Log["id"],
  query?: LogObjectsLocationQuery,
): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(LOG_OBJECTS, {
        dataStoreName: dataStore.name,
        logId,
      }),
      search: makeSearchString(query),
    };
  };
}

export function makeLogObjectLocation({
  logId,
  key,
}: DataStoreResourcePathParams<typeof LOG_OBJECT>): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(LOG_OBJECT, {
        dataStoreName: dataStore.name,
        logId,
        // Object keys can contain forward slashes. URI encoding is necessary
        // for react-router to match correctly
        key: encodeURIComponent(key),
      }),
    };
  };
}

export const useLogObjectParams = useParams as UseTypedParams<
  typeof LOG_OBJECT
>;

export const {
  LIST: LOG_QUERIES,
  NEW: NEW_LOG_QUERY,
  DETAILS: LOG_QUERY,
  EDIT: EDIT_LOG_QUERY,
} = createDataStoreSubresourcePaths(LOG, "queries", "queryId");

export const makeLogQueriesLocation =
  createDataStoreLocationGenerator(LOG_QUERIES);

export const makeNewLogQueryLocation =
  createDataStoreLocationGenerator(NEW_LOG_QUERY);

export const makeLogQueryLocation = createDataStoreLocationGenerator(LOG_QUERY);

export const useLogQueryParams = useParams as UseTypedParams<typeof LOG_QUERY>;

export const makeEditLogQueryLocation =
  createDataStoreLocationGenerator(EDIT_LOG_QUERY);

export const {
  LIST: INGESTIONS,
  NEW: NEW_INGESTION,
  DETAILS: INGESTION,
  EDIT: EDIT_INGESTION,
} = createDataStoreResourcePaths("ingestions", "ingestionId");

export function makeIngestionsLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(INGESTIONS, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeIngestionLocation =
  createDataStoreLocationGenerator(INGESTION);

export const useIngestionParams = useParams as UseTypedParams<typeof INGESTION>;

export function makeNewIngestionLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_INGESTION, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeEditIngestionLocation =
  createDataStoreLocationGenerator(EDIT_INGESTION);

export const {
  LIST: INGESTION_PARTS,
  NEW: NEW_INGESTION_PART,
  DETAILS: INGESTION_PART,
  EDIT: EDIT_INGESTION_PART,
} = createDataStoreSubresourcePaths(INGESTION, "parts", "ingestionPartId");

export const makeIngestionPartsLocation =
  createDataStoreLocationGenerator(INGESTION_PARTS);

export const makeNewIngestionPartLocation =
  createDataStoreLocationGenerator(NEW_INGESTION_PART);

export const makeIngestionPartLocation =
  createDataStoreLocationGenerator(INGESTION_PART);

export const useIngestionPartParams = useParams as UseTypedParams<
  typeof INGESTION_PART
>;

export const makeEditIngestionPartLocation =
  createDataStoreLocationGenerator(EDIT_INGESTION_PART);

export const {
  LIST: TOPICS,
  NEW: NEW_TOPIC,
  DETAILS: TOPIC,
  EDIT: EDIT_TOPIC,
} = createDataStoreResourcePaths("topics", "topicId");

export function makeTopicsLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(TOPICS, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeTopicLocation = createDataStoreLocationGenerator(TOPIC);

export const useTopicParams = useParams as UseTypedParams<typeof TOPIC>;

export function makeNewTopicLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_TOPIC, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeEditTopicLocation =
  createDataStoreLocationGenerator(EDIT_TOPIC);

export const {
  LIST: RECORDS,
  NEW: NEW_RECORD,
  DETAILS: RECORD,
  EDIT: EDIT_RECORD,
} = createDataStoreSubresourcePaths(TOPIC, "records", "timestamp");

export const makeRecordsLocation = createDataStoreLocationGenerator(RECORDS);

export const makeNewRecordLocation =
  createDataStoreLocationGenerator(NEW_RECORD);

export function makeRecordLocation({
  topicId,
  timestamp,
}: {
  topicId: Topic["id"];
  timestamp: Record["timestamp"];
}): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(RECORD, {
        dataStoreName: dataStore.name,
        topicId,
        timestamp: timestamp.toString(),
      }),
    };
  };
}

export function useRecordParams(): OverrideProperties<
  ParamsShape<typeof RECORD>,
  { timestamp: bigint }
> {
  const { dataStoreName, topicId, timestamp } = useParams() as ParamsShape<
    typeof RECORD
  >;

  return { dataStoreName, topicId, timestamp: BigInt(timestamp) };
}

export function makeEditRecordLocation({
  topicId,
  timestamp,
}: {
  topicId: Topic["id"];
  timestamp: Record["timestamp"];
}): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(EDIT_RECORD, {
        dataStoreName: dataStore.name,
        topicId,
        timestamp: String(timestamp),
      }),
    };
  };
}

export const {
  LIST: DIGESTIONS,
  NEW: NEW_DIGESTION,
  DETAILS: DIGESTION,
  EDIT: EDIT_DIGESTION,
} = createDataStoreResourcePaths("digestions", "digestionId");

export function makeDigestionsLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(DIGESTIONS, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeDigestionLocation =
  createDataStoreLocationGenerator(DIGESTION);

export const useDigestionParams = useParams as UseTypedParams<typeof DIGESTION>;

export function makeNewDigestionLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_DIGESTION, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeEditDigestionLocation =
  createDataStoreLocationGenerator(EDIT_DIGESTION);

export const {
  LIST: DIGESTIONS_TOPICS,
  NEW: NEW_DIGESTION_TOPIC,
  DETAILS: DIGESTION_TOPIC,
  EDIT: EDIT_DIGESTION_TOPIC,
} = createDataStoreSubresourcePaths(DIGESTION, "topics", "topicId");

export const makeDigestionTopicsLocation =
  createDataStoreLocationGenerator(DIGESTIONS_TOPICS);

export const makeNewDigestionTopicLocation =
  createDataStoreLocationGenerator(NEW_DIGESTION_TOPIC);

export const makeDigestionTopicLocation =
  createDataStoreLocationGenerator(DIGESTION_TOPIC);

export const useDigestionTopicParams = useParams as UseTypedParams<
  typeof DIGESTION_TOPIC
>;

export const makeEditDigestionTopicLocation =
  createDataStoreLocationGenerator(EDIT_DIGESTION_TOPIC);

export const {
  LIST: DIGESTION_PARTS,
  NEW: NEW_DIGESTION_PART,
  DETAILS: DIGESTION_PART,
  EDIT: EDIT_DIGESTION_PART,
} = createDataStoreSubresourcePaths(DIGESTION, "parts", "digestionPartId");

export const makeDigestionPartsLocation =
  createDataStoreLocationGenerator(DIGESTION_PARTS);

export const makeNewDigestionPartLocation =
  createDataStoreLocationGenerator(NEW_DIGESTION_PART);

export const makeDigestionPartLocation =
  createDataStoreLocationGenerator(DIGESTION_PART);

export const useDigestionPartParams = useParams as UseTypedParams<
  typeof DIGESTION_PART
>;

export const makeEditDigestionPartLocation =
  createDataStoreLocationGenerator(EDIT_DIGESTION_PART);

export const {
  LIST: USERS,
  NEW: NEW_USER,
  DETAILS: USER,
  EDIT: EDIT_USER,
} = createDataStoreResourcePaths("users", "userId");

export function makeUsersLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(USERS, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeUserLocation = createDataStoreLocationGenerator(USER);

export const useUserParams = useParams as UseTypedParams<typeof USER>;

export function makeNewUserLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_USER, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeEditUserLocation = createDataStoreLocationGenerator(EDIT_USER);

export const {
  LIST: GROUPS,
  NEW: NEW_GROUP,
  DETAILS: GROUP,
  EDIT: EDIT_GROUP,
} = createDataStoreResourcePaths("groups", "groupId");

export function makeGroupsLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(GROUPS, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeGroupLocation = createDataStoreLocationGenerator(GROUP);

export const useGroupParams = useParams as UseTypedParams<typeof GROUP>;

export function makeNewGroupLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_GROUP, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeEditGroupLocation =
  createDataStoreLocationGenerator(EDIT_GROUP);

export const {
  LIST: API_KEYS,
  NEW: NEW_API_KEY,
  DETAILS: API_KEY,
  EDIT: EDIT_API_KEY,
} = createDataStoreResourcePaths("api-keys", "apiKeyId");

export function makeApiKeysLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(API_KEYS, { dataStoreName: dataStore.name }),
    };
  };
}

export function makeNewApiKeyLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_API_KEY, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeApiKeyLocation = createDataStoreLocationGenerator(API_KEY);

export const makeEditApiKeyLocation =
  createDataStoreLocationGenerator(EDIT_API_KEY);

export const useApiKeyParams = useParams as UseTypedParams<typeof API_KEY>;

export const {
  LIST: ROLES,
  NEW: NEW_ROLE,
  DETAILS: ROLE,
  EDIT: EDIT_ROLE,
} = createDataStoreResourcePaths("roles", "roleId");

export function makeRolesLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(ROLES, {
        dataStoreName: dataStore.name,
      }),
    };
  };
}

export function makeNewRoleLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_ROLE, {
        dataStoreName: dataStore.name,
      }),
    };
  };
}

export const makeRoleLocation = createDataStoreLocationGenerator(ROLE);

export const useRoleParams = useParams as UseTypedParams<typeof ROLE>;

export const makeEditRoleLocation = createDataStoreLocationGenerator(EDIT_ROLE);

export const {
  LIST: WORKFLOWS,
  NEW: NEW_WORKFLOW,
  DETAILS: WORKFLOW,
  EDIT: EDIT_WORKFLOW,
} = createDataStoreResourcePaths("workflows", "workflowId");

export function makeWorkflowsLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(WORKFLOWS, {
        dataStoreName: dataStore.name,
      }),
    };
  };
}

export function makeNewWorkflowLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_WORKFLOW, {
        dataStoreName: dataStore.name,
      }),
    };
  };
}

export const makeWorkflowLocation = createDataStoreLocationGenerator(WORKFLOW);

export const useWorkflowParams = useParams as UseTypedParams<typeof WORKFLOW>;

export const makeEditWorkflowLocation =
  createDataStoreLocationGenerator(EDIT_WORKFLOW);

export const {
  LIST: HOOKS,
  NEW: NEW_HOOK,
  DETAILS: HOOK,
  EDIT: EDIT_HOOK,
} = createDataStoreSubresourcePaths(WORKFLOW, "hooks", "hookId");

export const makeHooksLocation = createDataStoreLocationGenerator(HOOKS);

export const makeNewHookLocation = createDataStoreLocationGenerator(NEW_HOOK);

export const makeHookLocation = createDataStoreLocationGenerator(HOOK);

export const useHookParams = useParams as UseTypedParams<typeof HOOK>;

export const makeEditHookLocation = createDataStoreLocationGenerator(EDIT_HOOK);

export const {
  LIST: LABELS,
  NEW: NEW_LABEL,
  DETAILS: LABEL,
  EDIT: EDIT_LABEL,
} = createDataStoreResourcePaths("labels", "labelId");

export function makeLabelsLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(LABELS, { dataStoreName: dataStore.name }),
    };
  };
}

export function makeNewLabelLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_LABEL, { dataStoreName: dataStore.name }),
    };
  };
}

export const makeLabelLocation = createDataStoreLocationGenerator(LABEL);

export const useLabelParams = useParams as UseTypedParams<typeof LABEL>;

export const makeEditLabelLocation =
  createDataStoreLocationGenerator(EDIT_LABEL);

export const {
  LIST: OBJECT_STORES,
  NEW: NEW_OBJECT_STORE,
  DETAILS: OBJECT_STORE,
  EDIT: EDIT_OBJECT_STORE,
} = createDataStoreResourcePaths("object-stores", "objectStoreId");

export function makeObjectStoresLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(OBJECT_STORES, { dataStoreName: dataStore.name }),
    };
  };
}

export function makeNewObjectStoreLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(NEW_OBJECT_STORE, {
        dataStoreName: dataStore.name,
      }),
    };
  };
}

export const makeObjectStoreLocation =
  createDataStoreLocationGenerator(OBJECT_STORE);

export const useObjectStoreParams = useParams as UseTypedParams<
  typeof OBJECT_STORE
>;

export const makeEditObjectStoreLocation =
  createDataStoreLocationGenerator(EDIT_OBJECT_STORE);

export const { LIST: OBJECT_STORE_OBJECTS, DETAILS: OBJECT_STORE_OBJECT } =
  createDataStoreSubresourcePaths(OBJECT_STORE, "objects", "objectKey");

interface ObjectStoreObjectsLocationQuery {
  directory?: string;
}

export function makeObjectStoreObjectsLocation(
  params: DataStoreResourcePathParams<typeof OBJECT_STORE_OBJECTS>,
  query?: ObjectStoreObjectsLocationQuery,
): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(OBJECT_STORE_OBJECTS, {
        dataStoreName: dataStore.name,
        ...params,
      }),
      search: makeSearchString(query),
    };
  };
}

export function makeObjectStoreObjectLocation(
  params: DataStoreResourcePathParams<typeof OBJECT_STORE_OBJECT>,
): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(OBJECT_STORE_OBJECT, {
        dataStoreName: dataStore.name,
        ...params,
        // Object keys can contain forward slashes. URI encoding is necessary
        // for react-router to match correctly
        objectKey: encodeURIComponent(params.objectKey),
      }),
    };
  };
}

export const useObjectStoreObjectParams = useParams as UseTypedParams<
  typeof OBJECT_STORE_OBJECT
>;

function makeSearchString(query: any): string {
  if (query == null) {
    return "";
  }

  return serializeSearchParams(query).toString();
}
