import { TextField as TextInput } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { getFieldLabel } from "~/utils";
import type { BaseFieldProps } from "./types";

interface TextFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
> extends BaseFieldProps<TFieldValues, TName> {
  multiline?: boolean;
  monospace?: boolean;
}

export function TextField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
>({
  control,
  name,
  label = getFieldLabel(name),
  required,
  multiline = false,
  monospace = false,
}: TextFieldProps<TFieldValues, TName>) {
  const {
    field: { value, onChange, ...field },
    fieldState,
  } = useController({ control, name });

  return (
    <TextInput
      fullWidth
      required={required}
      {...(multiline && {
        multiline,
        minRows: 3,
        maxRows: 6,
      })}
      {...(monospace && {
        sx: {
          "& textarea": {
            fontFamily: "monospace",
          },
        },
      })}
      label={label}
      error={fieldState.error !== undefined}
      helperText={fieldState.error?.message ?? " "}
      {...field}
      value={value ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onChange(null);
        } else {
          onChange(e.target.value);
        }
      }}
    />
  );
}
