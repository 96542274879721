import type { Maybe } from "~/types";
import { formatTimestamp } from "~/utils/formats";

export interface TimestampProps {
  date: Maybe<bigint>;
}

export function Timestamp({ date }: TimestampProps) {
  return date == null ? (
    "-"
  ) : (
    <span title={String(date)}>{formatTimestamp(date)}</span>
  );
}
