import { LOGQS_ICON, LOGQS_LOGO } from "~/links";

export interface LogoProps {
  icon?: boolean;
  alt?: string;
}

export function Logo({ icon = false, alt = "LogQS Icon" }: LogoProps) {
  return (
    <img
      className="logqs-logo"
      src={icon ? LOGQS_ICON : LOGQS_LOGO}
      alt={alt}
    />
  );
}
