import React from "react";
import { Settings } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Skeleton, Stack, Typography } from "@mui/material";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FileCloud, ScissorsCutting } from "mdi-material-ui";
import { Center } from "~/components/Center";
import { GlobalNavigation } from "~/components/GlobalNavigation";
import { Header } from "~/components/Header";
import { Helmet } from "~/components/Helmet";
import {
  Layout,
  LayoutStateProvider,
  SideSheetTrigger,
} from "~/components/Layout";
import { CacheBuster } from "./components/CacheBuster";
import PlaybackController from "./components/PlaybackController";
import TopicSection from "./components/TopicSection";
import { PlayerSidebar } from "./components/sidebar";
import { usePlayerConfig, usePlayerLog } from "./hooks";
import { PanelLayoutProvider } from "./panels";
import {
  PlaybackTimerProvider,
  LogPlaybackSourceProvider,
  PlaybackSettingsProvider,
} from "./playback";
import { TagStoreProvider } from "./tags";

export function PlayerPage() {
  const { logId } = usePlayerConfig();

  const logQuery = usePlayerLog();

  let content;
  if (logId == null) {
    content = (
      <Center>
        <Typography variant="h5" component="p" paragraph sx={{ px: 4 }}>
          To get started, search for a log in the side panel to the right
        </Typography>
      </Center>
    );
  } else if (
    logQuery.data?.startTime === null ||
    logQuery.data?.endTime === null
  ) {
    content = (
      <Center>
        <Typography variant="h5" component="p" paragraph>
          This log doesn't have any records to play yet
        </Typography>
        <LoadingButton
          variant="contained"
          loading={logQuery.isRefetching}
          onClick={() => logQuery.refetch()}
        >
          Refresh
        </LoadingButton>
      </Center>
    );
  } else {
    content = <TopicSection />;
  }

  const title =
    logId === null ? (
      "Player"
    ) : logQuery.isSuccess ? (
      `Player - ${logQuery.data.name}`
    ) : (
      <>
        Player - <Skeleton width="15ch" sx={{ display: "inline-block" }} />
      </>
    );

  return (
    <>
      <Helmet>
        <title>Player</title>
      </Helmet>
      <LayoutStateProvider
        initialSideSheetState={logId === null ? "logs" : undefined}
      >
        <PanelLayoutProvider>
          <CacheBuster />
          <PlaybackSettingsProvider>
            <LogPlaybackSourceProvider>
              <TagStoreProvider>
                <PlaybackTimerProvider>
                  <Layout
                    header={
                      <Header
                        title={title}
                        actions={
                          <Stack direction="row" spacing={1}>
                            <SideSheetTrigger
                              title="Logs"
                              sidebarId="logs"
                              icon={<FileCloud />}
                            />
                            <SideSheetTrigger
                              title="Create a digestion"
                              sidebarId="digestions"
                              icon={<ScissorsCutting />}
                            />
                            <SideSheetTrigger
                              title="Settings"
                              sidebarId="settings"
                              icon={<Settings />}
                            />
                          </Stack>
                        }
                      />
                    }
                    globalNavigation={<GlobalNavigation />}
                    sideSheet={<PlayerSidebar />}
                  >
                    <Stack sx={{ flex: 1, minWidth: 0 }}>
                      {content}
                      <PlaybackController />
                    </Stack>
                  </Layout>
                </PlaybackTimerProvider>
              </TagStoreProvider>
            </LogPlaybackSourceProvider>
          </PlaybackSettingsProvider>
        </PanelLayoutProvider>
      </LayoutStateProvider>
      <ReactQueryDevtools position="bottom-left" />
    </>
  );
}
