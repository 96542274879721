import { Pagination as MuiPagination } from "@mui/material";

export interface PaginationProps {
  /**
   * The total number of items being paginated
   */
  count: number;
  limit: number;
  offset: number;
  onChange: (offset: number) => void;
}

export function Pagination({
  count,
  limit,
  offset,
  onChange,
}: PaginationProps) {
  const disabled = count < limit;

  const pageCount = Math.ceil(count / limit);

  // MUI's pages are 1-indexed
  const currentPage = offset / limit + 1;

  function handlePageChange(_: unknown, newPage: number) {
    const newOffset = (newPage - 1) * limit;

    onChange(newOffset);
  }

  return (
    <MuiPagination
      disabled={disabled}
      count={pageCount}
      page={currentPage}
      onChange={handlePageChange}
    />
  );
}
