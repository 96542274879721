import { Grid } from "@mui/material";
import type { Control } from "react-hook-form";
import { BooleanField, DateField, UserSelect } from "~/components/Form";
import type { AuditableResourceFilters } from "./types";

export interface AuditFiltersProps<
  TFieldValues extends AuditableResourceFilters,
> {
  control: Control<TFieldValues>;
}

export function AuditFilters<TFieldValues extends AuditableResourceFilters>({
  control,
}: AuditFiltersProps<TFieldValues>) {
  // It appears necessary to cast `control` as `any` for all the fields below
  // since otherwise TS raises an error about the `name` props not being
  // assignable. My guess is there's an issue when the field components check
  // the `name` prop since the `control` prop here is already generic.

  return (
    <>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control as any} name="createdBy" />
        </Grid>
        <Grid item xs={4}>
          <DateField control={control as any} name="createdAtGte" />
        </Grid>
        <Grid item xs={4}>
          <DateField control={control as any} name="createdAtLte" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control as any} name="updatedBy" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField control={control as any} name="updatedByNull" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField control={control as any} name="updatedAtGte" />
        </Grid>
        <Grid item xs={4}>
          <DateField control={control as any} name="updatedAtLte" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField control={control as any} name="updatedAtNull" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control as any} name="deletedBy" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField control={control as any} name="deletedByNull" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField control={control as any} name="deletedAtGte" />
        </Grid>
        <Grid item xs={4}>
          <DateField control={control as any} name="deletedAtLte" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField control={control as any} name="deletedAtNull" />
        </Grid>
      </Grid>
    </>
  );
}
