import React from "react";
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import type { DataStorePathGenerator } from "~/paths";
import type { NoInfer } from "~/types";
import { Error } from "../Error";
import { Loading } from "../Loading";
import { QueryRenderer } from "../QueryRenderer";
import { DetailsToolbar } from "./DetailsToolbar";
import { renderField } from "./fields";
import type { ResourceField } from "./types";

export interface DetailsCardsProps<TResource extends object> {
  startActions?: React.ReactNode;
  editLocation?: DataStorePathGenerator;
  onDelete?: VoidFunction;
  query: UseQueryResult<TResource>;
  fields: ReadonlyArray<ResourceField<NoInfer<TResource>>>;
}

export function DetailsCards<TResource extends object>({
  startActions,
  editLocation,
  onDelete,
  query,
  fields,
}: DetailsCardsProps<TResource>) {
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <DetailsToolbar
          query={query}
          startActions={startActions}
          editLocation={editLocation}
          onInitiateDelete={onDelete}
        />
        <LinearProgress
          sx={{
            my: 2,
            // Will always take up the same amount of space but won't be
            // visible unless refetch is happening. Prevents a layout shift
            // when refetching starts or ends
            visibility: query.isRefetching ? "visible" : "hidden",
          }}
        />
        <QueryRenderer
          query={query}
          loading={<Loading type="circular" />}
          error={
            <Error>
              <Typography variant="h5" component="p">
                Error fetching details
              </Typography>
            </Error>
          }
          success={(resource) => (
            <Box
              component="dl"
              sx={{
                mx: 4,
                [theme.breakpoints.up("md")]: {
                  display: "grid",
                  gridTemplateColumns: "minmax(0, 1fr) minmax(0, 3fr)",
                  columnGap: 6,
                  "& dt": {
                    justifySelf: "end",
                  },
                },
                "& dd": {
                  wordBreak: "break-all",
                  // For `md` breakpoint and up, this will act like `row-gap`
                  mb: 3,
                },
              }}
            >
              {fields.map((field) => renderField(resource, field))}
            </Box>
          )}
        />
      </CardContent>
    </Card>
  );
}
