import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { LogObjectsTable } from "./components/LogObjectsTable";

export function LogObjectsPage() {
  return (
    <Page title="Search Objects">
      <FullWidthContainer>
        <DataStoreBreadcrumbs />
        <LogObjectsTable />
      </FullWidthContainer>
    </Page>
  );
}
