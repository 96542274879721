import { useState } from "react";
import type { FormEventHandler } from "react";
import { Error } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { invariant } from "~/lib/invariant";
import { some } from "~/lib/std";
import type { LayoutProfile } from "../panels";
import {
  useCreateLayoutProfile,
  useDeleteLayoutProfile,
  useLayoutProfiles,
  usePanelLayoutContext,
} from "../panels";

export interface ProfileDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ProfileDialog({ open, setOpen }: ProfileDialogProps) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const [selected, setSelected] = useState<LayoutProfile["name"] | null>(null);

  const { layout } = usePanelLayoutContext();

  const layoutProfilesQuery = useLayoutProfiles();
  const createLayoutProfile = useCreateLayoutProfile();
  const deleteLayoutProfile = useDeleteLayoutProfile();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit: FormEventHandler = function handleSubmit(e) {
    e.preventDefault();

    invariant(
      layoutProfilesQuery.isSuccess,
      "Cannot create profile before others have loaded",
    );

    invariant(layout !== null, "Layout cannot be null");

    if (name === "") {
      setError("Name is required");

      return;
    }

    if (some(layoutProfilesQuery.data, { name })) {
      setError("A profile with this name already exists");

      return;
    }

    createLayoutProfile.mutate(
      { name, layout },
      {
        onSuccess() {
          enqueueSnackbar("Layout profile created", {
            variant: "success",
          });
        },
        onError() {
          enqueueSnackbar("Unable to create layout profile", {
            variant: "error",
          });
        },
      },
    );
  };

  function handleDelete() {
    invariant(selected !== null, "Must select a profile to delete");

    deleteLayoutProfile.mutate(selected, {
      onSuccess() {
        setSelected(null);

        enqueueSnackbar(`Deleted profile "${selected}"`, {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("Unable to delete profile", {
          variant: "error",
        });
      },
    });
  }

  return (
    <Dialog
      aria-labelledby="profile-dialog-title"
      fullWidth
      open={open}
      onClose={() => {
        // Don't let user close dialog while request in progress
        if (!(createLayoutProfile.isLoading || deleteLayoutProfile.isLoading)) {
          setOpen(false);
        }
      }}
      TransitionProps={{
        onExited() {
          setName("");
          setError("");
          createLayoutProfile.reset();

          setSelected(null);
          deleteLayoutProfile.reset();
        },
      }}
    >
      <DialogTitle id="profile-dialog-title">
        Manage Your Layout Profiles
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" component="p" gutterBottom>
          Create a New Profile
        </Typography>
        <Typography paragraph>
          Save your current layout in a profile. You'll be able to quickly load
          the layout later for any log.
        </Typography>
        <Stack component="form" onSubmit={handleSubmit} spacing={2}>
          <TextField
            fullWidth
            label="Profile name"
            helperText={error}
            error={Boolean(error)}
            disabled={createLayoutProfile.isLoading}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <LoadingButton
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            loading={createLayoutProfile.isLoading}
            disabled={name === ""}
          >
            Create Profile
          </LoadingButton>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" component="p" gutterBottom>
          Delete Profiles
        </Typography>
        <Typography paragraph>
          To delete a profile, select it from the list below and confirm you
          would like to delete it
        </Typography>
        <List>
          {layoutProfilesQuery.isSuccess &&
          layoutProfilesQuery.data.length > 0 ? (
            layoutProfilesQuery.data.map((profile) => (
              <ListItem key={profile.name} disablePadding>
                <ListItemButton
                  role={undefined}
                  disabled={deleteLayoutProfile.isLoading}
                  selected={profile.name === selected}
                  onClick={() => setSelected(profile.name)}
                >
                  <ListItemText>{profile.name}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))
          ) : layoutProfilesQuery.isSuccess ? (
            <ListItem>
              <ListItemText>No profiles</ListItemText>
            </ListItem>
          ) : layoutProfilesQuery.isError ? (
            <ListItem>
              <ListItemIcon>
                <Error color="error" />
              </ListItemIcon>
              <ListItemText>Unable to get your profiles</ListItemText>
            </ListItem>
          ) : (
            <ListItem>
              <ListItemText>Fetching your profiles...</ListItemText>
            </ListItem>
          )}
        </List>
        {layoutProfilesQuery.isSuccess && (
          <>
            {selected === null ? (
              <Typography paragraph>No profile selected</Typography>
            ) : (
              <Typography sx={{ "& span": { fontWeight: "bold" } }} paragraph>
                Are you sure you want to delete <span>{selected}</span>?
              </Typography>
            )}
            <LoadingButton
              color="primary"
              variant="contained"
              disableElevation
              disabled={selected === null}
              loading={deleteLayoutProfile.isLoading}
              onClick={handleDelete}
            >
              Delete Profile
            </LoadingButton>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
