import {
  CheckboxField,
  EditResourceForm,
  RoleSelect,
  TextField,
} from "~/components/Form";
import { useUpdateUser, useUser } from "~/domain/datastores";
import { makeUserLocation, useUserParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_FIELDS, editUserSchema } from "../schemas";

export function UserEditForm() {
  const { userId } = useUserParams();

  return (
    <EditResourceForm
      schema={editUserSchema}
      resourceName="user"
      query={useUser(userId, { select: selectData })}
      editableFields={EDITABLE_FIELDS}
      mutation={useUpdateUser(userId)}
      resourceLocation={makeUserLocation({ userId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="username" required />
          <RoleSelect control={control} name="roleId" />
          <CheckboxField control={control} name="admin" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="externalId" />
        </>
      )}
    </EditResourceForm>
  );
}
