import { useWorkflow } from "~/domain/datastores";
import { makeWorkflowLocation } from "~/paths";
import type { Workflow } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface WorkflowInfoProps {
  workflowId: Workflow["id"];
}

export function WorkflowInfo({ workflowId }: WorkflowInfoProps) {
  const workflowNameQuery = useWorkflow(workflowId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <ResourceInfo
      name={workflowNameQuery.data}
      identifier={workflowId}
      to={makeWorkflowLocation({ workflowId })}
    />
  );
}
