import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { DigestionTable } from "./components/DigestionTable";

export function DigestionsPage() {
  return (
    <Page title="Search Digestions">
      <FullWidthContainer>
        <DigestionTable />
      </FullWidthContainer>
    </Page>
  );
}
