import { useObjectStore, useObjectStores } from "~/domain/datastores";
import type { ObjectStore } from "~/services/datastore";
import { makeResourceSelect } from "./makeResourceSelect";
import type { Option } from "./types";

export const ObjectStoreSelect = makeResourceSelect({
  useGet(uuid) {
    return useObjectStore(uuid, {
      select({ data }) {
        return selectOption(data);
      },
    });
  },
  useSearch(text) {
    return useObjectStores(
      {
        bucketName: text,
        limit: 5,
        sort: "asc",
        order: "bucket_name",
      },
      {
        keepPreviousData: true,
        enabled: text !== null,
        select({ data }) {
          return data.map(selectOption);
        },
      },
    );
  },
});

function selectOption(objectStore: ObjectStore): Option {
  return {
    value: objectStore.id,
    label: objectStore.bucketName,
  };
}
