import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { IngestionPartEditForm } from "./components/IngestionPartEditForm";

export function EditIngestionPartPage() {
  return (
    <Page title="Edit Ingestion Part">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <IngestionPartEditForm />
      </Container>
    </Page>
  );
}
