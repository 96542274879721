import { ErrorOutline } from "@mui/icons-material";
import { Box, styled, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { Details } from "~/components/Details";
import {
  DetailsLayout,
  HistoryCard,
  ManageCard,
  ResourceFields,
} from "~/components/DetailsCards";
import { JsonField } from "~/components/DetailsCards/JsonField";
import {
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import {
  useDeleteLogQuery,
  useLogQuery,
  useUpdateLogQuery,
} from "~/domain/datastores";
import { pick } from "~/lib/std";
import {
  makeEditLogQueryLocation,
  makeLogQueriesLocation,
  useLogQueryParams,
} from "~/paths";
import type { Query } from "~/services/datastore";
import { selectData } from "~/utils";
import { editLogQuerySchema } from "../schemas";

export function LogQueryDetails() {
  const { logId, queryId } = useLogQueryParams();

  const queryResult = useLogQuery(logId, queryId, { select: selectData });

  const generalSection = <GeneralSection queryResult={queryResult} />;
  const queryInputSection = <QueryInputSection queryResult={queryResult} />;
  const queryOutputSection = <QueryOutputSection queryResult={queryResult} />;
  const infoSection = <InfoSection queryResult={queryResult} />;
  const historySection = <HistoryCard query={queryResult} />;
  const manageSection = (
    <ManageCard
      resourceName="query"
      query={queryResult}
      editLocation={makeEditLogQueryLocation({ logId, queryId })}
      deleteMutation={useDeleteLogQuery(logId, queryId)}
      getReadableName={(logQuery) => logQuery.name ?? logQuery.id}
      listLocation={makeLogQueriesLocation({ logId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: 3,
              alignItems: "start",
            }}
          >
            {queryInputSection}
            {queryOutputSection}
          </Box>
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {queryInputSection}
          {queryOutputSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({
  queryResult,
}: {
  queryResult: UseQueryResult<Query>;
}) {
  return (
    <Card title="General">
      <ResourceFields
        query={queryResult}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "log", accessor: "logId" },
        ]}
      />
    </Card>
  );
}

function QueryInputSection({
  queryResult,
}: {
  queryResult: UseQueryResult<Query>;
}) {
  return (
    <Card title="Query Input">
      <ResourceFields
        query={queryResult}
        fields={[
          { dataType: "pre", accessor: "statement" },
          { dataType: "json", accessor: "parameters" },
        ]}
      />
    </Card>
  );
}

const ErrorContent = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: `
    "icon message"
    "icon error"
  `,
  columnGap: theme.spacing(2),
  gridTemplateColumns: "auto minmax(0, 1fr)",
}));

function QueryOutputSection({
  queryResult,
}: {
  queryResult: UseQueryResult<Query>;
}) {
  const hasError = queryResult.data?.error != null;

  return (
    <Card title="Query Output" error={hasError}>
      {hasError ? (
        <ErrorContent>
          <ErrorOutline
            sx={{
              gridArea: "icon",
              fontSize: "3rem",
            }}
            color="error"
          />
          <Typography paragraph sx={{ gridArea: "message" }}>
            The query failed
          </Typography>
          <Details sx={{ gridArea: "error" }}>
            <Details.Summary>Expand to see error</Details.Summary>
            <JsonField value={queryResult.data!.error} />
          </Details>
        </ErrorContent>
      ) : (
        <ResourceFields
          query={queryResult}
          fields={[
            { dataType: "json", accessor: "columns" },
            { dataType: "json", accessor: "rows" },
          ]}
        />
      )}
    </Card>
  );
}

function InfoSection({ queryResult }: { queryResult: UseQueryResult<Query> }) {
  return (
    <Card>
      <QueryRenderer
        query={queryResult}
        loading={<FormSkeleton shapes={["text", "multiline", "multiline"]} />}
        success={(logQuery) => <InfoSectionImpl logQuery={logQuery} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ logQuery }: { logQuery: Query }) {
  const updateLogQuery = useUpdateLogQuery(logQuery.logId, logQuery.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editLogQuerySchema.pick({
    name: true,
    note: true,
    context: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(logQuery, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateLogQuery.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Query updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update query", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateLogQuery.isLoading}
      submitText="Save Changes"
    >
      <TextField control={control} name="name" />
      <TextField control={control} name="note" multiline />
      <ObjectField control={control} name="context" />
    </Form>
  );
}
