import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { IngestionDetails } from "./components/IngestionDetails";

export function IngestionPage() {
  return (
    <Page title="Ingestion Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <IngestionDetails />
      </Container>
    </Page>
  );
}
