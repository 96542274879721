import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { DigestionPartTable } from "./components/DigestionPartTable";

export function DigestionPartsPage() {
  return (
    <Page title="Search Digestion Parts">
      <FullWidthContainer>
        <DataStoreBreadcrumbs />
        <DigestionPartTable />
      </FullWidthContainer>
    </Page>
  );
}
