/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Digestion } from "./Digestion";
import {
  DigestionFromJSON,
  DigestionFromJSONTyped,
  DigestionToJSON,
} from "./Digestion";

export interface DigestionListResponse {
  offset: number;
  limit: number;
  order: string;
  sort: string;
  count: number;
  data: Array<Digestion>;
}

export function DigestionListResponseFromJSON(
  json: any,
): DigestionListResponse {
  return DigestionListResponseFromJSONTyped(json);
}

export function DigestionListResponseFromJSONTyped(
  json: any,
): DigestionListResponse {
  if (json == null) {
    return json;
  }

  return {
    offset: json["offset"],
    limit: json["limit"],
    order: json["order"],
    sort: json["sort"],
    count: json["count"],
    data: (json["data"] as Array<any>).map(DigestionFromJSON),
  };
}

export function DigestionListResponseToJSON(
  value: DigestionListResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    offset: value.offset,
    limit: value.limit,
    order: value.order,
    sort: value.sort,
    count: value.count,
    data: (value.data as Array<any>).map(DigestionToJSON),
  };
}
