import { Container } from "@mui/material";
import { Page } from "~/components/Page";
import MeView from "./components/MeView";

export function ProfilePage() {
  return (
    <Page title="Profile">
      <Container fixed>
        <MeView />
      </Container>
    </Page>
  );
}
