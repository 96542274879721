import React from "react";
import {
  Flip,
  NotInterested,
  Rotate90DegreesCcw,
  Rotate90DegreesCw,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { capitalize } from "~/lib/std";
import {
  FlipDirection,
  RotationDirection,
  usePanelLayoutContext,
} from "../../../../panels";
import type {
  rotateImage,
  setImageFlipDirection,
  InitializedPanelNode,
} from "../../../../panels";

export interface TransformationsProps {
  panel: InitializedPanelNode;
  type: "image" | "segmentations";
  rotateActionCreator: typeof rotateImage;
  flipDirection: FlipDirection | null;
  flipActionCreator: typeof setImageFlipDirection;
}

export function Transformations({
  panel,
  type,
  rotateActionCreator,
  flipDirection,
  flipActionCreator,
}: TransformationsProps) {
  const { dispatch } = usePanelLayoutContext();

  function createRotationHandler(direction: RotationDirection) {
    return function handleRotate() {
      dispatch(rotateActionCreator({ panelId: panel.id, direction }));
    };
  }

  function handleChangeFlipDirection(e: React.ChangeEvent<HTMLInputElement>) {
    const flipDirection =
      e.target.value === "null" ? null : (e.target.value as FlipDirection);

    dispatch(flipActionCreator({ panelId: panel.id, flipDirection }));
  }

  return (
    <>
      <Typography>Rotate {capitalize(type)}</Typography>
      <ButtonGroup
        fullWidth
        disableElevation
        variant="contained"
        color="primary"
      >
        <Button
          startIcon={<Rotate90DegreesCcw />}
          onClick={createRotationHandler(RotationDirection.Left)}
        >
          Left
        </Button>
        <Button
          startIcon={<Rotate90DegreesCw />}
          onClick={createRotationHandler(RotationDirection.Right)}
        >
          Right
        </Button>
      </ButtonGroup>
      <TextField
        sx={{
          mt: 2,
          "& .MuiStack-root": {
            alignItems: "center",
            gap: 0.5,
          },
        }}
        select
        SelectProps={{
          MenuProps: {
            sx: {
              "& .MuiStack-root": {
                alignItems: "center",
                gap: 0.5,
              },
            },
          },
        }}
        fullWidth
        label="Flip"
        value={String(flipDirection)}
        onChange={handleChangeFlipDirection}
      >
        <MenuItem value="null">
          <Stack direction="row">
            <NotInterested />
            None
          </Stack>
        </MenuItem>
        <MenuItem value={FlipDirection.X}>
          <Stack direction="row">
            <Flip />
            Horizontal
          </Stack>
        </MenuItem>
        <MenuItem value={FlipDirection.Y}>
          <Stack direction="row">
            <Flip sx={{ rotate: "90deg" }} />
            Vertical
          </Stack>
        </MenuItem>
      </TextField>
    </>
  );
}
