import { useGroup, useGroups } from "~/domain/datastores";
import { makeResourceSelect } from "./makeResourceSelect";

export const GroupSelect = makeResourceSelect({
  useGet(uuid) {
    return useGroup(uuid, {
      select({ data: { id, name } }) {
        return {
          value: id,
          label: name,
        };
      },
    });
  },
  useSearch(text) {
    return useGroups(
      {
        nameLike: text,
        limit: 5,
        sort: "asc",
        order: "name",
      },
      {
        keepPreviousData: true,
        enabled: text !== null,
        select({ data }) {
          return data.map((group) => ({
            value: group.id,
            label: group.name,
          }));
        },
      },
    );
  },
});
