import {
  CheckboxField,
  EditResourceForm,
  ObjectField,
  TextField,
} from "~/components/Form";
import { useObjectStore, useUpdateObjectStore } from "~/domain/datastores";
import { makeObjectStoreLocation, useObjectStoreParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_OBJECT_STORE_FIELDS,
  editObjectStoreSchema,
} from "../schemas";

export function ObjectStoreEditForm() {
  const { objectStoreId } = useObjectStoreParams();

  return (
    <EditResourceForm
      schema={editObjectStoreSchema}
      resourceName="object store"
      query={useObjectStore(objectStoreId, { select: selectData })}
      editableFields={EDITABLE_OBJECT_STORE_FIELDS}
      mutation={useUpdateObjectStore(objectStoreId)}
      resourceLocation={makeObjectStoreLocation({ objectStoreId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="bucketName" required />
          <TextField control={control} name="accessKeyId" />
          <TextField control={control} name="regionName" />
          <TextField control={control} name="endpointUrl" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditResourceForm>
  );
}
