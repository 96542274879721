import { useIngestion, useIngestions } from "~/domain/datastores";
import { makeResourceSelect } from "./makeResourceSelect";

export const IngestionSelect = makeResourceSelect({
  useGet(uuid) {
    return useIngestion(uuid, {
      select({ data: { id, name } }) {
        return {
          value: id,
          label: name ?? id,
        };
      },
    });
  },
  useSearch(text) {
    return useIngestions(
      {
        nameLike: text,
        limit: 5,
        sort: "asc",
        order: "name",
      },
      {
        keepPreviousData: true,
        enabled: text !== null,
        select({ data }) {
          return data.map((ingestion) => ({
            value: ingestion.id,
            label: ingestion.name ?? ingestion.id,
          }));
        },
      },
    );
  },
});
