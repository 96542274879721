import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { LogObjectDetails } from "./components/LogObjectDetails";

export function LogObjectPage() {
  return (
    <Page title="Object Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LogObjectDetails />
      </Container>
    </Page>
  );
}
