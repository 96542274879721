import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useWorkflowHooks } from "~/domain/datastores";
import {
  DataStoreLink,
  makeHookLocation,
  makeNewHookLocation,
  useWorkflowParams,
} from "~/paths";
import type { Hook } from "~/services/datastore";
import { listWorkflowHooksSchema } from "../schemas";
import { WorkflowHookFilters } from "./WorkflowHookFilters";

const COLUMNS: ReadonlyArray<Column<Hook>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell(workflowHook) {
      return (
        <TableCell>
          {workflowHook.name ?? "-"}
          <Link
            component={DataStoreLink}
            to={makeHookLocation({
              workflowId: workflowHook.workflowId,
              hookId: workflowHook.id,
            })}
          >
            <Typography variant="body2">{workflowHook.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "triggerProcess",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "triggerState",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "uri",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listWorkflowHooksSchema);

export const WorkflowHookTable = withSearchValidationErrorBoundary(
  function WorkflowHookTable() {
    const { workflowId } = useWorkflowParams();

    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useWorkflowHooks(
      workflowId,
      prepareListRequest(request),
      {
        keepPreviousData: true,
        cacheTime: 0,
      },
    );

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="hook"
            resourceCreateLocation={makeNewHookLocation({ workflowId })}
            getRowKey={(workflowHook) => workflowHook.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <WorkflowHookFilters
                values={filterValues}
                setValues={setRequest}
              />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
