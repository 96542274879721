import type React from "react";
import type { Draft } from "immer";
import type { ImmerReducer } from "use-immer";
import { useImmerReducer } from "use-immer";
import { invariant } from "~/lib/invariant";
import {
  filter,
  findIndex,
  isEqual,
  map,
  maxBy,
  minBy,
  pullAllWith,
  pullAt,
} from "~/lib/std";
import type { Topic } from "~/services/datastore";
import { assertNever } from "~/utils";
import type { LayoutNode } from "../../../panels";
import { iteratePanels } from "../../../panels";
import type { PlaybackSource } from "../../../playback";
import type { DraftDigestionTopic, TimeRange } from "../../../types";

export type SetSelectedTopicsAction = {
  type: "set-selected-topics";
  payload: {
    topics: ReadonlyArray<Topic>;
  };
};

export function setSelectedTopics(
  payload: SetSelectedTopicsAction["payload"],
): SetSelectedTopicsAction {
  return {
    type: "set-selected-topics",
    payload,
  };
}

export type SelectLayoutTopicsAction = {
  type: "select-layout-topics";
};

export function selectLayoutTopics(): SelectLayoutTopicsAction {
  return {
    type: "select-layout-topics",
  };
}

export type DraftSelectedTopicsAction = {
  type: "draft-selected-topics";
};

export function draftSelectedTopics(): DraftSelectedTopicsAction {
  return {
    type: "draft-selected-topics",
  };
}

export type DraftTopicAction = {
  type: "draft-topic";
  payload: {
    topic: Topic;
    range: TimeRange;
  };
};

export function draftTopic(
  payload: DraftTopicAction["payload"],
): DraftTopicAction {
  return {
    type: "draft-topic",
    payload,
  };
}

export type RemoveDraftTopicAction = {
  type: "remove-draft-topic";
  payload: {
    topic: DraftDigestionTopic;
  };
};

export function removeDraftTopic(
  payload: RemoveDraftTopicAction["payload"],
): RemoveDraftTopicAction {
  return {
    type: "remove-draft-topic",
    payload,
  };
}

export type StartFinalizingAction = {
  type: "start-finalizing";
};

export function startFinalizing(): StartFinalizingAction {
  return {
    type: "start-finalizing",
  };
}

export type AbortFinalizingAction = {
  type: "abort-finalizing";
};

export function abortFinalizing(): AbortFinalizingAction {
  return {
    type: "abort-finalizing",
  };
}

export type ResetDraftAction = {
  type: "reset-draft";
};

export function resetDraft(): ResetDraftAction {
  return {
    type: "reset-draft",
  };
}

export type DraftDigestionActions =
  | SetSelectedTopicsAction
  | SelectLayoutTopicsAction
  | DraftSelectedTopicsAction
  | DraftTopicAction
  | RemoveDraftTopicAction
  | StartFinalizingAction
  | AbortFinalizingAction
  | ResetDraftAction;

type DraftDigestionReducerState = {
  isFinalizing: boolean;
  topics: Array<DraftDigestionTopic>;
  selectedTopicIds: Array<Topic["id"]>;
};

export type DraftDigestion = DraftDigestionReducerState & {
  canSelectLayoutTopics: boolean;
  dispatch: React.Dispatch<DraftDigestionActions>;
};

const initialState: DraftDigestionReducerState = {
  isFinalizing: false,
  topics: [],
  selectedTopicIds: [],
};

export type UseDraftDigestionArgs = {
  playerTopics: Array<Topic> | undefined;
  playerRange: PlaybackSource["range"];
  layout: LayoutNode;
};

export default function useDraftDigestion({
  playerTopics,
  playerRange,
  layout,
}: UseDraftDigestionArgs): DraftDigestion {
  const layoutTopicNames: MakeReducerArgs["layoutTopicNames"] = [];
  iteratePanels(layout, (panel) => {
    if (panel.isInitialized) {
      layoutTopicNames.push(panel.topicName);
    }
  });

  const [draftDigestionState, dispatch] = useImmerReducer(
    makeReducer({
      playerTopics,
      playerRange,
      layoutTopicNames,
    }),
    initialState,
  );

  return {
    ...draftDigestionState,
    canSelectLayoutTopics: layoutTopicNames.length > 0,
    dispatch,
  };
}

type MakeReducerArgs = Pick<
  UseDraftDigestionArgs,
  "playerTopics" | "playerRange"
> & {
  layoutTopicNames: Array<Topic["name"]>;
};

function makeReducer({
  playerTopics,
  playerRange,
  layoutTopicNames,
}: MakeReducerArgs): ImmerReducer<
  DraftDigestionReducerState,
  DraftDigestionActions
> {
  return function reducer(draftState, action) {
    invariant(
      playerTopics !== undefined && playerRange !== undefined,
      "Topics and/or range not defined",
    );

    switch (action.type) {
      case "set-selected-topics": {
        draftState.selectedTopicIds = map(action.payload.topics, "id");

        return;
      }
      case "select-layout-topics": {
        invariant(layoutTopicNames.length > 0, "No layout topics to select");

        const layoutTopics = playerTopics.filter(({ name }) =>
          layoutTopicNames.includes(name),
        );

        draftState.selectedTopicIds = map(layoutTopics, "id");

        return;
      }
      case "draft-selected-topics": {
        invariant(
          draftState.selectedTopicIds.length > 0,
          "Must have at least one topic selected",
        );

        for (const topicId of draftState.selectedTopicIds) {
          addTopicToDraft(draftState, topicId, playerRange);
        }

        return;
      }
      case "draft-topic": {
        addTopicToDraft(
          draftState,
          action.payload.topic.id,
          action.payload.range,
        );

        return;
      }
      case "remove-draft-topic": {
        const topicIndex = findIndex(draftState.topics, action.payload.topic);

        invariant(topicIndex !== -1, "Topic not found");

        pullAt(draftState.topics, topicIndex);

        return;
      }
      case "start-finalizing": {
        invariant(
          draftState.topics.length > 0,
          "Must have at least one topic drafted to finalize",
        );
        invariant(!draftState.isFinalizing, "Already finalizing");

        draftState.isFinalizing = true;

        return;
      }
      case "abort-finalizing": {
        invariant(draftState.isFinalizing, "Not currently finalizing");

        draftState.isFinalizing = false;

        return;
      }
      case "reset-draft": {
        return initialState;
      }
      default: {
        assertNever(action);
      }
    }
  };
}

function addTopicToDraft(
  draftState: Draft<DraftDigestionReducerState>,
  topicId: Topic["id"],
  playerBounds: NonNullable<MakeReducerArgs["playerRange"]>,
): void {
  const newDraftTopic: DraftDigestionTopic = {
    topicId,
    ...playerBounds,
  };

  // If the new draft topic's time range overlaps with any existing
  // draft topics with a matching topic ID, those need to be merged
  // into a single draft
  const overlappingTopics = filter(draftState.topics, (topic) => {
    if (topic.topicId !== topicId) {
      return false;
    }

    return areTimeRangesOverlapping(topic, newDraftTopic);
  });

  if (overlappingTopics.length === 0) {
    // New draft didn't overlap with anything so just push it
    // and continue
    draftState.topics.push(newDraftTopic);

    return;
  }

  // New draft topic and all drafts it overlapped with need to be
  // merged into a single draft whose time range spans them all.
  const { startTime: minOverlapTime } = minBy(
    [...overlappingTopics, newDraftTopic],
    "startTime",
  )!;
  const { endTime: maxOverlapTime } = maxBy(
    [...overlappingTopics, newDraftTopic],
    "endTime",
  )!;

  // Remove existing overlapped drafts which will be represented by
  // the merged draft
  pullAllWith(draftState.topics, overlappingTopics, isEqual);

  // Push the merged draft in place of new draft and overlapped drafts
  draftState.topics.push({
    topicId,
    startTime: minOverlapTime,
    endTime: maxOverlapTime,
  });
}

function areTimeRangesOverlapping(x: TimeRange, y: TimeRange): boolean {
  return y.startTime <= x.endTime && x.startTime <= y.endTime;
}
