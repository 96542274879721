import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useLabels } from "~/domain/datastores";
import {
  DataStoreLink,
  makeLabelLocation,
  makeNewLabelLocation,
} from "~/paths";
import type { Label } from "~/services/datastore";
import { listLabelsSchema } from "../schemas";
import { LabelFilters } from "./LabelFilters";

const COLUMNS: ReadonlyArray<Column<Label>> = [
  {
    header: "Value",
    sortKey: "value",
    renderCell(label) {
      return (
        <TableCell>
          {label.value}
          <Link
            component={DataStoreLink}
            to={makeLabelLocation({ labelId: label.id })}
          >
            <Typography variant="body2">{label.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listLabelsSchema);

export const LabelTable = withSearchValidationErrorBoundary(
  function LabelTable() {
    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useLabels(prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="label"
            resourceCreateLocation={makeNewLabelLocation()}
            getRowKey={(label) => label.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <LabelFilters values={filterValues} setValues={setRequest} />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
