import type { InitializedPanelNode } from "~/pages/player/panels";
import type { Topic } from "~/services/datastore";
import type { Segmentation } from "./segmentations";

export type SegmentationTopicSearchResult =
  | { status: "found"; topic: Topic }
  | { status: "unset"; topic?: undefined }
  | { status: "missing"; topic?: undefined };

export function searchForSegmentationTopic(
  panel: InitializedPanelNode,
  playerTopics: ReadonlyArray<Topic>,
): SegmentationTopicSearchResult {
  if (panel.segmentationTopicName === null) {
    return { status: "unset" };
  }

  const topic = playerTopics.find(
    (topic) => topic.name === panel.segmentationTopicName,
  );

  if (topic === undefined) {
    return { status: "missing" };
  } else {
    return { status: "found", topic };
  }
}

const MAX_HUE_DEG = 360;

const HUE_SECTIONS = 6;

const HUE_SECTION_SIZE_DEG = MAX_HUE_DEG / HUE_SECTIONS;

const LIGHTNESS_PCT = 65;

const CHROMA_PCT = 60;

export function mapSegmentationClassToOklchColor(
  segmentation: Readonly<Pick<Segmentation, "classId" | "className">>,
): string {
  const hueSection = segmentation.classId % HUE_SECTIONS;

  const codePointsSum = [...segmentation.className].reduce(
    (currentSum, codePoint) => currentSum + (codePoint.codePointAt(0) ?? 0),
    0,
  );

  const hueDeg =
    (codePointsSum % HUE_SECTION_SIZE_DEG) + hueSection * HUE_SECTION_SIZE_DEG;

  return `oklch(${LIGHTNESS_PCT}% ${CHROMA_PCT}% ${hueDeg})`;
}

export function isRotatedSideways(rotationDeg: number): boolean {
  const quadrantIndex = (((rotationDeg % 360) + 360) % 360) / 90;

  return [1, 3].includes(quadrantIndex);
}
