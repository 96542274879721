import { useIngestion } from "~/domain/datastores";
import { makeIngestionLocation } from "~/paths";
import type { Ingestion } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface IngestionInfoProps {
  ingestionId: Ingestion["id"];
}

export function IngestionInfo({ ingestionId }: IngestionInfoProps) {
  const ingestionNameQuery = useIngestion(ingestionId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <ResourceInfo
      name={ingestionNameQuery.data}
      identifier={ingestionId}
      to={makeIngestionLocation({ ingestionId })}
    />
  );
}
