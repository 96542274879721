import React from "react";
import { TableCell } from "@mui/material";
import { z } from "zod";
import { assertNever } from "~/utils";
import {
  formatBytes,
  formatDate,
  formatNumber,
  formatTimestamp,
} from "~/utils/formats";
import { Boolean } from "../Boolean";
import {
  DigestionInfo,
  GroupInfo,
  IngestionInfo,
  LabelInfo,
  LogInfo,
  ObjectStoreInfo,
  RoleInfo,
  TopicInfo,
  UserInfo,
  WorkflowInfo,
} from "../ResourceInfo";
import type { AccessorColumn, CellRenderer } from "./types";

export function makeCellRenderer<TResource extends object>(
  column: AccessorColumn<TResource>,
): CellRenderer<TResource> {
  return function renderCell(resource, props) {
    const cellData: unknown = resource[column.accessor];

    return (
      <TableCell {...props}>
        {cellData === null ? "-" : getCellContent(cellData, column)}
      </TableCell>
    );
  };
}

const uuidSchema = z.string().uuid();

const numberSchema = z.number();

const bigintSchema = z.bigint();

const datetimeSchema = z.date();

const booleanSchema = z.boolean();

function getCellContent(
  cellData: unknown,
  column: AccessorColumn<any>,
): React.ReactNode {
  const { dataType } = column;
  switch (dataType) {
    case "text": {
      return String(cellData);
    }
    case "number": {
      return formatNumber(numberSchema.parse(cellData));
    }
    case "percent": {
      return `${numberSchema.parse(cellData) * 100}%`;
    }
    case "timestamp": {
      const parsedData = bigintSchema.parse(cellData);

      return (
        <span title={String(parsedData)}>{formatTimestamp(parsedData)}</span>
      );
    }
    case "datetime": {
      const parsedData = datetimeSchema.parse(cellData);

      return (
        <span title={formatDate(parsedData, "local")}>
          {formatDate(parsedData, "UTC")}
        </span>
      );
    }
    case "bytes": {
      return formatBytes(numberSchema.parse(cellData));
    }
    case "boolean": {
      return <Boolean value={booleanSchema.parse(cellData)} />;
    }
    case "foreign-key": {
      const { resourceType } = column;
      switch (resourceType) {
        case "digestion": {
          return <DigestionInfo digestionId={uuidSchema.parse(cellData)} />;
        }
        case "group": {
          return <GroupInfo groupId={uuidSchema.parse(cellData)} />;
        }
        case "ingestion": {
          return <IngestionInfo ingestionId={uuidSchema.parse(cellData)} />;
        }
        case "label": {
          return <LabelInfo labelId={uuidSchema.parse(cellData)} />;
        }
        case "log": {
          return <LogInfo logId={uuidSchema.parse(cellData)} />;
        }
        case "object-store": {
          return <ObjectStoreInfo objectStoreId={uuidSchema.parse(cellData)} />;
        }
        case "role": {
          return <RoleInfo roleId={uuidSchema.parse(cellData)} />;
        }
        case "topic": {
          return <TopicInfo topicId={uuidSchema.parse(cellData)} />;
        }
        case "user": {
          return <UserInfo userId={uuidSchema.parse(cellData)} />;
        }
        case "workflow": {
          return <WorkflowInfo workflowId={uuidSchema.parse(cellData)} />;
        }
        default: {
          assertNever(resourceType);
        }
      }
    }
    // eslint-disable-next-line no-fallthrough
    default: {
      assertNever(dataType);
    }
  }
}
