import { Box } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  ManageCard,
  ResourceFields,
} from "~/components/DetailsCards";
import {
  Form,
  FormSkeleton,
  getChangedFields,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { useDeleteLabel, useLabel, useUpdateLabel } from "~/domain/datastores";
import { pick } from "~/lib/std";
import {
  makeEditLabelLocation,
  makeLabelsLocation,
  useLabelParams,
} from "~/paths";
import type { Label } from "~/services/datastore";
import { selectData } from "~/utils";
import { editLabelSchema } from "../schemas";

export function LabelDetails() {
  const { labelId } = useLabelParams();

  const query = useLabel(labelId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <HistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="label"
      query={query}
      editLocation={makeEditLabelLocation({ labelId })}
      deleteMutation={useDeleteLabel(labelId)}
      getReadableName={(label) => label.value}
      listLocation={makeLabelsLocation()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: 3,
            }}
          >
            {infoSection}
            {manageSection}
          </Box>
        </>
      }
      secondaryGridColumn={historySection}
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Label> }) {
  return (
    <Card title="General">
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "value" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Label> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["multiline"]} />}
        success={(label) => <InfoSectionImpl label={label} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ label }: { label: Label }) {
  const updateLabel = useUpdateLabel(label.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editLabelSchema.pick({ note: true });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(label, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateLabel.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Label updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update label", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateLabel.isLoading}
      submitText="Save Changes"
    >
      <TextField control={control} name="note" multiline />
    </Form>
  );
}
