import React from "react";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import type { SubmitHandler } from "react-hook-form";

export interface FormProps {
  onSubmit: SubmitHandler<any>;
  loading: boolean;
  submitText: string;
  children: React.ReactNode;
  disabled?: boolean;
}

export function Form({
  onSubmit,
  loading,
  submitText,
  children,
  disabled = false,
}: FormProps) {
  return (
    <Stack
      spacing={2}
      useFlexGap
      sx={{ maxWidth: 400, mx: "auto" }}
      component="form"
      onSubmit={onSubmit}
      noValidate
    >
      {children}
      <LoadingButton
        sx={{ alignSelf: "baseline" }}
        disabled={disabled}
        loading={loading}
        type="submit"
        variant="contained"
      >
        {submitText}
      </LoadingButton>
    </Stack>
  );
}
