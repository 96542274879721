import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { TagCreateForm } from "./components/TagCreateForm";

export function NewTagPage() {
  return (
    <Page title="New Tag">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <TagCreateForm />
      </Container>
    </Page>
  );
}
