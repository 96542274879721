import { useLabel, useLabels } from "~/domain/datastores";
import type { Label } from "~/services/datastore";
import { makeResourceSelect } from "./makeResourceSelect";
import type { Option } from "./types";

export const LabelSelect = makeResourceSelect({
  useGet(uuid) {
    return useLabel(uuid, {
      select({ data }) {
        return selectOption(data);
      },
    });
  },
  useSearch(text) {
    return useLabels(
      {
        valueLike: text,
        limit: 5,
        sort: "asc",
        order: "value",
      },
      {
        keepPreviousData: true,
        enabled: text !== null,
        select({ data }) {
          return data.map(selectOption);
        },
      },
    );
  },
});

function selectOption(label: Label): Option {
  return {
    value: label.id,
    label: label.value,
  };
}
