import type {
  Group,
  GroupCreateRequest,
  GroupUpdateRequest,
  ListGroupsRequest,
} from "~/services/datastore";
import { createResourceCrudHooks } from "./utils";

export const {
  queryKeyFactory: groupKeys,
  useList: useGroups,
  useFetch: useGroup,
  useCreate: useCreateGroup,
  useUpdate: useUpdateGroup,
  useDelete: useDeleteGroup,
} = createResourceCrudHooks({
  baseQueryKey: "groups",
  getIdentifier(group: Group) {
    return group.id;
  },
  listResource({ signal }, { groupApi }, request: ListGroupsRequest) {
    return groupApi.listGroups(request, { signal });
  },
  fetchResource({ signal }, { groupApi }, groupId: Group["id"]) {
    return groupApi.fetchGroup({ groupId }, { signal });
  },
  createResource({ groupApi }, request: GroupCreateRequest) {
    return groupApi.createGroup({ groupCreateRequest: request });
  },
  updateResource(
    { groupApi },
    groupId: Group["id"],
    updates: GroupUpdateRequest,
  ) {
    return groupApi.updateGroup({ groupId, groupUpdateRequest: updates });
  },
  deleteResource({ groupApi }, groupId: Group["id"]) {
    return groupApi.deleteGroup({ groupId });
  },
});
