import React from "react";
import type { LinkProps as RouterLinkProps } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import type { StrictOmit } from "ts-essentials";
import { useCurrentDataStore } from "~/domain/datastores";
import type { DataStorePathGenerator } from "./types";

export type DataStoreLinkProps = StrictOmit<RouterLinkProps, "to"> & {
  to: DataStorePathGenerator;
};

export const DataStoreLink = React.forwardRef<
  HTMLAnchorElement,
  DataStoreLinkProps
>(function DataStoreLink({ to: toProp, ...props }, ref) {
  const dataStore = useCurrentDataStore();

  const to = toProp(dataStore);

  return <RouterLink ref={ref} {...props} to={to} />;
});
