import type { ValueOf } from "type-fest";

export const IMAGE_ROTATION_STEP_MAGNITUDE_DEG = 90;

export const MAX_CHART_FIELDS = 3;

export const VisualizationType = {
  Timeline: "timeline",
  Chart: "chart",
  Image: "image",
  Map: "map",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VisualizationType = ValueOf<typeof VisualizationType>;

export const SplitOrientation = {
  Horizontal: "horizontal",
  Vertical: "vertical",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SplitOrientation = ValueOf<typeof SplitOrientation>;

export const RotationDirection = {
  Left: "left",
  Right: "right",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RotationDirection = ValueOf<typeof RotationDirection>;

export const FlipDirection = {
  X: "x",
  Y: "y",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FlipDirection = ValueOf<typeof FlipDirection>;
