import {
  CheckboxField,
  EditResourceForm,
  ObjectField,
  SelectField,
  TextField,
  TopicSelect,
  typeEncodingOptions,
} from "~/components/Form";
import { useTopic, useUpdateTopic } from "~/domain/datastores";
import { makeTopicLocation, useTopicParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_TOPIC_FIELDS, editTopicSchema } from "../schemas";

export function TopicEditForm() {
  const { topicId } = useTopicParams();

  return (
    <EditResourceForm
      schema={editTopicSchema}
      resourceName="topic"
      query={useTopic(topicId, { select: selectData })}
      editableFields={EDITABLE_TOPIC_FIELDS}
      mutation={useUpdateTopic(topicId)}
      resourceLocation={makeTopicLocation({ topicId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <TopicSelect control={control} name="associatedTopicId" />
          <CheckboxField control={control} name="strict" />
          <TextField control={control} name="typeName" />
          <SelectField
            control={control}
            name="typeEncoding"
            options={typeEncodingOptions}
          />
          <TextField control={control} name="typeData" />
          <ObjectField control={control} name="typeSchema" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditResourceForm>
  );
}
