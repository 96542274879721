import React from "react";
import { useImmerReducer } from "use-immer";
import { createSafeContext } from "~/contexts";
import type { LayoutNode } from "./api";
import type { PanelLayoutAction } from "./reducer";
import reducer, { initialState } from "./reducer";

export interface PanelLayoutContextValue {
  layout: LayoutNode;
  dispatch: React.Dispatch<PanelLayoutAction>;
}

export const [usePanelLayoutContext, PanelLayoutContext] =
  createSafeContext<PanelLayoutContextValue>("PanelLayout");

export interface PanelLayoutProviderProps {
  children: React.ReactNode;
}

export function PanelLayoutProvider({ children }: PanelLayoutProviderProps) {
  const [layoutState, dispatch] = useImmerReducer(reducer, initialState);

  return (
    <PanelLayoutContext.Provider value={{ layout: layoutState.root, dispatch }}>
      {children}
    </PanelLayoutContext.Provider>
  );
}
