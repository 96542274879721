import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  FuzzyField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListObjectStoresFormValues } from "../schemas";
import { listObjectStoresSchema } from "../schemas";

export interface ObjectStoreFiltersProps {
  values: ListObjectStoresFormValues;
  setValues: (newValues: ListObjectStoresFormValues) => void;
}

export function ObjectStoreFilters({
  values,
  setValues,
}: ObjectStoreFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listObjectStoresSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item xs={3}>
        <TextField control={control} name="bucketName" />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="accessKeyId" />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="regionName" />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="endpointUrl" />
      </Grid>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="note" fuzzyName="noteLike" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="disabled" />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
