import React, { useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { TimerSand } from "mdi-material-ui";
import { Center } from "~/components/Center";
import { Error } from "~/components/Error";
import { Loading } from "~/components/Loading";
import { useBlobSource } from "~/hooks";
import type { Topic } from "~/services/datastore";
import {
  useFirstRecordTimestamp,
  useUpdatePanelBuffering,
} from "../../../hooks";
import type { InitializedPanelNode } from "../../../panels";
import { FlipDirection } from "../../../panels";
import {
  usePlaybackSource,
  seek,
  useCalculateFrameTimestamp,
  useFormatPlaybackTimestamp,
} from "../../../playback";
import PanelLayout from "../../PanelLayout";
import { ImageControls } from "./controls";
import { SegmentationsOverlay } from "./segmentations";
import type { ImageRecord, ImageRecordQueryResult } from "./useImageRecord";
import { useImageRecord } from "./useImageRecord";
import { isRotatedSideways, searchForSegmentationTopic } from "./utils";
import type { SegmentationTopicSearchResult } from "./utils";

const flipDirectionToCssScale = new Map([
  [null, "1, 1"],
  [FlipDirection.X, "-1, 1"],
  [FlipDirection.Y, "1, -1"],
]);

export function calculateTransforms(
  rotationDeg: number,
  flipDirection: FlipDirection | null,
): string {
  return [
    "translate(-50%, -50%)",
    `rotate(${rotationDeg}deg)`,
    `scale(${flipDirectionToCssScale.get(flipDirection)})`,
  ].join(" ");
}

const ImageContainer = styled("div")<{
  panel: InitializedPanelNode;
}>(({ panel }) => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  containerType: "size",
  "& img": {
    width: "100cqw",
    height: "100cqh",
    objectFit: "contain",
    position: "absolute",
    top: "50%",
    left: "50%",
    ...(isRotatedSideways(panel.imageRotationDeg) && {
      // `object-fit: contain` is applied prior to transformations, so rotating
      // an image sideways doesn't give the desired effect. Consequently, when
      // the image is rotated, it should be sized according to the container's
      // swapped dimensions (i.e. its height is the container's width) to cause
      // the browser to apply `object-fit: contain` according to the dimensions
      // it'll have when it's rotated. Thankfully container queries and the new
      // container query units exist or a resize observer would be necessary.
      width: "100cqh",
      height: "100cqw",
    }),
    transform: calculateTransforms(
      panel.imageRotationDeg,
      panel.imageFlipDirection,
    ),
  },
  "& svg[data-image-segmentations]": {
    display: "block",
    width: "100cqw",
    height: "100cqh",
    position: "absolute",
    top: "50%",
    left: "50%",
    ...(isRotatedSideways(panel.segmentationRotationDeg) && {
      width: "100cqh",
      height: "100cqw",
    }),
    transform: calculateTransforms(
      panel.segmentationRotationDeg,
      panel.segmentationFlipDirection,
    ),
  },
}));

type NormalizedImageRecordQueryResult =
  | ImageRecordQueryResult
  | { status: "placeholder"; data: ImageRecord };

interface ImageVisualizationProps {
  panel: InitializedPanelNode;
  topic: Topic;
  playerTopics: ReadonlyArray<Topic>;
}

export function ImageVisualization({
  panel,
  topic,
  playerTopics,
}: ImageVisualizationProps) {
  const segmentationTopicSearchResult = searchForSegmentationTopic(
    panel,
    playerTopics,
  );

  const imageRecordQuery = useImageRecord({
    topic,
    segmentationTopicId: segmentationTopicSearchResult.topic?.id,
  });

  return (
    <StorePreviousImage
      panel={panel}
      topic={topic}
      playerTopics={playerTopics}
      imageRecordQuery={imageRecordQuery}
      segmentationTopicSearchResult={segmentationTopicSearchResult}
    />
  );
}

function StorePreviousImage({
  panel,
  topic,
  playerTopics,
  imageRecordQuery,
  segmentationTopicSearchResult,
}: ImageVisualizationProps & {
  imageRecordQuery: ImageRecordQueryResult;
  segmentationTopicSearchResult: SegmentationTopicSearchResult;
}) {
  // Since react-query doesn't have a nice "previous data" feature for the
  // `useQueries` hook, conditionally setting state during render can fill the
  // gap. If the current query is loading, the last-seen record can be used
  // as a placeholder to show underneath the loading overlay
  const [lastSeenImageRecord, setLastSeenImageRecord] = useState<ImageRecord>();

  function shouldStoreQueryData(): boolean {
    // Never store the current query's data unless the query is successful
    if (imageRecordQuery.status !== "success") {
      return false;
    }

    // If the current query's data is `undefined` (meaning it has no recent
    // image) and the last-seen record is also `undefined` (for any reason),
    // don't call the state setter or we could be in an infinite re-render loop
    if (
      imageRecordQuery.data === undefined &&
      lastSeenImageRecord === undefined
    ) {
      return false;
    }

    // At this point, at least the current query's data or the last-seen record
    // are defined. If the two having matching IDs, it indicates both are
    // defined but there's not been a change in the image being shown on
    // screen or its segmentations. Calling the state setter here would lead to
    // an infinite re-render loop
    if (imageRecordQuery.data?.id === lastSeenImageRecord?.id) {
      return false;
    }

    // The current query's data should be stored, whether because it represents
    // a new image on screen or there's no recent image to be shown
    return true;
  }

  if (shouldStoreQueryData()) {
    setLastSeenImageRecord(imageRecordQuery.data);
  }

  let normalizedImageRecordQuery: NormalizedImageRecordQueryResult;
  if (imageRecordQuery.status === "success") {
    normalizedImageRecordQuery = {
      status: "success",
      data: imageRecordQuery.data,
    };
  } else if (
    imageRecordQuery.status === "error" ||
    imageRecordQuery.status === "error:image"
  ) {
    normalizedImageRecordQuery = {
      status: imageRecordQuery.status,
      data: undefined,
    };
  } else if (lastSeenImageRecord !== undefined) {
    normalizedImageRecordQuery = {
      status: "placeholder",
      data: lastSeenImageRecord,
    };
  } else {
    normalizedImageRecordQuery = {
      status: "loading",
      data: undefined,
    };
  }

  return (
    <ImageVisualizationInner
      panel={panel}
      topic={topic}
      playerTopics={playerTopics}
      imageRecordQuery={normalizedImageRecordQuery}
      segmentationTopicSearchResult={segmentationTopicSearchResult}
    />
  );
}

function ImageVisualizationInner({
  panel,
  topic,
  playerTopics,
  imageRecordQuery,
  segmentationTopicSearchResult,
}: ImageVisualizationProps & {
  imageRecordQuery: NormalizedImageRecordQueryResult;
  segmentationTopicSearchResult: SegmentationTopicSearchResult;
}) {
  const playbackSource = usePlaybackSource();

  const firstRecordTimestampQuery = useFirstRecordTimestamp(topic.id);

  const calculateFrameTimestamp = useCalculateFrameTimestamp();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();
  function formatElapsedTime(timestamp: bigint): string {
    return formatPlaybackTimestamp(
      Number(
        calculateFrameTimestamp(playbackSource.timestamp!) -
          calculateFrameTimestamp(timestamp),
      ),
    );
  }

  useUpdatePanelBuffering(
    imageRecordQuery.status === "loading" ||
      imageRecordQuery.status === "placeholder",
  );
  const currentImgRef = useBlobSource(imageRecordQuery.data?.image);

  const currentImg = imageRecordQuery.data?.image;
  const imageSegmentations = imageRecordQuery.data?.segmentations;

  let rootContent;
  if (imageRecordQuery.status === "loading" || playbackSource.isLoading) {
    rootContent = <Loading type="circular" />;
  } else if (imageRecordQuery.status === "error") {
    rootContent = (
      <Error>
        <Typography variant="h5" component="p" color="error">
          An error occurred. Unable to get images
        </Typography>
      </Error>
    );
  } else if (imageRecordQuery.status === "error:image") {
    rootContent = (
      <Error>
        <Typography variant="h5" component="p" color="error">
          Error fetching this image
        </Typography>
      </Error>
    );
  } else {
    const firstRecordTimestamp = firstRecordTimestampQuery.data;

    let imageContent;
    if (
      firstRecordTimestamp !== undefined &&
      calculateFrameTimestamp(playbackSource.timestamp) <
        calculateFrameTimestamp(firstRecordTimestamp)
    ) {
      imageContent = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p">
            No recent image
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              playbackSource.dispatch(
                seek({ to: calculateFrameTimestamp(firstRecordTimestamp) }),
              )
            }
          >
            Skip to First Message
          </Button>
        </Center>
      );
    } else {
      if (currentImg === undefined) {
        imageContent = (
          <Center>
            <TimerSand fontSize="large" />
            <Typography variant="h5" component="p">
              No recent image
            </Typography>
          </Center>
        );
      } else {
        imageContent = (
          <>
            <img alt="" ref={currentImgRef} />
            {imageSegmentations != null && (
              <SegmentationsOverlay
                imageSegmentations={imageSegmentations}
                rotationDeg={panel.segmentationRotationDeg}
                flipDirection={panel.segmentationFlipDirection}
                showBoundingBoxes={panel.showSegmentationBoundingBoxes}
                showClassNames={panel.showSegmentationClassNames}
                hiddenClassNames={panel.hiddenObjectClassNames}
              />
            )}
            {imageRecordQuery.data?.isStale && (
              <Tooltip
                title={`No new image for ${formatElapsedTime(
                  imageRecordQuery.data.timestamp,
                )}`}
                placement="left"
              >
                <TimerSand
                  sx={{
                    position: "absolute",
                    top: (theme) => theme.spacing(1),
                    right: (theme) => theme.spacing(1),
                  }}
                />
              </Tooltip>
            )}
            {imageRecordQuery.status === "placeholder" && (
              <Backdrop open sx={{ position: "absolute" }}>
                <CircularProgress />
              </Backdrop>
            )}
          </>
        );
      }
    }

    rootContent = <ImageContainer panel={panel}>{imageContent}</ImageContainer>;
  }

  return (
    <PanelLayout
      controls={
        <ImageControls
          panel={panel}
          topic={topic}
          playerTopics={playerTopics}
          imageRecord={imageRecordQuery.data}
          segmentationTopicSearchResult={segmentationTopicSearchResult}
        />
      }
    >
      {rootContent}
    </PanelLayout>
  );
}
