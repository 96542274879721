import React, { useState } from "react";
import type { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { createSafeContext } from "~/contexts";
import {
  createDataStoreManagementApiConfiguration,
  createQueryClient,
} from "~/factories";
import { DataStoreApi } from "~/services/dsm";
import type { DataStoreManagementClients } from "./types";

export interface DataStoreManagementProviderProps {
  children: React.ReactNode;
}

export const [useDataStoreManagementContext, DataStoreManagementContext] =
  createSafeContext<DataStoreManagementClients>("DataStoreManagement");

export const DataStoreManagementQueryClientContext = React.createContext<
  QueryClient | undefined
>(undefined);

export function DataStoreManagementProvider({
  children,
}: DataStoreManagementProviderProps) {
  const [clients] = useState(() => {
    const configuration = createDataStoreManagementApiConfiguration();

    return {
      dataStoreApi: new DataStoreApi(configuration),
    };
  });

  const [queryClient] = useState(createQueryClient);

  return (
    <DataStoreManagementContext.Provider value={clients}>
      <QueryClientProvider
        client={queryClient}
        // Isolate DSM queries and mutations while still being accessible
        // anywhere in the tree if this context is passed to the TanStack hooks.
        context={DataStoreManagementQueryClientContext}
      >
        {children}
      </QueryClientProvider>
    </DataStoreManagementContext.Provider>
  );
}
