import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import {
  optionalText,
  requiredText,
  schemaShapeForType,
} from "~/components/Form";
import { filterText } from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  Label,
  LabelCreateRequest,
  LabelUpdateRequest,
  ListLabelsRequest,
} from "~/services/datastore";

export const listLabelsSchema = z.object(
  schemaShapeForType<ListLabelsRequest>()({
    value: filterText,
    valueLike: filterText,
    noteLike: filterText,
    ...auditFieldSchemas,
  }),
);

export type ListLabelsFormValues = z.infer<typeof listLabelsSchema>;

export const createLabelSchema = z.object(
  schemaShapeForType<LabelCreateRequest>()({
    value: requiredText,
    note: optionalText,
  }),
);

export type CreateLabelFormValues = z.infer<typeof createLabelSchema>;

export const editLabelSchema = z.object(
  schemaShapeForType<LabelUpdateRequest>()({
    value: requiredText,
    note: optionalText,
  }),
);

export const EDITABLE_LABEL_FIELDS = editLabelSchema.keyof().options;

export type EditLabelFormValues = Pick<
  Label,
  ElementOf<typeof EDITABLE_LABEL_FIELDS>
>;
