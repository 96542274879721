import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  LockCard,
  ManageCard,
  ResourceFields,
} from "~/components/DetailsCards";
import { calculateProcessRefetchInterval, Process } from "~/components/Process";
import {
  useDeleteDigestionPart,
  useDigestionPart,
  useUpdateDigestionPart,
} from "~/domain/datastores";
import {
  makeDigestionPartsLocation,
  makeEditDigestionPartLocation,
  useDigestionPartParams,
} from "~/paths";
import type { DigestionPart } from "~/services/datastore";
import { selectData } from "~/utils";

export function DigestionPartDetails() {
  const { digestionId, digestionPartId } = useDigestionPartParams();

  const query = useDigestionPart(digestionId, digestionPartId, {
    select: selectData,
    refetchInterval: calculateProcessRefetchInterval,
  });

  const generalSection = <GeneralSection query={query} />;
  const processSection = (
    <Process
      query={query}
      mutation={useUpdateDigestionPart(digestionId, digestionPartId)}
    />
  );
  const lockSection = (
    <LockCard
      resourceName="digestion part"
      query={query}
      updateMutation={useUpdateDigestionPart(digestionId, digestionPartId)}
    />
  );
  const historySection = <HistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="digestion part"
      query={query}
      editLocation={makeEditDigestionPartLocation({
        digestionId,
        digestionPartId,
      })}
      deleteMutation={useDeleteDigestionPart(digestionId, digestionPartId)}
      getReadableName={(digestionPart) => digestionPart.id}
      listLocation={makeDigestionPartsLocation({ digestionId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {processSection}
        </>
      }
      secondaryGridColumn={
        <>
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {processSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<DigestionPart> }) {
  return (
    <Card title="General">
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "digestion", accessor: "digestionId" },
          { dataType: "number", accessor: "sequence" },
        ]}
      />
    </Card>
  );
}
