import {
  CheckboxField,
  EditResourceForm,
  ObjectField,
  processStateOptions,
  processTypeOptions,
  SelectField,
  TextField,
} from "~/components/Form";
import { useUpdateWorkflowHook, useWorkflowHook } from "~/domain/datastores";
import { makeHookLocation, useHookParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_WORKFLOW_HOOK_FIELDS,
  editWorkflowHookSchema,
} from "../schemas";

export function WorkflowHookEditForm() {
  const { workflowId, hookId } = useHookParams();

  return (
    <EditResourceForm
      schema={editWorkflowHookSchema}
      resourceName="hook"
      query={useWorkflowHook(workflowId, hookId, { select: selectData })}
      editableFields={EDITABLE_WORKFLOW_HOOK_FIELDS}
      mutation={useUpdateWorkflowHook(workflowId, hookId)}
      resourceLocation={makeHookLocation({ workflowId, hookId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <SelectField
            control={control}
            name="triggerProcess"
            options={processTypeOptions}
            required
          />
          <SelectField
            control={control}
            name="triggerState"
            options={processStateOptions}
            required
          />
          <TextField control={control} name="uri" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditResourceForm>
  );
}
