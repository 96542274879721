import type {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import type {
  CloudObject,
  ListObjectsRequest,
  ListObjectStoresRequest,
  ObjectDataResponse,
  ObjectListResponse,
  ObjectStore,
  ObjectStoreCreateRequest,
  ObjectStoreUpdateRequest,
} from "~/services/datastore";
import type { KeyFactory } from "~/types";
import { useDataStoreClients } from "../providers";
import { createResourceCrudHooks } from "./utils";

export const {
  queryKeyFactory: objectStoreKeys,
  useList: useObjectStores,
  useFetch: useObjectStore,
  useCreate: useCreateObjectStore,
  useUpdate: useUpdateObjectStore,
  useDelete: useDeleteObjectStore,
} = createResourceCrudHooks({
  baseQueryKey: "object-stores",
  getIdentifier(objectStore: ObjectStore) {
    return objectStore.id;
  },
  listResource(
    { signal },
    { objectStoreApi },
    request: ListObjectStoresRequest,
  ) {
    return objectStoreApi.listObjectStores(request, { signal });
  },
  fetchResource(
    { signal },
    { objectStoreApi },
    objectStoreId: ObjectStore["id"],
  ) {
    return objectStoreApi.fetchObjectStore({ objectStoreId }, { signal });
  },
  createResource({ objectStoreApi }, request: ObjectStoreCreateRequest) {
    return objectStoreApi.createObjectStore({
      objectStoreCreateRequest: request,
    });
  },
  updateResource(
    { objectStoreApi },
    objectStoreId: ObjectStore["id"],
    updates: ObjectStoreUpdateRequest,
  ) {
    return objectStoreApi.updateObjectStore({
      objectStoreId,
      objectStoreUpdateRequest: updates,
    });
  },
  deleteResource({ objectStoreApi }, objectStoreId: ObjectStore["id"]) {
    return objectStoreApi.deleteObjectStore({ objectStoreId });
  },
});

export const objectStoreObjectKeys = {
  all: (objectStoreId: ObjectStore["id"]) =>
    [...objectStoreKeys.fetch(objectStoreId), "objects"] as const,
  lists: (objectStoreId: ObjectStore["id"]) =>
    [...objectStoreObjectKeys.all(objectStoreId), "list"] as const,
  list: (
    objectStoreId: ObjectStore["id"],
    request: StrictOmit<ListObjectsRequest, "objectStoreId">,
  ) => [...objectStoreObjectKeys.lists(objectStoreId), request] as const,
  fetches: (objectStoreId: ObjectStore["id"]) =>
    [...objectStoreObjectKeys.all(objectStoreId), "fetch"] as const,
  fetch: (objectStoreId: ObjectStore["id"], objectKey: CloudObject["key"]) =>
    [...objectStoreObjectKeys.fetches(objectStoreId), objectKey] as const,
};

type ObjectStoreObjectKeys = KeyFactory<typeof objectStoreObjectKeys>;

export function useObjectStoreObjects<TData = ObjectListResponse>(
  objectStoreId: ObjectStore["id"],
  request: StrictOmit<
    ListObjectsRequest,
    "objectStoreId" | "continuationToken"
  >,
  options?: StrictOmit<
    UseInfiniteQueryOptions<
      ObjectListResponse,
      unknown,
      TData,
      ObjectListResponse,
      ObjectStoreObjectKeys["list"]
    >,
    "getNextPageParam" | "getPreviousPageParam"
  >,
): UseInfiniteQueryResult<TData> {
  const { objectStoreApi } = useDataStoreClients();

  return useInfiniteQuery({
    queryKey: objectStoreObjectKeys.list(objectStoreId, request),
    queryFn({ signal, pageParam: continuationToken }) {
      return objectStoreApi.listObjects(
        { objectStoreId, ...request, continuationToken },
        { signal },
      );
    },
    getNextPageParam(lastPage) {
      // react-query would interpret a `null` continuation token to mean
      // there are more pages, so `null` needs to be defaulted to `undefined`
      return lastPage.continuationToken ?? undefined;
    },
    ...options,
  });
}

export function useObjectStoreObject<TData = ObjectDataResponse>(
  objectStoreId: ObjectStore["id"],
  objectKey: CloudObject["key"],
  options?: StrictOmit<
    UseQueryOptions<
      ObjectDataResponse,
      unknown,
      TData,
      ObjectStoreObjectKeys["fetch"]
    >,
    "initialData"
  >,
): UseQueryResult<TData> {
  const { objectStoreApi } = useDataStoreClients();

  return useQuery({
    queryKey: objectStoreObjectKeys.fetch(objectStoreId, objectKey),
    queryFn({ signal }) {
      return objectStoreApi.fetchObject(
        { objectStoreId, objectKey },
        { signal },
      );
    },
    ...options,
  });
}
