import {
  CheckboxField,
  NewResourceForm,
  NumberField,
  ObjectField,
  processStateOptions,
  SelectField,
  TextField,
  WorkflowSelect,
} from "~/components/Form";
import { useCreateIngestionPart } from "~/domain/datastores";
import { makeIngestionPartLocation, useIngestionParams } from "~/paths";
import { ProcessState } from "~/services/datastore";
import { createIngestionPartSchema } from "../schemas";

export function IngestionPartCreateForm() {
  const { ingestionId } = useIngestionParams();

  return (
    <NewResourceForm
      schema={createIngestionPartSchema}
      defaultValues={{
        sequence: null,
        source: null,
        state: ProcessState.Ready,
        workflowId: null,
        workflowContext: null,
        locked: false,
      }}
      resourceName="ingestion part"
      mutation={useCreateIngestionPart(ingestionId)}
      generateLocation={(response) =>
        makeIngestionPartLocation({
          ingestionId,
          ingestionPartId: response.data.id,
        })
      }
    >
      {(control) => (
        <>
          <NumberField control={control} name="sequence" required />
          <TextField control={control} name="source" />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <CheckboxField control={control} name="locked" />
        </>
      )}
    </NewResourceForm>
  );
}
