import { z } from "zod";
import { ProcessType } from "~/services/datastore";
import type { Option } from "./types";
import { createErrorMap } from "./utils";

export const processTypeOptions: Option[] = Object.values(ProcessType).map(
  (value) => ({
    label: value,
    value,
  }),
);

export const requiredProcessTypeSchema = z.nativeEnum(ProcessType, {
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
    invalid_enum_value: "Unexpected value",
  }),
});

export const optionalProcessTypeSchema = requiredProcessTypeSchema.nullable();
