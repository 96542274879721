import { Skeleton, Stack } from "@mui/material";

const shapeHeightMap = {
  text: 56,
  multiline: 102,
};

export interface FormSkeletonProps {
  shapes: ReadonlyArray<"text" | "multiline">;
}

export function FormSkeleton({ shapes }: FormSkeletonProps) {
  return (
    <Stack spacing={2} useFlexGap sx={{ maxWidth: 400, mx: "auto" }}>
      {shapes.map((shape, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          height={shapeHeightMap[shape]}
        />
      ))}
      <Skeleton variant="rounded" width={150} height={36} />
    </Stack>
  );
}
