import React from "react";
import { Card, CardContent } from "@mui/material";
import type { UseMutationResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type { FieldValues } from "react-hook-form";
import type { z } from "zod";
import type { DataStorePathGenerator } from "~/paths";
import { useNavigateDataStore } from "~/paths";
import { Form } from "./Form";
import type { UseStudioFormProps, UseStudioFormReturn } from "./hooks";
import { useStudioForm } from "./hooks";

export interface NewResourceFormProps<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResponse extends object,
> extends Required<
    Pick<
      UseStudioFormProps<z.ZodType<TFormValues, z.ZodTypeDef, unknown>>,
      "schema" | "defaultValues"
    >
  > {
  resourceName: string;
  mutation: UseMutationResult<TResponse, unknown, TRequest>;
  generateLocation: (response: TResponse) => DataStorePathGenerator;
  children: (
    control: UseStudioFormReturn<TFormValues>["control"],
  ) => React.ReactNode;
}

export function NewResourceForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResponse extends object,
>({
  schema,
  defaultValues,
  resourceName,
  mutation,
  generateLocation,
  children,
}: NewResourceFormProps<TRequest, TFormValues, TResponse>) {
  const navigateDataStore = useNavigateDataStore();

  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useStudioForm({
    schema,
    defaultValues,
    onSubmit: function onSubmit(values: TFormValues) {
      mutation.mutate(values, {
        onSuccess(response) {
          navigateDataStore(generateLocation(response));
        },
        onError() {
          enqueueSnackbar(`Unable to create ${resourceName}`, {
            variant: "error",
          });
        },
      });
    } as any,
  });

  return (
    <Card>
      <CardContent>
        <Form
          onSubmit={handleSubmit}
          loading={mutation.isLoading}
          submitText="Create"
        >
          {children(control)}
        </Form>
      </CardContent>
    </Card>
  );
}
