import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  FuzzyField,
  RoleSelect,
  useStudioForm,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListUsersFormValues } from "../schemas";
import { listUsersSchema } from "../schemas";

export interface UserFiltersProps {
  values: ListUsersFormValues;
  setValues: (newValues: ListUsersFormValues) => void;
}

export function UserFilters({ values, setValues }: UserFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listUsersSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={3}>
        <FuzzyField
          control={control}
          exactName="username"
          fuzzyName="usernameLike"
        />
      </Grid>
      <Grid item xs={3}>
        <RoleSelect control={control} name="roleId" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="admin" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="managed" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="disabled" />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
