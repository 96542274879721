import {
  CheckboxField,
  EditResourceForm,
  ObjectField,
  TextField,
} from "~/components/Form";
import { useUpdateWorkflow, useWorkflow } from "~/domain/datastores";
import { makeWorkflowLocation, useWorkflowParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_WORKFLOW_FIELDS, editWorkflowSchema } from "../schemas";

export function WorkflowEditForm() {
  const { workflowId } = useWorkflowParams();

  return (
    <EditResourceForm
      schema={editWorkflowSchema}
      resourceName="workflow"
      query={useWorkflow(workflowId, { select: selectData })}
      editableFields={EDITABLE_WORKFLOW_FIELDS}
      mutation={useUpdateWorkflow(workflowId)}
      resourceLocation={makeWorkflowLocation({ workflowId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <CheckboxField control={control} name="_default" />
          <CheckboxField control={control} name="disabled" />
          <ObjectField control={control} name="contextSchema" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditResourceForm>
  );
}
