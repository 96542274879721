import { useDigestion } from "~/domain/datastores";
import { makeDigestionLocation } from "~/paths";
import type { Digestion } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface DigestionInfoProps {
  digestionId: Digestion["id"];
}

export function DigestionInfo({ digestionId }: DigestionInfoProps) {
  const digestionNameQuery = useDigestion(digestionId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <ResourceInfo
      name={digestionNameQuery.data}
      identifier={digestionId}
      to={makeDigestionLocation({ digestionId })}
    />
  );
}
