import {
  CheckboxField,
  EditResourceForm,
  ObjectField,
  TextField,
  WorkflowSelect,
} from "~/components/Form";
import { useGroup, useUpdateGroup } from "~/domain/datastores";
import { makeGroupLocation, useGroupParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_GROUP_FIELDS, editGroupSchema } from "../schemas";

export function GroupEditForm() {
  const { groupId } = useGroupParams();

  return (
    <EditResourceForm
      schema={editGroupSchema}
      resourceName="group"
      query={useGroup(groupId, { select: selectData })}
      editableFields={EDITABLE_GROUP_FIELDS}
      mutation={useUpdateGroup(groupId)}
      resourceLocation={makeGroupLocation({ groupId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <WorkflowSelect control={control} name="defaultWorkflowId" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditResourceForm>
  );
}
