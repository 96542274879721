import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { DigestionPartEditForm } from "./components/DigestionPartEditForm";

export function EditDigestionPartPage() {
  return (
    <Page title="Edit Digestion Part">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <DigestionPartEditForm />
      </Container>
    </Page>
  );
}
