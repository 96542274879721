import React from "react";
import {
  Album,
  Badge,
  CloudDownload,
  CloudUpload,
  CloudUploadOutlined,
  Group,
  Key,
  Label,
  People,
  Person,
  Schema,
} from "@mui/icons-material";
import type { SvgIcon } from "@mui/material";
import {
  Button,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import { Database, FileCloud } from "mdi-material-ui";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import { AdminGuard } from "~/domain/auth";
import { DOCS_ORIGIN } from "~/links";
import * as paths from "~/paths";
import { DataStoreLink } from "~/paths";
import type { DataStorePathGenerator } from "~/paths";
import { redirectToSignOut } from "~/services/auth";
import {
  ScreenConfiguration,
  useLayoutStateContext,
  useScreenConfiguration,
} from "./Layout";
import { Logo } from "./Logo";

function useIsActivePath() {
  const location = useLocation();

  return function isActivePath(path: string, end = false): boolean {
    return matchPath({ path, end }, location.pathname) !== null;
  };
}

export function GlobalNavigation() {
  const { isGlobalNavigationOpen } = useLayoutStateContext();
  const screenConfiguration = useScreenConfiguration();

  const isActivePath = useIsActivePath();

  const useMiniDrawerStyles = !(
    screenConfiguration === ScreenConfiguration.Mobile || isGlobalNavigationOpen
  );

  function renderLink(
    Icon: typeof SvgIcon,
    text: string,
    path: string,
    to: DataStorePathGenerator,
  ) {
    const isSelected = isActivePath(path);

    const icon = <Icon fontSize="small" />;

    return (
      <ListItem component="div" disablePadding>
        <ListItemButton
          component={DataStoreLink}
          to={to}
          selected={isSelected}
          sx={{
            py: 0.5,
            ...(useMiniDrawerStyles && {
              px: 0,
              justifyContent: "center",
            }),
          }}
        >
          {useMiniDrawerStyles ? (
            <ListItemIcon sx={{ minWidth: 0, width: "auto", my: 0.5 }}>
              <Tooltip title={text} placement="right">
                {icon}
              </Tooltip>
            </ListItemIcon>
          ) : (
            <>
              <ListItemIcon sx={{ minWidth: 0, width: "auto", mr: 2 }}>
                {icon}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: isSelected ? 600 : undefined,
                }}
              >
                {text}
              </ListItemText>
            </>
          )}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <List
        component="nav"
        aria-label="global"
        sx={{
          "& .MuiListItemButton-root": {
            my: 0.5,
            borderRadius: "8px",
          },
        }}
      >
        <ListItem
          component="div"
          disablePadding
          sx={{
            justifyContent: "center",
            "& .homepage-link": {
              display: "flex",
              justifyContent: "center",
            },
            "& .logqs-logo": {
              display: "block",
              width: "auto",
              height: 40,
              mb: 2,
            },
          }}
        >
          {isActivePath(paths.DATASTORE, true) ? (
            <Logo alt="LogQS logo" icon={useMiniDrawerStyles} />
          ) : (
            <DataStoreLink
              className="homepage-link"
              to={paths.makeDataStoreLocation()}
            >
              <Logo alt="Homepage" icon={useMiniDrawerStyles} />
            </DataStoreLink>
          )}
        </ListItem>
        {renderLink(FileCloud, "Logs", paths.LOGS, paths.makeLogsLocation())}
        {renderLink(
          CloudUpload,
          "Upload",
          paths.UPLOAD,
          paths.makeUploadLocation(),
        )}
        {renderLink(Album, "Player", paths.PLAYER, paths.makePlayerLocation())}
        <Divider sx={{ my: 1 }} />
        {renderLink(
          CloudDownload,
          "Topics",
          paths.TOPICS,
          paths.makeTopicsLocation(),
        )}
        {renderLink(
          CloudUploadOutlined,
          "Ingestions",
          paths.INGESTIONS,
          paths.makeIngestionsLocation(),
        )}
        {renderLink(
          CloudDownload,
          "Digestions",
          paths.DIGESTIONS,
          paths.makeDigestionsLocation(),
        )}
        {renderLink(
          Schema,
          "Workflows",
          paths.WORKFLOWS,
          paths.makeWorkflowsLocation(),
        )}
        {renderLink(Label, "Labels", paths.LABELS, paths.makeLabelsLocation())}
        {renderLink(
          Database,
          "Object Stores",
          paths.OBJECT_STORES,
          paths.makeObjectStoresLocation(),
        )}
        <Divider sx={{ my: 1 }} />
        {renderLink(
          Person,
          "Profile",
          paths.PROFILE,
          paths.makeProfileLocation(),
        )}
        <AdminGuard>
          {renderLink(People, "Users", paths.USERS, paths.makeUsersLocation())}
          {renderLink(
            Group,
            "Groups",
            paths.GROUPS,
            paths.makeGroupsLocation(),
          )}
          {renderLink(Badge, "Roles", paths.ROLES, paths.makeRolesLocation())}
          {renderLink(
            Key,
            "API Keys",
            paths.API_KEYS,
            paths.makeApiKeysLocation(),
          )}
        </AdminGuard>
      </List>
      {!useMiniDrawerStyles && (
        <Stack spacing={3} mt="auto" pt={3} alignItems="center">
          <Button
            component={RouterLink}
            to={paths.makeDataStoresLocation()}
            variant="contained"
            size="small"
          >
            Change DataStore
          </Button>
          <Link
            href={DOCS_ORIGIN}
            variant="body2"
            underline="none"
            fontWeight="bold"
          >
            Docs
          </Link>
          <Link
            variant="body2"
            component="button"
            onClick={redirectToSignOut}
            underline="none"
            fontWeight="bold"
          >
            Sign Out
          </Link>
        </Stack>
      )}
    </Stack>
  );
}
