import type { ElementOf, StrictOmit } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  optionalObject,
  optionalText,
  requiredProcessStateSchema,
  requiredProcessTypeSchema,
  requiredText,
  schemaShapeForType,
} from "~/components/Form";
import {
  filterBoolean,
  filterProcessState,
  filterProcessType,
  filterText,
} from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  Hook,
  HookCreateRequest,
  HookUpdateRequest,
  ListHooksRequest,
  ListWorkflowsRequest,
  Workflow,
  WorkflowCreateRequest,
  WorkflowUpdateRequest,
} from "~/services/datastore";

export const listWorkflowsSchema = z.object(
  schemaShapeForType<StrictOmit<ListWorkflowsRequest, "contextSchemaFilter">>()(
    {
      name: filterText,
      nameLike: filterText,
      _default: filterBoolean,
      managed: filterBoolean,
      disabled: filterBoolean,
      ...auditFieldSchemas,
    },
  ),
);

export type ListWorkflowsFormValues = z.infer<typeof listWorkflowsSchema>;

export const createWorkflowSchema = z.object(
  schemaShapeForType<WorkflowCreateRequest>()({
    name: requiredText,
    _default: boolean,
    managed: boolean,
    disabled: boolean,
    contextSchema: optionalObject,
    note: optionalText,
    context: optionalObject,
  }),
);

export type CreateWorkflowFormValues = z.infer<typeof createWorkflowSchema>;

export const editWorkflowSchema = z.object(
  schemaShapeForType<WorkflowUpdateRequest>()({
    name: requiredText,
    _default: boolean,
    disabled: boolean,
    contextSchema: optionalObject,
    note: optionalText,
    context: optionalObject,
  }),
);

export const EDITABLE_WORKFLOW_FIELDS = editWorkflowSchema.keyof().options;

export type EditWorkflowFormValues = Pick<
  Workflow,
  ElementOf<typeof EDITABLE_WORKFLOW_FIELDS>
>;

export const listWorkflowHooksSchema = z.object(
  schemaShapeForType<StrictOmit<ListHooksRequest, "workflowId">>()({
    name: filterText,
    nameLike: filterText,
    uri: filterText,
    uriLike: filterText,
    triggerProcess: filterProcessType,
    triggerState: filterProcessState,
    noteLike: filterText,
    managed: filterBoolean,
    disabled: filterBoolean,
    ...auditFieldSchemas,
  }),
);

export type ListWorkflowHooksFormValues = z.infer<
  typeof listWorkflowHooksSchema
>;

export const createWorkflowHookSchema = z.object(
  schemaShapeForType<HookCreateRequest>()({
    name: optionalText,
    triggerProcess: requiredProcessTypeSchema,
    triggerState: requiredProcessStateSchema,
    uri: optionalText,
    secret: optionalText,
    managed: boolean,
    disabled: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export type CreateWorkflowHookFormValues = z.infer<
  typeof createWorkflowHookSchema
>;

export const editWorkflowHookSchema = z.object(
  schemaShapeForType<StrictOmit<HookUpdateRequest, "secret">>()({
    name: optionalText,
    triggerProcess: requiredProcessTypeSchema,
    triggerState: requiredProcessStateSchema,
    uri: optionalText,
    disabled: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export const EDITABLE_WORKFLOW_HOOK_FIELDS =
  editWorkflowHookSchema.keyof().options;

export type EditWorkflowHookFormValues = Pick<
  Hook,
  ElementOf<typeof EDITABLE_WORKFLOW_HOOK_FIELDS>
>;
