import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { TopicTable } from "./components/TopicTable";

export function TopicsPage() {
  return (
    <Page title="Search Topics">
      <FullWidthContainer>
        <TopicTable />
      </FullWidthContainer>
    </Page>
  );
}
