import { useLog } from "~/domain/datastores";
import { makeLogLocation } from "~/paths";
import type { Log } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface LogInfoProps {
  logId: Log["id"];
}

export function LogInfo({ logId }: LogInfoProps) {
  const logNameQuery = useLog(logId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <ResourceInfo
      name={logNameQuery.data}
      identifier={logId}
      to={makeLogLocation({ logId })}
    />
  );
}
