import type { FieldNamesMarkedBoolean } from "react-hook-form";
import type { z } from "zod";
import type { ResourceTableModel } from "../Table";

export function getChangedFields<
  TFieldValues extends Record<
    string,
    | number
    | bigint
    | string
    | Date
    | boolean
    | null
    | undefined
    // This just includes fields whose values are objects. Nested form fields
    // aren't supported
    | object
  >,
>(
  values: TFieldValues,
  dirtyFields: Partial<FieldNamesMarkedBoolean<TFieldValues>>,
): Partial<TFieldValues> {
  const changedFields: Partial<TFieldValues> = {};

  for (const field in values) {
    if (dirtyFields[field]) {
      changedFields[field] = values[field];
    }
  }

  return changedFields;
}

type ErrorMapHandlers = Partial<{
  [Issue in z.ZodIssueOptionalMessage as Issue["code"]]:
    | string
    | ((issue: Issue) => string | undefined);
}>;

export function createErrorMap(handlers: ErrorMapHandlers): z.ZodErrorMap {
  return function errorMap(issue, ctx) {
    const handler = handlers[issue.code];

    let message: string | undefined = undefined;
    if (typeof handler === "string") {
      message = handler;
    } else if (typeof handler === "function") {
      message = handler(issue as any);
    }

    return { message: message ?? ctx.defaultError };
  };
}

type SchemaShape<TType extends object> = {
  [Key in keyof TType]-?: z.ZodType<TType[Key], z.ZodTypeDef, any>;
};

export function schemaShapeForType<TType extends object>() {
  return function enforceExactness<
    TShape extends SchemaShape<Omit<TType, keyof ResourceTableModel>>,
  >(
    shape: TShape & {
      [Key in Exclude<keyof TShape, keyof TType>]: never;
    },
  ) {
    return shape;
  };
}
