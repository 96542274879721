import React from "react";
import { Circle, NavigateBefore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { Topic } from "~/services/datastore";
import type { Maybe } from "~/types";
import { pluralize } from "~/utils";
import type { InitializedPanelNode } from "../../../../panels";
import {
  changeObjectClassVisibility,
  changeSegmentationsLock,
  rotateImage,
  setImageFlipDirection,
  setSegmentationTopic,
  showSegmentationBoundingBoxes,
  showSegmentationClassNames,
  usePanelLayoutContext,
} from "../../../../panels";
import { useClassifySegmentationsQuality } from "../../../../queries";
import { ControlsSection } from "../../../PanelControls";
import { TopicSelect } from "../../../TopicSelect";
import type { ImageSegmentations, Segmentation } from "../segmentations";
import type { ImageRecord } from "../useImageRecord";
import type { SegmentationTopicSearchResult } from "../utils";
import { mapSegmentationClassToOklchColor } from "../utils";
import { Transformations } from "./Transformations";

export interface SegmentationControlsProps {
  onExitPage: () => void;
  panel: InitializedPanelNode;
  topic: Topic;
  playerTopics: ReadonlyArray<Topic>;
  segmentationTopicSearchResult: SegmentationTopicSearchResult;
  imageRecord: ImageRecord | undefined;
}

export function SegmentationControls({
  onExitPage,
  panel,
  topic,
  playerTopics,
  segmentationTopicSearchResult,
  imageRecord,
}: SegmentationControlsProps) {
  const { dispatch } = usePanelLayoutContext();

  const disableClassifying = imageRecord?.segmentations == null;
  const classifySegmentationsQuality = useClassifySegmentationsQuality({
    topic,
  });
  const { enqueueSnackbar } = useSnackbar();

  function handleChangeSegmentationTopic(newValue: Topic | null) {
    dispatch(
      setSegmentationTopic({ panelId: panel.id, segmentationTopic: newValue }),
    );
  }

  function handleChangeSegmentationsLock(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    dispatch(
      changeSegmentationsLock({
        panelId: panel.id,
        lockSegmentations: e.target.checked,
      }),
    );
  }

  function handleChangeShowBoundingBoxes(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    dispatch(
      showSegmentationBoundingBoxes({
        panelId: panel.id,
        showSegmentationBoundingBoxes: e.target.checked,
      }),
    );
  }

  function handleChangeShowClassNames(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(
      showSegmentationClassNames({
        panelId: panel.id,
        showSegmentationClassNames: e.target.checked,
      }),
    );
  }

  function createSegmentationsQualityClassificationHandler(
    classification: "accept" | "reject",
  ) {
    return function handleClassifySegmentationsQuality() {
      if (
        segmentationTopicSearchResult.status !== "found" ||
        disableClassifying ||
        classifySegmentationsQuality.isLoading
      ) {
        return;
      }

      classifySegmentationsQuality.mutate(
        {
          segmentationsTopicId: segmentationTopicSearchResult.topic.id,
          timestamp: imageRecord.timestamp,
          classification,
        },
        {
          onSuccess() {
            enqueueSnackbar("Segmentations classified", {
              variant: "success",
            });
          },
          onError() {
            enqueueSnackbar("Error classifying segmentations", {
              variant: "error",
            });
          },
        },
      );
    };
  }

  function createClassNameVisibilityChangeHandler(
    className: Segmentation["className"],
  ) {
    return function handleChangeClassNameVisibility(
      e: React.ChangeEvent<HTMLInputElement>,
    ) {
      dispatch(
        changeObjectClassVisibility({
          panelId: panel.id,
          className,
          hideClass: !e.target.checked,
        }),
      );
    };
  }

  function renderDetectedClassesList() {
    const listItems = prepareDetectedClassesListItems(
      imageRecord?.segmentations,
      panel,
    );

    return (
      <List
        dense
        subheader={
          <ListSubheader disableGutters disableSticky sx={{ fontWeight: 600 }}>
            Detected Classes
          </ListSubheader>
        }
        sx={{
          "& .MuiListItemIcon-root": {
            minWidth: "auto",
            mr: 1,
          },
        }}
      >
        {listItems.length === 0 ? (
          <ListItem disablePadding>
            <ListItemText>No classes for this image</ListItemText>
          </ListItem>
        ) : (
          listItems.map((listItem) => (
            <ListItem
              key={listItem.className}
              secondaryAction={<Circle sx={{ color: listItem.color }} />}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={listItem.checked}
                  onChange={createClassNameVisibilityChangeHandler(
                    listItem.className,
                  )}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                secondary={pluralize(listItem.count, "object")}
                secondaryTypographyProps={{ noWrap: true }}
              >
                {listItem.className}
              </ListItemText>
            </ListItem>
          ))
        )}
      </List>
    );
  }

  return (
    <>
      <ControlsSection>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Tooltip title="Go back">
            <IconButton onClick={onExitPage}>
              <NavigateBefore />
            </IconButton>
          </Tooltip>
          <Typography>Segmentations</Typography>
        </Stack>
      </ControlsSection>
      <ControlsSection>
        <Transformations
          panel={panel}
          type="image"
          rotateActionCreator={rotateImage}
          flipDirection={panel.imageFlipDirection}
          flipActionCreator={setImageFlipDirection}
        />
      </ControlsSection>
      <ControlsSection>
        <TopicSelect
          sx={{ mb: 1 }}
          inputLabel="Segmentation Topic"
          value={segmentationTopicSearchResult.topic ?? null}
          topics={filterSegmentationTopics(topic, playerTopics)}
          onChange={handleChangeSegmentationTopic}
        />
        {segmentationTopicSearchResult.status === "missing" && (
          <Typography variant="subtitle2" component="p">
            {panel.segmentationTopicName} is not in this log
          </Typography>
        )}
        {segmentationTopicSearchResult.status === "found" && (
          <>
            <FormControlLabel
              sx={{ justifyContent: "space-between", ml: 0 }}
              control={
                <Switch
                  checked={panel.lockSegmentations}
                  onChange={handleChangeSegmentationsLock}
                />
              }
              label="Lock segmentations"
              labelPlacement="start"
            />
            <FormControlLabel
              sx={{ justifyContent: "space-between", ml: 0 }}
              control={
                <Switch
                  checked={panel.showSegmentationBoundingBoxes}
                  onChange={handleChangeShowBoundingBoxes}
                />
              }
              label="Bounding boxes"
              labelPlacement="start"
            />
            <FormControlLabel
              sx={{ justifyContent: "space-between", ml: 0 }}
              control={
                <Switch
                  checked={panel.showSegmentationClassNames}
                  onChange={handleChangeShowClassNames}
                />
              }
              label="Class names"
              labelPlacement="start"
            />
            <Box sx={{ my: 2 }}>
              <Typography>Segmentations Quality</Typography>
              {/* <LoadingButton /> doesn't yet inherit props from
                <ButtonGroup /> but should soon */}
              <ButtonGroup
                fullWidth
                disableElevation
                variant="contained"
                color="primary"
              >
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={classifySegmentationsQuality.isLoading}
                  disabled={disableClassifying}
                  onClick={createSegmentationsQualityClassificationHandler(
                    "accept",
                  )}
                >
                  Accept
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={classifySegmentationsQuality.isLoading}
                  disabled={disableClassifying}
                  onClick={createSegmentationsQualityClassificationHandler(
                    "reject",
                  )}
                >
                  Reject
                </LoadingButton>
              </ButtonGroup>
            </Box>
            {renderDetectedClassesList()}
          </>
        )}
      </ControlsSection>
    </>
  );
}

function filterSegmentationTopics(
  baseTopic: Topic,
  playerTopics: ReadonlyArray<Topic>,
): Array<Topic> {
  // Not every one of these is guaranteed to be segmentation topic but if a
  // segmentation topic exists, it'll be included here.
  return playerTopics.filter(
    (topic) => topic.associatedTopicId === baseTopic.id,
  );
}

interface DetectedClassListItem {
  checked: boolean;
  className: Segmentation["className"];
  count: number;
  color: string;
}

function prepareDetectedClassesListItems(
  imageSegmentations: Maybe<ImageSegmentations>,
  panel: InitializedPanelNode,
): Array<DetectedClassListItem> {
  const listItemsMap = new Map<
    Segmentation["className"],
    DetectedClassListItem
  >();

  imageSegmentations?.segmentations.forEach((segmentation) => {
    let listItem = listItemsMap.get(segmentation.className);
    if (listItem === undefined) {
      listItem = {
        checked: !panel.hiddenObjectClassNames.includes(segmentation.className),
        className: segmentation.className,
        count: 1,
        color: mapSegmentationClassToOklchColor(segmentation),
      };

      listItemsMap.set(segmentation.className, listItem);
    } else {
      listItem.count++;
    }
  });

  return Array.from(listItemsMap.values());
}
