import { nanosecondsToMilliseconds } from "~/lib/dates";
import { isEqual, pullAllWith, range } from "~/lib/std";
import { bigintMax, bigintMin } from "~/utils";
import type { TimeRange } from "../types";

export function timeRangeToDomain(timeRange: TimeRange): [number, number] {
  return [
    nanosecondsToMilliseconds(timeRange.startTime),
    nanosecondsToMilliseconds(timeRange.endTime),
  ];
}

/**
 * Calculate a panel's viewing window within the player bounds using the
 * current timestamp and a desired window size.
 *
 * A window is best understood in terms of the chart visualizations. Rather
 * than displaying a chart spanning the entire topic, the user can view a
 * "window" which shows a narrower time range based around the current
 * timestamp. The following explains the relationship between a window and the
 * topic it's derived from:
 *
 *                     +---------+
 *                     |--       |
 *          Window --> |  \      |
 *                     |   ------|
 *                     +---------+
 *      10 seconds --> [---------]
 * +--------------------------------------+
 * |  /\    --------------                |
 * | /  \  /              \               |
 * |/    \/                ---------------|
 * +--------------------------------------+
 * 0:00                     0:45          1:00
 *                         Current
 *                        timestamp
 * [--------------------------------------]
 *        Player bounds (60 seconds)
 *
 * The window spans 10 seconds and is centered around the current timestamp
 * (0:45). The window is showing a slice of the chart in the time range
 * [0:35, 0:55]. If the current timestamp were incremented to 0:46, the window
 * would then show only the slice of the chart in the range [0:36, 0:56].
 *
 * When the player is playing, the constantly-changing timestamp will give the
 * appearance of the window sliding along. However, the window will always be
 * contained entirely within the player bounds. The window essentially stops
 * "sliding" once its end time reaches the player's end time. Though the
 * timestamp can continue increasing until it too reaches the player's end time,
 * the window will remain static and, importantly, still honor the provided
 * window size. To use the example above, if the current timestamp were 0:59,
 * the calculated window would be [0:50, 1:00]; the window would no longer be
 * centered around the current timestamp as doing so would overflow the player
 * bounds.
 *
 * The only situation in which the calculated window's size is less than the
 * desired window size is if the player's duration is less than the window size.
 */
export function calculateRecordWindow(
  windowSize: bigint,
  timestamp: bigint,
  bounds: TimeRange,
): TimeRange {
  if (windowSize >= bounds.endTime - bounds.startTime) {
    // Window can only equal the player bounds
    return bounds;
  }

  const halfWindowSize = windowSize / 2n;

  if (timestamp <= bounds.startTime + halfWindowSize) {
    // Centering the window around the timestamp would cause the lower half
    // of the window to overflow the player's starting bound
    return {
      startTime: bounds.startTime,
      endTime: bounds.startTime + windowSize,
    };
  }

  if (timestamp >= bounds.endTime - halfWindowSize) {
    // Centering the window around the timestamp would cause the upper half
    // of the window to overflow the player's ending bound
    return {
      startTime: bounds.endTime - windowSize,
      endTime: bounds.endTime,
    };
  }

  // The window is centered around the current timestamp. There's no danger here
  // of either window bound overflowing the player's bounds since the conditions
  // above guarantee the difference between the timestamp and both player
  // bounds is > `halfWindowSize`
  return {
    startTime: timestamp - halfWindowSize,
    endTime: timestamp + halfWindowSize,
  };
}

export interface CalculateWindowChunksParams {
  chunkSize: bigint;
  bufferAhead: bigint;
  bufferBehind: bigint;
  window: TimeRange | undefined;
  playerBounds: TimeRange | undefined;
}

export interface WindowChunks {
  required: TimeRange[];
  bufferAhead: TimeRange[];
  bufferBehind: TimeRange[];
}

/**
 * Calculate the chunks required to display the record window as well as chunks
 * to be buffered prior to and after the window.
 *
 * For a given window within the player's bounds, three categories of chunks
 * need to be calculated:
 *
 * 1. Required chunks - Chunks needed to satisfy the range of records defined
 *    by the window
 * 2. Buffer-ahead chunks - Chunks after the window that will likely be needed
 *    soon and should therefore be buffered
 * 3. Buffer-behind chunks - Chunks prior to the window that will likely be
 *    needed if the user seeks backwards and should therefore be buffered
 *
 * A given chunk can only belong to a single category.
 *
 * The three categories also have relative priorities based on which should be
 * fetched first:
 *
 * 1. Required chunks - Needed immediately
 * 2. Buffer-ahead chunks - Needed soon but not immediately
 * 3. Buffer-behind chunks - Lowest priority. Since automatic playback is
 *    typical (i.e. the window is moving forward), these won't be needed unless
 *    the user manually seeks backwards.
 *
 * Note the priorities above only describe the fetching priority assuming some
 * or none of the chunks have been fetched. Once fetched, chunks in all three
 * categories should be buffered in memory.
 *
 * Buffering behind will be truncated at the player's start bound. For example,
 * in the following diagram any amount of time to buffer behind the window will
 * be ignored since the window starts at the player's start bound.
 *
 *    Window
 * [----------]
 * |-----|-----|-----|-----|-----|-----|-----|
 * 0     5     10    15    20    25    30    35
 *
 *
 * Buffering ahead, however, will "wrap around" the timeline if the given
 * amount of time to buffer is truncated. Consider the diagram below:
 *
 *                              Window
 * +*----->                  [----------]-----*-+
 * ||-----|-----|-----|-----|-----|-----|-----| |
 * |0     5     10    15    20    25    30    35|
 * +--------------------------------------------+
 *
 * The window ends at 30 seconds and there's a buffer-ahead time of 10 seconds.
 * Because there's only 5 seconds between the end of the window and the
 * player's end bound, 5 seconds of buffer-ahead time would have otherwise been
 * truncated. Those remaining 5 seconds essentially "wrap around" the player
 * bounds and are used to buffer more chunks from the start of the player
 * bounds onwards.
 *
 * This is desirable since a user can replay a log once they reach the end.
 * Though the chunks are chronologically prior to the current window, they are
 * logically the next chunks the user would see. So it may be more accurate to
 * think of buffer-ahead chunks as "the chunks the user will likely need
 * next" and buffer-behind chunks as "the chunks the user likely just saw."
 *
 * There is the possibility of overlaps between chunk categories using this
 * buffering strategy. The buffer-ahead time could wrap around and overlap with
 * the buffer-behind time. The buffer-ahead time could even wrap around and
 * overlap the window's time range if the log is small enough and the
 * buffer-ahead time large enough.
 *
 * Additionally, because chunk boundaries are always multiples of the chunk
 * size {@link calculateRangeChunks (see `calculateRangeChunks`)}, a chunk
 * could be calculated to belong to multiple categories if the window bounds
 * aren't lined up with chunk boundaries.
 *
 * As a chunk can only belong to a single category, in these cases a chunk
 * will belong to the highest-priority category in which it's found.
 * For example:
 *
 *                         Window     BA
 *                      [----------]------>
 * |-----|-----|-----|-----|-----|-----|-----|
 * 0     5     10    15    20    25    30    35
 *
 * The chunk [25, 30) would be calculated to belong to both the required and
 * buffer-ahead chunks. However, since the required chunks have a higher
 * priority than buffer-ahead chunks, [25, 30) will **only** belong to the
 * required chunks.
 */
export function calculateWindowChunks({
  chunkSize,
  bufferAhead,
  bufferBehind,
  window,
  playerBounds,
}: CalculateWindowChunksParams): WindowChunks {
  if (window === undefined || playerBounds === undefined) {
    return {
      required: [],
      bufferAhead: [],
      bufferBehind: [],
    };
  }

  const requiredChunks = calculateRangeChunks(window, chunkSize);

  const bufferAheadEndTime = window.endTime + bufferAhead;
  const bufferAheadRange: TimeRange = {
    startTime: window.endTime,
    endTime: bigintMin(playerBounds.endTime, bufferAheadEndTime),
  };
  const bufferAheadChunks = calculateRangeChunks(bufferAheadRange, chunkSize);

  const wasBufferAheadTruncated = bufferAheadEndTime > playerBounds.endTime;
  if (wasBufferAheadTruncated) {
    const truncatedAmount = bufferAheadEndTime - playerBounds.endTime;
    const wrappedBufferAheadRange: TimeRange = {
      startTime: playerBounds.startTime,
      endTime: bigintMin(
        window.startTime,
        playerBounds.startTime + truncatedAmount,
      ),
    };
    bufferAheadChunks.push(
      ...calculateRangeChunks(wrappedBufferAheadRange, chunkSize),
    );
  }

  // Remove chunks from the buffer-ahead chunks that are also in the
  // required chunks
  pullAllWith(bufferAheadChunks, requiredChunks, isEqual);

  const bufferBehindStartTime = window.startTime - bufferBehind;
  const bufferBehindRange: TimeRange = {
    startTime: bigintMax(playerBounds.startTime, bufferBehindStartTime),
    endTime: window.startTime,
  };
  const bufferBehindChunks = calculateRangeChunks(bufferBehindRange, chunkSize);

  // Remove chunks from the buffer-behind chunks that are also in the
  // required or buffer-ahead chunks
  pullAllWith(bufferBehindChunks, requiredChunks, isEqual);
  pullAllWith(bufferBehindChunks, bufferAheadChunks, isEqual);

  return {
    required: requiredChunks,
    bufferAhead: bufferAheadChunks,
    bufferBehind: bufferBehindChunks,
  };
}

/**
 * Calculate the minimum sequence of chunks to fetch for the given time range.
 *
 * A "chunk" is a time range within which a single record list request should
 * be made for a given topic's records. A chunk has the following properties:
 * - Its endpoints are represented as UTC timestamps in nanoseconds.
 * - Its start time is inclusive while its end time is exclusive.
 * - Its size is the distance between its start and end points
 *
 * A topic's chunks collectively have the following properties:
 * - They all have the same size
 * - They do not overlap
 * - There are no timestamps in the player's bounds not represented by a chunk
 * - A given record can only belong to a single chunk. A record belongs to a
 *   chunk if and only if its timestamp is within the chunk's bounds.
 *
 * The following timeline visualizes a topic's chunks:
 *
 *       [-----)
 * |-----|-----|-----|-----|-----|-----|-----|
 * 0     5     10    15    20    25    30    35
 *
 * Each chunk has a size of 5 seconds. A single chunk is adjacent to but not
 * overlapping its neighbors. The second chunk in the topic - defined by the
 * interval [5, 10) - would contain any records whose timestamp `t` satisfies
 * the relationship `5 <= t < 10`.
 *
 * The most important property of all chunks is that their bounds are calculated
 * deterministically. A chunk's bounds are always a multiple of the chunk size,
 * so given an arbitrary timestamp, calculating the chunk containing it just
 * means flooring that timestamp to the nearest multiple of the chunk size.
 *
 * To effectively fetch and cache a topic's records, it's critical a set of
 * records can be deterministically identified. The chunking algorithm solves
 * this. Given an arbitrary time range, this utility calculates the sequence
 * of chunks which fully contain it. Consider the diagram above, this time with
 * a time range defined by the interval [13, 23]:
 *
 *                  Time Range
 *                 [----------]
 * |-----|-----|-----|-----|-----|-----|-----|
 * 0     5     10    15    20    25    30    35
 *
 * The range overlaps with 3 chunks, starting at chunk [10, 15) and ending
 * with chunk [20, 25). Consequently, to display all records within the range
 * would mean fetching those chunks. However, once a chunk's records are
 * fetched, they can be cached using the chunk's bounds as an identifier since
 * those bounds are always deterministically calculated.
 */
function calculateRangeChunks(
  timeRange: TimeRange,
  chunkSize: bigint,
): TimeRange[] {
  // To support stable cache entries, chunks need to be deterministically
  // calculated regardless of the range from which the chunks are being
  // calculated. In practice, this means chunk boundaries must always be a
  // multiple of the chunk size.
  const earliestChunkStartTime = (timeRange.startTime / chunkSize) * chunkSize;

  // Calculate the minimum number of sequential chunks needed to fully contain
  // the time range
  let numChunks = Number(
    (timeRange.endTime - earliestChunkStartTime) / chunkSize,
  );
  if ((timeRange.endTime - earliestChunkStartTime) % chunkSize > 0) {
    numChunks++;
  }

  return range(numChunks).map((chunkIndex) => {
    const chunkOffset = chunkSize * BigInt(chunkIndex);
    const chunkStartBound = earliestChunkStartTime + chunkOffset;

    return {
      startTime: chunkStartBound,
      endTime: chunkStartBound + chunkSize,
    };
  });
}
