import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useIngestionParts } from "~/domain/datastores";
import {
  DataStoreLink,
  makeIngestionPartLocation,
  makeNewIngestionPartLocation,
  useIngestionParams,
} from "~/paths";
import type { IngestionPart } from "~/services/datastore";
import { listIngestionPartsSchema } from "../schemas";
import { IngestionPartFilters } from "./IngestionPartFilters";

const COLUMNS: ReadonlyArray<Column<IngestionPart>> = [
  {
    header: "ID",
    renderCell(ingestionPart) {
      return (
        <TableCell>
          <Link
            component={DataStoreLink}
            to={makeIngestionPartLocation({
              ingestionId: ingestionPart.ingestionId,
              ingestionPartId: ingestionPart.id,
            })}
          >
            <Typography>{ingestionPart.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "sequence",
    dataType: "number",
    isSortable: true,
  },
  {
    accessor: "source",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "state",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "progress",
    dataType: "percent",
    isSortable: true,
  },
  {
    accessor: "transitionedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "workflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
    defaultHidden: true,
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listIngestionPartsSchema);

export const IngestionPartTable = withSearchValidationErrorBoundary(
  function IngestionPartTable() {
    const { ingestionId } = useIngestionParams();

    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useIngestionParts(
      ingestionId,
      prepareListRequest(request),
      {
        keepPreviousData: true,
        cacheTime: 0,
      },
    );

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="ingestion part"
            resourceCreateLocation={makeNewIngestionPartLocation({
              ingestionId,
            })}
            getRowKey={(ingestionPart) => ingestionPart.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <IngestionPartFilters
                values={filterValues}
                setValues={setRequest}
              />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
