import { Amplify } from "aws-amplify";
import { z } from "zod";
import { invariant } from "~/lib/invariant";
import type { DataStore } from "~/services/dsm";

function getEnvVar(varName: string): string {
  const name = `REACT_APP_${varName}`;
  const value = process.env[name];

  invariant(
    value !== undefined,
    `Env var with name "${name}" was expected but not found in environment`,
  );

  return value;
}

const configurationSchema = z.object({
  mapName: z.string(),
  mapIdentityPoolId: z.string(),
  apiEndpoint: z
    .string()
    .url()
    .transform((value, ctx) => {
      const url = new URL(value);

      if (url.protocol !== "https:") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Only HTTPS API endpoints are supported",
          fatal: true,
        });

        return z.NEVER;
      }

      // A URL object's pathname will always start with "/" even if the string
      // it was parsed from didn't have any path
      if (url.pathname !== "/" && url.pathname.endsWith("/")) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "API endpoints with a path segment cannot end with '/'",
          fatal: true,
        });

        return z.NEVER;
      }

      if (url.search !== "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "API endpoints cannot have query parameters",
          fatal: true,
        });

        return z.NEVER;
      }

      if (url.hash !== "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "API endpoints cannot have a URL fragment (#)",
          fatal: true,
        });

        return z.NEVER;
      }

      // A pathname of "/" is effectively the same as no path so the origin is
      // sufficient in that case. It's important for this string to not end
      // with "/" as downstream consumers make that assumption.
      return url.pathname === "/" ? url.origin : url.href;
    })
    .default(window.location.origin),
  userPoolRegion: z.string(),
  userPoolId: z.string(),
  userPoolWebClientId: z.string(),
  oAuthDomain: z.string(),
  oAuthScopes: z.string().array(),
});

export type Configuration = z.infer<typeof configurationSchema>;

const CONFIG_JSON_URL = `${process.env.PUBLIC_URL}/config.json`;

async function fetchRemoteConfig() {
  const response = await fetch(CONFIG_JSON_URL);

  if (!response.ok) {
    console.error(
      "Unable to load required application config",
      response,
      "Is config.json available?",
    );

    throw new Error(
      "Error fetching application configuration. See console for details",
    );
  }

  return response.json();
}

let STUDIO_BASE_HREF: string;
if (process.env.PUBLIC_URL === "") {
  STUDIO_BASE_HREF = window.location.origin;
} else {
  STUDIO_BASE_HREF = new URL(process.env.PUBLIC_URL, window.location.origin)
    .href;
}

function createStaticConfig(): unknown {
  const MAP_NAME = getEnvVar("MAP_NAME");
  const MAP_IDENTITY_POOL_ID = getEnvVar("MAP_IDENTITY_POOL_ID");
  const API_ENDPOINT = getEnvVar("API_ENDPOINT");
  const USER_POOL_REGION = getEnvVar("USER_POOL_REGION");
  const USER_POOL_ID = getEnvVar("USER_POOL_ID");
  const USER_POOL_WEB_CLIENT_ID = getEnvVar("USER_POOL_WEB_CLIENT_ID");
  const OAUTH_DOMAIN = getEnvVar("OAUTH_DOMAIN");
  const OAUTH_SCOPES = getEnvVar("OAUTH_SCOPES").split(",");

  return {
    mapName: MAP_NAME,
    mapIdentityPoolId: MAP_IDENTITY_POOL_ID,
    apiEndpoint: API_ENDPOINT,
    userPoolRegion: USER_POOL_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    oAuthDomain: OAUTH_DOMAIN,
    oAuthScopes: OAUTH_SCOPES,
  };
}

function getMapIdentityPoolRegion() {
  const [mapIdentityPoolRegion] = getAppConfig().mapIdentityPoolId.split(":");
  return mapIdentityPoolRegion;
}

let _config: Configuration | undefined = undefined;

// TODO: Can use top-level await with webpack now so this roundabout way of
//       initializing the config can be removed
export async function initAppConfig(): Promise<void> {
  try {
    _config = configurationSchema.parse(
      await (process.env["REACT_APP_USE_DYNAMIC_CONFIGURATION"] === "true"
        ? fetchRemoteConfig()
        : createStaticConfig()),
    );
  } catch (e) {
    throw new Error(
      "Invalid configuration for Studio. See console for details",
      { cause: e },
    );
  }
}

export function getAppConfig(): Configuration {
  invariant(_config !== undefined, "Configuration not initialized yet");

  return _config;
}

export function configureAuth(): void {
  const config = getAppConfig();

  const mapIdentityPoolRegion = getMapIdentityPoolRegion();

  Amplify.configure({
    Auth: {
      identityPoolRegion: mapIdentityPoolRegion,
      identityPoolId: config.mapIdentityPoolId,
      region: config.userPoolRegion,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolWebClientId,
      oauth: {
        domain: config.oAuthDomain,
        scope: config.oAuthScopes,
        redirectSignIn: STUDIO_BASE_HREF,
        redirectSignOut: STUDIO_BASE_HREF,
        responseType: "code",
      },
    },
  });
}

export function configureGeo(): void {
  const config = getAppConfig();

  const mapIdentityPoolRegion = getMapIdentityPoolRegion();

  Amplify.configure({
    geo: {
      AmazonLocationService: {
        maps: {
          items: {
            [config.mapName]: {
              style: "RasterEsriImagery",
            },
          },
          default: config.mapName,
        },
        region: mapIdentityPoolRegion,
      },
    },
  });
}

export function getDsmUrl(): string {
  return `${getAppConfig().apiEndpoint}/apps/dsm`;
}

function getLqsUrl(): string {
  return `${getAppConfig().apiEndpoint}/apps/lqs`;
}

export function getLqsRestApiDocsUrl(): string {
  return `${getLqsUrl()}/redoc`;
}

export function getDataStoreUrl(dataStore: DataStore): string {
  return `${getLqsUrl()}/${dataStore.id}`;
}
