import { Settings } from "@mui/icons-material";
import { GlobalNavigation } from "~/components/GlobalNavigation";
import { Header } from "~/components/Header";
import { Helmet } from "~/components/Helmet";
import {
  Layout,
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "~/components/Layout";
import { SettingsDrawer } from "~/components/SettingsDrawer";
import { LogTable } from "./components/LogTable";
import { Map } from "./components/Map";
import Tabs from "./components/Tabs";
import Thumbnail from "./components/Thumbnail";

export function LogsPage() {
  return (
    <>
      <Helmet>
        <title>Search Logs</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title="Search Logs"
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <Tabs thumbnails={<Thumbnail />} map={<Map />} table={<LogTable />} />
        </Layout>
      </LayoutStateProvider>
    </>
  );
}
