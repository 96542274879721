import React from "react";
import {
  Album,
  CloudDownload,
  CloudUpload,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { FileCloud } from "mdi-material-ui";
import { GlobalNavigation } from "~/components/GlobalNavigation";
import { Header } from "~/components/Header";
import { Helmet } from "~/components/Helmet";
import {
  Layout,
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "~/components/Layout";
import { LogQSLink } from "~/components/LogQSLink";
import { SettingsDrawer } from "~/components/SettingsDrawer";
import * as paths from "~/paths";
import { DataStoreLink } from "~/paths";
import type { DataStorePathGenerator } from "~/paths";

export function HomePage() {
  const theme = useTheme();

  function renderCard(
    icon: React.ReactNode,
    title: string,
    text: string,
    to: DataStorePathGenerator,
  ) {
    return (
      <Grid item xs={12} md={6} sx={{ mx: "auto" }}>
        <Card
          sx={{
            height: 1,
            width: 1,
            "& .MuiCardContent-root": {
              height: 1,
              display: "flex",
            },
            "& .MuiSvgIcon-root": {
              mr: 2,
              ...theme.typography.h1,
            },
          }}
        >
          <CardContent>
            {icon}
            <div>
              <Typography
                component={DataStoreLink}
                to={to}
                variant="h4"
                color="primary"
                gutterBottom
                display="block"
              >
                {title}
              </Typography>
              <Typography variant="h6" component="p">
                {text}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title="Home"
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <Container fixed>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h2" component="h1" align="center">
                    Welcome to Studio!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" component="p" align="center">
                    What would you like to do?
                  </Typography>
                </Grid>
                {renderCard(
                  <FileCloud />,
                  "Search Logs",
                  "Search for logs in your DataStore to find one worth exploring",
                  paths.makeLogsLocation(),
                )}
                {renderCard(
                  <CloudUpload />,
                  "Upload a Log",
                  "Upload a ROSBAG from your device",
                  paths.makeUploadLocation(),
                )}
                {renderCard(
                  <Album />,
                  "Play Records",
                  "Visually explore your logs and extract subsets of data for local analysis",
                  paths.makePlayerLocation(),
                )}
                {renderCard(
                  <CloudDownload />,
                  "Find Digestions",
                  "Find curated ROSBAGs of your DataStore's logs",
                  paths.makeDigestionsLocation(),
                )}
                <Grid item xs={12}>
                  <Divider sx={{ width: 50, m: "auto" }} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    "& .LogQSLink-image": {
                      width: 150,
                      height: "auto",
                    },
                  }}
                >
                  <LogQSLink />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Layout>
      </LayoutStateProvider>
    </>
  );
}
