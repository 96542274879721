import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { ApiKeyCreateForm } from "./components/ApiKeyCreateForm";

export function NewApiKeyPage() {
  return (
    <Page title="New API Key">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <ApiKeyCreateForm />
      </Container>
    </Page>
  );
}
