import prettyMilliseconds from "pretty-ms";
import { Time } from "~/components/Time";
import {
  fromUnixTime,
  nanosecondsToMilliseconds,
  nanosecondsToSeconds,
} from "~/lib/dates";
import type { Log } from "~/services/datastore";

export function renderRecorded(log: Log) {
  return log.startTime === null ? (
    "-"
  ) : (
    <Time date={fromUnixTime(nanosecondsToSeconds(log.startTime))} />
  );
}

export function renderDuration(log: Log) {
  const { startTime, endTime } = log;

  return startTime === null || endTime === null
    ? "-"
    : prettyMilliseconds(nanosecondsToMilliseconds(endTime - startTime), {
        secondsDecimalDigits: 0,
      });
}

export type LogMarker = {
  id: Log["id"];
  name: Log["name"];
  coordinates: {
    longitude: number;
    latitude: number;
  };
};

export function calculateLogMarkers(logs: Log[]): LogMarker[] {
  return logs
    .map((log): LogMarker | null => {
      if (
        log.context === null ||
        !("sample_coordinates" in log.context) ||
        log.context.sample_coordinates == null
      ) {
        return null;
      }

      const { name, longitude, latitude } = log.context
        .sample_coordinates as any;

      return {
        id: log.id,
        name: name ?? log.name,
        coordinates: {
          longitude,
          latitude,
        },
      };
    })
    .filter((value): value is LogMarker => value !== null);
}
