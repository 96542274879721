import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { GroupTable } from "./components/GroupTable";

export function GroupsPage() {
  return (
    <Page title="Search Groups">
      <FullWidthContainer>
        <GroupTable />
      </FullWidthContainer>
    </Page>
  );
}
