import React, { useState } from "react";
import { InsertChart } from "@mui/icons-material";
import { Box, ToggleButton, Tooltip, Typography } from "@mui/material";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import useResizeObserver from "use-resize-observer";
import { Error } from "~/components/Error";
import { Loading } from "~/components/Loading";
import {
  millisecondsToNanoseconds,
  nanosecondsToMilliseconds,
  secondsToNanoseconds,
  toNanoseconds,
} from "~/lib/dates";
import { get, round } from "~/lib/std";
import type { Topic } from "~/services/datastore";
import {
  useRecordWindowQuery,
  useTopicOverview,
  useUpdatePanelBuffering,
} from "../../../hooks";
import { timeRangeToDomain } from "../../../hooks/utils";
import type { InitializedPanelNode } from "../../../panels";
import {
  useFormatPlaybackTimestamp,
  usePlaybackSource,
} from "../../../playback";
import type { TimeRange } from "../../../types";
import { PanelControls, TagsControlButton } from "../../PanelControls";
import PanelLayout from "../../PanelLayout";
import PlaceholderOverlay from "../../PlaceholderOverlay";
import { calculateWindowTicks } from "../../utils";
import lookupFieldUnit from "./lookupFieldUnit";

interface ChartVisualizationProps {
  panel: InitializedPanelNode;
  topic: Topic;
}

const strokeStyles = ["#e6194B", "#4363d8", "#3cb44b"];

export default function ChartVisualization({
  panel,
  topic,
}: ChartVisualizationProps) {
  const { ref, height, width } = useResizeObserver();

  const [showOverview, setShowOverview] = useState(false);

  const playbackSource = usePlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const windowQuery = useRecordWindowQuery({
    topicId: topic.id,
    bufferBehind: secondsToNanoseconds(30),
    bufferAhead: toNanoseconds({
      minutes: 1,
      seconds: 30,
    }),
    windowSize: secondsToNanoseconds(30),
    chunkSize: secondsToNanoseconds(15),
  });

  const recordsOverview = useTopicOverview({
    topicId: topic.id,
    enabled: showOverview,
    playerBounds: playbackSource.bounds,
  });

  const isWindowBuffering = windowQuery.status === "loading";

  // TODO: Properly handle this when visual bugs in overview are fixed
  const isOverviewBuffering = false;
  // const isOverviewBuffering =
  //   showOverview &&
  //   (recordsOverview.status === "idle" || recordsOverview.status === "loading");

  useUpdatePanelBuffering(isWindowBuffering || isOverviewBuffering);

  const { fields } = panel;

  let content;
  if (windowQuery.data === undefined || playbackSource.isLoading) {
    content = <Loading type="circular" />;
  } else if (windowQuery.status === "error") {
    // TODO: Do something else if record window has error and placeholder data?
    content = (
      <Error>
        <Typography variant="h5" component="p" color="error">
          An error occurred. Can't get chart data
        </Typography>
      </Error>
    );
  } else {
    const qualifiedFields = fields.map((field) => `queryData.${field}`);
    const [windowRangeLow, windowRangeHigh] = findExtremes(
      qualifiedFields,
      windowQuery.data,
    );

    let overview: React.ReactNode = undefined;
    if (showOverview && height !== undefined) {
      const overviewHeight = Math.round(height * 0.2);

      if (recordsOverview.status === "loading") {
        overview = (
          <Box width="100%" height={overviewHeight}>
            <Loading type="circular" />
          </Box>
        );
      } else if (recordsOverview.status === "error") {
        overview = (
          <Box width="100%" height={overviewHeight}>
            <Error>
              <Typography variant="h5" component="p" color="error">
                An error occurred. Unable to show overview chart
              </Typography>
            </Error>
          </Box>
        );
      } else {
        overview = (
          <LineChart
            height={overviewHeight}
            width={width}
            margin={{ left: 50, right: 50 }}
            data={recordsOverview.data}
          >
            <CartesianGrid horizontal={false} strokeDasharray="6 3" />
            <XAxis
              dataKey={(record) => nanosecondsToMilliseconds(record.timestamp)}
              type="number"
              ticks={calculateOverviewTicks(playbackSource.bounds, 5)}
              tickLine={false}
              tickFormatter={(tick) =>
                formatPlaybackTimestamp(millisecondsToNanoseconds(tick))
              }
              domain={timeRangeToDomain(playbackSource.bounds)}
            />
            <YAxis axisLine={false} tick={false} />
            <ReferenceArea
              x1={nanosecondsToMilliseconds(windowQuery.recordWindow.startTime)}
              x2={nanosecondsToMilliseconds(windowQuery.recordWindow.endTime)}
            />
            {qualifiedFields.map((field, index) => (
              <Line
                key={field}
                dot={false}
                isAnimationActive={false}
                dataKey={field}
                stroke={strokeStyles[index]}
              />
            ))}
          </LineChart>
        );
      }
    }

    content = height !== undefined && width !== undefined && (
      <Box height={1} width={1} overflow="hidden" position="relative">
        <LineChart
          height={showOverview ? Math.round(height * 0.8) : height}
          width={width}
          margin={{ top: 5, bottom: 5, left: 50, right: 50 }}
          data={windowQuery.data}
        >
          <CartesianGrid strokeDasharray="6 3" />
          <XAxis
            dataKey={(record) => nanosecondsToMilliseconds(record.timestamp)}
            type="number"
            ticks={calculateWindowTicks(
              windowQuery.recordWindow,
              secondsToNanoseconds(5),
            )}
            tickLine={false}
            tickFormatter={(tick) =>
              formatPlaybackTimestamp(millisecondsToNanoseconds(tick))
            }
            domain={timeRangeToDomain(windowQuery.recordWindow)}
          />
          <YAxis
            scale="linear"
            tickLine={false}
            tickFormatter={(value) => String(round(value, 3))}
            domain={[windowRangeLow, windowRangeHigh]}
            padding={{ top: 25, bottom: 25 }}
          />
          <Legend verticalAlign="top" />
          <ReferenceLine
            x={nanosecondsToMilliseconds(playbackSource.timestamp)}
          />
          {qualifiedFields.map((field, index) => {
            const originalField = fields[index];
            const fieldUnit = lookupFieldUnit(topic.typeName, originalField);
            const name =
              fieldUnit === null
                ? originalField
                : `${originalField} (${fieldUnit})`;

            return (
              <Line
                key={field}
                isAnimationActive={false}
                name={name}
                dataKey={field}
                stroke={strokeStyles[index]}
              />
            );
          })}
        </LineChart>
        {overview}
        <Tooltip title="Toggle overview chart">
          <ToggleButton
            sx={{
              position: "absolute",
              left: (theme) => theme.spacing(1),
              bottom: (theme) => theme.spacing(1),
            }}
            aria-label="Toggle overview chart"
            value={true}
            selected={showOverview}
            onChange={() => setShowOverview(!showOverview)}
          >
            <InsertChart />
          </ToggleButton>
        </Tooltip>
        <PlaceholderOverlay windowQuery={windowQuery} />
      </Box>
    );
  }

  return (
    <PanelLayout
      controls={
        <PanelControls>
          <TagsControlButton topic={topic} />
        </PanelControls>
      }
      contentRef={ref}
    >
      {content}
    </PanelLayout>
  );
}

function findExtremes(
  fields: InitializedPanelNode["fields"],
  windowData: any[],
): [number, number] {
  // TODO: Consider smarter ways of handling empty windows than
  //  setting the range to [0, 0]
  if (windowData.length === 0) {
    return [0, 0];
  }

  let min = Infinity;
  let max = -Infinity;
  windowData.forEach((datum) => {
    const fieldVals = fields.map((field) => get(datum, field));
    min = Math.min(min, ...fieldVals);
    max = Math.max(max, ...fieldVals);
  });

  return [min, max];
}

function calculateOverviewTicks(
  playerBounds: TimeRange,
  tickCount: number,
): number[] {
  const durationMs = nanosecondsToMilliseconds(
    playerBounds.endTime - playerBounds.startTime,
  );
  const intervalMs = durationMs / (tickCount + 1);

  const ticks: number[] = [];
  for (let tickIndex = 1; tickIndex <= tickCount; tickIndex++) {
    ticks.push(
      Math.floor(
        nanosecondsToMilliseconds(playerBounds.startTime) +
          tickIndex * intervalMs,
      ),
    );
  }

  return ticks;
}
