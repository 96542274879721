import { Button, Grid } from "@mui/material";
import { FuzzyField, TextField, useStudioForm } from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListLabelsFormValues } from "../schemas";
import { listLabelsSchema } from "../schemas";

export interface LabelFiltersProps {
  values: ListLabelsFormValues;
  setValues: (newValues: ListLabelsFormValues) => void;
}

export function LabelFilters({ values, setValues }: LabelFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listLabelsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="value" fuzzyName="valueLike" />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="noteLike" label="Note" />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
