import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  FuzzyField,
  LogSelect,
  NumberField,
  processStateOptions,
  SelectField,
  TextField,
  useStudioForm,
  WorkflowSelect,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListDigestionsFormValues } from "../schemas";
import { listDigestionsSchema } from "../schemas";

export interface DigestionFiltersProps {
  values: ListDigestionsFormValues;
  setValues: (newValues: ListDigestionsFormValues) => void;
}

export function DigestionFilters({ values, setValues }: DigestionFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listDigestionsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      </Grid>
      <Grid item xs={3}>
        <LogSelect control={control} name="logId" />
      </Grid>
      <Grid item xs={3}>
        <WorkflowSelect control={control} name="workflowId" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="workflowIdNull" />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          control={control}
          name="state"
          options={processStateOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="noteLike" label="Note" />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          <NumberField control={control} name="progressGte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="progressLte" />
        </Grid>
        <Grid item xs={3}>
          <BooleanField control={control} name="progressNull" />
        </Grid>
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
