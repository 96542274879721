import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useTags } from "~/domain/datastores";
import {
  DataStoreLink,
  makeNewTagLocation,
  makeTagLocation,
  useLogParams,
} from "~/paths";
import type { Tag } from "~/services/datastore";
import { listTagsSchema } from "../schemas";
import { TagFilters } from "./TagFilters";

const COLUMNS: ReadonlyArray<Column<Tag>> = [
  {
    header: "ID",
    renderCell(tag) {
      return (
        <TableCell>
          <Link
            component={DataStoreLink}
            to={makeTagLocation({ logId: tag.logId, tagId: tag.id })}
          >
            <Typography variant="body2">{tag.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "labelId",
    dataType: "foreign-key",
    resourceType: "label",
  },
  {
    accessor: "topicId",
    dataType: "foreign-key",
    resourceType: "topic",
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listTagsSchema);

export const TagTable = withSearchValidationErrorBoundary(function TagTable() {
  const { logId } = useLogParams();

  const [request, setRequest] = useSearchRequest(requestSchema);

  const searchQuery = useTags(logId, prepareListRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <Card>
      <CardContent>
        <ResourceTable
          resourceName="tag"
          resourceCreateLocation={makeNewTagLocation({ logId })}
          getRowKey={(tag) => tag.id}
          columns={COLUMNS}
          searchQuery={searchQuery}
          tableModel={request}
          onTableModelChange={setRequest}
          filterSection={
            <TagFilters values={filterValues} setValues={setRequest} />
          }
          activeFilterCount={getActiveFiltersCount(filterValues)}
        />
      </CardContent>
    </Card>
  );
});
