import type { UseQueryOptions } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import { useDataStoreClients } from "~/domain/datastores";
import { convertBase64ImageToBlob } from "~/domain/network";
import {
  millisecondsToNanoseconds,
  nanosecondsToSeconds,
  secondsToMilliseconds,
  secondsToNanoseconds,
  toNanoseconds,
} from "~/lib/dates";
import type { ListRecordsRequest, Record, Topic } from "~/services/datastore";
import type { SimpleQueryResult } from "~/types";
import { combineQueries } from "~/utils";
import { useRecordWindow } from "../../../hooks";
import { calculateWindowChunks } from "../../../hooks/utils";
import {
  useCalculateFrameTimestamp,
  usePlaybackSettings,
  usePlaybackSource,
} from "../../../playback";
import { playerRecordKeys, useRecordsQueries } from "../../../queries";
import type { TimeRange } from "../../../types";
import { SampleFrequency, Timestep } from "../../../types";
import type { ImageSegmentations } from "./segmentations";
import { normalizeCocoRecords } from "./segmentations";

type UseImageQueryRequest = StrictOmit<ListRecordsRequest, "topicId">;

interface ImageRecordSuccessfulFetchResult {
  status: "success";
  timestamp: bigint;
  image: Blob;
}

interface ImageRecordErrorFetchResult {
  status: "error";
  timestamp: bigint;
}

type ImageRecordFetchResult =
  | ImageRecordSuccessfulFetchResult
  | ImageRecordErrorFetchResult;

export interface ImageRecord {
  id: string;
  timestamp: bigint;
  isStale: boolean;
  image: Blob;
  segmentations: ImageSegmentations | null;
}

export type ImageRecordQueryResult =
  | { status: "loading"; data?: undefined }
  | { status: "success"; data?: ImageRecord | undefined }
  | { status: "error"; data?: undefined }
  | { status: "error:image"; data?: undefined };

const optionsMap = new Map([
  [
    Timestep.Second,
    {
      sampleFrequency: SampleFrequency.Second,
      // 1 chunk behind
      bufferBehind: secondsToNanoseconds(30),
      // 7 chunks ahead
      bufferAhead: toNanoseconds({ minutes: 3, seconds: 30 }),
      windowSize: secondsToNanoseconds(30),
      // One chunk corresponds to a max of 30 images
      chunkSize: secondsToNanoseconds(30),
      freshDuration: secondsToNanoseconds(2),
    },
  ],
  [
    Timestep.Decisecond,
    {
      sampleFrequency: SampleFrequency.Decisecond,
      // 1 chunk behind
      bufferBehind: secondsToNanoseconds(3),
      // 7 chunks ahead
      bufferAhead: secondsToNanoseconds(21),
      windowSize: secondsToNanoseconds(3),
      // One chunk corresponds to a max of 30 images
      chunkSize: secondsToNanoseconds(3),
      freshDuration: millisecondsToNanoseconds(200),
    },
  ],
]);

export function useImageRecord({
  topic,
  segmentationTopicId,
}: {
  topic: Topic;
  segmentationTopicId: Topic["id"] | undefined;
}): ImageRecordQueryResult {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const calculateFrameTimestamp = useCalculateFrameTimestamp();

  const {
    windowSize,
    chunkSize,
    sampleFrequency,
    bufferAhead,
    bufferBehind,
    freshDuration,
  } = optionsMap.get(playbackSettings.timestep)!;

  const recordWindow = useRecordWindow(windowSize);

  const windowChunks = calculateWindowChunks({
    chunkSize,
    bufferAhead,
    bufferBehind,
    window: recordWindow,
    playerBounds: playbackSource.bounds,
  });

  function createRequestForChunks(chunks: ReadonlyArray<TimeRange>) {
    return {
      topicId: topic.id,
      segmentationTopicId,
      requests: chunks.map(
        (chunk): UseImageQueryRequest => ({
          limit: nanosecondsToSeconds(chunkSize) * sampleFrequency,
          timestampGte: chunk.startTime,
          timestampLt: chunk.endTime,
          frequency: sampleFrequency,
        }),
      ),
    };
  }

  const query = useImagesQueries(createRequestForChunks(windowChunks.required));

  useImagesQueries(createRequestForChunks(windowChunks.bufferAhead));
  useImagesQueries(createRequestForChunks(windowChunks.bufferBehind));

  if (playbackSource.isLoading) {
    return { status: "loading" };
  } else if (query.status !== "success") {
    return query;
  } else {
    const playbackFrameTimestamp = calculateFrameTimestamp(
      playbackSource.timestamp,
    );

    const currentResult = query.data.images.findLast(
      (result) =>
        calculateFrameTimestamp(result.timestamp) <= playbackFrameTimestamp,
    );

    if (currentResult?.status === "error") {
      // A fetch result was found whose image should be shown but this result
      // encountered an error while fetching
      return { status: "error:image" };
    } else if (currentResult === undefined) {
      // No image exists <= the playback time
      return { status: "success" };
    } else {
      const isStale =
        playbackFrameTimestamp -
          calculateFrameTimestamp(currentResult.timestamp) >
        freshDuration;

      const segmentationsRecord = query.data.segmentationsRecords.find(
        (segmentationsRecord) =>
          segmentationsRecord.timestamp === currentResult.timestamp,
      );

      // Used later on when checking if the image record should be kept in
      // state based on whether it's semantically equivalent to a stored
      // record. Only the segmentation topic's ID is needed here as its
      // timestamp is equivalent to the image's timestamp.
      const id = `${currentResult.timestamp}:${segmentationTopicId}`;

      return {
        status: "success",
        data: {
          id,
          timestamp: currentResult.timestamp,
          image: currentResult.image,
          segmentations:
            segmentationsRecord === undefined
              ? null
              : normalizeCocoRecords(segmentationsRecord),
          isStale,
        },
      };
    }
  }
}

function useImagesQueries({
  topicId,
  segmentationTopicId,
  requests,
}: {
  topicId: Topic["id"];
  segmentationTopicId: Topic["id"] | undefined;
  requests: ReadonlyArray<UseImageQueryRequest>;
}) {
  const { topicApi } = useDataStoreClients();

  const imagesQueries = useQueries({
    queries: requests.map(
      (request): UseQueryOptions<Array<ImageRecordFetchResult>> => {
        const imagesRequest: ListRecordsRequest = {
          ...request,
          topicId,
          includeAuxiliaryData: true,
        };

        return {
          queryKey: playerRecordKeys.images(imagesRequest),
          async queryFn({ signal }) {
            const recordsResponse = await topicApi.listRecords(imagesRequest, {
              signal,
            });

            return Promise.all(
              recordsResponse.data.map(async (recordObject) => {
                const base64EncodedImage = (recordObject.auxiliaryData as any)
                  ?.image;
                if (typeof base64EncodedImage !== "string") {
                  return {
                    status: "error",
                    timestamp: recordObject.timestamp,
                  };
                }

                // Studio's architecture expects to work with images as blobs.
                // As we transition to using base64-encoded strings to return
                // images, all that's needed is to "fetch" the base64-encoded
                // image using a data URL to get the equivalent blob.
                const image =
                  await convertBase64ImageToBlob(base64EncodedImage);

                if (image === null) {
                  return {
                    status: "error",
                    timestamp: recordObject.timestamp,
                  };
                }

                return {
                  status: "success",
                  timestamp: recordObject.timestamp,
                  image,
                };
              }),
            );
          },
          staleTime: Infinity,
          cacheTime: secondsToMilliseconds(20),
        };
      },
    ),
  });

  const combinedImageQuery = combineQueries({
    queries: imagesQueries,
    transform(results) {
      return results.flat();
    },
  });

  const segmentationQueries = useRecordsQueries({
    requests:
      segmentationTopicId == null
        ? []
        : requests.map((request) => ({
            ...request,
            topicId: segmentationTopicId,
          })),
    options: {
      cacheTime: secondsToMilliseconds(20),
    },
  });

  const combinedSegmentationQuery: SimpleQueryResult<Array<Record>> =
    segmentationTopicId == null
      ? // If `segmentationTopicId` is nullish then 0 requests would've been
        // passed to `useRecordsQueries` above whose results `combineQueries`
        // would interpret as a loading state. In this scenario though, there
        // will never be any queries to fetch so the combined query can be
        // vacuously considered successful.
        { status: "success", data: [] }
      : combineQueries({
          queries: segmentationQueries,
          transform(results) {
            return results.flatMap((result) => result.data);
          },
        });

  return combineQueries({
    queries: [combinedImageQuery, combinedSegmentationQuery],
    transform([images, segmentationsRecords]) {
      return { images, segmentationsRecords };
    },
  });
}
