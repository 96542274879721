import { useObjectStore } from "~/domain/datastores";
import { makeObjectStoreLocation } from "~/paths";
import type { ObjectStore } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface ObjectStoreInfoProps {
  objectStoreId: ObjectStore["id"];
}

export function ObjectStoreInfo({ objectStoreId }: ObjectStoreInfoProps) {
  const bucketNameQuery = useObjectStore(objectStoreId, {
    select(response) {
      return response.data.bucketName;
    },
  });

  return (
    <ResourceInfo
      name={bucketNameQuery.data}
      identifier={objectStoreId}
      to={makeObjectStoreLocation({ objectStoreId })}
    />
  );
}
