import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { TopicEditForm } from "./components/TopicEditForm";

export function EditTopicPage() {
  return (
    <Page title="Edit Topic">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <TopicEditForm />
      </Container>
    </Page>
  );
}
