/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DigestionTopic {
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  id: string;
  digestionId: string;
  topicId: string;
  startTime: bigint | null;
  endTime: bigint | null;
  frequency: number | null;
  queryDataFilter: object | null;
  contextFilter: object | null;
}

export function DigestionTopicFromJSON(json: any): DigestionTopic {
  return DigestionTopicFromJSONTyped(json);
}

export function DigestionTopicFromJSONTyped(json: any): DigestionTopic {
  if (json == null) {
    return json;
  }

  return {
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    id: json["id"],
    digestionId: json["digestion_id"],
    topicId: json["topic_id"],
    startTime: json["start_time"] === null ? null : BigInt(json["start_time"]),
    endTime: json["end_time"] === null ? null : BigInt(json["end_time"]),
    frequency: json["frequency"],
    queryDataFilter: json["query_data_filter"],
    contextFilter: json["context_filter"],
  };
}

export function DigestionTopicToJSON(
  value: DigestionTopic | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    id: value.id,
    digestion_id: value.digestionId,
    topic_id: value.topicId,
    start_time: value.startTime === null ? null : value.startTime.toString(),
    end_time: value.endTime === null ? null : value.endTime.toString(),
    frequency: value.frequency,
    query_data_filter: value.queryDataFilter,
    context_filter: value.contextFilter,
  };
}
