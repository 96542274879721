import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useDigestions } from "~/domain/datastores";
import {
  DataStoreLink,
  makeDigestionLocation,
  makeNewDigestionLocation,
} from "~/paths";
import type { Digestion } from "~/services/datastore";
import { listDigestionsSchema } from "../schemas";
import { DigestionFilters } from "./DigestionFilters";

const COLUMNS: ReadonlyArray<Column<Digestion>> = [
  {
    header: "Digestion",
    renderCell(digestion) {
      return (
        <TableCell>
          {digestion.name}
          <Link
            component={DataStoreLink}
            to={makeDigestionLocation({ digestionId: digestion.id })}
          >
            <Typography variant="body2">{digestion.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "logId",
    dataType: "foreign-key",
    resourceType: "log",
  },
  {
    accessor: "state",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "progress",
    dataType: "percent",
    isSortable: true,
  },
  {
    accessor: "transitionedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "workflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listDigestionsSchema);

export const DigestionTable = withSearchValidationErrorBoundary(
  function DigestionTable() {
    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useDigestions(prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="digestion"
            resourceCreateLocation={makeNewDigestionLocation()}
            getRowKey={(digestion) => digestion.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <DigestionFilters values={filterValues} setValues={setRequest} />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
