import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { TagEditForm } from "./components/TagEditForm";

export function EditTagPage() {
  return (
    <Page title="Edit Tag">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <TagEditForm />
      </Container>
    </Page>
  );
}
