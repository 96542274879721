import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useObjectStores } from "~/domain/datastores";
import {
  DataStoreLink,
  makeNewObjectStoreLocation,
  makeObjectStoreLocation,
} from "~/paths";
import type { ObjectStore } from "~/services/datastore";
import { listObjectStoresSchema } from "../schemas";
import { ObjectStoreFilters } from "./ObjectStoreFilters";

const COLUMNS: ReadonlyArray<Column<ObjectStore>> = [
  {
    header: "Bucket Name",
    sortKey: "bucketName",
    renderCell(objectStore) {
      return (
        <TableCell>
          {objectStore.bucketName}
          <Link
            component={DataStoreLink}
            to={makeObjectStoreLocation({ objectStoreId: objectStore.id })}
          >
            <Typography variant="body2">{objectStore.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "accessKeyId",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "regionName",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "endpointUrl",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listObjectStoresSchema);

export const ObjectStoreTable = withSearchValidationErrorBoundary(
  function ObjectStoreTable() {
    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useObjectStores(prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="object store"
            resourceCreateLocation={makeNewObjectStoreLocation()}
            getRowKey={(objectStore) => objectStore.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <ObjectStoreFilters
                values={filterValues}
                setValues={setRequest}
              />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
