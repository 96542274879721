import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { RoleCreateForm } from "./components/RoleCreateForm";

export function NewRolePage() {
  return (
    <Page title="New Role">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <RoleCreateForm />
      </Container>
    </Page>
  );
}
