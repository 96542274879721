import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  FuzzyField,
  processStateOptions,
  processTypeOptions,
  SelectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListWorkflowHooksFormValues } from "../schemas";
import { listWorkflowHooksSchema } from "../schemas";

export interface WorkflowHookFiltersProps {
  values: ListWorkflowHooksFormValues;
  setValues: (newValues: ListWorkflowHooksFormValues) => void;
}

export function WorkflowHookFilters({
  values,
  setValues,
}: WorkflowHookFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listWorkflowHooksSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      </Grid>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="uri" fuzzyName="uriLike" />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          control={control}
          name="triggerProcess"
          options={processTypeOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          control={control}
          name="triggerState"
          options={processStateOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="managed" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="disabled" />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="noteLike" label="Note" />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
