import { createObjectExplorer } from "~/components/ObjectExplorer";
import { useObjectStoreObjects } from "~/domain/datastores";
import {
  makeObjectStoreObjectLocation,
  makeObjectStoreObjectsLocation,
  useObjectStoreParams,
} from "~/paths";

export const ObjectStoreObjectsTable = createObjectExplorer({
  useIdentifier() {
    return useObjectStoreParams().objectStoreId;
  },
  useSearch: useObjectStoreObjects,
  generateDirectoryLocation(objectStoreId, directory) {
    return makeObjectStoreObjectsLocation({ objectStoreId }, { directory });
  },
  generateObjectLocation(objectStoreId, objectKey) {
    return makeObjectStoreObjectLocation({ objectStoreId, objectKey });
  },
});
