import React from "react";
import { LOGQS_ICON, LOGQS_LOGO, LOGQS_ORIGIN } from "~/links";

export interface LogQSLinkProps {
  icon?: boolean;
}

export function LogQSLink({ icon = false }: LogQSLinkProps) {
  return (
    <a className="LogQSLink-root" href={LOGQS_ORIGIN}>
      <img
        className="LogQSLink-image"
        src={icon ? LOGQS_ICON : LOGQS_LOGO}
        alt="LogQS Homepage"
      />
    </a>
  );
}
