import {
  LabelSelect,
  NewResourceForm,
  ObjectField,
  TextField,
  TimestampField,
  TopicSelect,
} from "~/components/Form";
import { useCreateTag } from "~/domain/datastores";
import { makeTagLocation, useLogParams } from "~/paths";
import { createTagSchema } from "../schemas";

export function TagCreateForm() {
  const { logId } = useLogParams();

  return (
    <NewResourceForm
      schema={createTagSchema}
      defaultValues={{
        labelId: null,
        topicId: null,
        note: null,
        startTime: null,
        endTime: null,
        context: null,
      }}
      resourceName="tag"
      mutation={useCreateTag(logId)}
      generateLocation={(response) =>
        makeTagLocation({ logId, tagId: response.data.id })
      }
    >
      {(control) => (
        <>
          <LabelSelect control={control} name="labelId" required />
          <TopicSelect control={control} name="topicId" logId={logId} />
          <TextField control={control} name="note" />
          <TimestampField control={control} name="startTime" />
          <TimestampField control={control} name="endTime" />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewResourceForm>
  );
}
