import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { WorkflowDetails } from "./components/WorkflowDetails";

export function WorkflowPage() {
  return (
    <Page title="Workflow Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <WorkflowDetails />
      </Container>
    </Page>
  );
}
