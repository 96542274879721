import { Button } from "@mui/material";
import { Card } from "~/components/Card";
import { ResourceFields } from "~/components/DetailsCards";
import { useObjectStoreObject } from "~/domain/datastores";
import { useObjectStoreObjectParams } from "~/paths";
import { selectData } from "~/utils";

export function ObjectStoreObjectDetails() {
  const { objectStoreId, objectKey } = useObjectStoreObjectParams();

  const query = useObjectStoreObject(objectStoreId, objectKey, {
    select: selectData,
  });

  const presignedUrl = query.data?.presignedUrl;

  return (
    <Card
      title="General"
      action={
        <Button
          variant="contained"
          {...(presignedUrl == null
            ? { disabled: true }
            : { href: presignedUrl })}
        >
          Download
        </Button>
      }
    >
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "key" },
          { dataType: "text", accessor: "etag" },
          { dataType: "bytes", accessor: "size" },
          { dataType: "text", accessor: "uploadState" },
          { dataType: "datetime", accessor: "lastModified" },
        ]}
      />
    </Card>
  );
}
