import type { ElementOf, StrictOmit } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  optionalBigInt,
  optionalNumber,
  optionalObject,
  optionalText,
  optionalUuid,
  requiredNumber,
  requiredProcessStateSchema,
  requiredText,
  schemaShapeForType,
  uuid,
} from "~/components/Form";
import {
  filterBigInt,
  filterBoolean,
  filterNumber,
  filterProcessState,
  filterText,
  filterUuid,
} from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  Digestion,
  DigestionCreateRequest,
  DigestionPartCreateRequest,
  DigestionPartUpdateRequest,
  DigestionTopic,
  DigestionTopicCreateRequest,
  DigestionTopicUpdateRequest,
  DigestionUpdateRequest,
  ListDigestionPartsRequest,
  ListDigestionsRequest,
  ListDigestionTopicsRequest,
} from "~/services/datastore";

export const listDigestionsSchema = z.object(
  schemaShapeForType<
    StrictOmit<
      ListDigestionsRequest,
      "workflowContextFilter" | "contextFilter" | "errorLike"
    >
  >()({
    name: filterText,
    nameLike: filterText,
    logId: filterUuid,
    workflowId: filterUuid,
    workflowIdNull: filterBoolean,
    noteLike: filterText,
    progressGte: filterNumber,
    progressLte: filterNumber,
    progressNull: filterBoolean,
    state: filterProcessState,
    ...auditFieldSchemas,
  }),
);

export type ListDigestionsFormValues = z.infer<typeof listDigestionsSchema>;

export const createDigestionSchema = z.object(
  schemaShapeForType<DigestionCreateRequest>()({
    name: optionalText,
    logId: uuid,
    state: requiredProcessStateSchema,
    workflowId: optionalUuid,
    workflowContext: optionalObject,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export type CreateDigestionFormValues = z.infer<typeof createDigestionSchema>;

export const editDigestionSchema = z.object(
  schemaShapeForType<DigestionUpdateRequest>()({
    name: requiredText,
    state: requiredProcessStateSchema,
    progress: optionalNumber,
    workflowId: optionalUuid,
    workflowContext: optionalObject,
    error: optionalObject,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export const EDITABLE_DIGESTION_FIELDS = editDigestionSchema.keyof().options;

export type EditDigestionFormValues = Pick<
  Digestion,
  ElementOf<typeof EDITABLE_DIGESTION_FIELDS>
>;

export const listDigestionTopicsSchema = z.object(
  schemaShapeForType<
    StrictOmit<ListDigestionTopicsRequest, "digestionId" | "topicId">
  >()({
    startTimeGte: filterBigInt,
    startTimeLte: filterBigInt,
    startTimeNull: filterBoolean,
    endTimeGte: filterBigInt,
    endTimeLte: filterBigInt,
    endTimeNull: filterBoolean,
    ...auditFieldSchemas,
  }),
);

export type ListDigestionTopicsFormValues = z.infer<
  typeof listDigestionTopicsSchema
>;

export const createDigestionTopicSchema = z.object(
  schemaShapeForType<DigestionTopicCreateRequest>()({
    topicId: uuid,
    startTime: optionalBigInt,
    endTime: optionalBigInt,
    frequency: optionalNumber,
    queryDataFilter: optionalObject,
    contextFilter: optionalObject,
  }),
);

export type CreateDigestionTopicFormValues = z.infer<
  typeof createDigestionTopicSchema
>;

export const editDigestionTopicSchema = z.object(
  schemaShapeForType<DigestionTopicUpdateRequest>()({
    startTime: optionalBigInt,
    endTime: optionalBigInt,
    frequency: optionalNumber,
    queryDataFilter: optionalObject,
    contextFilter: optionalObject,
  }),
);

export const EDITABLE_DIGESTION_TOPIC_FIELDS =
  editDigestionTopicSchema.keyof().options;

export type EditDigestionTopicFormValues = Pick<
  DigestionTopic,
  ElementOf<typeof EDITABLE_DIGESTION_TOPIC_FIELDS>
>;

export const listDigestionPartsSchema = z.object(
  schemaShapeForType<StrictOmit<ListDigestionPartsRequest, "digestionId">>()({
    sequence: filterNumber,
    state: filterProcessState,
    workflowId: filterUuid,
    workflowIdNull: filterBoolean,
    ...auditFieldSchemas,
  }),
);

export type ListDigestionPartsFormValues = z.infer<
  typeof listDigestionPartsSchema
>;

export const createDigestionPartSchema = z.object(
  schemaShapeForType<StrictOmit<DigestionPartCreateRequest, "index">>()({
    sequence: requiredNumber,
    state: requiredProcessStateSchema,
    workflowId: optionalUuid,
    workflowContext: optionalObject,
    locked: boolean,
  }),
);

export const editDigestionPartSchema = z.object(
  schemaShapeForType<StrictOmit<DigestionPartUpdateRequest, "index">>()({
    sequence: requiredNumber,
    state: requiredProcessStateSchema,
    progress: optionalNumber,
    workflowId: optionalUuid,
    workflowContext: optionalObject,
    error: optionalObject,
    locked: boolean,
  }),
);

export const EDITABLE_DIGESTION_PART_FIELDS =
  editDigestionPartSchema.keyof().options;
