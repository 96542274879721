import { DateTimePicker } from "@mui/x-date-pickers";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { getFieldLabel } from "~/utils";
import type { BaseFieldProps } from "./types";

// TODO: MUI gives the date back in local timezone, not UTC

export function DateField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, Date | null>,
>({
  control,
  name,
  label = getFieldLabel(name),
  required,
}: BaseFieldProps<TFieldValues, TName>) {
  const { field, fieldState } = useController({ control, name });

  return (
    <DateTimePicker
      {...field}
      label={label}
      ampm={false}
      slotProps={{
        field: {
          clearable: true,
        },
        textField: {
          fullWidth: true,
          error: fieldState.error !== undefined,
          helperText: fieldState.error?.message ?? " ",
          required,
        },
      }}
    />
  );
}
