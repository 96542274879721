import type { ElementOf, StrictOmit } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  optionalNumber,
  optionalObject,
  optionalText,
  optionalUuid,
  requiredNumber,
  requiredProcessStateSchema,
  schemaShapeForType,
  uuid,
} from "~/components/Form";
import {
  filterBoolean,
  filterNumber,
  filterProcessState,
  filterText,
  filterUuid,
} from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  Ingestion,
  IngestionCreateRequest,
  IngestionPartCreateRequest,
  IngestionPartUpdateRequest,
  IngestionUpdateRequest,
  ListIngestionPartsRequest,
  ListIngestionsRequest,
} from "~/services/datastore";

export const listIngestionsSchema = z.object(
  schemaShapeForType<
    StrictOmit<
      ListIngestionsRequest,
      "contextFilter" | "workflowContextFilter" | "errorLike"
    >
  >()({
    name: filterText,
    nameLike: filterText,
    logId: filterUuid,
    workflowId: filterUuid,
    workflowIdNull: filterBoolean,
    objectStoreId: filterUuid,
    objectKey: filterText,
    objectKeyLike: filterText,
    noteLike: filterText,
    progressGte: filterNumber,
    progressLte: filterNumber,
    progressNull: filterBoolean,
    state: filterProcessState,
    ...auditFieldSchemas,
  }),
);

export type ListIngestionsFormValues = z.infer<typeof listIngestionsSchema>;

export const createIngestionSchema = z.object(
  schemaShapeForType<IngestionCreateRequest>()({
    name: optionalText,
    logId: uuid,
    objectStoreId: optionalUuid,
    objectKey: optionalText,
    state: requiredProcessStateSchema,
    workflowId: optionalUuid,
    workflowContext: optionalObject,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export type CreateIngestionFormValues = z.infer<typeof createIngestionSchema>;

export const editIngestionSchema = z.object(
  schemaShapeForType<IngestionUpdateRequest>()({
    name: optionalText,
    objectStoreId: optionalUuid,
    objectKey: optionalText,
    state: requiredProcessStateSchema,
    progress: optionalNumber,
    workflowId: optionalUuid,
    workflowContext: optionalObject,
    error: optionalObject,
    locked: boolean,
    note: optionalText,
    context: optionalObject,
  }),
);

export const EDITABLE_INGESTION_FIELDS = editIngestionSchema.keyof().options;

export type EditIngestionFormValues = Pick<
  Ingestion,
  ElementOf<typeof EDITABLE_INGESTION_FIELDS>
>;

export const listIngestionPartsSchema = z.object(
  schemaShapeForType<StrictOmit<ListIngestionPartsRequest, "ingestionId">>()({
    sequence: filterNumber,
    source: filterText,
    workflowId: filterUuid,
    workflowIdNull: filterBoolean,
    state: filterProcessState,
    ...auditFieldSchemas,
  }),
);

export type ListIngestionPartsFormValues = z.infer<
  typeof listIngestionPartsSchema
>;

export const createIngestionPartSchema = z.object(
  schemaShapeForType<StrictOmit<IngestionPartCreateRequest, "index">>()({
    sequence: requiredNumber,
    source: optionalText,
    state: requiredProcessStateSchema,
    workflowId: optionalUuid,
    workflowContext: optionalObject,
    locked: boolean,
  }),
);

export const editIngestionPartSchema = z.object(
  schemaShapeForType<StrictOmit<IngestionPartUpdateRequest, "index">>()({
    sequence: requiredNumber,
    source: optionalText,
    state: requiredProcessStateSchema,
    progress: optionalNumber,
    workflowId: optionalUuid,
    workflowContext: optionalObject,
    error: optionalObject,
    locked: boolean,
  }),
);

export const EDITABLE_INGESTION_PART_FIELDS =
  editIngestionPartSchema.keyof().options;
