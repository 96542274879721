import { Button, Grid } from "@mui/material";
import { FuzzyField, useStudioForm } from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListGroupsFormValues } from "../schemas";
import { listGroupsSchema } from "../schemas";

export interface GroupFiltersProps {
  values: ListGroupsFormValues;
  setValues: (newValues: ListGroupsFormValues) => void;
}

export function GroupFilters({ values, setValues }: GroupFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listGroupsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
