import { Box, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  LockCard,
  ManageCard,
  ResourceFields,
} from "~/components/DetailsCards";
import {
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { useDeleteGroup, useGroup, useUpdateGroup } from "~/domain/datastores";
import { pick } from "~/lib/std";
import {
  makeEditGroupLocation,
  makeGroupsLocation,
  useGroupParams,
} from "~/paths";
import type { Group } from "~/services/datastore";
import { selectData } from "~/utils";
import { editGroupSchema } from "../schemas";

export function GroupDetails() {
  const { groupId } = useGroupParams();

  const query = useGroup(groupId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="group"
      query={query}
      updateMutation={useUpdateGroup(groupId)}
    />
  );
  const historySection = <HistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="group"
      query={query}
      editLocation={makeEditGroupLocation({ groupId })}
      deleteMutation={useDeleteGroup(groupId)}
      getReadableName={(group) => group.name}
      listLocation={makeGroupsLocation()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: 3,
            }}
          >
            {lockSection}
            {manageSection}
          </Box>
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Group> }) {
  return (
    <Card title="General">
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "workflow", accessor: "defaultWorkflowId" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Group> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["text", "multiline", "multiline"]} />}
        success={(group) => <InfoSectionImpl group={group} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ group }: { group: Group }) {
  const updateGroup = useUpdateGroup(group.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editGroupSchema.pick({
    name: true,
    note: true,
    context: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(group, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateGroup.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Group updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update group", { variant: "error" });
        },
      });
    },
  });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        loading={updateGroup.isLoading}
        submitText="Save Changes"
        disabled={group.locked}
      >
        <TextField control={control} name="name" />
        <TextField control={control} name="note" multiline />
        <ObjectField control={control} name="context" />
      </Form>
      {group.locked && (
        <Typography variant="body2">
          Unlock this group to make updates.
        </Typography>
      )}
    </>
  );
}
