import { useLabel } from "~/domain/datastores";
import { makeLabelLocation } from "~/paths";
import type { Label } from "~/services/datastore";
import { ResourceInfo } from "./ResourceInfo";

export interface LabelInfoProps {
  labelId: Label["id"];
}

export function LabelInfo({ labelId }: LabelInfoProps) {
  const labelValueQuery = useLabel(labelId, {
    select(response) {
      return response.data.value;
    },
  });

  return (
    <ResourceInfo
      name={labelValueQuery.data}
      identifier={labelId}
      to={makeLabelLocation({ labelId })}
    />
  );
}
