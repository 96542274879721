import type {
  Label,
  LabelCreateRequest,
  LabelUpdateRequest,
  ListLabelsRequest,
} from "~/services/datastore";
import { createResourceCrudHooks } from "./utils";

export const {
  queryKeyFactory: labelKeys,
  useList: useLabels,
  useFetch: useLabel,
  useCreate: useCreateLabel,
  useUpdate: useUpdateLabel,
  useDelete: useDeleteLabel,
} = createResourceCrudHooks({
  baseQueryKey: "labels",
  getIdentifier(label: Label) {
    return label.id;
  },
  listResource({ signal }, { labelApi }, request: ListLabelsRequest) {
    return labelApi.listLabels(request, { signal });
  },
  fetchResource({ signal }, { labelApi }, labelId: Label["id"]) {
    return labelApi.fetchLabel({ labelId }, { signal });
  },
  createResource({ labelApi }, request: LabelCreateRequest) {
    return labelApi.createLabel({ labelCreateRequest: request });
  },
  updateResource(
    { labelApi },
    labelId: Label["id"],
    updates: LabelUpdateRequest,
  ) {
    return labelApi.updateLabel({ labelId, labelUpdateRequest: updates });
  },
  deleteResource({ labelApi }, labelId: Label["id"]) {
    return labelApi.deleteLabel({ labelId });
  },
});
