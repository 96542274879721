import { z } from "zod";
import { ProcessState } from "~/services/datastore";
import type { Option } from "./types";
import { createErrorMap } from "./utils";

export const processStateOptions: Option[] = Object.values(ProcessState).map(
  (value) => ({
    label: value,
    value,
  }),
);

export const requiredProcessStateSchema = z.nativeEnum(ProcessState, {
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
    invalid_enum_value: "Unexpected value",
  }),
});

export const optionalProcessStateSchema = requiredProcessStateSchema.nullable();
