import { Stack } from "@mui/system";
import type { UseQueryResult } from "@tanstack/react-query";
import { DetailsCards } from "~/components/DetailsCards";
import { auditFields } from "~/domain/auditable";
import { useApiKeys, useCurrentUser } from "~/domain/datastores";
import type { User } from "~/services/datastore";
import { selectData } from "~/utils";
import APIKeysView from "./APIKeysView";

export default function MeView() {
  const userQuery = useCurrentUser({ select: selectData });

  const userId = userQuery.data?.id;
  const enabled = userQuery.isSuccess;

  const apiKeysQuery = useApiKeys({ userId, limit: 100 }, { enabled });

  return (
    <Stack spacing={4}>
      {userQuery.isSuccess && userQuery.data === null ? (
        "System user"
      ) : (
        // TODO: Remove cast
        <DetailsCards
          query={userQuery as UseQueryResult<User>}
          fields={[
            { dataType: "text", accessor: "id" },
            { dataType: "text", accessor: "username" },
            { dataType: "boolean", accessor: "admin" },
            ...auditFields,
          ]}
        />
      )}
      <APIKeysView apiKeysQuery={apiKeysQuery} userId={userId} />
    </Stack>
  );
}
