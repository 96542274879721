import {
  CheckboxField,
  NewResourceForm,
  ObjectField,
  TextField,
} from "~/components/Form";
import { useCreateObjectStore } from "~/domain/datastores";
import { makeObjectStoreLocation } from "~/paths";
import { createObjectStoreSchema } from "../schemas";

export function ObjectStoreCreateForm() {
  return (
    <NewResourceForm
      schema={createObjectStoreSchema}
      defaultValues={{
        bucketName: null,
        accessKeyId: null,
        secretAccessKey: null,
        regionName: null,
        endpointUrl: null,
        disabled: false,
        note: null,
        context: null,
      }}
      resourceName="object store"
      mutation={useCreateObjectStore()}
      generateLocation={(response) =>
        makeObjectStoreLocation({ objectStoreId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="bucketName" required />
          <TextField control={control} name="accessKeyId" />
          <TextField control={control} name="secretAccessKey" />
          <TextField control={control} name="regionName" />
          <TextField control={control} name="endpointUrl" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewResourceForm>
  );
}
