import { useWorkflow, useWorkflows } from "~/domain/datastores";
import type { Workflow } from "~/services/datastore";
import { makeResourceSelect } from "./makeResourceSelect";
import type { Option } from "./types";

export const WorkflowSelect = makeResourceSelect({
  useGet(uuid) {
    return useWorkflow(uuid, {
      select({ data }) {
        return makeOptionFromWorkflow(data);
      },
    });
  },
  useSearch(text) {
    return useWorkflows(
      {
        nameLike: text,
        limit: 5,
        sort: "asc",
        order: "name",
      },
      {
        keepPreviousData: true,
        enabled: text !== null,
        select({ data }) {
          return data.map(makeOptionFromWorkflow);
        },
      },
    );
  },
});

function makeOptionFromWorkflow({ id, name }: Workflow): Option {
  return {
    value: id,
    label: name,
  };
}
