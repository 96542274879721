import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useWorkflows } from "~/domain/datastores";
import {
  DataStoreLink,
  makeNewWorkflowLocation,
  makeWorkflowLocation,
} from "~/paths";
import type { Workflow } from "~/services/datastore";
import { listWorkflowsSchema } from "../schemas";
import { WorkflowFilters } from "./WorkflowFilters";

const COLUMNS: ReadonlyArray<Column<Workflow>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell(workflow) {
      return (
        <TableCell>
          {workflow.name}
          <Link
            component={DataStoreLink}
            to={makeWorkflowLocation({ workflowId: workflow.id })}
          >
            <Typography variant="body2">{workflow.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "_default",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listWorkflowsSchema);

export const WorkflowTable = withSearchValidationErrorBoundary(
  function WorkflowTable() {
    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useWorkflows(prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="workflow"
            resourceCreateLocation={makeNewWorkflowLocation()}
            getRowKey={(workflow) => workflow.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <WorkflowFilters values={filterValues} setValues={setRequest} />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
