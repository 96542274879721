import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { MeDataResponse } from "~/services/datastore";
import type { KeyFactory } from "~/types";
import { useDataStoreClients } from "../providers";

export const meKeys = {
  all: ["me"] as const,
  fetches: () => [...meKeys.all, "fetch"] as const,
  fetch: () => [...meKeys.fetches()] as const,
};

export type MeKeys = KeyFactory<typeof meKeys>;

export function useCurrentUser<TData = MeDataResponse>(
  options?: UseQueryOptions<MeDataResponse, unknown, TData, MeKeys["fetch"]>,
): UseQueryResult<TData> {
  const { userApi } = useDataStoreClients();

  return useQuery({
    queryKey: meKeys.fetch(),
    queryFn(context) {
      return userApi.fetchUserMe(context);
    },
    ...options,
  });
}
