import { EditResourceForm, TextField } from "~/components/Form";
import { useLabel, useUpdateLabel } from "~/domain/datastores";
import { makeLabelLocation, useLabelParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_LABEL_FIELDS, editLabelSchema } from "../schemas";

export function LabelEditForm() {
  const { labelId } = useLabelParams();

  return (
    <EditResourceForm
      schema={editLabelSchema}
      resourceName="label"
      query={useLabel(labelId, { select: selectData })}
      editableFields={EDITABLE_LABEL_FIELDS}
      mutation={useUpdateLabel(labelId)}
      resourceLocation={makeLabelLocation({ labelId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="value" required />
          <TextField control={control} name="note" />
        </>
      )}
    </EditResourceForm>
  );
}
