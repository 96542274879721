import React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Center } from "~/components/Center";
import { Error } from "~/components/Error";
import { Header } from "~/components/Header";
import { Helmet } from "~/components/Helmet";
import { Layout, LayoutStateProvider } from "~/components/Layout";
import { Logo } from "~/components/Logo";
import { QueryRenderer } from "~/components/QueryRenderer";
import { useDataStores } from "~/domain/dsm";
import { DOCS_ORIGIN } from "~/links";
import type * as paths from "~/paths";
import { makeDataStoreLocation } from "~/paths";
import { redirectToSignOut } from "~/services/auth";
import type { Maybe } from "~/types";
import { selectData } from "~/utils";

export function DataStoresPage() {
  const dataStoresQuery = useDataStores(
    {
      limit: 100,
      sort: "asc",
      order: "name",
    },
    { select: selectData },
  );

  const location = useLocation();

  function renderRedirectStateAlert() {
    const locationState = location.state as Maybe<paths.DataStoresState>;

    if (locationState == null) {
      return null;
    }

    let children = undefined;

    if (locationState.unknownDataStore !== undefined) {
      children = (
        <>
          <AlertTitle>Error Connecting to DataStore</AlertTitle>
          <Typography fontSize="inherit" paragraph>
            You do not have access to the following DataStore:
          </Typography>
          <pre>{locationState.unknownDataStore}</pre>
          <Typography fontSize="inherit">
            If you expect to have access, you will need to contact an
            administrator.
          </Typography>
        </>
      );
    }

    return (
      <Grid item xs={12}>
        <Alert
          severity="error"
          variant="filled"
          sx={{
            "& .MuiAlert-message": {
              flexGrow: 1,
            },
            "& pre": {
              borderRadius: 1,
              bgcolor: "grey.400",
              p: 0.75,
            },
          }}
        >
          {children}
        </Alert>
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Your DataStores</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={<Header title="Your DataStores" />}
          globalNavigation={
            <Stack
              spacing={4}
              useFlexGap
              sx={{
                flexGrow: 1,
                minHeight: 0,
                overflowY: "auto",
                alignItems: "center",
                "& .logqs-logo": {
                  display: "block",
                  width: "auto",
                  height: 40,
                },
                pt: 1,
              }}
            >
              <Logo />
              <Link
                sx={{ mt: "auto" }}
                href={DOCS_ORIGIN}
                underline="none"
                fontWeight="bold"
              >
                Docs
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={redirectToSignOut}
                underline="none"
                fontWeight="bold"
              >
                Sign Out
              </Link>
            </Stack>
          }
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <Container fixed>
              <Typography variant="h6" component="p" paragraph>
                Connect to one of your DataStores to use Studio.
              </Typography>
              <Grid container spacing={4}>
                {renderRedirectStateAlert()}
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <QueryRenderer
                        query={dataStoresQuery}
                        loading={<Skeleton variant="rounded" height={78} />}
                        error={
                          <Error>
                            <Typography variant="h6" component="p">
                              Unable to load your DataStores
                            </Typography>
                          </Error>
                        }
                        success={(dataStores) => {
                          if (dataStores.length === 0) {
                            return (
                              <Center>
                                <Typography variant="h6" component="p">
                                  You don't have access to any DataStores
                                </Typography>
                              </Center>
                            );
                          } else {
                            return (
                              <List
                                disablePadding
                                sx={{
                                  "& .MuiListItem-root": {
                                    flexWrap: "wrap",
                                  },
                                }}
                              >
                                {dataStores.map((dataStore) => (
                                  <ListItem key={dataStore.id} disablePadding>
                                    <ListItemButton
                                      component={RouterLink}
                                      to={makeDataStoreLocation()(dataStore)}
                                    >
                                      <ListItemText
                                        primaryTypographyProps={{
                                          variant: "h5",
                                          component: "p",
                                          sx: {
                                            mb: 1,
                                          },
                                        }}
                                        primary={
                                          <>
                                            <span>{dataStore.name}</span>
                                            {dataStore.name !== null && (
                                              <Typography
                                                variant="body1"
                                                component="span"
                                                color="text.secondary"
                                                sx={{
                                                  fontStyle: "italic",
                                                  ml: 1.5,
                                                }}
                                              >
                                                {dataStore.note}
                                              </Typography>
                                            )}
                                          </>
                                        }
                                        secondary={dataStore.id}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                              </List>
                            );
                          }
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Layout>
      </LayoutStateProvider>
    </>
  );
}
