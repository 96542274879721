import { secondsToNanoseconds } from "~/lib/dates";
import type { Topic } from "~/services/datastore";
import type { TimeRange } from "../types";
import useRecordChunks from "./useRecordChunks";

interface UseOverviewOptions {
  topicId: Topic["id"];
  enabled?: boolean;
  playerBounds?: TimeRange;
}

export function useTopicOverview({
  topicId,
  enabled = topicId != null,
  playerBounds,
}: UseOverviewOptions) {
  return useRecordChunks({
    topicId,
    enabled,
    chunkSize: secondsToNanoseconds(100),
    playerBounds,
  });
}
