import React, { useState } from "react";
import type { Topic } from "~/services/datastore";
import { assertNever } from "~/utils";
import type { InitializedPanelNode } from "../../../../panels";
import { PanelControls } from "../../../PanelControls";
import type { ImageRecord } from "../useImageRecord";
import type { SegmentationTopicSearchResult } from "../utils";
import { GeneralControls } from "./GeneralControls";
import { SegmentationControls } from "./SegmentationControls";
import { ControlsPage } from "./constants";

export interface ImageControlsProps {
  panel: InitializedPanelNode;
  topic: Topic;
  playerTopics: ReadonlyArray<Topic>;
  imageRecord: ImageRecord | undefined;
  segmentationTopicSearchResult: SegmentationTopicSearchResult;
}

export function ImageControls({
  panel,
  topic,
  playerTopics,
  imageRecord,
  segmentationTopicSearchResult,
}: ImageControlsProps) {
  const [controlsPage, setControlsPage] = useState<ControlsPage>(
    ControlsPage.General,
  );

  function renderPage() {
    switch (controlsPage) {
      case ControlsPage.General: {
        return (
          <GeneralControls
            panel={panel}
            topic={topic}
            onToSegmentationsPage={() =>
              setControlsPage(ControlsPage.Segmentations)
            }
          />
        );
      }
      case ControlsPage.Segmentations: {
        return (
          <SegmentationControls
            onExitPage={() => setControlsPage(ControlsPage.General)}
            panel={panel}
            topic={topic}
            playerTopics={playerTopics}
            segmentationTopicSearchResult={segmentationTopicSearchResult}
            imageRecord={imageRecord}
          />
        );
      }
      default: {
        assertNever(controlsPage);
      }
    }
  }

  return <PanelControls>{renderPage()}</PanelControls>;
}
