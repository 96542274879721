import type { TextFieldProps } from "@mui/material";
import { Divider, MenuItem, Stack, TextField } from "@mui/material";
import { Pagination } from "../Pagination";
import type { PaginationModel } from "./validation";
import { LIMIT_OPTIONS } from "./validation";

export interface TableFooterProps {
  count: number | undefined;
  paginationModel: PaginationModel;
  onPaginationModelChange: (changes: Partial<PaginationModel>) => void;
}

export function TableFooter({
  count = 0,
  paginationModel,
  onPaginationModelChange,
}: TableFooterProps) {
  const handleLimitChange: TextFieldProps["onChange"] =
    function handleLimitChange(e) {
      const newLimit = Number(e.target.value);

      onPaginationModelChange({ limit: newLimit, offset: 0 });
    };

  function handleOffsetChange(newOffset: number) {
    onPaginationModelChange({ offset: newOffset });
  }

  return (
    <Stack spacing={2}>
      <Divider />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <TextField
          sx={{ width: "15ch" }}
          select
          size="small"
          label="Results per page"
          value={paginationModel.limit}
          onChange={handleLimitChange}
        >
          {LIMIT_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Pagination
          count={count}
          limit={paginationModel.limit}
          offset={paginationModel.offset}
          onChange={handleOffsetChange}
        />
      </Stack>
    </Stack>
  );
}
