import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import type { DataStorePathGenerator } from "~/paths";
import { DataStoreLink } from "~/paths";
import { Card } from "../Card";
import { DeleteDialog, useDeleteResourceDialog } from "../DeleteDialog";

interface BaseManageCardProps<TResource> {
  resourceName: string;
  query: UseQueryResult<TResource>;
  deleteMutation: UseMutationResult<void, unknown, void>;
  getReadableName: (resource: TResource) => string;
  listLocation: DataStorePathGenerator;
}

interface ManageCardPropsWithEditing<TResource>
  extends BaseManageCardProps<TResource> {
  preventEditing?: false;
  editLocation: DataStorePathGenerator;
}
interface ManageCardPropsWithoutEditing<TResource>
  extends BaseManageCardProps<TResource> {
  preventEditing: true;
  editLocation?: undefined;
}

export function ManageCard<TResource>(
  props: ManageCardPropsWithEditing<TResource>,
): React.JSX.Element;
export function ManageCard<TResource>(
  props: ManageCardPropsWithoutEditing<TResource>,
): React.JSX.Element;
export function ManageCard<TResource>({
  resourceName,
  query,
  preventEditing,
  editLocation,
  deleteMutation,
  getReadableName,
  listLocation,
}:
  | ManageCardPropsWithEditing<TResource>
  | ManageCardPropsWithoutEditing<TResource>) {
  const deleteDialog = useDeleteResourceDialog({ query, listLocation });

  return (
    <Card title="Manage">
      <Stack spacing={2} useFlexGap>
        {!preventEditing && (
          <Button
            component={DataStoreLink}
            to={editLocation}
            variant="outlined"
            color="secondary"
            fullWidth
            disabled={!query.isSuccess}
            startIcon={<Edit />}
          >
            Edit
          </Button>
        )}
        <Button
          variant="outlined"
          fullWidth
          disabled={!query.isSuccess}
          onClick={deleteDialog.handleOpen}
          startIcon={<Delete />}
        >
          Delete...
        </Button>
      </Stack>
      <DeleteDialog
        open={deleteDialog.open}
        onClose={deleteDialog.handleClose}
        resourceName={resourceName}
        mutation={deleteMutation}
        onDeleted={deleteDialog.handleDeleted}
      >
        {query.isSuccess ? getReadableName(query.data) : null}
      </DeleteDialog>
    </Card>
  );
}
