import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { LabelCreateForm } from "./components/LabelCreateForm";

export function NewLabelPage() {
  return (
    <Page title="New Label">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LabelCreateForm />
      </Container>
    </Page>
  );
}
