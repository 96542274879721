import { Button, Grid } from "@mui/material";
import { FuzzyField, UserSelect, useStudioForm } from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListApiKeysFormValues } from "../schemas";
import { listApiKeysSchema } from "../schemas";

export interface ApiKeyFiltersProps {
  values: ListApiKeysFormValues;
  setValues: (newValues: ListApiKeysFormValues) => void;
}

export function ApiKeyFilters({ values, setValues }: ApiKeyFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listApiKeysSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={3}>
        <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      </Grid>
      <Grid item xs={3}>
        <UserSelect control={control} name="userId" />
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
