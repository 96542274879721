import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  IngestionSelect,
  NumberField,
  processStateOptions,
  SelectField,
  TextField,
  useStudioForm,
  WorkflowSelect,
} from "~/components/Form";
import { AuditFilters } from "~/domain/auditable";
import type { ListRecordsFormValues } from "../schemas";
import { listRecordsSchema } from "../schemas";

export interface RecordFiltersProps {
  values: ListRecordsFormValues;
  setValues: (newValues: ListRecordsFormValues) => void;
}

export function RecordFilters({ values, setValues }: RecordFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listRecordsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={3}>
        <IngestionSelect control={control} name="ingestionId" />
      </Grid>
      <Grid item xs={3}>
        <WorkflowSelect control={control} name="workflowId" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="workflowIdNull" />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          control={control}
          name="state"
          options={processStateOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="source" />
      </Grid>
      <Grid item xs={3}>
        <BooleanField control={control} name="altered" />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          <NumberField control={control} name="dataLengthGte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="dataLengthLte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="dataOffsetGte" />
        </Grid>
        <Grid item xs={3}>
          <NumberField control={control} name="dataOffsetLte" />
        </Grid>
      </Grid>
      <AuditFilters control={control} />
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
