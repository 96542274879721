import { Card, CardContent, Link, TableCell, Typography } from "@mui/material";
import type { Column } from "~/components/Table";
import {
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
  withSearchValidationErrorBoundary,
} from "~/components/Table";
import { auditColumns } from "~/domain/auditable";
import { useIngestions } from "~/domain/datastores";
import {
  DataStoreLink,
  makeIngestionLocation,
  makeNewIngestionLocation,
} from "~/paths";
import type { Ingestion } from "~/services/datastore";
import { listIngestionsSchema } from "../schemas";
import { IngestionFilters } from "./IngestionFilters";

const COLUMNS: ReadonlyArray<Column<Ingestion>> = [
  {
    header: "Ingestion",
    renderCell(ingestion) {
      return (
        <TableCell>
          {ingestion.name ?? "-"}
          <Link
            component={DataStoreLink}
            to={makeIngestionLocation({ ingestionId: ingestion.id })}
          >
            <Typography variant="body2">{ingestion.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "logId",
    dataType: "foreign-key",
    resourceType: "log",
  },
  {
    accessor: "objectStoreId",
    dataType: "foreign-key",
    resourceType: "object-store",
  },
  {
    accessor: "objectKey",
    dataType: "text",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "state",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "progress",
    dataType: "percent",
    isSortable: true,
  },
  {
    accessor: "transitionedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "workflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...auditColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listIngestionsSchema);

export const IngestionTable = withSearchValidationErrorBoundary(
  function IngestionTable() {
    const [request, setRequest] = useSearchRequest(requestSchema);

    const searchQuery = useIngestions(prepareListRequest(request), {
      keepPreviousData: true,
      cacheTime: 0,
    });

    const filterValues = withoutBaseTableModel(request);

    return (
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="ingestion"
            resourceCreateLocation={makeNewIngestionLocation()}
            getRowKey={(ingestion) => ingestion.id}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <IngestionFilters values={filterValues} setValues={setRequest} />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    );
  },
);
