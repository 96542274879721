import {
  CheckboxField,
  NewResourceForm,
  ObjectField,
  TextField,
} from "~/components/Form";
import { useCreateRole } from "~/domain/datastores";
import { makeRoleLocation } from "~/paths";
import { createRoleSchema } from "../schemas";

export function RoleCreateForm() {
  return (
    <NewResourceForm
      schema={createRoleSchema}
      defaultValues={{
        name: null,
        policy: null,
        note: null,
        _default: false,
        managed: false,
        disabled: false,
      }}
      resourceName="role"
      mutation={useCreateRole()}
      generateLocation={(response) =>
        makeRoleLocation({ roleId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <ObjectField control={control} name="policy" required />
          <CheckboxField control={control} name="_default" />
          <CheckboxField control={control} name="managed" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" />
        </>
      )}
    </NewResourceForm>
  );
}
