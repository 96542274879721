import { Button, Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  LockCard,
  ManageCard,
  RelatedResourceLink,
  ResourceFields,
} from "~/components/DetailsCards";
import {
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { useDeleteLog, useLog, useUpdateLog } from "~/domain/datastores";
import { pick } from "~/lib/std";
import {
  DataStoreLink,
  makeEditLogLocation,
  makeLogObjectsLocation,
  makeLogQueriesLocation,
  makeLogsLocation,
  makePlayerLocation,
  makeTagsLocation,
  useLogParams,
} from "~/paths";
import type { Log } from "~/services/datastore";
import { selectData } from "~/utils";
import { editLogSchema } from "../schemas";

export function LogDetails() {
  const { logId } = useLogParams();

  const query = useLog(logId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2} useFlexGap>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResourceLink text="Tags" to={makeTagsLocation({ logId })} />
      <RelatedResourceLink text="Objects" to={makeLogObjectsLocation(logId)} />
      <RelatedResourceLink
        text="Queries"
        to={makeLogQueriesLocation({ logId })}
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="log"
      query={query}
      updateMutation={useUpdateLog(logId)}
    />
  );
  const historySection = <HistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="log"
      query={query}
      editLocation={makeEditLogLocation({ logId })}
      deleteMutation={useDeleteLog(logId)}
      getReadableName={(log) => log.name}
      listLocation={makeLogsLocation()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Log> }) {
  const { logId } = useLogParams();

  return (
    <Card
      title="General"
      action={
        <Button
          variant="contained"
          component={DataStoreLink}
          to={makePlayerLocation({ logId })}
        >
          Open in Player
        </Button>
      }
    >
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "group", accessor: "groupId" },
          { dataType: "workflow", accessor: "defaultWorkflowId" },
          { dataType: "timestamp", accessor: "startTime" },
          { dataType: "timestamp", accessor: "endTime" },
          { dataType: "bytes", accessor: "recordSize" },
          { dataType: "number", accessor: "recordCount" },
          { dataType: "bytes", accessor: "objectSize" },
          { dataType: "number", accessor: "objectCount" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Log> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["text", "multiline", "multiline"]} />}
        success={(log) => <InfoSectionImpl log={log} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ log }: { log: Log }) {
  const updateLog = useUpdateLog(log.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editLogSchema.pick({ name: true, note: true, context: true });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(log, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateLog.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Log updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update log", { variant: "error" });
        },
      });
    },
  });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        loading={updateLog.isLoading}
        submitText="Save Changes"
        disabled={log.locked}
      >
        <TextField control={control} name="name" />
        <TextField control={control} name="note" multiline />
        <ObjectField control={control} name="context" />
      </Form>
      {log.locked && (
        <Typography variant="body2">
          Unlock this log to make updates.
        </Typography>
      )}
    </>
  );
}
