import type { ElementOf, StrictOmit } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  optionalText,
  optionalUuid,
  requiredText,
  schemaShapeForType,
} from "~/components/Form";
import { filterBoolean, filterText, filterUuid } from "~/components/Table";
import { auditFieldSchemas } from "~/domain/auditable";
import type {
  ListUsersRequest,
  User,
  UserCreateRequest,
  UserUpdateRequest,
} from "~/services/datastore";

export const listUsersSchema = z.object(
  schemaShapeForType<ListUsersRequest>()({
    username: filterText,
    usernameLike: filterText,
    roleId: filterUuid,
    admin: filterBoolean,
    managed: filterBoolean,
    disabled: filterBoolean,
    ...auditFieldSchemas,
  }),
);

export type ListUsersFormValues = z.infer<typeof listUsersSchema>;

export const createUserSchema = z.object(
  schemaShapeForType<StrictOmit<UserCreateRequest, "password">>()({
    username: requiredText,
    roleId: optionalUuid,
    admin: boolean,
    managed: boolean,
    disabled: boolean,
    externalId: optionalText,
  }),
);

export type CreateUserFormValues = z.infer<typeof createUserSchema>;

export const editUserSchema = z.object(
  schemaShapeForType<StrictOmit<UserUpdateRequest, "password">>()({
    username: requiredText,
    roleId: optionalUuid,
    admin: boolean,
    disabled: boolean,
    externalId: optionalText,
  }),
);

export const EDITABLE_FIELDS = editUserSchema.keyof().options;

export type EditUserFormValues = Pick<User, ElementOf<typeof EDITABLE_FIELDS>>;
