import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import type { TopicKeys } from "~/domain/datastores";
import {
  circumventPagination,
  topicKeys,
  useDataStoreClients,
} from "~/domain/datastores";
import { merge } from "~/lib/std";
import type {
  DigestionCreateRequest,
  ListRecordsRequest,
  ListTopicsRequest,
  Log,
  Record,
  RecordListResponse,
  Topic,
  TopicListResponse,
} from "~/services/datastore";
import { ProcessState } from "~/services/datastore";
import type { KeyFactory } from "~/types";
import { stringifyBigintFields } from "~/utils";
import type { DraftDigestionTopic } from "./types";

const API_MAX_LIMIT = 100;

export interface UseRecordsQueriesParams<TData = RecordListResponse> {
  requests: ReadonlyArray<ListRecordsRequest>;
  options?: Pick<
    UseQueryOptions<RecordListResponse, unknown, TData>,
    "select" | "enabled" | "cacheTime"
  >;
}

export interface UseCreateDigestionArgs {
  logId: Log["id"];
  draftDigestionTopics: DraftDigestionTopic[];
  name: DigestionCreateRequest["name"];
}

export const playerRecordKeys = {
  all: ["player-records"] as const,
  lists: () => [...playerRecordKeys.all, "list"] as const,
  list: (request: ListRecordsRequest) =>
    [...playerRecordKeys.lists(), stringifyBigintFields(request)] as const,
  allImages: () => [...playerRecordKeys.all, "images"] as const,
  images: (request: ListRecordsRequest) =>
    [...playerRecordKeys.allImages(), stringifyBigintFields(request)] as const,
};

export type PlayerRecordKeys = KeyFactory<typeof playerRecordKeys>;

export function useTopics<TData = TopicListResponse>(
  request: ListTopicsRequest,
  options?: UseQueryOptions<
    TopicListResponse,
    unknown,
    TData,
    TopicKeys["list"]
  >,
) {
  const { topicApi } = useDataStoreClients();

  return useQuery({
    queryKey: topicKeys.list(request),
    queryFn(context) {
      return request.limit === -1
        ? circumventPagination(
            topicApi.listTopics.bind(topicApi),
            API_MAX_LIMIT,
            request,
            context,
          )
        : topicApi.listTopics(request, context);
    },
    ...options,
  });
}

export function useRecordsQueries<TData = RecordListResponse>({
  requests,
  options,
}: UseRecordsQueriesParams<TData>) {
  const { topicApi } = useDataStoreClients();

  return useQueries({
    queries: requests.map(
      (
        request,
      ): UseQueryOptions<
        RecordListResponse,
        unknown,
        TData,
        PlayerRecordKeys["list"]
      > => ({
        queryKey: playerRecordKeys.list(request),
        queryFn({ signal }) {
          return topicApi.listRecords(
            {
              sort: "asc",
              order: "timestamp",
              ...request,
            },
            { signal },
          );
        },
        staleTime: Infinity,
        // For non-image queries, the data will be kept in the cache so long
        // as at least one panel on screen exists for the request's topic ID
        cacheTime: Infinity,
        ...options,
      }),
    ),
  });
}

export function useCreateDigestion() {
  const { digestionApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({
      logId,
      draftDigestionTopics,
      name,
    }: UseCreateDigestionArgs) {
      const newDigestion = await digestionApi.createDigestion({
        digestionCreateRequest: {
          logId,
          name,
        },
      });

      await Promise.all(
        draftDigestionTopics.map((draftDigestionTopic) =>
          digestionApi.createDigestionTopic({
            digestionId: newDigestion.data.id,
            digestionTopicCreateRequest: draftDigestionTopic,
          }),
        ),
      );

      return digestionApi.updateDigestion({
        digestionId: newDigestion.data.id,
        digestionUpdateRequest: {
          state: ProcessState.Queued,
        },
      });
    },
  });
}

export function useClassifySegmentationsQuality({ topic }: { topic: Topic }) {
  const { topicApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({
      segmentationsTopicId,
      timestamp,
      classification,
    }: {
      segmentationsTopicId: Topic["id"];
      timestamp: Record["timestamp"];
      classification: "accept" | "reject";
    }) {
      const {
        data: { context },
      } = await topicApi.fetchRecord({
        topicId: topic.id,
        timestamp,
      });

      return topicApi.updateRecord({
        topicId: topic.id,
        timestamp,
        recordUpdateRequest: {
          context: mergeSegmentationsClassificationIntoContext(
            context,
            segmentationsTopicId,
            classification,
          ),
        },
      });
    },
  });
}

function mergeSegmentationsClassificationIntoContext(
  context: object | null,
  segmentationTopicId: Topic["id"],
  classification: "accept" | "reject",
): object {
  return merge({}, context ?? {}, {
    studio: {
      ml: {
        segmentations: {
          [segmentationTopicId]: classification,
        },
      },
    },
  });
}
