import {
  CheckboxField,
  GroupSelect,
  NewResourceForm,
  ObjectField,
  TextField,
  WorkflowSelect,
} from "~/components/Form";
import { useCreateLog } from "~/domain/datastores";
import { makeLogLocation } from "~/paths";
import { createLogSchema } from "../schemas";

export function LogCreateForm() {
  return (
    <NewResourceForm
      schema={createLogSchema}
      defaultValues={{
        name: null,
        groupId: null,
        defaultWorkflowId: null,
        locked: false,
        note: null,
        context: null,
      }}
      resourceName="log"
      mutation={useCreateLog()}
      generateLocation={(response) =>
        makeLogLocation({ logId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <GroupSelect control={control} name="groupId" required />
          <WorkflowSelect control={control} name="defaultWorkflowId" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewResourceForm>
  );
}
