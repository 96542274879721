import {
  CheckboxField,
  EditResourceForm,
  ObjectField,
  TextField,
} from "~/components/Form";
import { useRecord, useUpdateRecord } from "~/domain/datastores";
import { makeRecordLocation, useRecordParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_RECORD_FIELDS, editRecordSchema } from "../schemas";

export function RecordEditForm() {
  const { topicId, timestamp } = useRecordParams();

  return (
    <EditResourceForm
      schema={editRecordSchema}
      resourceName="record"
      query={useRecord(topicId, timestamp, { select: selectData })}
      editableFields={EDITABLE_RECORD_FIELDS}
      mutation={useUpdateRecord(topicId, timestamp)}
      resourceLocation={makeRecordLocation({ topicId, timestamp })}
    >
      {(control) => (
        <>
          <ObjectField control={control} name="queryData" />
          <ObjectField control={control} name="error" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditResourceForm>
  );
}
