import React, { useEffect, useRef } from "react";
import { ArrowBack, Clear } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { BreakableText } from "~/components/BreakableText";
import { DrawerHeader } from "~/components/DrawerHeader";
import { useLayoutStateContext } from "~/components/Layout";
import { minutesToNanoseconds, secondsToNanoseconds } from "~/lib/dates";
import { invariant } from "~/lib/invariant";
import { filter, find, sortBy } from "~/lib/std";
import { DataStoreLink, makeDigestionLocation } from "~/paths";
import type { Digestion, Log } from "~/services/datastore";
import { pluralize } from "~/utils";
import { usePlayerConfig, usePlayerTopics } from "../../../hooks";
import {
  centerRange,
  enterRangeMode,
  exitRangeMode,
  useFormatPlaybackTimestamp,
  usePlaybackSource,
} from "../../../playback";
import { useCreateDigestion } from "../../../queries";
import type { DraftDigestionTopic } from "../../../types";
import { TopicSelect } from "../../TopicSelect";
import TfStaticAlert from "./TfStaticAlert";
import type { DraftDigestion } from "./useDraftDigestion";
import {
  abortFinalizing,
  draftSelectedTopics,
  removeDraftTopic,
  resetDraft,
  selectLayoutTopics,
  setSelectedTopics,
  startFinalizing,
} from "./useDraftDigestion";

type FormValues = {
  name: string;
};

const defaultFormValues: FormValues = {
  name: "",
};

export type DigestionDrawerProps = {
  draftDigestion: DraftDigestion;
} & ReturnType<typeof useDigestionFinalizer>;

export default function DigestionDrawer({
  draftDigestion,
  createDigestion,
  form,
  onSubmit,
}: DigestionDrawerProps) {
  const { logId } = usePlayerConfig();

  const topicsQuery = usePlayerTopics();

  const playbackSource = usePlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const loading = playbackSource.isLoading || !topicsQuery.isSuccess;
  const selectLayoutTopicsDisabled =
    loading ||
    playbackSource.inRangeMode ||
    !draftDigestion.canSelectLayoutTopics;
  const topicSelectDisabled = loading || playbackSource.inRangeMode;
  const selectTimeManuallyDisabled = loading;
  const selectRelativeTimeDisabled = loading || playbackSource.inRangeMode;
  const addToDraftDisabled =
    loading ||
    playbackSource.inRangeMode ||
    draftDigestion.selectedTopicIds.length === 0;
  const finalizeDisabled =
    loading || playbackSource.inRangeMode || draftDigestion.topics.length === 0;
  const areRangeEndsEqual =
    !playbackSource.isLoading &&
    playbackSource.range.startTime === playbackSource.range.endTime;

  function handleSelectLayoutTopics() {
    invariant(
      !selectLayoutTopicsDisabled,
      "Layout topics button should not be enabled",
    );

    draftDigestion.dispatch(selectLayoutTopics());
  }

  function handleSelectTimeManually() {
    invariant(
      !selectTimeManuallyDisabled,
      "Manual-select button should not be enabled yet",
    );

    playbackSource.dispatch(enterRangeMode());
  }

  function makeRelativeTimeClickHandler(relativeTime: bigint) {
    return function onRelativeTimeClick() {
      invariant(
        !selectRelativeTimeDisabled,
        "Relative time buttons should not be enabled right now",
      );

      playbackSource.dispatch(centerRange({ halfDuration: relativeTime }));
    };
  }

  function handleAddDraftTopics() {
    invariant(!addToDraftDisabled, "Cannot add draft topics yet");

    draftDigestion.dispatch(draftSelectedTopics());
  }

  function handleReset() {
    draftDigestion.dispatch(resetDraft());

    createDigestion.reset();
    form.reset(defaultFormValues);
  }

  // TODO: Move this into the reducer?
  const selectedTopics = filter(topicsQuery.data ?? [], (topic) =>
    draftDigestion.selectedTopicIds.includes(topic.id),
  );

  if (!draftDigestion.isFinalizing) {
    return (
      <>
        <DrawerHeader title="Create a Digestion" />
        {renderOptionalAlert(logId)}
        <Typography paragraph>
          To create a digestion, select topics you're interested in and choose
          what time ranges in the log you want records for those topics. You can
          choose multiple time ranges for a topic or choose topics in different
          time ranges.
        </Typography>
        <Stack spacing={5}>
          <div>
            <Typography variant="h6" component="p" gutterBottom>
              Select Topics
            </Typography>
            <Stack spacing={3}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                disabled={selectLayoutTopicsDisabled}
                onClick={handleSelectLayoutTopics}
              >
                Select Layout Topics
              </Button>
              <TopicSelect
                multiple
                disabled={topicSelectDisabled}
                value={selectedTopics}
                onChange={(topics) =>
                  draftDigestion.dispatch(setSelectedTopics({ topics }))
                }
                inputLabel="Selected topics"
                topics={topicsQuery.data ?? []}
              />
            </Stack>
          </div>
          <div>
            <Typography variant="h6" component="p" gutterBottom>
              Select Time Range
            </Typography>
            <Stack spacing={3}>
              {playbackSource.inRangeMode ? (
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={areRangeEndsEqual}
                    onClick={() => playbackSource.dispatch(exitRangeMode())}
                  >
                    Confirm Selection
                  </Button>
                  {areRangeEndsEqual && (
                    <Typography>Time range edges cannot be equal</Typography>
                  )}
                </div>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  fullWidth
                  disabled={selectTimeManuallyDisabled}
                  onClick={handleSelectTimeManually}
                >
                  Select Manually
                </Button>
              )}
              <div>
                <Typography id="relative-time-title">
                  Relative to playback time:
                </Typography>
                <ButtonGroup
                  aria-labelledby="relative-time-title"
                  color="primary"
                  variant="contained"
                  disableElevation
                  fullWidth
                  disabled={selectRelativeTimeDisabled}
                >
                  <Button
                    onClick={makeRelativeTimeClickHandler(
                      secondsToNanoseconds(15),
                    )}
                  >
                    &plusmn; 15 sec
                  </Button>
                  <Button
                    onClick={makeRelativeTimeClickHandler(
                      minutesToNanoseconds(1),
                    )}
                  >
                    &plusmn; 1 min
                  </Button>
                  <Button
                    onClick={makeRelativeTimeClickHandler(
                      minutesToNanoseconds(3),
                    )}
                  >
                    &plusmn; 3 min
                  </Button>
                </ButtonGroup>
              </div>
              <div>
                <Typography>Selected time range:</Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    "& pre": {
                      bgcolor: (theme) =>
                        theme.palette.mode === "light"
                          ? "grey.300"
                          : "grey.700",
                      border: 1,
                      borderColor: "divider",
                      borderRadius: 1,
                      m: 0,
                      px: 1,
                      py: 0.5,
                    },
                  }}
                >
                  <pre>
                    {loading
                      ? "--"
                      : formatPlaybackTimestamp(playbackSource.range.startTime)}
                  </pre>
                  -
                  <pre>
                    {loading
                      ? "--"
                      : formatPlaybackTimestamp(playbackSource.range.endTime)}
                  </pre>
                </Stack>
              </div>
            </Stack>
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Add to Draft
            </Typography>
            <Typography paragraph>
              {addToDraftDisabled ? (
                "Select topics and a time range to add to the draft"
              ) : (
                <>
                  You've selected{" "}
                  {pluralize(draftDigestion.selectedTopicIds.length, "topic")}{" "}
                  to be added between{" "}
                  {formatPlaybackTimestamp(playbackSource.range.startTime)}
                  {" - "}
                  {formatPlaybackTimestamp(playbackSource.range.endTime)}
                </>
              )}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disableElevation
              disabled={addToDraftDisabled}
              onClick={handleAddDraftTopics}
            >
              Add to Draft
            </Button>
          </div>
          <div>
            <Typography variant="h6" component="p" gutterBottom>
              Digestion Summary
            </Typography>
            {logId === null ? (
              <Typography paragraph>
                Choose a log to start making a digestion
              </Typography>
            ) : loading ? (
              <Typography paragraph>Loading...</Typography>
            ) : draftDigestion.topics.length === 0 ? (
              <Typography paragraph>
                No topics added to this draft yet
              </Typography>
            ) : (
              <DraftDigestionList
                draftDigestionTopics={draftDigestion.topics}
                dispatch={draftDigestion.dispatch}
              />
            )}
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={finalizeDisabled}
              onClick={() => draftDigestion.dispatch(startFinalizing())}
            >
              Finalize Digestion
            </Button>
          </div>
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <DrawerHeader title="Finalize Digestion" />
        <Button
          sx={{ mb: 2 }}
          disabled={createDigestion.isLoading || createDigestion.isSuccess}
          color="primary"
          variant="text"
          startIcon={<ArrowBack />}
          onClick={() => draftDigestion.dispatch(abortFinalizing())}
        >
          Back to Draft
        </Button>
        {!loading && (
          <TfStaticAlert
            playbackTopics={topicsQuery.data}
            playerBounds={playbackSource.bounds}
            draftDigestionTopics={draftDigestion.topics}
            dispatch={draftDigestion.dispatch}
          />
        )}
        <Typography mt={2}>
          The following topics will be extracted in the time ranges you chose:
        </Typography>
        <DraftDigestionList
          disableRemove
          draftDigestionTopics={draftDigestion.topics}
          dispatch={draftDigestion.dispatch}
        />
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Typography paragraph>
            You can provide a name for the digestion to help you identify it
            later:
          </Typography>
          <Controller
            name="name"
            control={form.control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={
                  createDigestion.isLoading || createDigestion.isSuccess
                }
                label="Name (optional)"
              />
            )}
          />
          <LoadingButton
            sx={{ mt: 2, mb: 4 }}
            color="primary"
            variant="contained"
            fullWidth
            loading={createDigestion.isLoading}
            disabled={createDigestion.isSuccess}
            type="submit"
          >
            Submit Digestion
          </LoadingButton>
        </form>
        {createDigestion.isError && (
          <Alert severity="error" variant="filled">
            Failed to create the digestion
          </Alert>
        )}
        {createDigestion.isSuccess && (
          <>
            <SuccessAlert digestionId={createDigestion.data.data.id} />
            <Button
              sx={{ mt: 2 }}
              color="primary"
              variant="text"
              onClick={handleReset}
            >
              Start New Digestion
            </Button>
          </>
        )}
      </>
    );
  }
}

interface SuccessAlertProps {
  digestionId: Digestion["id"];
}

function SuccessAlert({ digestionId }: SuccessAlertProps) {
  return (
    <Alert severity="success" variant="filled">
      <Link
        component={DataStoreLink}
        to={makeDigestionLocation({ digestionId })}
        color="inherit"
      >
        View your digestion's details
      </Link>
    </Alert>
  );
}

interface DraftDigestionListProps {
  disableRemove?: boolean;
  draftDigestionTopics: DraftDigestionTopic[];
  dispatch: DraftDigestion["dispatch"];
}

function DraftDigestionList({
  disableRemove = false,
  draftDigestionTopics,
  dispatch,
}: DraftDigestionListProps) {
  const topicsQuery = usePlayerTopics();
  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  function makeRemoveDraftTopicHandler(draftTopic: DraftDigestionTopic) {
    return function handleRemoveDraftTopic() {
      dispatch(removeDraftTopic({ topic: draftTopic }));
    };
  }

  return (
    <List>
      {sortBy(Array.from(groupDrafts(draftDigestionTopics).entries()), "0").map(
        ([topicId, drafts]) => {
          const topic = find(topicsQuery.data, { id: topicId });

          invariant(topic !== undefined, "Topic not found");

          return (
            <ListItem
              key={topicId}
              sx={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <ListItemText>
                <BreakableText separator={/(\/)/}>{topic.name}</BreakableText>
              </ListItemText>
              <List sx={{ alignSelf: "stretch" }}>
                {sortBy(drafts, "startTime").map((draft) => (
                  <ListItem
                    key={draft.startTime}
                    secondaryAction={
                      disableRemove ? undefined : (
                        <Tooltip title="Remove this time range">
                          <IconButton
                            onClick={makeRemoveDraftTopicHandler(draft)}
                          >
                            <Clear />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  >
                    <ListItemText>
                      {formatPlaybackTimestamp(draft.startTime)}
                      {" - "}
                      {formatPlaybackTimestamp(draft.endTime)}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </ListItem>
          );
        },
      )}
    </List>
  );
}

function groupDrafts(
  drafts: DraftDigestionTopic[],
): Map<DraftDigestionTopic["topicId"], DraftDigestionTopic[]> {
  const draftMap = new Map<
    DraftDigestionTopic["topicId"],
    DraftDigestionTopic[]
  >();

  drafts.forEach((draft) => {
    if (!draftMap.has(draft.topicId)) {
      draftMap.set(draft.topicId, []);
    }

    draftMap.get(draft.topicId)!.push(draft);
  });

  return draftMap;
}

function renderOptionalAlert(logId: Log["id"] | null) {
  if (logId === null) {
    return (
      <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
        Choose a log to create a digestion
      </Alert>
    );
  }
}

export function useDigestionFinalizer(
  draftDigestionTopics: DraftDigestionTopic[],
) {
  const { logId } = usePlayerConfig();

  const { sideSheetState } = useLayoutStateContext();

  // Since <DigestionDrawer /> is always mounted even if not visible, there
  // needs to be a way to let the user know their digestion is done if they
  // close the drawer while the mutation is ongoing. This ref is used by the
  // mutation callbacks to show a snackbar if the mutation settles while the
  // drawer is closed.
  const isDrawerOpenRef = useRef(sideSheetState === "digestions");
  useEffect(
    function updateDrawerOpenRef() {
      isDrawerOpenRef.current = sideSheetState === "digestions";
    },
    [sideSheetState],
  );

  const form = useForm<FormValues>({ defaultValues: defaultFormValues });

  const { enqueueSnackbar } = useSnackbar();
  const createDigestion = useCreateDigestion();
  const onSubmit: SubmitHandler<FormValues> = function onSubmit({ name }) {
    invariant(logId !== null, "Log ID cannot be null");

    createDigestion.mutate(
      {
        logId,
        draftDigestionTopics,
        name: name || undefined,
      },
      {
        onSuccess() {
          if (isDrawerOpenRef.current) {
            return;
          }

          enqueueSnackbar(
            "Digestion created! Open the digestion sidebar for more details",
            { variant: "success" },
          );
        },
        onError() {
          if (isDrawerOpenRef.current) {
            return;
          }

          enqueueSnackbar(
            "Couldn't create digestion. Open the digestion sidebar for more details",
            { variant: "error" },
          );
        },
      },
    );
  };

  return {
    createDigestion,
    form,
    onSubmit,
  };
}
