import React from "react";
import {
  Check,
  Close,
  HorizontalSplit,
  MoreHoriz,
  SettingsApplications,
  VerticalSplit,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { FileTreeOutline } from "mdi-material-ui";
import { invariant } from "~/lib/invariant";
import {
  chooseNewTopic,
  chooseVisualization,
  removePanel,
  SplitOrientation,
  splitPanel,
  supportsImage,
  supportsMap,
  togglePanelControls,
  usePanelContext,
  usePanelLayoutContext,
  VisualizationType,
} from "../panels";

export default function PanelHeader() {
  const panel = usePanelContext();
  const { dispatch } = usePanelLayoutContext();

  const popupState = usePopupState({
    variant: "popover",
    popupId: `panel-${panel.id}-menu`,
  });

  function handleTabChange(tab: VisualizationType) {
    return function onTabChange() {
      dispatch(chooseVisualization({ panelId: panel.id, tab }));
    };
  }

  function handleClearTopic() {
    dispatch(chooseNewTopic({ panelId: panel.id }));
  }

  function handleSplit(orientation: SplitOrientation) {
    return function onSplit() {
      dispatch(splitPanel({ panelId: panel.id, orientation }));
    };
  }

  function handleClose() {
    dispatch(removePanel({ panelId: panel.id }));
  }

  function handleTogglePanelControls() {
    dispatch(togglePanelControls({ panelId: panel.id }));
  }

  function renderMenuVisItem(
    key: number,
    text: string,
    value: VisualizationType,
    disabled: boolean = false,
  ) {
    invariant(panel.isInitialized, "Can only render vis items for vis panel");

    const isCurrent = panel.visualization === value;

    return (
      <MenuItem key={key} disabled={disabled} onClick={handleTabChange(value)}>
        {isCurrent && (
          <ListItemIcon>
            <Check />
          </ListItemIcon>
        )}
        <ListItemText inset={!isCurrent}>{text}</ListItemText>
      </MenuItem>
    );
  }

  return (
    <Stack direction="row" spacing={1} useFlexGap alignItems="center">
      <Tooltip title="Open panel settings">
        <IconButton {...bindTrigger(popupState)} size="small">
          <MoreHoriz />
        </IconButton>
      </Tooltip>
      {panel.isInitialized ? (
        <Typography fontWeight="bold" noWrap>
          {panel.topicName}
        </Typography>
      ) : (
        <Typography fontStyle="italic" noWrap>
          No topic selected
        </Typography>
      )}
      {panel.isInitialized && (
        <Box sx={{ ml: "auto" }}>
          <Tooltip title="Panel controls">
            <IconButton size="small" onClick={handleTogglePanelControls}>
              <SettingsApplications />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Menu
        {...bindPopover(popupState)}
        PaperProps={{
          sx: {
            width: 300,
            "& .MuiDivider-root": {
              my: 1,
            },
          },
        }}
      >
        {/* MUI won't let you pass a fragment as a Menu's child so you
          have to use an array instead */}
        {panel.isInitialized && [
          <MenuItem key={0} onClick={handleClearTopic}>
            <ListItemIcon>
              <FileTreeOutline />
            </ListItemIcon>
            <ListItemText>Choose new topic</ListItemText>
          </MenuItem>,
          <Divider key={1} component="li" />,
          renderMenuVisItem(2, "Timeline", VisualizationType.Timeline),
          renderMenuVisItem(
            3,
            "Chart",
            VisualizationType.Chart,
            panel.fields.length === 0,
          ),
          renderMenuVisItem(
            4,
            "Image",
            VisualizationType.Image,
            !supportsImage(panel.topicTypeName),
          ),
          renderMenuVisItem(
            5,
            "Map",
            VisualizationType.Map,
            !supportsMap(panel.topicTypeName),
          ),
          <Divider key={6} component="li" />,
        ]}
        <MenuItem onClick={handleSplit(SplitOrientation.Vertical)}>
          <ListItemIcon>
            <VerticalSplit />
          </ListItemIcon>
          <ListItemText>Split panel right</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSplit(SplitOrientation.Horizontal)}>
          <ListItemIcon>
            <HorizontalSplit />
          </ListItemIcon>
          <ListItemText>Split panel down</ListItemText>
        </MenuItem>
        <Divider component="li" />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Close />
          </ListItemIcon>
          <ListItemText>Close panel</ListItemText>
        </MenuItem>
      </Menu>
    </Stack>
  );
}
