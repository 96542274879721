import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { FullWidthContainer } from "~/components/FullWidthContainer";
import { Page } from "~/components/Page";
import { DigestionTopicTable } from "./components/DigestionTopicTable";

export function DigestionTopicsPage() {
  return (
    <Page title="Search Digestion Topics">
      <FullWidthContainer>
        <DataStoreBreadcrumbs />
        <DigestionTopicTable />
      </FullWidthContainer>
    </Page>
  );
}
