import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type { FieldValues } from "react-hook-form";
import type { StrictOmit } from "ts-essentials";
import type { z } from "zod";
import { pick } from "~/lib/std";
import type { DataStorePathGenerator } from "~/paths";
import { useNavigateDataStore } from "~/paths";
import { Error } from "../Error";
import { Loading } from "../Loading";
import { QueryRenderer } from "../QueryRenderer";
import { Form } from "./Form";
import type { UseStudioFormProps, UseStudioFormReturn } from "./hooks";
import { useStudioForm } from "./hooks";
import { getChangedFields } from "./utils";

export interface EditResourceFormProps<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
> extends Pick<
    UseStudioFormProps<z.ZodType<TFormValues, z.ZodTypeDef, unknown>>,
    "schema"
  > {
  resourceName: string;
  query: UseQueryResult<TResource>;
  mutation: UseMutationResult<{ data: TResource }, unknown, TRequest>;
  editableFields: ReadonlyArray<keyof TResource & string>;
  resourceLocation: DataStorePathGenerator;
  children: (
    control: UseStudioFormReturn<TFormValues>["control"],
  ) => React.ReactNode;
}

export function EditResourceForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
>({
  schema,
  resourceName,
  query,
  editableFields,
  mutation,
  resourceLocation,
  children,
}: EditResourceFormProps<TRequest, TFormValues, TResource>) {
  return (
    <Card>
      <CardContent>
        <QueryRenderer
          query={query}
          loading={<Loading type="circular" />}
          error={
            <Error>
              <Typography variant="h5" component="p">
                Error fetching {resourceName}
              </Typography>
            </Error>
          }
          success={(resource) => (
            <InnerForm
              schema={schema}
              resourceName={resourceName}
              resource={resource}
              editableFields={editableFields}
              mutation={mutation}
              resourceLocation={resourceLocation}
              // eslint-disable-next-line react/no-children-prop
              children={children}
            />
          )}
        />
      </CardContent>
    </Card>
  );
}

function InnerForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
>({
  schema,
  resourceName,
  resource,
  editableFields,
  mutation,
  resourceLocation,
  children,
}: StrictOmit<
  EditResourceFormProps<TRequest, TFormValues, TResource>,
  "query"
> & { resource: TResource }) {
  const navigateDataStore = useNavigateDataStore();

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema,
    defaultValues: pick(resource, editableFields) as any,
    onSubmit: function onSubmit(values: TFormValues) {
      const changedFields = getChangedFields(values, dirtyFields as any);

      mutation.mutate(changedFields as TRequest, {
        onSuccess() {
          enqueueSnackbar(`Updated ${resourceName}`, { variant: "success" });

          navigateDataStore(resourceLocation);
        },
        onError() {
          enqueueSnackbar(`Unable to update ${resourceName}`, {
            variant: "error",
          });
        },
      });
    } as any,
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={mutation.isLoading}
      submitText="Update"
    >
      {children(control)}
    </Form>
  );
}
