/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  HTTPValidationError,
  ObjectDataResponse,
  ObjectListResponse,
  ObjectStoreCreateRequest,
  ObjectStoreDataResponse,
  ObjectStoreListResponse,
  ObjectStoreUpdateRequest,
} from "../models";
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ObjectDataResponseFromJSON,
  ObjectDataResponseToJSON,
  ObjectListResponseFromJSON,
  ObjectListResponseToJSON,
  ObjectStoreCreateRequestFromJSON,
  ObjectStoreCreateRequestToJSON,
  ObjectStoreDataResponseFromJSON,
  ObjectStoreDataResponseToJSON,
  ObjectStoreListResponseFromJSON,
  ObjectStoreListResponseToJSON,
  ObjectStoreUpdateRequestFromJSON,
  ObjectStoreUpdateRequestToJSON,
} from "../models";

export interface CreateObjectStoreRequest {
  objectStoreCreateRequest: ObjectStoreCreateRequest;
}

export interface DeleteObjectStoreRequest {
  objectStoreId: string;
}

export interface FetchObjectRequest {
  objectKey: string;
  objectStoreId: string;
  redirect?: boolean | null;
  offset?: number | null;
  length?: number | null;
}

export interface FetchObjectStoreRequest {
  objectStoreId: string;
}

export interface ListObjectStoresRequest {
  bucketName?: string | null;
  accessKeyId?: string | null;
  regionName?: string | null;
  endpointUrl?: string | null;
  note?: string | null;
  noteLike?: string | null;
  disabled?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface ListObjectsRequest {
  objectStoreId: string;
  processing?: boolean | null;
  maxKeys?: number | null;
  prefix?: string | null;
  startAfter?: string | null;
  delimiter?: string | null;
  continuationToken?: string | null;
}

export interface UpdateObjectStoreRequest {
  objectStoreId: string;
  objectStoreUpdateRequest: ObjectStoreUpdateRequest;
}

export class ObjectStoreApi extends runtime.BaseAPI {
  async createObjectStore(
    this: ObjectStoreApi,
    requestParameters: CreateObjectStoreRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectStoreDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.objectStoreCreateRequest,
      "objectStoreCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/objectStores`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ObjectStoreCreateRequestToJSON(
          requestParameters.objectStoreCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectStoreDataResponseFromJSON(jsonValue);
  }

  async deleteObjectStore(
    this: ObjectStoreApi,
    requestParameters: DeleteObjectStoreRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(
      requestParameters.objectStoreId,
      "objectStoreId",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/objectStores/{object_store_id}`.replace(
          `{${"object_store_id"}}`,
          encodeURIComponent(String(requestParameters.objectStoreId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchObject(
    this: ObjectStoreApi,
    requestParameters: FetchObjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectDataResponse> {
    runtime.assertParamIsPresent(requestParameters.objectKey, "objectKey");

    runtime.assertParamIsPresent(
      requestParameters.objectStoreId,
      "objectStoreId",
    );

    const queryParameters: any = {};

    if (requestParameters.redirect != null) {
      queryParameters["redirect"] = requestParameters.redirect;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.length != null) {
      queryParameters["length"] = requestParameters.length;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/objectStores/{object_store_id}/objects/{object_key}`
          .replace(
            `{${"object_key"}}`,
            encodeURIComponent(String(requestParameters.objectKey)),
          )
          .replace(
            `{${"object_store_id"}}`,
            encodeURIComponent(String(requestParameters.objectStoreId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectDataResponseFromJSON(jsonValue);
  }

  async fetchObjectStore(
    this: ObjectStoreApi,
    requestParameters: FetchObjectStoreRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectStoreDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.objectStoreId,
      "objectStoreId",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/objectStores/{object_store_id}`.replace(
          `{${"object_store_id"}}`,
          encodeURIComponent(String(requestParameters.objectStoreId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectStoreDataResponseFromJSON(jsonValue);
  }

  async listObjectStores(
    this: ObjectStoreApi,
    requestParameters: ListObjectStoresRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectStoreListResponse> {
    const queryParameters: any = {};

    if (requestParameters.bucketName != null) {
      queryParameters["bucket_name"] = requestParameters.bucketName;
    }

    if (requestParameters.accessKeyId != null) {
      queryParameters["access_key_id"] = requestParameters.accessKeyId;
    }

    if (requestParameters.regionName != null) {
      queryParameters["region_name"] = requestParameters.regionName;
    }

    if (requestParameters.endpointUrl != null) {
      queryParameters["endpoint_url"] = requestParameters.endpointUrl;
    }

    if (requestParameters.note != null) {
      queryParameters["note"] = requestParameters.note;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.disabled != null) {
      queryParameters["disabled"] = requestParameters.disabled;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/objectStores`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectStoreListResponseFromJSON(jsonValue);
  }

  async listObjects(
    this: ObjectStoreApi,
    requestParameters: ListObjectsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectListResponse> {
    runtime.assertParamIsPresent(
      requestParameters.objectStoreId,
      "objectStoreId",
    );

    const queryParameters: any = {};

    if (requestParameters.processing != null) {
      queryParameters["processing"] = requestParameters.processing;
    }

    if (requestParameters.maxKeys != null) {
      queryParameters["max_keys"] = requestParameters.maxKeys;
    }

    if (requestParameters.prefix != null) {
      queryParameters["prefix"] = requestParameters.prefix;
    }

    if (requestParameters.startAfter != null) {
      queryParameters["start_after"] = requestParameters.startAfter;
    }

    if (requestParameters.delimiter != null) {
      queryParameters["delimiter"] = requestParameters.delimiter;
    }

    if (requestParameters.continuationToken != null) {
      queryParameters["continuation_token"] =
        requestParameters.continuationToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/objectStores/{object_store_id}/objects`.replace(
          `{${"object_store_id"}}`,
          encodeURIComponent(String(requestParameters.objectStoreId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectListResponseFromJSON(jsonValue);
  }

  async updateObjectStore(
    this: ObjectStoreApi,
    requestParameters: UpdateObjectStoreRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ObjectStoreDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.objectStoreId,
      "objectStoreId",
    );

    runtime.assertParamIsPresent(
      requestParameters.objectStoreUpdateRequest,
      "objectStoreUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/objectStores/{object_store_id}`.replace(
          `{${"object_store_id"}}`,
          encodeURIComponent(String(requestParameters.objectStoreId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: ObjectStoreUpdateRequestToJSON(
          requestParameters.objectStoreUpdateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return ObjectStoreDataResponseFromJSON(jsonValue);
  }
}
