/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface APIKey {
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  id: string;
  name: string;
  userId: string;
  disabled: boolean;
  secret: string | null;
}

export function APIKeyFromJSON(json: any): APIKey {
  return APIKeyFromJSONTyped(json);
}

export function APIKeyFromJSONTyped(json: any): APIKey {
  if (json == null) {
    return json;
  }

  return {
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    id: json["id"],
    name: json["name"],
    userId: json["user_id"],
    disabled: json["disabled"],
    secret: json["secret"],
  };
}

export function APIKeyToJSON(value: APIKey | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    id: value.id,
    name: value.name,
    user_id: value.userId,
    disabled: value.disabled,
    secret: value.secret,
  };
}
