import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { Page } from "~/components/Page";
import { ObjectStoreObjectDetails } from "./components/ObjectStoreObjectDetails";

export function ObjectStoreObjectPage() {
  return (
    <Page title="Object Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <ObjectStoreObjectDetails />
      </Container>
    </Page>
  );
}
