import React from "react";
import type { GridProps } from "@mui/material";
import { Grid, Typography } from "@mui/material";

export type DtProps = React.PropsWithChildren<unknown>;

export type DdProps = React.PropsWithChildren<unknown>;

export type DlGroupProps = React.PropsWithChildren<
  Omit<GridProps, "container" | "item" | "component">
>;

export type DlProps = React.PropsWithChildren<
  Omit<GridProps<"dl", { component: "dl" }>, "container" | "item" | "component">
>;

export function Dt({ children }: DtProps) {
  return (
    <Typography component="dt" fontWeight="bold" color="text.secondary">
      {children}
    </Typography>
  );
}

export function Dd({ children }: DdProps) {
  return <Typography component="dd">{children}</Typography>;
}

export function DlGroup({ children, ...props }: DlGroupProps) {
  return (
    <Grid {...props} item>
      {children}
    </Grid>
  );
}

export function Dl({ children, ...props }: DlProps) {
  return (
    <Grid {...props} container component="dl">
      {children}
    </Grid>
  );
}

export function renderDlGroup(
  dt: React.ReactNode,
  dd: React.ReactNode,
  groupProps: Omit<DlGroupProps, "children"> = {
    xs: 12,
    md: 6,
  },
) {
  return (
    <DlGroup {...groupProps}>
      <Dt>{dt}</Dt>
      <Dd>{dd}</Dd>
    </DlGroup>
  );
}
