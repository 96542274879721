import React, { useEffect, useMemo, useState } from "react";
import {
  AppBar,
  Drawer,
  IconButton,
  styled,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { ValueOf } from "ts-essentials";
import { createSafeContext } from "~/contexts";
import { useSessionStorage } from "~/hooks";
import { find } from "~/lib/std";

export const ScreenConfiguration = {
  Mobile: "mobile",
  Desktop: "desktop",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ScreenConfiguration = ValueOf<typeof ScreenConfiguration>;

export interface LayoutStateContextValue {
  isGlobalNavigationOpen: boolean;
  setIsGlobalNavigationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sideSheetState: string | null;
  setSideSheetState: React.Dispatch<React.SetStateAction<string | null>>;
}

export const [useLayoutStateContext, LayoutStateContext] =
  createSafeContext<LayoutStateContextValue>("LayoutState");

const DESKTOP_NAV_STATE_STORAGE_KEY = "desktop-global-nav-open";

export interface LayoutStateProviderProps {
  initialSideSheetState?: string;
  children: React.ReactNode;
}

// Must be rendered above the <Layout /> component. Allows maintaining
// layout state even if <Layout /> gets remounted in a given view.
// TODO: See if there are better ways of handling layout in app
export function LayoutStateProvider({
  initialSideSheetState,
  children,
}: LayoutStateProviderProps) {
  const isMobileConfiguration =
    useScreenConfiguration() === ScreenConfiguration.Mobile;

  // Global navigation defaults to open for desktop screen configuration. State
  // is synced with session storage to preserve it across view changes and page
  // refreshes.
  const [isDesktopGlobalNavOpen, setIsDesktopGlobalNavOpen] = useSessionStorage(
    DESKTOP_NAV_STATE_STORAGE_KEY,
    true,
    Boolean,
  );
  // Global navigation defaults to closed for mobile screen configuration.
  // Additionally, it's only kept in memory for mobile configuration since the
  // nav will be shown as a modal. It wouldn't be good UX for the nav to
  // be open on page refresh just because it was open prior to refresh
  const [isMobileGlobalNavOpen, setIsMobileGlobalNavOpen] = useState(false);

  const [sideSheetState, setSideSheetState] = useState(
    initialSideSheetState ?? null,
  );

  useEffect(
    function handleScreenConfigurationChange() {
      // Regardless of how the configuration changed, the mobile global nav
      // should always be closed. Especially import if the nav was open and the
      // user went from mobile -> desktop -> mobile: don't want the nav open
      // when they return to mobile configuration.
      setIsMobileGlobalNavOpen(false);

      if (isMobileConfiguration) {
        // Going from desktop -> mobile with the side sheet open should result
        // in it closing. Accomplishing this in an effect isn't ideal though as
        // it could cause a flash where the side sheet is open on screen only
        // to immediately close.
        setSideSheetState(null);
      }
    },
    [isMobileConfiguration],
  );

  let isGlobalNavigationOpen: LayoutStateContextValue["isGlobalNavigationOpen"];
  let setIsGlobalNavigationOpen: LayoutStateContextValue["setIsGlobalNavigationOpen"];
  if (isMobileConfiguration) {
    isGlobalNavigationOpen = isMobileGlobalNavOpen;
    setIsGlobalNavigationOpen = setIsMobileGlobalNavOpen;
  } else {
    isGlobalNavigationOpen = isDesktopGlobalNavOpen;
    setIsGlobalNavigationOpen = setIsDesktopGlobalNavOpen;
  }

  const value = useMemo(
    () => ({
      isGlobalNavigationOpen,
      setIsGlobalNavigationOpen,
      sideSheetState,
      setSideSheetState,
    }),
    [isGlobalNavigationOpen, setIsGlobalNavigationOpen, sideSheetState],
  );

  return (
    <LayoutStateContext.Provider value={value}>
      {children}
    </LayoutStateContext.Provider>
  );
}

const Root = styled("div")({
  flex: "1",
  minHeight: 0,
  display: "grid",
  gridTemplateAreas: `
    "nav header  header"
    "nav content sheet"
  `,
  gridTemplateColumns: "auto minmax(0, 1fr) auto",
  gridTemplateRows: "auto minmax(0, 1fr)",
});

const DESKTOP_NAVIGATION_OPEN_WIDTH_PX = 220;
const DESKTOP_NAVIGATION_CLOSED_WIDTH_PX = 64;
const DesktopNavigation = styled("div")(({ theme }) => ({
  gridArea: "nav",
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2, 1, 5),
  width: DESKTOP_NAVIGATION_CLOSED_WIDTH_PX,
  "&[data-open=true]": {
    width: DESKTOP_NAVIGATION_OPEN_WIDTH_PX,
  },
}));

const MobileNavigation = styled(Drawer)(({ theme }) => ({
  overflowX: "hidden",
  "& .MuiDrawer-paper": {
    width: DESKTOP_NAVIGATION_OPEN_WIDTH_PX,
    maxWidth: "80%",
    padding: theme.spacing(2, 1, 5),
  },
}));

const Header = styled(AppBar)({
  gridArea: "header",
});

const Content = styled("main")({
  gridArea: "content",
  display: "flex",
});

const SHEET_WIDTH_PX = 400;
const DesktopSheet = styled("div")(({ theme }) => ({
  gridArea: "sheet",
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  width: 0,
  "&[data-open=true]": {
    width: SHEET_WIDTH_PX,
    borderLeft: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 2, 5),
  },
}));

const MobileSheet = styled(Drawer)(({ theme }) => ({
  overflowX: "hidden",
  "& .MuiDrawer-paper": {
    width: SHEET_WIDTH_PX,
    maxWidth: "80%",
    padding: theme.spacing(2, 2, 5),
  },
}));

export interface LayoutProps {
  header: React.ReactNode;
  globalNavigation: React.ReactNode;
  sideSheet?: React.ReactNode;
  children: React.ReactNode;
}

export function Layout({
  header,
  globalNavigation,
  sideSheet,
  children,
}: LayoutProps) {
  const {
    isGlobalNavigationOpen,
    setIsGlobalNavigationOpen,
    sideSheetState,
    setSideSheetState,
  } = useLayoutStateContext();
  const isMobileConfiguration =
    useScreenConfiguration() === ScreenConfiguration.Mobile;

  const isSideSheetOpen = sideSheetState !== null;

  return (
    <Root>
      {isMobileConfiguration ? (
        <MobileNavigation
          anchor="left"
          variant="temporary"
          transitionDuration={0}
          open={isGlobalNavigationOpen}
          onClose={() => setIsGlobalNavigationOpen(false)}
        >
          {globalNavigation}
        </MobileNavigation>
      ) : (
        <DesktopNavigation data-open={isGlobalNavigationOpen}>
          {globalNavigation}
        </DesktopNavigation>
      )}
      <Header position="static">{header}</Header>
      <Content>{children}</Content>
      {isMobileConfiguration ? (
        <MobileSheet
          anchor="right"
          variant="temporary"
          transitionDuration={0}
          open={isSideSheetOpen}
          onClose={() => setSideSheetState(null)}
        >
          {sideSheet}
        </MobileSheet>
      ) : (
        <DesktopSheet data-open={isSideSheetOpen}>{sideSheet}</DesktopSheet>
      )}
    </Root>
  );
}

export interface SideSheetTriggerProps {
  title: string;
  sidebarId: string;
  icon: React.ReactNode;
}

export function SideSheetTrigger({
  title,
  sidebarId,
  icon,
}: SideSheetTriggerProps) {
  const { sideSheetState, setSideSheetState } = useLayoutStateContext();

  function handleClick() {
    setSideSheetState(sideSheetState === sidebarId ? null : sidebarId);
  }

  return (
    <Tooltip title={title}>
      <IconButton onClick={handleClick}>{icon}</IconButton>
    </Tooltip>
  );
}

export interface SidebarConfigItem {
  id: string;
  element: JSX.Element;
}

export interface SidebarSwitchProps {
  config: SidebarConfigItem[];
}

export function SidebarSwitch({ config }: SidebarSwitchProps) {
  const { sideSheetState } = useLayoutStateContext();

  if (sideSheetState === null) {
    return null;
  }

  // If a matching config item is found, render its element. Otherwise render
  // nothing (though that shouldn't be expected to happen).
  return find(config, { id: sideSheetState })?.element ?? null;
}

export function useScreenConfiguration(): ScreenConfiguration {
  const theme = useTheme();
  const isMobileConfiguration = useMediaQuery(theme.breakpoints.down("lg"), {
    noSsr: true,
  });

  return isMobileConfiguration
    ? ScreenConfiguration.Mobile
    : ScreenConfiguration.Desktop;
}
