import {
  CheckboxField,
  LogSelect,
  NewResourceForm,
  ObjectField,
  ObjectStoreSelect,
  processStateOptions,
  SelectField,
  TextField,
  WorkflowSelect,
} from "~/components/Form";
import { useCreateIngestion } from "~/domain/datastores";
import { makeIngestionLocation } from "~/paths";
import { ProcessState } from "~/services/datastore";
import { createIngestionSchema } from "../schemas";

export function IngestionCreateForm() {
  return (
    <NewResourceForm
      schema={createIngestionSchema}
      defaultValues={{
        name: null,
        logId: null,
        objectStoreId: null,
        objectKey: null,
        state: ProcessState.Ready,
        workflowId: null,
        workflowContext: null,
        locked: false,
        note: null,
        context: null,
      }}
      resourceName="ingestion"
      mutation={useCreateIngestion()}
      generateLocation={(response) =>
        makeIngestionLocation({ ingestionId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <LogSelect control={control} name="logId" required />
          <ObjectStoreSelect control={control} name="objectStoreId" />
          <TextField control={control} name="objectKey" />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewResourceForm>
  );
}
