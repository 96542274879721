import React from "react";
import { Stack, styled, useMediaQuery, useTheme } from "@mui/material";

const GridRoot = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: `
    "primary secondary"
  `,
  gridTemplateColumns: "minmax(0, 5fr) minmax(0, 2fr)",
  gap: theme.spacing(3),
}));

export interface DetailsLayoutProps {
  primaryGridColumn: React.ReactNode;
  secondaryGridColumn: React.ReactNode;
  stack: React.ReactNode;
}

export function DetailsLayout({
  primaryGridColumn,
  secondaryGridColumn,
  stack,
}: DetailsLayoutProps) {
  const theme = useTheme();
  const shouldDisplayAsGrid = useMediaQuery(theme.breakpoints.up("md"));

  if (shouldDisplayAsGrid) {
    return (
      <GridRoot>
        <Stack sx={{ gridArea: "primary" }} spacing={3} useFlexGap>
          {primaryGridColumn}
        </Stack>
        <Stack sx={{ gridArea: "secondary" }} spacing={3} useFlexGap>
          {secondaryGridColumn}
        </Stack>
      </GridRoot>
    );
  } else {
    return (
      <Stack spacing={3} useFlexGap>
        {stack}
      </Stack>
    );
  }
}
